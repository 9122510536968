import {
	AfterContentInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter, Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';

import { timer } from 'rxjs';
import { DOCUMENT } from '@angular/common';

/**
 * Компонент кастомного скроллбара.
 */
@Component({
	selector: 'app-custom-scroll',
	templateUrl: './custom-scroll.component.html',
	styleUrls: ['./custom-scroll.component.scss']
})

export class CustomScrollComponent implements OnInit, AfterContentInit, OnDestroy {

	// -----------------------------
	//  Input properties
	// -----------------------------
	/**
	 * Высота скроллбара.
	 */
	@Input()
	height: string | number = 'auto';

	/**
	 * Максимальная высота скроллбара.
	 */
	@Input()
	maxHeight: string | number = 'none';

	/**
	 * Всегда показывать скроллбар.
	 */
	@Input()
	alwaysShowScroll = false;

	/**
	 * Обновлять скроллбар по таймеру.
	 */
	@Input()
	timerUpdate = true;

	/**
	 * Малый ли размер скроллбара.
	 */
	@Input()
	isSmall = false;

	/**
	 * Класс для заголовка над прокручиваемым контентом.
	 */
	@Input()
	scrollHeader: string;

	/**
	 * Видимость кнопок прокрутки контента (вверх/вниз).
	 */
	@Input()
	buttonsVisible = true;

	/**
	 * Прокрутить ли контент до конца.
	 */
	@Input()
	scrollToEnd = false;

	// -----------------------------
	//  Output properties
	// -----------------------------
	/**
	 * Событие обновления скроллбара.
	 */
	@Output()
	readonly scrollUpdated: EventEmitter<boolean> = new EventEmitter();

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Таймер для обновления скроллбара.
	 * @private
	 */
	private timerSource;

	/**
	 * Подписка на таймер для обновления скроллбара.
	 * @private
	 */
	private timerSubscribe;

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Ссылка на элемент скроллбара.
	 */
	@ViewChild('customScroll', { static: false })
	customScroll: ElementRef;

	/**
	 * Флаг видимости кнопок прокрутки контента (вверх/вниз).
	 */
	showButtons = false;

	/**
	 * Активна ли кнопка прокрутки контента вверх.
	 */
	upButtonActive = false;

	/**
	 * Активна ли кнопка прокрутки контента вниз.
	 */
	downButtonActive = true;

	/**
	 * Текущая позиция прокрутки контента.
	 */
	scrollTop = 0;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {ChangeDetectorRef} cdr Сервис для отслеживания изменений.
	 * @param document Ссылка на DOM-документ.
	 */
	constructor(
		private readonly cdr: ChangeDetectorRef,
		@Inject(DOCUMENT) private readonly document: Document
	) {}

	/**
	 * Проверка активности кнопок прокрутки контента (вверх/вниз).
	 */
	checkForActive(): void {
		const nativeEl = this.customScroll.nativeElement;
		this.upButtonActive = !(nativeEl.scrollTop === 0);
		this.downButtonActive = !(nativeEl.scrollTop === (nativeEl.scrollHeight - nativeEl.offsetHeight));
		this.scrollTop = nativeEl.scrollTop;
	}

	/**
	 * Функция прокрутки контента до конца.
	 */
	scrollToBottom(): void {
		const tmr = timer(50)
			.subscribe(() => {
				(this.customScroll.nativeElement as HTMLDivElement).scrollTo(0, this.customScroll.nativeElement.scrollHeight);
				tmr.unsubscribe();
			});
	}

	/**
	 * Слушатель клика по кнопкам прокрутки контента (вверх/вниз).
	 *
	 * @param {number} scrollValue Величина прокрутки.
	 */
	onClickButtonHandler(scrollValue: number): void {
		this.customScroll.nativeElement.scrollBy(0, scrollValue);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Функция обновления скроллбара.
	 * @private
	 */
	private updateScrollButtons(): void {
		if (this.customScroll) {
			this.showButtons = (this.customScroll.nativeElement.scrollHeight > this.customScroll.nativeElement.clientHeight);
			this.checkForActive();
		}

		if (!!this.scrollHeader) {
			const headerElem: Element = this.document.getElementsByClassName(this.scrollHeader)[0];
			if (this.showButtons || this.alwaysShowScroll) {
				headerElem.classList.add(`${this.scrollHeader}_scrolled`);
			} else {
				headerElem.classList.remove(`${this.scrollHeader}_scrolled`);
			}
		}

		if (this.cdr) {
			this.cdr.detectChanges();
		}

		if (this.scrollToEnd) {
			this.scrollToBottom();
		}

		this.scrollUpdated.emit(this.showButtons);
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.updateScrollButtons();
		if (this.timerUpdate) {
			this.timerSource = timer(0, 250);
			this.timerSubscribe = this.timerSource.subscribe(() => this.updateScrollButtons());
		}
	}

	/**
	 * Метод жизненного цикла, вызывается после инициализации контента
	 */
	ngAfterContentInit(): void {
		this.updateScrollButtons();
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		if (this.timerUpdate && this.timerSubscribe) {
			this.timerSubscribe.unsubscribe();
		}
	}

}
