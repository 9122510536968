<div class="calendar-date-input-container"
	 data-cy="calendar-date-input-container"
>

	<!-- INPUT -->
	<div
		class="cdi-input"
		[class.cdi-input_date-selected]="!!value && canClearDate"
		data-cy="cdi-input"
		appCalendarDateInput
		[currentDate]="value"
		[selectedDate]="value"
		[calendarTheme]="calendarTheme"
		[topPadding]="topPadding"
		[leftPadding]="leftPadding"
		[absolutePaddings]="absolutePaddings"
		[showOverlay]="showOverlay"
		[showTitleText]="showTitleText"
		[disableDatesLaterThanCurrent]="disableDatesLaterThanCurrent"
		(dateChanged)="onDateChangedHandler($event)"
		>

		<!-- DATE -->
		<label *ngIf="value; else showPrompt"
			   class="cdi-input-title"
			   data-cy="cdi-input-title"
		>
			{{ value | date:DATE_TEMPLATE_DD_MM_YYYY }}
		</label>

		<!-- PROMPT -->
		<ng-template #showPrompt>
			<label class="prompt-title" data-cy="prompt-title">
				{{ (prompt ? prompt : 'calendar.select') | translate }}
			</label>
		</ng-template>
	</div>

	<!-- ICON -->
	<i
		class="material-icons select-date"
		data-cy="select-date"
		*ngIf="!value || !canClearDate; else showClearIcon"
		>
		insert_invitation
	</i>
	<ng-template #showClearIcon>
		<i
			class="material-icons clear-date"
			data-cy="clear-date"
			(click)="onClickClearHandler()"
			>
			cancel
		</i>
	</ng-template>

</div>
