import { IResponse } from '@app/core/net/http/api/types';

/**
 * Флаги отмены последней транзакции.
 * Значения флагов:
 * - {@link Auto_PrintError} – отмена транзакции в случае сбоя на этапе печати;
 * - {@link Auto_NoCSAnswer} – отмена из-за отсутствия ответа ЦС на запрос регистрации продажи;
 * - {@link Auto_PowerOff} – отмена всей транзакции после аварийного отключения питания терминала
 * в ходе выполнения транзакции регистрации продажи;
 * - {@link Unknown_1} - ???Виплата виграша 4???
 * - {@link Manual_NoMoney} – ручная отмена продавцом по причине отказа игрока от оплаты билетов;
 * - {@link Manual_PrintError} – ручная отмена продавцом по причине нераспечатки билета;
 * - {@link Manual_BadTickets} – ручная отмена продавцом по причине дефектов на распечатанных билетах.
 * - {@link Unknown_2} - ???отмена по штрихкоду???
 */
export enum CancelFlag {
	Auto_PrintError		= '1',
	Auto_NoCSAnswer		= '2',
	Auto_PowerOff		= '3',
	Unknown_1			= '4',
	Manual_NoMoney		= '11',
	Manual_PrintError	= '12',
	Manual_BadTickets	= '13',
	Unknown_2			= '21'
}

/**
 * Параметры транзакции для хранения в Storage-сервисе.
 */
export interface ITransactionParams {
	/**
	 * URL запроса на выполнение транзакции.
	 */
	url: string;
	/**
	 * Идентификатор транзакции.
	 */
	trans_id: string;
	/**
	 * Идентификатор пользователя.
	 */
	user_id: string;
	/**
	 * Идентификатор сессии.
	 */
	sess_id: string;
	/**
	 * Код игры.
	 */
	game_code: number;
}

/**
 * Интерфейс диапазона билетов.
 */
export interface ITicketRange {
	/**
	 * Количество билетов в диапазоне.
	 */
	count: number;
	/**
	 * Номера билетов в диапазоне.
	 */
	numbers: string;
	/**
	 * Сумма ставки в диапазоне.
	 */
	sum: number;
}

/**
 * Интерфейс серии билетов.
 */
export interface ITicketSerie {
	/**
	 * Название серии.
	 */
	name: string;
	/**
	 * Диапазоны билетов.
	 */
	range: Array<ITicketRange>;
	/**
	 * Цена билета.
	 */
	ticket_price: number;
}

export interface ITicketData {

	/**
	 * Маккод (баркод) билета, как строка десятичных цифр
	 */
	mac_code: string;

	/**
	 * Номер типографского бланка, как строка десятичных цифр
	 */
	blank_num: string;

	/**
	 * Маккод (баркод) типографского бланка, как строка десятичных цифр
	 */
	blank_mac_code: string;
}

/**
 * Интерфейс модели данных связанных с печатью билета.
 * Может содержать параметры: {@link id}, {@link bet_sum}, {@link description}, {@link ticket_templ_url}.
 */
export interface ITransactionTicket {

	/**
	 * Идентификатор билета.
	 */
	id: string;

	/**
	 * Сумма ставки на чеке.
	 */
	bet_sum: string;

	/**
	 * Описание билета.
	 */
	description: string;

	/**
	 * Частичная ссылка на шаблон билета.
	 */
	ticket_templ_url?: string;

	/**
	 * Штрихкод билета.
	 */
	mac_code?: string;

	/**
	 * Имя игры.
	 */
	game_name?: string;

	/**
	 * Серия (для стирачек)
	 */
	serie?: Array<ITicketSerie>;

	/**
	 * Ссылка на шаблон билета.
	 */
	url?: string;

	/**
	 * Номер тиража
	 */
	draw_num?: string;

	/**
	 * Дата и время регистрации билета
	 */
	regdate?: string;

	/**
	 * Дата и время тиража
	 */
	draw_date?: string;

	/**
	 * Номер билета
	 */
	ticket_num?: number | string;

	/**
	 * Количество билетов в чеке
	 */
	ticket_count?: number;

	/**
	 * Данные о билетах
	 */
	ticket_data?: Array<ITicketData>;

	/**
	 * Признак участия в акции
	 */
	action?: any;

	imageData?: string;
}

/**
 * Интерфейс для выделения, из ответа ЦС, модели обеспечивающей доступ к данным для печати билетов.
 * Может содержать параметры: {@link ticket}, {@link ticket_templ_url}
 */
export interface ITransactionResponse extends IResponse {

	/**
	 * Список билетов для текущей транзакции, которые необходимо распечатать.
	 */
	ticket: Array<ITransactionTicket>;

	/**
	 * Частичная ссылка на шаблон билета.
	 */
	ticket_templ_url?: string;

}
