import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CameraDevice} from "html5-qrcode";

@Component({
  selector: 'app-cameras-selector',
  templateUrl: './cameras-selector.component.html',
  styleUrls: ['./cameras-selector.component.scss']
})
export class CamerasSelectorComponent {

	@Input() cameras: Array<CameraDevice> = [];
	@Input() selectedCameraId: string = '';

	@Output() changeCamera: EventEmitter<string> = new EventEmitter<string>();

  	constructor() { }

	/**
	 * Функция для отслеживания изменений в массиве значений.
	 * @param index индекс элемента
	 * @param item элемент
	 */
	trackByFn = (index, item) => index;

	onCameraChange(event: Event): void {
		this.changeCamera.emit((event.target as HTMLSelectElement).value);
	}
}
