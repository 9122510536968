import { Component, Input } from '@angular/core';

/**
 * Модель информации о выигрышных категориях.
 */
export interface IWinCatView {
	/** Название выигрышной категории. */
	name: string;

	/** Сумма выигрыша по категории. */
	win_sum: string;

	/** Количество выигрышей (опционально). */
	win_cnt: string;
}

/**
 * Компонент отображения таблицы по выигрышным категориям.
 */
@Component({
	selector: 'app-win-category-table',
	templateUrl: './win-category-table.component.html',
	styleUrls: ['./win-category-table.component.scss']
})
export class WinCategoryTableComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------
	/**
	 * Список выигрышных категорий.
	 */
	@Input()
	winCategoryList: Array<IWinCatView>;

	/**
	 * Показывать ли кнопки прокрутки.
	 */
	@Input()
	scrollButtonsVisible = false;

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Функция отслеживания изменений в списке выигрышных категорий.
	 * @param index Индекс элемента.
	 * @param item Элемент.
	 */
	trackByWinCatFn = (index, item: IWinCatView) => index;

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------


}
