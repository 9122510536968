import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule, CustomPreloadingStrategy } from './app-routing.module';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { CalendarModule } from '@app/calendar/calendar.module';
import { TmlBmlInputModule } from '@app/tml-bml-input/tml-bml-input.module';
import { TopBarModule } from '@app/top-bar/top-bar.module';
import { HamburgerModule } from '@app/hamburger/hamburger.module';
import { CentralMenuModule } from '@app/central-menu/central-menu.module';
import { AppComponent } from './app.component';
import { TotalCheckModule } from '@app/total-check/total-check.module';
import { AppType } from '@app/core/services/store/settings';
import { environment } from '@app/env/environment';
import { HammerConfig } from '@app/util/hammer-config';
import {CameraModule} from "./modules/features/camera/camera.module";

/**
 * Фабрика для загрузки переводов.
 * @param http - http клиент
 * @constructor
 */
export const HttpLoaderFactory = (http: HttpClient): TranslateLoader => new TranslateHttpLoader(http, './assets/i18n/', '.json');

if (!environment.production) {
	environment.terminal = environment.terminal || (document.location.search.indexOf(`apptype=${AppType.ALTTerminal}`) > -1);
}

/**
 * Корневой модуль в приложении ALT.
 * Используется в качестве точки входа.
 */
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        TopBarModule,
        HamburgerModule,
        CentralMenuModule,
        HammerModule,
        TotalCheckModule.forRoot(),
        CalendarModule.forRoot(),
        TmlBmlInputModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CameraModule
    ],
	providers: [
		CustomPreloadingStrategy,
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: HammerConfig
		}
	],
	declarations: [
		AppComponent
	],
	bootstrap: [
		AppComponent
	]
})

export class AppModule {}
