import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import {
	CSDateToDateObject,
	CSDateToRusLocale,
	DATE_TEMPLATE_DD_MM_YYYY_HH_MM,
	DATE_TEMPLATE_DD_MM_YYYY_HH_MM_SS
} from '@app/util/utils';
import { IDrawingResult } from '@app/core/net/http/api/models/get-draw-results';
import { UpdateDrawInfoDraws } from '@app/core/net/http/api/models/update-draw-info';
import { IDrawButton } from '@app/shared/components/draws-buttons/draws-buttons-models';
import { calcTimeLeft } from '@app/shared/components/draws-buttons/draws-buttons-utils';

/**
 * Компонент отображения списка тиражей или результатов в виде желтых кнопок.
 */
@Component({
	selector: 'app-draws-buttons',
	templateUrl: './draws-buttons.component.html',
	styleUrls: ['./draws-buttons.component.scss']
})
export class DrawsButtonsComponent implements OnInit, OnDestroy {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Количество выводимых тиражей
	 */
	@Input()
	drawsToShow: number;

	/**
	 * Задать список тиражей с элементами типа {@link UpdateDrawInfoDraws}.
	 * @param value	Список тиражей.
	 */
	@Input()
	set draws(value: Array<UpdateDrawInfoDraws>) {
		let arr: Array<IDrawButton> = [];
		if (value) {
			arr = value
				.map((v, index) => {
					// const endSale = new Date(Date.now() + 1000 * 10 * (index + 1)); // TODO just for test - remove !!!
					const endSale = CSDateToDateObject(v.draw.sale_edate);

					const db: IDrawButton = {
						selected: false,
						number: v.draw.num,
						showDate: CSDateToRusLocale(v.draw.sale_edate, this.showSeconds),
						timeLeft: calcTimeLeft(endSale.getTime()),
						showTimeLeft: this.alwaysShowTimeLeft,
						jackpot: 'НЕТ ДАННЫХ',
						showJackpot: this.isVisibleJackpot,
						endSale: endSale.getTime(),
						isDrawActive: false
					};

					return db;
				})
				.sort((a, b) => a.endSale - b.endSale);

			// выбрать первый тираж
			if (arr.length > 0) {
				arr[0].selected = true;
			}

			// при частичном показе тиражей спрятать остаток в пул
			if (Number.isInteger(this.drawsToShow)) {
				this.drawsPool = arr.splice(this.drawsToShow);
			}
		}

		this.drawsList = arr;
	}

	/**
	 * Задать активный тираж.
	 *
	 * @param {UpdateDrawInfoDraws} drawData Данные тиража.
	 */
	@Input()
	set activeDraw(drawData: UpdateDrawInfoDraws) {
		if (!!drawData && !!this.drawsList) {
			const draw = this.drawsList.find(f => f.number === drawData.draw.num);
			if (draw) {
				this.drawsList.forEach(f => f.selected = false);
				draw.selected = true;
			}
		}
	}

	/**
	 * Задать список результатов типа {@link IDrawingResult}.
	 *
	 * @param {Array<IDrawingResult>} value Список результатов.
	 */
	@Input()
	set results(value: Array<IDrawingResult>) {
		if (value && Array.isArray(value)) {
			this.drawsList = value
				.map((v, i) => {
					const endSale = v ? new Date(v.drawing_date_begin) : new Date(0);

					return {
						selected: i === 0,
						number: v ? v.draw_name : '---',
						showDate: v ? CSDateToRusLocale(v.drawing_date_begin, this.showSeconds) : '',
						timeLeft: undefined,
						showTimeLeft: this.alwaysShowTimeLeft,
						jackpot: undefined,
						showJackpot: this.isVisibleJackpot,
						endSale: endSale.getTime(),
						isDrawActive: false
					};
				})
				.sort((a, b) => b.endSale - a.endSale);
		} else {
			this.drawsList = [];
		}
	}

	/**
	 * Параметр, который указывает на возможность отображения размера джекпота.
	 */
	@Input()
	isVisibleJackpot = false;

	/**
	 * Параметр, который указывает на возможность отображения оставшегося времени до начала тиража.
	 */
	@Input()
	isVisibleTimeLeft = true;

	/**
	 * Параметр, который указывает на возможность отображения оставшегося времени
	 * до начала тиража только для ближайшего тиража.
	 */
	@Input()
	isVisibleOnlyFirstDraw = true;

	/**
	 * Время автообновление компонента.
	 * По умолчанию - 1 секунда.
	 */
	@Input()
	autoUpdateTime = 1000;

	/**
	 * Признак, указывающий на необходимость отображать секунды на отсавшемся времени.
	 */
	@Input()
	showSeconds = false;

	/**
	 * Не скрывать просроченные тиражи (по умолчанию скрываются).
	 */
	@Input()
	dontHideDraws = false;

	/**
	 * Строка Осталось до...
	 */
	@Input()
	remainingString = 'draws-buttons.remaining';

	/**
	 * Строка Осталось до...
	 */
	@Input()
	alwaysShowTimeLeft = false;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие генерируется при выборе тиража.
	 * Возвращается индекс тиража из заданного списка тиражей.
	 */
	@Output()
	readonly action = new EventEmitter<number>();

	/**
	 * Событие, которое генерируется при отсутствии активных тиражей.
	 */
	@Output()
	readonly noActiveDraws = new EventEmitter();

	/**
	 * Событие, которое генерируется при выборе нового тиража.
	 */
	@Output()
	readonly newSelectedDraw = new EventEmitter<number>();

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Шаблон длинной записи даты.
	 */
	readonly longDate = DATE_TEMPLATE_DD_MM_YYYY_HH_MM_SS;

	/**
	 * Шаблон короткой записи даты.
	 */
	readonly shortDate = DATE_TEMPLATE_DD_MM_YYYY_HH_MM;

	/**
	 * Список кнопок с тиражами или результатами по тиражам.
	 */
	drawsList: Array<IDrawButton>;

	/**
	 * Пул тиражей.
	 */
	drawsPool: Array<IDrawButton>;

	/**
	 * Выбранный элемент типа {@link IDrawButton}.
	 */
	get selectedDraw(): IDrawButton | undefined {
		return Array.isArray(this.drawsList) ? this.drawsList.find(f => f.selected) : undefined;
	}

	/**
	 * Геттер индекса выбранного тиража.
	 */
	get selectedDrawIndex(): number {
		const draw = this.drawsList.find(f => f.selected);
		if (draw) {
			return this.drawsList.indexOf(draw);
		}

		return -1;
	}

	/**
	 * Сеттер индекса выбранного тиража.
	 * @param value индекс выбранного тиража.
	 */
	set selectedDrawIndex(value: number) {
		this.drawsList.forEach((v, i) => v.selected = i === value);
	}

	/**
	 * Геттер количества активных тиражей.
	 */
	get activeDrawCount(): number {
		return this.drawsList.filter(f => f.isDrawActive).length;
	}

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 * @private
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Функция для отслеживания изменений в массиве тиражей.
	 * @param index Индекс элемента в массиве.
	 * @param item Элемент массива.
	 */
	trackByDraw = (index, item: IDrawButton) => index;

	/**
	 * Обработчик выбора тиража.
	 * Задает по выбранному индексу свойство {@link IDrawButton.selected selected} и эмитит событие "action".
	 *
	 * @param event Передаваемое событие.
	 * @param {number} idx Индекс тиража в списке тиражей.
	 */
	onSelectedDrawHandler(event, idx: number): void {
		this.selectedDrawIndex = idx;
		this.action.emit(idx);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Обновить параметры отображения кнопок тиражей.
	 * @private
	 */
	private updateButtons(): void {
		// запомнить выбранный тираж
		const selectedItem = this.selectedDraw;
		const hasActiveBefore = !!this.drawsList.find(f => f.isDrawActive);

		// обновить параметры отображения оставшегося времени
		let countActive = 0;
		this.drawsList.forEach((v, i) => {
			const timeLeft = calcTimeLeft(v.endSale);
			const isDrawActive = timeLeft && timeLeft.value > 0;
			if (isDrawActive) {
				countActive++;
			}

			v.timeLeft = timeLeft;
			v.isDrawActive = isDrawActive;
			v.showTimeLeft = timeLeft
				&& (this.alwaysShowTimeLeft
					|| (this.isVisibleTimeLeft
						&& isDrawActive
						&& (countActive === 1 || (countActive > 1 && !this.isVisibleOnlyFirstDraw))
					)
				);
		});

		// если выбранный тираж стал неактивным, выбрать следующий активный тираж
		if (selectedItem && !selectedItem.isDrawActive) {
			const newSelection = this.drawsList.find(f => f.isDrawActive);
			if (newSelection) {
				// сгенерировать событие о новом выборе по таймеру
				this.selectedDrawIndex = this.drawsList.indexOf(newSelection);
				this.newSelectedDraw.emit(this.drawsList.indexOf(newSelection));
			} else {
				this.selectedDrawIndex = -1;
			}
		}

		// если не стало активных тиражей - сгенерировать событие "noActiveDraws"
		const hasActiveAfter = !!this.drawsList.find(f => f.isDrawActive);
		if (hasActiveBefore && !hasActiveAfter) {
			this.noActiveDraws.emit();

			//
			if (Array.isArray(this.drawsPool) && this.drawsPool.length > 0) {
				const dl = this.drawsPool.splice(0, 1);
				dl[0].selected = true;
				this.drawsList = dl;
			}
		}
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		// запустить таймер для автообновления времени на кнопках
		if (this.autoUpdateTime > 0) {
			timer(0, this.autoUpdateTime)
				.pipe(takeUntil(this.unsubscribe$$))
				.subscribe(this.updateButtons.bind(this));
		}
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

}
