import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LogOutButtonComponent } from '@app/logout/components/log-out-button/log-out-button.component';
import { LogOutService } from '@app/logout/services/log-out.service';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		LogOutButtonComponent
	],
	exports: [
		LogOutButtonComponent
	],
	entryComponents: [
	],
	providers: [
		LogOutService
	]
})
export class LogOutModule {}
