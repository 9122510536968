<div class="scanner-block" data-cy="scanner-block" id="scanner-block">
	<div class="scanner-component scanner-component_1 scanner-component_shown" data-cy="scanner-component">
		<div class="wrapper"
			 [class.hidden]="!active"
			 data-cy="wrapper-block"
		>
			<div class="bc-reader" id="reader"></div>
			<div *ngIf="error" class="scanner-error">{{ 'scanner.error' | translate }} <br> {{ error }}</div>
		</div>
		<app-cameras-selector *ngIf="(cameraService.cameras$ | async)?.length > 1"
							  class="cameras-selector"
							  [cameras]="cameraService.cameras$ | async"
							  (changeCamera)="onCameraChange($event)"
		></app-cameras-selector>
	</div>
</div>
