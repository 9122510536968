import { INavigationMenuItem } from '@app/shared/components/navigation-menu.component';
import { TerminalRoles } from '@app/core/services/store/operator';
import { URL_ACTIONS, URL_CHECK, URL_LOTTERIES, URL_OTHER, URL_REPORTING, URL_RESULTS, URL_TICKETS } from '@app/util/route-utils';
import { environment } from '@app/env/environment';

/**
 * Элемент центрального меню "ДРУГОЕ".
 * Добавляется в центральное меню и меню-гамбургер при наличии работающих отчетов и настроенной конфигурации.
 */
export const CENTRAL_MENU_ITEM_OTHER = {
	label: 'main_navigation.other',
	class: 'list-item',
	path: `/${URL_REPORTING}/${URL_OTHER}`,
	allowedRoles: [TerminalRoles.OPERATOR, TerminalRoles.RETAILER, TerminalRoles.CASHIER]
};

/**
 * Элемент центрального меню "ОТЧЕТЫ".
 * Добавляется в центральное меню и меню-гамбургер при наличии работающих отчетов и настроенной конфигурации.
 */
export const CENTRAL_MENU_ITEM_REPORT = {
	label: 'header.reports',
	class: 'list-item',
	path: `/${URL_REPORTING}`,
	allowedRoles: [TerminalRoles.OPERATOR, TerminalRoles.RETAILER, TerminalRoles.CASHIER, TerminalRoles.HEAD_RETAILER]
};

/**
 * Элемент меню-гамбургер "ОТМЕНА ПОСЛЕДНЕЙ ТРАНЗАКЦИИ".
 */
export const HAMBURGER_MENU_CANCEL_LAST = {
	label: 'header.cancel_transaction',
	class: 'cancel-last',
	// path: `/${URL_CANCEL_LAST}`,
	allowedRoles: [TerminalRoles.OPERATOR, TerminalRoles.CASHIER],
};

/**
 * Основной список центрального меню.
 */
export const MENU_LIST_CENTRAL = [
	{
		label: 'main_navigation.games',
		path: `/${URL_LOTTERIES}`,
		allowedRoles: [TerminalRoles.OPERATOR, TerminalRoles.CASHIER]
	},
	{
		label: 'main_navigation.tickets_checking',
		path: `/${URL_TICKETS}/${URL_CHECK}`,
		allowedRoles: [TerminalRoles.OPERATOR, TerminalRoles.RETAILER, TerminalRoles.CASHIER]
	},
	environment.enableActions
		? {
			label: 'main_navigation.actions',
			path: `/${URL_ACTIONS}`,
			allowedRoles: [TerminalRoles.OPERATOR, TerminalRoles.CASHIER]
		}
		: undefined,
	{
		label: 'main_navigation.results',
		path: `/${URL_RESULTS}`,
		allowedRoles: [TerminalRoles.OPERATOR]
	}
].filter(f => !!f);

/**
 * Основной список меню-гамбургер.
 */
const M_LIST_HAMBURGER = [
	{
		label: 'main_navigation.games',
		class: 'list-item',
		path: `/${URL_LOTTERIES}`,
		allowedRoles: [TerminalRoles.OPERATOR, TerminalRoles.CASHIER]
	}
];

if (environment.enableActions) {
	M_LIST_HAMBURGER.push({
		label: 'main_navigation.actions',
		class: 'list-item',
		path: `/${URL_ACTIONS}`,
		allowedRoles: [TerminalRoles.OPERATOR, TerminalRoles.CASHIER]
	});
}

/**
 * Полный список меню-гамбургер.
 */
export const MENU_LIST_HAMBURGER = [
	...M_LIST_HAMBURGER,
	{
		label: 'tickets.check_tickets',
		class: 'list-item',
		path: `/${URL_TICKETS}/${URL_CHECK}`,
		allowedRoles: [TerminalRoles.OPERATOR, TerminalRoles.RETAILER, TerminalRoles.CASHIER]
	},
	{
		label: 'main_navigation.results',
		class: 'list-item',
		path: `/${URL_RESULTS}`,
		allowedRoles: [TerminalRoles.OPERATOR]
	}
	// HAMBURGER_MENU_CANCEL_LAST
];


/**
 * Хранилище различных типов меню базирующихся на {@link INavigationMenuItem}.
 */
export class Menu {
	/**
	 * Список элементов меню-гамбургер.
	 */
	hamburgerList: Array<INavigationMenuItem> = MENU_LIST_HAMBURGER;
	/**
	 * Список элементов центрального меню.
	 */
	centralMenuList: Array<INavigationMenuItem> = MENU_LIST_CENTRAL;

	/**
	 * Возвращает первый видимый элемент центрального меню.
	 */
	get firstVisibleCentralMenuItem(): INavigationMenuItem | undefined {
		const arr = this.centralMenuList.filter(f => f.isVisible);

		return Array.isArray(arr) && arr.length > 0 ? arr[0] : undefined;
	}
}
