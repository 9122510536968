import { Component } from '@angular/core';
import { MslButtonComponent } from '@app/shared/components/msl-button.component';

/**
 * Кнопка закрытия диалогов.
 */
@Component({
	selector: 'app-close-button',
	templateUrl: './close-button.component.html',
	styleUrls: ['./close-button.component.scss']
})
export class CloseButtonComponent extends MslButtonComponent  {

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------



}
