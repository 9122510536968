import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, from, Observable } from 'rxjs';

import { calculateStringHash, DurationYear, secretHeader } from '@app/util/utils';

import { Report } from '@app/core/configuration/cs';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { IError } from '@app/core/error/types';
import { GetReportDataReq, GetReportDataResp } from '@app/core/net/http/api/models/get-report-data';
import { HttpService } from '@app/core/net/http/services/http.service';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { TransactionService } from '@app/core/services/transaction/transaction.service';
import { PrintService } from '@app/core/net/ws/services/print/print.service';
import { PrintData } from '@app/core/net/ws/api/models/print/print-models';
import { REPORT_TEMPLATE_CACHE_PREFIX, ResponseCacheService } from '@app/core/services/response-cache.service';
import { InputTag, ReportTag } from '@app/core/services/report/tags';
import { ReportParser } from '@app/core/services/report/template';
import { ReportViewType } from '@app/core/services/report/report';

import { ITmlBmlListItem } from '@app/tml-bml-input/interfaces/itml-bml-list-item';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders } from '@angular/common/http';

/**
 * Модель поля ввода для запроса отчета.
 */
export interface IReportField {

	/**
	 * Имя контрола на форме.
	 */
	controlName: string;

	/**
	 * Тег с меткой поля.
	 */
	labelTag: InputTag;

	/**
	 * Тег с типом поля.
	 */
	fieldTag: InputTag;

	/**
	 * Признак, указывающий на необходимость скрытия поля ввода.
	 */
	hidden?: boolean;

	/**
	 * Признак, указывающий на необходимость авто-фокуса на данном элементе ввода.
	 */
	autofocus?: boolean;

	/**
	 * Признак, указывающий на возможности использовании сканера штрих-кодов на данном элементе ввода.
	 */
	enableBarcodeInput?: boolean;

	/**
	 * Индекс для кастомной сортировки полей ввода.
	 * Позволяет отображать поля в определенных отчетах в нужной последовательности.
	 */
	index: number;

	/**
	 * Индекс для формирования запроса.
	 * Необходим для формирования правильной последовательности паарметров.
	 */
	inputIndex: number;

	/**
	 * Плейсхолдер для текстового поля
	 * Пока-что используется в отчетах для полей штрихкод первого билета и штрихкод последнего билета
	 */
	placeholder?: string;

}

/**
 * Перечень типов входных полей в отчетах.
 * Содержит:
 * - {@link DATE_EDIT} - поле ввода даты.
 * - {@link LINE_EDIT} - поле текстовой информации.
 * - {@link TICKET_LIST} - поле списка билетов.
 */
export enum ReportInputField {
	DATE_EDIT = 'dateedit',
	LINE_EDIT = 'lineedit',
	TICKET_LIST = 'iticketlist'
}

/**
 * Модель загруженного отчета с результатом парсинга.
 */
interface IReportResponse {
	/**
	 * Объект отчета.
	 */
	report: Report;
	/**
	 * Объект парсера.
	 */
	parser: ReportParser;
	/**
	 * Объект ошибки.
	 */
	error: IError;
}

/**
 * Перечень вариантов отчетов.
 * Значение варианта соответствует параметру ID из файла конфигурации.
 * - {@link Reports} - секция отчеты
 * - {@link PaperInstant}
 * - {@link OperatorShift}
 * - {@link Finance}
 */
export enum ReportsId {
	Reports			= 'reports',
	PaperInstant	= 'paper-instant-lottery',
	OperatorShift	= 'operator-shift',
	Finance			= 'finance'
}

/**
 * Сервис по загрузке (обновлению), обработке, хранению отчетов из ЦС.
 * Так-же используется для построения (печати, вывода на экран) отчетов.
 */
@Injectable({
	providedIn: 'root'
})
export class ReportsService {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Сеттер текущего выбранного типа отчета.
	 *
	 * @param {string} id Идентификатор операции отчета.
	 */
	set currentReportId(id: string) {
		this._currentReportId = id;
	}
	/**
	 * Геттер текущего выбранного типа отчета.
	 */
	get currentReportId(): string {
		return this._currentReportId;
	}

	// ---

	/**
	 * Сеттер операции отчета, по которой производится построение.
	 *
	 * @param {string} id Идентификатор операции отчета.
	 */
	set operationId(id: string) {
		this._operationId = id;
	}

	/**
	 * Геттер операции отчета, по которой производится построение.
	 */
	get operationId(): string {
		return this._operationId;
	}

	// ---
	/**
	 * Признак завершения загрузки шаблонов отчетов.
	 */
	readonly ready$ = new BehaviorSubject<boolean>(false);

	/**
	 * Последний загруженный необработанный контент по выбранному отчету.
	 */
	readonly lastReportResponse$$ = new BehaviorSubject<GetReportDataResp>(undefined);

	/**
	 * Строка с пропарсенными данными из {@link lastReportResponse$$} для показа на экране.
	 */
	readonly lastScreenData$ = new BehaviorSubject<string>('');

	/**
	 * Массив ответов по загруженным отчетам.
	 */
	reportResponses: Array<IReportResponse>;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Идентификатор операции отчета.
	 * @private
	 */
	private _operationId: string;

	/**
	 * Текущий выбранный идентификатор отчета.
	 * @private
	 */
	private _currentReportId: string;

	/**
	 * Результат построения отчета, пригодный для печати.
	 * @private
	 */
	private reportResult: string | Array<PrintData>;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {Router} router Сервис маршрутизации.
	 * @param {DialogContainerService} dialogInfoService Сервис диалоговых окон.
	 * @param {HttpService} httpService Сервис HTTP запросов.
	 * @param {TransactionService} transactionService Сервис транзакций.
	 * @param {AppStoreService} appStoreService Сервис хранилища приложения.
	 * @param {PrintService} printService Сервис печати.
	 * @param {ResponseCacheService} responseCacheService Сервис кэширования ответов.
	 * @param translateService Сервис локализации.
	 */
	constructor(
		private readonly router: Router,
		private readonly dialogInfoService: DialogContainerService,
		private readonly httpService: HttpService,
		private readonly transactionService: TransactionService,
		private readonly appStoreService: AppStoreService,
		private readonly printService: PrintService,
		private readonly responseCacheService: ResponseCacheService,
		private readonly translateService: TranslateService
	) {}

	/**
	 * Запускает процедуру обновления параметров отчетов по всем отчетам в конфигурационном
	 * файле полученном из ЦС.
	 * Структуру конфиг файла смотри {@link CsConfig}.
	 * Параметры отчетов синхронизируются с ЦС и сохраняются сторадже и памяти терминала.
	 *
	 * @returns {Promise<IError | void>}
	 */
	reload(): Promise<IError> { // | void
		this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.loading_report');

		return this.loadAllReports()
			.then(response => {
				this.reportResponses = response;
				const errorItem = response.find(f => !!f.error);

				if (errorItem) {
					Logger.Log.e('ReportsService', `Cant't parse report ${errorItem.report.id}`)
						.console();
				}

				return this.completeTemplateParsing(errorItem ? errorItem.error : undefined);
			});
	}

	/**
	 * Возвращает список отчетов, доступных на данном терминале по идентификатору отчета.
	 *
	 * @param {string} key Ключ (идентификатор из config.json), по которому будут найдены отчеты.
	 * @returns {Array<ReportTag>} Список отчетов.
	 */
	getReportsList(key: string): Array<ReportTag> {
		Logger.Log.i('ReportsService', `getReportsList -> get report list by ID: "${key}"`)
			.console();

		const rep = this.reportResponses.find(f => f.report.id === key);
		if (rep && rep.parser && rep.parser.reporting && rep.parser.reporting.reports) {
			Logger.Log.i('ReportsService',
				`was found ${rep.parser.reporting.reports.length} report(s) by ID: "${key}"`)
				.console();

			return rep.parser.reporting.reports;
		}

		Logger.Log.e('ReportsService', `getReportsList -> can't find reports by ID: "${key}"`)
			.console();

		return undefined;
	}

	/**
	 * Возвращает список полей (и типов) ввода, доступных для выбранной операции из выбранного отчета.
	 *
	 * @returns {Array<InputTag>} Типы ввода.
	 */
	getInputs(key?: string): Array<InputTag> {
		const rid = key ? key : this.currentReportId;
		const rep = this.reportResponses.find(f => f.report.id === rid);
		if (rep && rep.parser && rep.parser.reporting && rep.parser.reporting.reports) {
			const repInd = this.getReportIndex(this.operationId, rid);

			return rep.parser.reporting.reports[repInd].inputs;
		}
	}

	/**
	 * Загружает контент для выбранного отчета согласно указанным параметрам
	 * и подготавливает данные для отображения на терминале.
	 *
	 * @param {string} params Параметры для запроса отчета.
	 * @returns {Observable<boolean>} Возвращает наблюдаемый параметр с возможными
	 * значениями <code>true</code> (успешная загрузка) или <code>false</code> (ошибка при загрузке).
	 */
	getReportByParams(params: string): Observable<GetReportDataResp | undefined> {
		Logger.Log.i('ReportsService', `getReportByParams -> by ID: "${this.currentReportId}", params: ${params}`)
			.console();

		const repObject = this.appStoreService.Settings.esapReports.find(f => f.id === this.currentReportId);
		const getReportDataReq = new GetReportDataReq(
			this.appStoreService,
			this.operationId,
			params,
			repObject.requestAction,
			repObject.requestArgType,
			repObject.requestArgParams
		);

		this.transactionService.setLastUnCanceled();
		const result = this.httpService.sendApi(getReportDataReq);

		return from(result) as Observable<GetReportDataResp>;
	}

	/**
	 * Разбирает последний загруженные отчет и возвращает его в виде массива строк для печати.
	 * @param reportId Идентификатор отчета.
	 */
	parseLastLoadedReportToPrint(reportId?: string): Array<PrintData> {
		const rid = reportId ? reportId : this.currentReportId;
		Logger.Log.i('ReportsService', `parseLastLoadedReportToPrint -> ${rid}`)
			.console();

		return this.parseReport('printer', this.lastReportResponse$$.value, rid) as Array<PrintData>;
	}

	/**
	 * Метод для печати отчета.
	 * @param report Отчет для печати.
	 */
	printReport(report: Array<PrintData>): Promise<string> {
		return this.printService.printDocument(report);
	}

	/**
	 * Распечатать произвольный XML контент, используя базовый XML-парсер.
	 *
	 * @param {string} xmlTemplate Произвольный XML (строка), соответствующий базовым XML сценариям (отчеты).
	 * @param {string} data Данные для наполнения XML-шаблона.
	 * @param {number} count Количество копий (по умолчанию не задано, т.е. эквивалентно 1 копии).
	 */
	printXMLContentFromReport(xmlTemplate: string, data: string, count?: number): void {
		Logger.Log.i('ReportsService', `print XML report:\ncount: %s\n%s`, count, data)
			.console();

		const dom = new DOMParser();
		const parser = new ReportParser();
		const error = parser.parseTemplate(dom.parseFromString(xmlTemplate, 'text/xml'));

		if (!error) {
			this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.report_printing_wait_info');

			const printData = parser.formatReport(parser.reporting.reports[0], data, 'printer');
			this.printService.printDocument(printData as Array<PrintData>, undefined, count)
				.then(() => this.dialogInfoService.hideActive())
				.catch(err => {
					Logger.Log.e('ReportsService', `printXMLContent(): can't print XML data => %s`, err)
						.console();

					this.dialogInfoService.showOneButtonError(err, {
						click: () => {},
						text: 'dialog.dialog_button_continue'
					});
				});
		} else {
			Logger.Log.e('ReportsService', `printXMLContent(): parser error => %`, error)
				.console();
		}
	}

	/**
	 * Парсит контент отчета, загружаемый из ЦС, для указанного типа вывода (экран или принтер).
	 *
	 * @param {ReportViewType} type Тип вывода.
	 * @param {GetReportDataResp} content Ответ сервиса с контентом отчета.
	 * @param {string} reportId Id отчета из списка отчетов.
	 * @param {string} operationId Id операции (необязательный параметр)
	 * @returns {string | Array<PrintData>}
	 */
	parseReport(
		type: ReportViewType,
		content: GetReportDataResp,
		reportId: string,
		operationId?: string
	): string | Array<PrintData> {
		const rpId = reportId.endsWith('_graphical_bcs') ? reportId.substr(0, reportId.length - 14) : reportId;
		const rep = this.reportResponses.find(f => f.report.id === rpId);
		if (rep && rep.parser && rep.parser.reporting && rep.parser.reporting.reports) {
			const oid = operationId ? operationId : this._operationId;
			const idx = this.getReportIndex(oid, rpId);

			const cnt = content.report || content.finance_data;
			const report23Title = this.translateService.instant('reports.report_tickets_remaining');
			const report23Operator = this.translateService.instant('reports.operator');
			const report23Date = this.translateService.instant('reports.date');
			const report23Total = this.translateService.instant('reports.total');

			if (reportId.endsWith('_graphical_bcs')) {
				this.reportResult = rep.parser.formatReport22_2(rep.parser.reporting.reports[idx], cnt, type);
			} else {
				this.reportResult = rep.parser.formatReport(rep.parser.reporting.reports[idx], cnt, type,
					{report23Title, report23Operator, report23Date, report23Total});
			}

			if (typeof this.reportResult === 'string') {
				Logger.Log.i('ReportsService', 'report: %s', this.reportResult)
					.console();
				const dom = new DOMParser();
				const el = dom.parseFromString(this.reportResult, 'text/xml');
			}

			return this.reportResult;
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Возвращает индекс отчета в списке на основании идентификатора отчета.
	 *
	 * @param {string} id Идентификатора отчета.
	 * @param {string} key Ключ отчета из списка отчетов.
	 * @returns {number} Индекс отчета.
	 */
	private getReportIndex(id: string, key: string): number {
		const searchKey = key ? key : ReportsId.Reports;
		const rep = this.reportResponses.find(f => f.report.id === searchKey);
		if (rep && rep.parser && rep.parser.reporting && rep.parser.reporting.reports) {
			return rep.parser.reporting.reports.findIndex(p => p.id === id);
		}
	}

	/**
	 * Загрузить все шаблоны отчетов.
	 *
	 * @returns {Promise<Array<IReportResponse>>}
	 */
	private loadAllReports(): Promise<Array<IReportResponse>> {
		const arr = this.appStoreService.Settings.esapReports.map(m => this.getTemplateByUrl(m));

		return Promise.all(arr);
	}

	/**
	 * Загрузить шаблон отчета по ссылке.
	 * При загрузке сравнить версию. Если версия загруженного отчета оказалась выше, сохранить отчет в кеше.
	 *
	 * @param {Report} report Передаваемый объект с идентификатором отчета.
	 * @returns {Promise<IReportResponse>}
	 */
	private async getTemplateByUrl(report: Report): Promise<IReportResponse> {
		const key = `${REPORT_TEMPLATE_CACHE_PREFIX}${report.id}${calculateStringHash(report.config)}`;

		// загрузить отчет из кеша (если есть, если нету - по ссылке)
		let reportResponse = await this.loadByUrlAndParseContent(report, key);

		// сравнить версии. если загруженная версия выше - сохранить в кеше
		const v = parseInt(reportResponse.parser.reporting.version, 10);
		Logger.Log.i('ReportsService', `check cached version of report -> %s: %s vs %s`, report.id, report.version, v)
			.console();

		// reload report not only version upgraded, need reload if version downgrade or it's url changed, url stored in 'config' field
		if (!reportResponse.error && (reportResponse.report.config !== report.config || reportResponse.report.version !== v)) {
			Logger.Log.i('ReportsService', `version in cache is older then loaded, will cleanup and load a new version`)
				.console();

			// чистим кеш и грузим повторно
			await this.responseCacheService.cleanUp(key);
			reportResponse = await this.loadByUrlAndParseContent(report, key);
		} else {
			Logger.Log.i('ReportsService', `version in cache is the newest or the same`)
				.console();
		}

		return reportResponse;
	}

	/**
	 * Загрузить отчет и пропарсить полученный контент.
	 *
	 * @param {Report} report Передаваемый объект с идентификатором отчета.
	 * @param {string} key Ключ, по которому читается отчет из хранилища.
	 * @returns {Promise<IReportResponse>}
	 */
	private async loadByUrlAndParseContent(report: Report, key: string): Promise<IReportResponse> {
		Logger.Log.i('ReportsService', `load report by key: "${key}"`)
			.console();

		const lifetime = DurationYear * 10;
		const url = report.config;

		const content = await this.responseCacheService.httpGET(url, new HttpHeaders(secretHeader), key, lifetime);

		const dom = new DOMParser();
		const parser = new ReportParser();
		const error = parser.parseTemplate(dom.parseFromString(content, 'text/xml'));

		Logger.Log.i('ReportsService', `parsed report template: %s`, parser.reporting)
			.console();

		return {report, parser, error};
	}

	/**
	 * Завершение загрузки шаблонов отчетов.
	 *
	 * @param {IError} error Ошибка, если есть.
	 */
	private completeTemplateParsing(error: IError): IError {
		this.dialogInfoService.hideActive();
		this.ready$.next(true);

		if (Array.isArray(this.reportResponses)) {
			Logger.Log.i('ReportsService', 'All templates loaded: %s',
				this.reportResponses.map(m => [m.report, m.error]))
				.console();
		} else {
			Logger.Log.e('ReportsService', `All templates loaded but array is empty`)
				.console();
		}

		return error;
	}
}

// -----------------------------
//  Static functions
// -----------------------------

/**
 * Получить имя для {@link FormControl} для постройки реактивной формы.
 *
 * @param {InputTag} tag Поле, для которого будет строится элемент формы.
 * @returns {string} Строка, представляющая из себя комбинацию типа поля и его номера.
 */
export const getFieldControlName = (tag: InputTag): string => {
	if (tag) {
		return `${tag.type}-${tag.param_number}`;
	}

	Logger.Log.e('ReportsService', 'empty FormControl name')
		.console();

	return '';
};

/**
 * Возвращает значение по умолчанию для определенных типов полей.
 *
 * @param {IReportField} field Поле ввода.
 * @param {string} defaultLineValue Дефолтная инициализация строки.
 * @returns {string} Текущее значение для данного типа поля ввода.
 */
export const getDefaultValue = (field: IReportField, defaultLineValue?: string): string | Date | Array<ITmlBmlListItem> => {
	switch (field.fieldTag.type) {
		case ReportInputField.DATE_EDIT:
			return new Date();

		case ReportInputField.LINE_EDIT:
			return defaultLineValue ? defaultLineValue : '';

		case ReportInputField.TICKET_LIST:
			return [];

		default:
			return '';
	}
};
