import { INotification } from '@app/core/net/ws/api/types';

/**
 * Строковой идентификатор сервиса печати.
 */
export const PRINT_SERVICE_OWNER = 'ua.msl.periphery.printer_service';

/**
 * Таймаут ожидания ответа от сервиса печати.
 */
export const DEFAULT_PRINTER_TIMEOUT = 60 * 1000;

/**
 * Типы данных, которые можно отправить на печать.
 */
export type PrintData = Text | Format | Barcode | Image;

/**
 * Возможные способы выравнивания текста.
 */
export type AlignFormat = 'left' | 'right' | 'center';

/**
 * Возможное расположение числового баркода относительно штрих-кода.
 */
export type SignBarcode = 'none' | 'top' | 'bottom';

/**
 * Возможные состояния печатаемого документа.
 */
export type DocumentStatus = 'in-queue' | 'printing' | 'printComplete' | 'printError' | 'in_queue';

/**
 * Модель хранения и обработки данных (подготовке к печати) типа текста.
 * Смотри описание API Print Service
 */
export class Text {

	/**
	 * Тип данных, который будет отправлен на печать.
	 */
	readonly control = 'text';
	// readonly data: string;

	/**
	 * Конструктор класса.
	 * @param data Текст для печати.
	 */
	constructor(
		readonly data: string
	) {
		if (data) {
			this.data = data;
		} else {
			throw new Error('Text, value of data is undefined');
		}
	}
}

/**
 * Модель хранения и обработки данных (подготовке к печати) типа форматирования.
 * Смотри описание API Print Service
 */
export class Format {

	/**
	 * Название команды.
	 */
	readonly control = 'format';

	/**
	 * Выравнивание текста.
	 */
	align?: AlignFormat;

	/**
	 * Признак жирного шрифта.
	 */
	bold?: boolean;

	/**
	 * Ширина шрифта.
	 */
	fontWidth?: number;

	/**
	 * Расстояние между строками.
	 */
	spacing?: number;

	/**
	 * Конструктор класса.
	 * @param align Выравнивание текста.
	 * @param bold Признак жирного шрифта.
	 * @param fontWidth Ширина шрифта.
	 * @param spacing Расстояние между строками.
	 */
	constructor(
		align?: AlignFormat,
		bold?: string,
		fontWidth?: string,
		spacing?: string
	) {
		if (align) {
			this.align = align;
		}

		if (bold) {
			this.bold = bold === '1';
		}

		if (fontWidth) {
			const value = Number.parseInt(fontWidth, 10);
			if (!isNaN(value)) {
				this.fontWidth = value;
			} else {
				throw new Error(`Format, value of fontWidth is not number: ${fontWidth}`);
			}
		}

		if (spacing) {
			const value = Number.parseInt(spacing, 10);
			if (!isNaN(value)) {
				this.spacing = value === 0 ? -1 : value;
			} else {
				throw new Error(`Format, value of spacing is not number: ${spacing}`);
			}
		}
	}

	/**
	 * Проверка наличия различий между текущими данными и данными, сохраненными в объекте класса.
	 * @param format Объект класса Format.
	 */
	hasDifference(format: Format): boolean {
		let isChange = false;

		if (this.align) {
			if (format.align !== this.align) {
				format.align = this.align;
				isChange = true;
			} else {
				this.align = undefined;
			}
		}

		if (this.bold) {
			if (format.bold !== this.bold) {
				format.bold = this.bold;
				isChange = true;
			} else {
				this.bold = undefined;
			}
		}

		if (this.fontWidth) {
			if (format.fontWidth !== this.fontWidth) {
				format.fontWidth = this.fontWidth;
				isChange = true;
			} else {
				this.fontWidth = undefined;
			}
		}

		if (this.spacing) {
			if (format.spacing !== this.spacing) {
				format.spacing = this.spacing;
				isChange = true;
			} else {
				this.spacing = undefined;
			}
		}

		return isChange;
	}
}

/**
 * Модель хранения и обработки данных (подготовке к печати) типа штрих-кодов.
 * Смотри описание API Print Service
 */
export class Barcode {
	/**
	 * Название команды.
	 */
	readonly control = 'barcode';

	/**
	 * Данные для печати.
	 */
	data: string;

	/**
	 * Возможное расположение числового баркода относительно штрих-кода.
	 */
	sign?: SignBarcode;

	/**
	 * Высота штрих-кода.
	 */
	height?;

	/**
	 * Конструктор класса.
	 * @param data Данные для печати.
	 * @param sign Возможное расположение числового баркода относительно штрих-кода.
	 * @param height Высота штрих-кода.
	 */
	constructor(
		data: string,
		sign: string,
		height?: string
	) {
		if (sign) {
			if (sign === '0') {
				this.sign = 'none';
			}
			if (sign === '1') {
				this.sign = 'top';
			}
		}
		if (data) {
			this.data = data;
		} else {
			throw new Error('Image, value of data is undefined');
		}
		if (height) {
			const value = Number.parseInt(height, 10);
			if (!isNaN(value)) {
				this.height = value;
			} else {
				throw new Error(`Image, value of height is not number: ${height}`);
			}
		}
	}
}

/**
 * Модель хранения и обработки данных (подготовке к печати) типа картинок.
 * Смотри описание API Print Service.
 */
export class Image {

	/**
	 * Название элемента.
	 */
	readonly control = 'image';

	/**
	 * Данные для печати.
	 */
	data: string;

	/**
	 * Ключ для поиска картинки.
	 */
	key: string;

	/**
	 * Признак вставки картинки в текст.
	 */
	inline?: boolean;

	/**
	 * Ширина картинки.
	 */
	width?: number;

	/**
	 * Конструктор класса.
	 * @param data Данные для печати.
	 * @param key Ключ для поиска картинки.
	 * @param width Ширина картинки.
	 * @param inline Признак вставки картинки в текст.
	 */
	constructor(
		data: string,
		key: string,
		width?: string,
		inline?: string
	) {
		if (inline) {
			this.inline = inline === 'inline';
		}

		if (data) {
			this.data = data;
		} else {
			throw new Error('Image, value of data is undefined');
		}

		if (key) {
			this.key = key;
		} else {
			throw new Error('Image key is undefined');
		}

		if (width) {
			const value = Number.parseInt(width, 10);
			if (!isNaN(value)) {
				this.width = value;
			} else {
				throw new Error(`Image, value of width is not number: ${width}`);
			}
		}
	}
}

/**
 * Список типов нотификаций, предоставляемых API.
 */
export enum PrinterEvents {
	/**
	 * Событие, возникающее при изменении состояния принтера.
	 */
	StateChanged		= 'stateChanged',
	/**
	 * Событие, возникающее при изменении состояния документа.
	 */
	DocStatusChanged	= 'docStatusChanged'
}

/**
 * Возможные состояния принтера
 */
export enum PrinterState {
	/**
	 * Принтер подключен
	 */
	Connected			= 'connected', // ////???
	/**
	 * Принтер отключен
	 */
	Disconnected		= 'disconnected', // ///??
	/**
	 * Принтер готов
	 */
	OnLine				= 'on-line',
	/**
	 * Принтер не готов
	 */
	OffLine				= 'off-line',
	/**
	 * Принтер занят
	 */
	Busy				= 'busy'
}

/**
 * Модель уведомлений, смотри описание API Print Service - {@link PrinterEvents.StateChanged stateChanged}
 */
export interface StateChanged extends INotification {
	/**
	 * Текущее состояние принтера
	 */
	state: PrinterState;
	/**
	 * Предыдущее состояние принтера
	 */
	prevState: PrinterState;
}

/**
 * Модель уведомлений, смотри описание API Print Service - {@link PrinterEvents.DocStatusChanged docStatusChanged}
 */
export interface DocStatusChanged extends INotification {
	/**
	 * Идентификатор документа
	 */
	docId: string;
	/**
	 * Статус документа
	 */
	docStatus: DocumentStatus;
	/**
	 * Код ошибки
	 */
	errorCode?: number;
	/**
	 * Описание ошибки
	 */
	errorDesc?: string;
}

/**
 * Информация о принтере
 */
export interface PrinterInfo {
	/**
	 * Ширина бумаги
	 */
	paperWidth: number;
	/**
	 * Количество пикселей на линию
	 */
	pixelsPerLine: number;
	/**
	 * Количество символов на линию
	 */
	charsPerLine: number;
}
