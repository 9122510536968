/**
 * Mock-список билетов для бумажных моментальных лотерей.
 */
export const BML_LIST = [
	{
		bcFrom: {
			barcode: '0662542857001006',
			gameNumber: '0662',
			intGameNumber: 662,
			ticketPackage: '542857',
			intTicketPackage: 542857,
			ticketNumber: '001',
			intTicketNumber: 1,
			verificationCode: '006'
		},
		bcTo: {
			barcode: '0662542857500006',
			gameNumber: '0662',
			intGameNumber: 662,
			ticketPackage: '542857',
			intTicketPackage: 542857,
			ticketNumber: '500',
			intTicketNumber: 500,
			verificationCode: '006'
		},
		draws: {
			draw: {
				bet_sum: '49.55',
				code: '1725',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '2000000.00'
				},
				dr_bdate: '2018-01-30T18:56:01',
				dr_edate: '2018-01-30T18:56:01',
				inst_lott_name: 'ЧОРНА ПЕРЛИНА',
				num: 'Серія 01',
				sale_bdate: '2018-01-30T17:00:38',
				sale_edate: '2022-01-31T17:00:00',
				serie_code: '662',
				serie_name: 'Серія 01',
				serie_num: 'Серія 01',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '20',
				win_bdate: '2018-01-30T17:00:38',
				win_edate: '2024-01-30T17:00:00'
			}
		}
	},
	{
		bcFrom: {
			barcode: '0660025661255769',
			gameNumber: '0660',
			intGameNumber: 660,
			ticketPackage: '025661',
			intTicketPackage: 25661,
			ticketNumber: '255',
			intTicketNumber: 255,
			verificationCode: '769'
		},
		bcTo: {
			barcode: '0660025661255769',
			gameNumber: '0660',
			intGameNumber: 660,
			ticketPackage: '025661',
			intTicketPackage: 25661,
			ticketNumber: '255',
			intTicketNumber: 255,
			verificationCode: '769'
		},
		draws: {
			draw: {
				bet_sum: '49.55',
				code: '1732',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '2000000.00'
				},
				dr_bdate: '2018-02-01T16:06:29',
				dr_edate: '2018-02-01T16:06:29',
				inst_lott_name: 'Золото ацтеків',
				num: 'Серія 01',
				sale_bdate: '2018-02-01T15:00:24',
				sale_edate: '2022-05-03T16:00:00',
				serie_code: '660',
				serie_name: 'Серія 01',
				serie_num: 'Серія 01',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '16',
				win_bdate: '2018-02-01T15:00:24',
				win_edate: '2024-01-30T16:00:00'
			}
		}
	},
	{
		bcFrom: {
			barcode: '0661782553885294',
			gameNumber: '0661',
			intGameNumber: 661,
			ticketPackage: '782553',
			intTicketPackage: 782553,
			ticketNumber: '885',
			intTicketNumber: 885,
			verificationCode: '294'
		},
		bcTo: {
			barcode: '0661782553885294',
			gameNumber: '0661',
			intGameNumber: 661,
			ticketPackage: '782553',
			intTicketPackage: 782553,
			ticketNumber: '885',
			intTicketNumber: 885,
			verificationCode: '294'
		},
		draws: {
			draw: {
				bet_sum: '19.65',
				code: '1734',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '500000.00'
				},
				dr_bdate: '2018-02-01T16:59:40',
				dr_edate: '2018-02-01T16:59:40',
				inst_lott_name: 'КОЛЕСО УДАЧІ',
				num: 'Серія 01',
				sale_bdate: '2018-02-01T16:00:00',
				sale_edate: '2022-01-31T16:52:00',
				serie_code: '661',
				serie_name: 'Серія 01',
				serie_num: 'Серія 01',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '15',
				win_bdate: '2018-02-01T16:00:00',
				win_edate: '2024-02-28T16:53:00'
			}
		}
	},
	{
		bcFrom: {
			barcode: '0660883295599998',
			gameNumber: '0660',
			intGameNumber: 660,
			ticketPackage: '883295',
			intTicketPackage: 883295,
			ticketNumber: '599',
			intTicketNumber: 599,
			verificationCode: '998'
		},
		bcTo: {
			barcode: '0660883295599998',
			gameNumber: '0660',
			intGameNumber: 660,
			ticketPackage: '883295',
			intTicketPackage: 883295,
			ticketNumber: '599',
			intTicketNumber: 599,
			verificationCode: '998'
		},
		draws: {
			draw: {
				bet_sum: '49.55',
				code: '1732',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '2000000.00'
				},
				dr_bdate: '2018-02-01T16:06:29',
				dr_edate: '2018-02-01T16:06:29',
				inst_lott_name: 'Золото ацтеків',
				num: 'Серія 01',
				sale_bdate: '2018-02-01T15:00:24',
				sale_edate: '2022-05-03T16:00:00',
				serie_code: '660',
				serie_name: 'Серія 01',
				serie_num: 'Серія 01',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '16',
				win_bdate: '2018-02-01T15:00:24',
				win_edate: '2024-01-30T16:00:00'
			}
		}
	},
	{
		bcFrom: {
			barcode: '0663708532039622',
			gameNumber: '0663',
			intGameNumber: 663,
			ticketPackage: '708532',
			intTicketPackage: 708532,
			ticketNumber: '039',
			intTicketNumber: 39,
			verificationCode: '622'
		},
		bcTo: {
			barcode: '0663708532039622',
			gameNumber: '0663',
			intGameNumber: 663,
			ticketPackage: '708532',
			intTicketPackage: 708532,
			ticketNumber: '039',
			intTicketNumber: 39,
			verificationCode: '622'
		},
		draws: {
			draw: {
				bet_sum: '19.65',
				code: '1735',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '500000.00'
				},
				dr_bdate: '2018-02-01T16:59:45',
				dr_edate: '2018-02-01T16:59:45',
				inst_lott_name: 'Твій сейф',
				num: 'Серія 11',
				sale_bdate: '2018-02-01T16:00:41',
				sale_edate: '2022-01-31T16:00:00',
				serie_code: '663',
				serie_name: 'Серія 11',
				serie_num: 'Серія 11',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '14',
				win_bdate: '2018-02-01T16:00:41',
				win_edate: '2024-01-30T16:54:00'
			}
		}
	},
	{
		bcFrom: {
			barcode: '0663837706534712',
			gameNumber: '0663',
			intGameNumber: 663,
			ticketPackage: '837706',
			intTicketPackage: 837706,
			ticketNumber: '534',
			intTicketNumber: 534,
			verificationCode: '712'
		},
		bcTo: {
			barcode: '0663837706534712',
			gameNumber: '0663',
			intGameNumber: 663,
			ticketPackage: '837706',
			intTicketPackage: 837706,
			ticketNumber: '534',
			intTicketNumber: 534,
			verificationCode: '712'
		},
		draws: {
			draw: {
				bet_sum: '19.65',
				code: '1735',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '500000.00'
				},
				dr_bdate: '2018-02-01T16:59:45',
				dr_edate: '2018-02-01T16:59:45',
				inst_lott_name: 'Твій сейф',
				num: 'Серія 11',
				sale_bdate: '2018-02-01T16:00:41',
				sale_edate: '2022-01-31T16:00:00',
				serie_code: '663',
				serie_name: 'Серія 11',
				serie_num: 'Серія 11',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '14',
				win_bdate: '2018-02-01T16:00:41',
				win_edate: '2024-01-30T16:54:00'
			}
		}
	},
	{
		bcFrom: {
			barcode: '0662649110873897',
			gameNumber: '0662',
			intGameNumber: 662,
			ticketPackage: '649110',
			intTicketPackage: 649110,
			ticketNumber: '873',
			intTicketNumber: 873,
			verificationCode: '897'
		},
		bcTo: {
			barcode: '0662649110873897',
			gameNumber: '0662',
			intGameNumber: 662,
			ticketPackage: '649110',
			intTicketPackage: 649110,
			ticketNumber: '873',
			intTicketNumber: 873,
			verificationCode: '897'
		},
		draws: {
			draw: {
				bet_sum: '49.55',
				code: '1725',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '2000000.00'
				},
				dr_bdate: '2018-01-30T18:56:01',
				dr_edate: '2018-01-30T18:56:01',
				inst_lott_name: 'ЧОРНА ПЕРЛИНА',
				num: 'Серія 01',
				sale_bdate: '2018-01-30T17:00:38',
				sale_edate: '2022-01-31T17:00:00',
				serie_code: '662',
				serie_name: 'Серія 01',
				serie_num: 'Серія 01',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '20',
				win_bdate: '2018-01-30T17:00:38',
				win_edate: '2024-01-30T17:00:00'
			}
		}
	},
	{
		bcFrom: {
			barcode: '0660834108706555',
			gameNumber: '0660',
			intGameNumber: 660,
			ticketPackage: '834108',
			intTicketPackage: 834108,
			ticketNumber: '706',
			intTicketNumber: 706,
			verificationCode: '555'
		},
		bcTo: {
			barcode: '0660834108706555',
			gameNumber: '0660',
			intGameNumber: 660,
			ticketPackage: '834108',
			intTicketPackage: 834108,
			ticketNumber: '706',
			intTicketNumber: 706,
			verificationCode: '555'
		},
		draws: {
			draw: {
				bet_sum: '49.55',
				code: '1732',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '2000000.00'
				},
				dr_bdate: '2018-02-01T16:06:29',
				dr_edate: '2018-02-01T16:06:29',
				inst_lott_name: 'Золото ацтеків',
				num: 'Серія 01',
				sale_bdate: '2018-02-01T15:00:24',
				sale_edate: '2022-05-03T16:00:00',
				serie_code: '660',
				serie_name: 'Серія 01',
				serie_num: 'Серія 01',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '16',
				win_bdate: '2018-02-01T15:00:24',
				win_edate: '2024-01-30T16:00:00'
			}
		}
	},
	{
		bcFrom: {
			barcode: '0661692702119515',
			gameNumber: '0661',
			intGameNumber: 661,
			ticketPackage: '692702',
			intTicketPackage: 692702,
			ticketNumber: '119',
			intTicketNumber: 119,
			verificationCode: '515'
		},
		bcTo: {
			barcode: '0661692702119515',
			gameNumber: '0661',
			intGameNumber: 661,
			ticketPackage: '692702',
			intTicketPackage: 692702,
			ticketNumber: '119',
			intTicketNumber: 119,
			verificationCode: '515'
		},
		draws: {
			draw: {
				bet_sum: '19.65',
				code: '1734',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '500000.00'
				},
				dr_bdate: '2018-02-01T16:59:40',
				dr_edate: '2018-02-01T16:59:40',
				inst_lott_name: 'КОЛЕСО УДАЧІ',
				num: 'Серія 01',
				sale_bdate: '2018-02-01T16:00:00',
				sale_edate: '2022-01-31T16:52:00',
				serie_code: '661',
				serie_name: 'Серія 01',
				serie_num: 'Серія 01',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '15',
				win_bdate: '2018-02-01T16:00:00',
				win_edate: '2024-02-28T16:53:00'
			}
		}
	},
	{
		bcFrom: {
			barcode: '0660403136224476',
			gameNumber: '0660',
			intGameNumber: 660,
			ticketPackage: '403136',
			intTicketPackage: 403136,
			ticketNumber: '224',
			intTicketNumber: 224,
			verificationCode: '476'
		},
		bcTo: {
			barcode: '0660403136224476',
			gameNumber: '0660',
			intGameNumber: 660,
			ticketPackage: '403136',
			intTicketPackage: 403136,
			ticketNumber: '224',
			intTicketNumber: 224,
			verificationCode: '476'
		},
		draws: {
			draw: {
				bet_sum: '49.55',
				code: '1732',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '2000000.00'
				},
				dr_bdate: '2018-02-01T16:06:29',
				dr_edate: '2018-02-01T16:06:29',
				inst_lott_name: 'Золото ацтеків',
				num: 'Серія 01',
				sale_bdate: '2018-02-01T15:00:24',
				sale_edate: '2022-05-03T16:00:00',
				serie_code: '660',
				serie_name: 'Серія 01',
				serie_num: 'Серія 01',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '16',
				win_bdate: '2018-02-01T15:00:24',
				win_edate: '2024-01-30T16:00:00'
			}
		}
	},
	{
		bcFrom: {
			barcode: '0661429530961640',
			gameNumber: '0661',
			intGameNumber: 661,
			ticketPackage: '429530',
			intTicketPackage: 429530,
			ticketNumber: '961',
			intTicketNumber: 961,
			verificationCode: '640'
		},
		bcTo: {
			barcode: '0661429530961640',
			gameNumber: '0661',
			intGameNumber: 661,
			ticketPackage: '429530',
			intTicketPackage: 429530,
			ticketNumber: '961',
			intTicketNumber: 961,
			verificationCode: '640'
		},
		draws: {
			draw: {
				bet_sum: '19.65',
				code: '1734',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '500000.00'
				},
				dr_bdate: '2018-02-01T16:59:40',
				dr_edate: '2018-02-01T16:59:40',
				inst_lott_name: 'КОЛЕСО УДАЧІ',
				num: 'Серія 01',
				sale_bdate: '2018-02-01T16:00:00',
				sale_edate: '2022-01-31T16:52:00',
				serie_code: '661',
				serie_name: 'Серія 01',
				serie_num: 'Серія 01',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '15',
				win_bdate: '2018-02-01T16:00:00',
				win_edate: '2024-02-28T16:53:00'
			}
		}
	},
	{
		bcFrom: {
			barcode: '0663964829643761',
			gameNumber: '0663',
			intGameNumber: 663,
			ticketPackage: '964829',
			intTicketPackage: 964829,
			ticketNumber: '643',
			intTicketNumber: 643,
			verificationCode: '761'
		},
		bcTo: {
			barcode: '0663964829643761',
			gameNumber: '0663',
			intGameNumber: 663,
			ticketPackage: '964829',
			intTicketPackage: 964829,
			ticketNumber: '643',
			intTicketNumber: 643,
			verificationCode: '761'
		},
		draws: {
			draw: {
				bet_sum: '19.65',
				code: '1735',
				data: {
					bet_sum_max: '22500.00',
					bet_sum_min: '100.00',
					max_win_sum: '500000.00'
				},
				dr_bdate: '2018-02-01T16:59:45',
				dr_edate: '2018-02-01T16:59:45',
				inst_lott_name: 'Твій сейф',
				num: 'Серія 11',
				sale_bdate: '2018-02-01T16:00:41',
				sale_edate: '2022-01-31T16:00:00',
				serie_code: '663',
				serie_name: 'Серія 11',
				serie_num: 'Серія 11',
				status: 'Продажа и выплата выигрышей',
				status_code: 'SALES AND PAYS',
				version: '14',
				win_bdate: '2018-02-01T16:00:41',
				win_edate: '2024-01-30T16:54:00'
			}
		}
	}
];
