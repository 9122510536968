<div class="central-menu-container"
	 data-cy="central-menu-container"
>
	<ul class="central-menu"
		(click)="onClickMenuItemHandler($event)"
		data-cy="central-menu"
	>
		<ng-container *ngFor="let menu of menuItems; trackBy: trackByFn; let i = index">
			<li
				*ngIf="menu.isVisible"
				class="central-menu__item"
				[class]="'central-menu__item_no' + i + ' central-menu__item_role_' + currentUserRole"
				[class.central-menu__item_active]="menu.isActive"
				[class.central-menu__item_fixed]="appStoreService.operator.value?.isOperator"
				[value]="i"
				data-cy="central-menu-item"
			>
				{{ menu.label | translate }}
			</li>
		</ng-container>
	</ul>
</div>
