<div class="draws-buttons-container"
	 data-cy="draws-buttons-container"
>

	<!-- КНОПКИ -->
	<div class="dbc-draws"
		 [class.dbc-draws_one]="drawsList?.length === 1"
		 data-cy="dbc-draws"
	>
		<ng-container *ngFor="let draw of drawsList; trackBy: trackByDraw; let i = index">
			<button *ngIf="draw.isDrawActive || dontHideDraws"
				type="button"
				class="button dbc-draw"
				[ngClass]="draw.selected ? 'button_theme_yellow' : 'dbc-draw_gold'"
				data-cy="dbc-draw"
				(click)="onSelectedDrawHandler($event, i)"
				>

				<!-- НОМЕР ТИРАЖА -->
				<div class="dbc-number"
					 data-cy="dbc-number"
				>
					{{ draw.number }}
				</div>

				<!-- ДАННЫЕ ПО ТИРАЖУ -->
				<div class="dbc-data"
					 data-cy="dbc-data"
				>

					<!-- ДЖЕКПОТ -->
					<div class="dbc-jackpot"
						 *ngIf="draw.showJackpot"
						 data-cy="dbc-jackpot"
					>
						{{ 'lottery.jackpot' | translate }}&nbsp;{{ draw.jackpot }}
					</div>

					<!-- ДАТА -->
					<div class="dbc-date"
						 data-cy="dbc-date"
					>
						{{ draw.showDate }}
					</div>

					<!-- ОСТАВШЕЕСЯ ВРЕМЯ -->
					<div
						class="dbc-time-left"
						*ngIf="draw.showTimeLeft"
						data-cy="dbc-time-left"
						>
						<div class="dbc-time-left_1" innerHTML="{{ remainingString | translate:{ time: draw.timeLeft?.value } }}"></div>
						<div class="dbc-time-left_2">{{ draw.timeLeft?.type | translate }}</div>
					</div>

				</div>
			</button>
		</ng-container>
	</div>
</div>
