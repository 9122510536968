import { Profile, ProfileKey } from '@app/util/profile';
import { Stack } from '@app/util/stack';
import { DurationYear, loadImage, secretHeader } from '@app/util/utils';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { ITransactionResponse, ITransactionTicket } from '@app/core/services/transaction/transaction-types';

import { Print } from '@app/tickets-print/parser/print';
import { Tag } from '@app/tickets-print/parser/tag';
import { ConvertTag } from '@app/tickets-print/parser/tags/convert';
import { ImgTag } from '@app/tickets-print/parser/tags/img';
import { ParamTag } from '@app/tickets-print/parser/tags/param';
import { SetTag } from '@app/tickets-print/parser/tags/set';
import { BarcodeUnit } from '@app/tickets-print/parser/units/barcode';
import { ConditionsUnit } from '@app/tickets-print/parser/units/conditions';
import { LineUnit } from '@app/tickets-print/parser/units/line';
import { IStackParams, SectionUnit } from '@app/tickets-print/parser/units/section';
import { PrintData, PrinterInfo } from '@app/core/net/ws/api/models/print/print-models';
import { PRINT_TEMPLATE_CACHE_PREFIX, ResponseCacheService } from '@app/core/services/response-cache.service';

import { IGetParam } from '@app/tickets-print/parser/iget-param';
import { IFlowUnit } from '@app/tickets-print/parser/iflow-unit';
import { ITransactionTicketTemplate } from '@app/tickets-print/interfaces/itransaction-ticket-template';
import { HttpHeaders } from '@angular/common/http';
import { QRTag } from '@app/tickets-print/parser/tags/qr';
import QRCode from 'qrcode';
import { from, Observable, of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { GetImageReq, GetImageResp } from '@app/core/net/http/api/models/get-image';
import { HttpService } from '@app/core/net/http/services/http.service';

/**
 * Версия QR-кода.
 */
const QR_VERSION = 9;

/**
 * Высота строки для печати.
 */
const PRINT_LINE_HEIGHT = 24;

/**
 * Модель реализующая функционал подготовке билета к печати на основе
 * шаблона билета и ответа на регистрацию лотереи в ЦС.
 */
export class Template implements IGetParam {

	/**
	 * Регулярное выражение для парсинга индекса в массиве.
	 * @private
	 */
	private readonly index = new RegExp(/\[\d+\]/);

	/**
	 * Регулярное выражение для парсинга параметра в массиве.
	 * @private
	 */
	private readonly indexParam = new RegExp(/\[(\d+)\]/);

	/**
	 * Стек параметров секций.
	 * @private
	 */
	private readonly sectionsStack: Stack<IStackParams> = new Stack<IStackParams>();

	/**
	 * Стек потока данных.
	 * @private
	 */
	private readonly flowStack: Stack<IFlowUnit> = new Stack<IFlowUnit>();

	/**
	 * Объект для трансформации готового шаблона билета в формат данных для печати.
	 * @private
	 */
	private readonly print: Print;

	/**
	 * Билет для регистрации в ЦС.
	 * @private
	 */
	private ticketData: any;

	/**
	 * Ответа на регистрацию лотереи в ЦС.
	 * @private
	 */
	private buyResponse: any;

	/**
	 * Параметры QR-кода.
	 * @private
	 */
	private qrs: {[key: string]: any} = {};

	/**
	 * Внеэкранный холст для рисования QR-кода.
	 * @private
	 */
	private offscreenCanvas: HTMLCanvasElement;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор.
	 *
	 * @param {AppStoreService} appStoreService Сервис хранилища приложения.
	 * @param {LotteryGameCode} code Код игры.
	 * @param {ResponseCacheService} responseCacheService Сервис кэширования ответов.
	 * @param httpService Сервис для работы с http запросами.
	 */
	constructor(
		private readonly appStoreService: AppStoreService,
		private readonly code: LotteryGameCode,
		private readonly responseCacheService: ResponseCacheService,
		private readonly httpService: HttpService
	) {
		this.offscreenCanvas = document.createElement('canvas');
		this.offscreenCanvas.height = PRINT_LINE_HEIGHT;
		this.print = new Print(this.appStoreService, code, responseCacheService);
	}

	/**
	 * Обрабатывает (форматирует) шаблон билета согласно API (для шаблона билета)
	 * и данных в ответе на регистрацию лотереи в ЦС.
	 * Затем преобразовывает готовый (заполненный) шаблон билета к виду, требуемого для печати.
	 *
	 * @param {ITransactionResponse} response Ответ на регистрацию лотереи в ЦС.
	 * @param {ITransactionTicket} ticket Модель билета из ответа.
	 * @param {ITransactionTicketTemplate} template Шаблон билета.
	 * @param isBlank Признак печати бланка
	 * @returns {Promise<Array<PrintData>>} Данные для печати.
	 */
	formatTicket(
		response: ITransactionResponse,
		ticket: ITransactionTicket,
		template: ITransactionTicketTemplate,
		isBlank = false
	): Observable<Array<PrintData>> {
		Profile.startCheckPoint(ProfileKey.GetTicketTemplate);
		this.ticketData = ticket;
		this.buyResponse = response;

		const sPrinterInfo = localStorage.getItem('PRINTER_INFO');
		const printerInfo: PrinterInfo = sPrinterInfo ? JSON.parse(sPrinterInfo) : null;
		let media = printerInfo?.paperWidth === 80 ? 'alt/usb' : 'PNG_384';
		if (isBlank && media === 'PNG_384') {
			media = 'TEXT_32';
		}
		const keyPart = printerInfo?.paperWidth || 58;
		const url = `${template.url}?media=${media}&ver=2`;
		// const url = `/esap/templates/zabava-ticket-0-utf8-28.xml`;
		const key = `${PRINT_TEMPLATE_CACHE_PREFIX}${keyPart}_${template.path}`;
		const duration = DurationYear * 10;

		let doc: Document;

		return from(this.responseCacheService.httpGET(url, new HttpHeaders(secretHeader), key, duration))
			.pipe(
				concatMap(content => {
					Profile.stopCheckPoint(ProfileKey.GetTicketTemplate);
					Profile.startCheckPoint(ProfileKey.ParseTicketTemplate);
					const parser = new DOMParser();
					doc = parser.parseFromString(content, 'text/xml');

					// вывести в лог ошибки парсинга XML
					const parserError = doc.getElementsByTagName('parsererror');
					if (parserError && parserError.length > 0) {
						Logger.Log.e('Template', 'invalid XML template: %s', parserError[0].textContent)
							.console();
					}
					// Обработать все теги <qrmap>
					const qrMaps = doc.getElementsByTagName('qrmap');

					return qrMaps.length ? from(Array.prototype.slice.call(qrMaps, 0)) : of(null);
				}),
				concatMap(this.processQRMapTag.bind(this)),
				concatMap(() => {
					// запустить парсер шаблона
					this.parseTemplate(doc.childNodes);
					Profile.stopCheckPoint(ProfileKey.ParseTicketTemplate);

					return from(this.print.formatPrintData());
				})
			);
	}

	// -----------------------------
	//  IGetParam interface
	// -----------------------------

	/**
	 * Враппер для получения параметров при обработке шаблона билета.
	 *
	 * @param {string} name Имя параметра.
	 * @returns {any} Объект связанный с данным именем.
	 */
	getParam(name: string): any {
		try {
			return this.unsafeGetParam(name);
		} catch (e) {
			return;
		}
	}

	/**
	 * Возвращает текущую секцию, для поиска параметров.
	 *
	 * @returns {any}
	 */
	getSectionParam(): any {
		if (!this.sectionsStack.isEmpty()) {
			const sectionParam = this.sectionsStack.peek();
			Logger.Log.d('Template', 'getSectionParam, stack peek: %s', sectionParam)
				.console();

			if (sectionParam && sectionParam.section_root) {
				return sectionParam.section_root;
			}
		} else {
			return this.getGlobalParams();
		}
	}

	/**
	 * Возвращает корневой элемент ответа ЦС, для поиска параметров.
	 *
	 * @returns {any}
	 */
	getGlobalParams(): any {
		return this.ticketData;
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Парсит шаблон билета согласно API.
	 *
	 * @param {NodeList} list Список узлов, которые необходимо распарсить.
	 */
	private parseTemplate(list: NodeList): void {
		for (let i = 0; i < list.length; i++) {
			const node = list.item(i);

			switch (node.nodeType) {
				case Node.TEXT_NODE: // 3
				case Node.CDATA_SECTION_NODE: // 4
					if (!this.flowStack.isEmpty()) {
						const unit = this.flowStack.peek();
						if (unit instanceof LineUnit || unit instanceof BarcodeUnit) {
							unit.setText(node.nodeValue);
						}
					}

					break;

				case Node.ELEMENT_NODE: // 1
					this.processTag(node);
					break;

				default:
					break;
			}
		}
	}

	/**
	 * Обрабатывает xml теги и создает структуры для хранения и обработки содержимого и атрибутов данных тегов.
	 *
	 * @param {Node} node Текущий узел (тег).
	 */
	private processTag(node: Node): void {
		switch (node.nodeName) {
			case Tag.PARAM:
				this.processParamTag(node);
				break;
			case Tag.IMG:
				this.processImgTag(node);
				break;
			case Tag.LINE:
				this.processLineTag(node);
				break;
			case Tag.SECTION:
				this.processSectionTag(node);
				break;
			case Tag.CONVERT:
				this.processConvertTag(node);
				break;
			case Tag.SET:
				this.processSetTag(node);
				break;
			case Tag.IF:
				this.processIfTag(node);
				break;
			case Tag.ELIF:
				this.processElifTag(node);
				break;
			case Tag.THEN:
				this.processThenTag(node);
				break;
			case Tag.ELSE:
				this.processElseTag(node);
				break;
			case Tag.ENDIF:
				this.processEndifTag(node);
				break;
			case Tag.BARCODE:
				this.processBarcodeTag(node);
				break;
			case Tag.QR:
				this.processQRTag(node);
				break;
			default:
				if (node.hasChildNodes()) {
					this.parseTemplate(node.childNodes);
				}
		}
	}

	/**
	 * Получения параметров при обработке шаблона билета.
	 *
	 * @param {string} name Имя параметра.
	 * @returns {any} Объект связанный с данным именем.
	 */
	private unsafeGetParam(name: string): any {
		const params = name.split('.');

		let value;
		for (let i = 0; i < params.length; i++) {
			if (i === 0) {
				value = this.index.test(params[0])
					? this.getIndexParam(params[0])
					: this.getBaseParam(params[0]);
			} else {
				if (value !== undefined) {
					this.index.test(params[i])
						? value = this.getIndexParam(params[i], value)
						: (
							Array.isArray(value)
								? value = value[0][params[i]]
								: value = value[params[i]]
						);
				}
			}
		}

		return value === undefined ? '' : value;
	}

	/**
	 * Поиск объекта, относительно родительского, в теле ответа, ЦС на регистрацию ставки,
	 * на основании имени параметра и текущей секции и родительского объекта.
	 *
	 * @param {string} param Имя искомого параметра в родительском объекте.
	 * @param base Родительский объект.
	 * @returns {any} Объект связанный с данным именем.
	 */
	private getIndexParam(param: string, base?: any): any {
		const params = param.split(this.indexParam, 2);
		const value = base ? base[params[0]] : this.getBaseParam(params[0]);

		if (value && Array.isArray(value)) {
			const index = Number.parseInt(params[1], 10);
			if (!Number.isNaN(index)) {
				return value[index];
			}
		}

		return;
	}

	/**
	 * Поиск объекта в теле ответа, ЦС на регистрацию ставки, на основании имени параметра и текущей секции.
	 *
	 * @param {string} name Имя параметра.
	 * @returns {any} Объект связанный с данным именем.
	 */
	private getBaseParam(name: string): any {
		let property;
		if (!this.sectionsStack.isEmpty()) {
			const sectionParam = this.sectionsStack.peek();
			Logger.Log.d('Template', 'getBaseParam, stack peek: %s', sectionParam)
				.console();

			if (sectionParam) {

				if (sectionParam.section_root) {
					property = Object.getOwnPropertyDescriptor(sectionParam.section_root, name);
					if (property) {
						return property.value;
					}
				}

				property = Object.getOwnPropertyDescriptor(sectionParam, name);
				if (property) {
					return property.value;
				}
			}
		}

		property = Object.getOwnPropertyDescriptor(this.ticketData, name);
		if (property) {
			return property.value;
		}

		property = Object.getOwnPropertyDescriptor(this.buyResponse, name);
		if (property) {
			return property.value;
		}
	}

	/**
	 * Обрабатывает xml тег <code><param></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processParamTag(node: Node): void {
		if (this.flowStack.isEmpty()) {
			throw new Error('processParamTag, flowStack is empty');
		}

		const unit = this.flowStack.peek();
		if (unit instanceof LineUnit || unit instanceof BarcodeUnit) {
			const param = new ParamTag(this, node['attributes']);
			// unit.setParam(new XMLSerializer().serializeToString(node));
			unit.setParam(param.format());
		}
	}

	/**
	 * Обрабатывает xml тег <code><img></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processImgTag(node: Node): void {
		if (this.flowStack.isEmpty()) {
			throw new Error('processImgTag, flowStack is empty');
		}

		const unit = this.flowStack.peek();
		if (unit instanceof LineUnit) {
			const img = new ImgTag(this, node['attributes']);
			if (img.getPath() !== null && img.getPath() !== undefined) {
				unit.setImage(img);
			} else {
				console.log('My_log img == undefined');
			}
		}
	}

	/**
	 * Обрабатывает xml тег <code><line></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processLineTag(node: Node): void {
		const unit = new LineUnit(this.print.printTags, node['attributes']);
		if (node.hasChildNodes()) {
			this.flowStack.push(unit);
			this.parseTemplate(node.childNodes);
			this.flowStack.pop()
				.clean();
		}
	}

	/**
	 * Обрабатывает xml тег <code><section></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processSectionTag(node: Node): void {
		Logger.Log.d('Template', 'start section tag, stack is: %s', this.sectionsStack)
			.console();

		const unit = new SectionUnit(this.sectionsStack, this, node['attributes']);
		if (node.hasChildNodes()) {
			this.flowStack.push(unit);
			while (unit.next()) {
				this.parseTemplate(node.childNodes);
			}
			this.flowStack.pop()
				.clean();
		}

		Logger.Log.d('Template', 'end section tag: %s', this.sectionsStack)
			.console();
	}

	/**
	 * Обрабатывает xml тег <code><set></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processSetTag(node: Node): void {
		Logger.Log.d('Template', 'start processSetTag')
			.console();

		const set = new SetTag(this, node['attributes']);

		Logger.Log.d('Template', 'end processSetTag: %s', this.ticketData)
			.console();
	}

	/**
	 * Обрабатывает xml тег <code><convert></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processConvertTag(node: Node): void {
		Logger.Log.d('Template', 'start processConvertTag')
			.console();

		const convert = new ConvertTag(this, node['attributes']);

		Logger.Log.d('Template', 'end processConvertTag: %s', this.ticketData)
			.console();
	}

	/**
	 * Обрабатывает xml тег <code><if></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processIfTag(node: Node): void {
		const unit = new ConditionsUnit();
		unit.currentTag = Tag.IF;
		unit.test(this, node['attributes']);
		this.flowStack.push(unit);
	}

	/**
	 * Обрабатывает xml тег <code><elif></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processElifTag(node: Node): void {
		if (this.flowStack.isEmpty()) {
			throw new Error('processElifTag, flowStack is empty');
		}

		const unit = this.flowStack.peek();
		if (unit instanceof ConditionsUnit) {
			if (!unit.isFinished) {
				if (unit.currentTag === Tag.IF || unit.currentTag === Tag.ELIF) {
					unit.currentTag = Tag.ELIF;
					unit.test(this, node['attributes']);
				} else {
					throw new Error(`processElifTag, current tag: ${unit.currentTag}`);
				}
			}
		}
	}

	/**
	 * Обрабатывает xml тег <code><then></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processThenTag(node: Node): void {
		if (this.flowStack.isEmpty()) {
			throw new Error('processThenTag, flowStack is empty');
		}

		const unit = this.flowStack.peek();
		if (unit instanceof ConditionsUnit) {
			if (!unit.isFinished && unit.condition) {
				if (unit.currentTag === Tag.IF || unit.currentTag === Tag.ELIF) {
					unit.currentTag = Tag.THEN;
					if (node.hasChildNodes()) {
						this.parseTemplate(node.childNodes);
					}
					unit.isFinished = true;
				} else {
					throw new Error(`processThenTag, current tag: ${unit.currentTag}`);
				}
			}
		}
	}

	/**
	 * Обрабатывает xml тег <code><else></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processElseTag(node: Node): void {
		if (this.flowStack.isEmpty()) {
			throw new Error('processElseTag, flowStack is empty');
		}

		const unit = this.flowStack.peek();
		if (unit instanceof ConditionsUnit) {
			if (!unit.isFinished && !unit.condition) {
				if (unit.currentTag === Tag.IF || unit.currentTag === Tag.ELIF) {
					unit.currentTag = Tag.ELSE;
					if (node.hasChildNodes()) {
						this.parseTemplate(node.childNodes);
					}
					unit.isFinished = true;
				} else {
					throw new Error(`processElseTag, current tag: ${unit.currentTag}`);
				}
			}
		}
	}

	/**
	 * Обрабатывает xml тег <code><endif></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега
	 */
	private processEndifTag(node: Node): void {
		if (this.flowStack.isEmpty()) {
			throw new Error('processEndifTag, flowStack is empty');
		}

		const unit = this.flowStack.peek();
		if (unit instanceof ConditionsUnit) {
			if (unit.currentTag === Tag.IF
				|| unit.currentTag === Tag.ELIF
				|| unit.currentTag === Tag.THEN
				|| unit.currentTag === Tag.ELSE
			) {
				unit.currentTag = Tag.ENDIF;
				this.flowStack
					.pop()
					.clean();
			} else {
				throw new Error(`processEndifTag, current tag: ${unit.currentTag}`);
			}
		}
	}

	/**
	 * Обрабатывает xml тег <code><barcode></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processBarcodeTag(node: Node): void {
		const unit = new BarcodeUnit(this.print.printTags, node['attributes']);
		if (node.hasChildNodes()) {
			this.flowStack.push(unit);
			this.parseTemplate(node.childNodes);
			this.flowStack.pop()
				.clean();
		}
	}

	/**
	 * Обрабатывает xml тег <code><qrmap></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processQRMapTag(node: Node): Observable<void> {
		if (!node) {
			return of(null);
		}

		const params = node['attributes'];

		return new Observable<void>(observer => {
			if (params['key']) {
				if (params['value'] || (params['v-param'] && params['v-fmt'])) {
					let dataToEncode = '';
					if (params['value']) {
						dataToEncode = params['value'].value;
					} else if (params['v-param'] && params['v-fmt']) {
						dataToEncode = params['v-fmt'].value.replace('%s', this.ticketData[params['v-param'].value]);
					}
					const moduleSize = params['module-size']?.value || 4;
					const margin = params['frame-size']?.value || 16;
					const frameSize = Math.floor(margin / moduleSize);
					const qrModulesTotal = (QR_VERSION - 1) * 4 + 21;
					this.offscreenCanvas.width = qrModulesTotal * moduleSize + margin * 2;

					QRCode.toCanvas(dataToEncode,
						{
							version: QR_VERSION,
							margin: frameSize,
							scale: moduleSize,
							errorCorrectionLevel: params['corr'] || 'H'
						}, (error, canvas) => {
							if (error) {
								console.error(error);
								observer.error(error);
							} else {
								this.qrs[params['key'].value] = canvas;
								observer.next();
							}
							observer.complete();
						});
				}
			} else {
				observer.error();
				observer.complete();
			}
		}).pipe(
			concatMap(() => {
				if (!params['img-id']) {
					return of(null);
				}
				const getImageReq = new GetImageReq(this.appStoreService, this.code, params['img-id'].value);

				return from(this.httpService.sendApi(getImageReq))
					.pipe(
						concatMap((getImageResp: GetImageResp) => loadImage(`data:image/png;base64,${getImageResp.image_data}`)),
						tap((logoImage: HTMLImageElement) => {
							const ctx = this.qrs[params['key'].value].getContext('2d');
							// const logoScale = 0.85;
							const logoWidth = 167;
							const logoHeight = 33;
							ctx.drawImage(logoImage,
								(this.qrs[params['key'].value].width - logoWidth) / 2,
								(this.qrs[params['key'].value].height - logoHeight) / 2,
								logoWidth, logoHeight
							);
							console.log('Logo size:', logoWidth, logoHeight);
							console.log('Result qr: ', this.qrs[params['key'].value].toDataURL());
						})
					);
			})
		);
	}

	/**
	 * Обрабатывает xml тег <code><qr></code> шаблона билета и заполняет соответствующую ему модель.
	 *
	 * @param {Node} node Элемент тега.
	 */
	private processQRTag(node: Node): void {
		// const qr = new QRTag(node['attributes']);
		if (this.flowStack.isEmpty()) {
			throw new Error('processQRTag, flowStack is empty');
		}

		const unit = this.flowStack.peek();
		if (unit instanceof LineUnit) {
			const qr = new QRTag(this, node['attributes']);
			if (qr.getKey() !== null && qr.getKey() !== undefined && qr.getRow() !== null && qr.getRow() !== undefined) {
				const neededImage = this.qrs[qr.getKey()];
				console.log(neededImage.toDataURL());
				const context = this.offscreenCanvas.getContext('2d');
				context.clearRect(0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height);
				context.drawImage(neededImage, 0, qr.getRow() * PRINT_LINE_HEIGHT, this.offscreenCanvas.width, PRINT_LINE_HEIGHT,
					0, 0, this.offscreenCanvas.width, PRINT_LINE_HEIGHT);
				qr.setImagePart(this.offscreenCanvas.toDataURL());
				unit.setQR(qr);
			} else {
				console.log('My_log qr == undefined');
			}
		}

	}
}
