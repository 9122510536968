import { Injectable } from '@angular/core';
import { BarcodeReaderService } from '@app/core/barcode/barcode-reader.service';
import { LogOutService } from '@app/logout/services/log-out.service';

/**
 * Сервис для работы с периферийными устройствами.
 */
@Injectable({
	providedIn: 'root'
})
export class PeripheralService {

	/**
	 * Конструктор сервиса.
	 *
	 * @param {BarcodeReaderService} barcodeReaderService Сервис для работы со сканером штрих-кодов.
	 * @param {LogOutService} logOutService Сервис для работы с автоматическим выходом из системы.
	 */
	constructor(
		private readonly barcodeReaderService: BarcodeReaderService,
		private readonly logOutService: LogOutService,
	) {}

	/**
	 * Возобновить работу сервисов внешних устройств.
	 */
	startExternalDevices(): void {
		this.barcodeReaderService.startDetector();
		this.logOutService.enableShutdown();
	}

	/**
	 * Остановить работу сервисов внешних устройств и убрать фокус с активных элементов.
	 */
	stopExternalDevices(): void {
		if (document.activeElement) {
			(document.activeElement as HTMLElement).blur();
		}

		this.barcodeReaderService.stopDetector();
		this.logOutService.disableShutdown();
	}
}
