<app-one-button-custom
	#helpDialog
	class="help-dialog"
	data-cy="help-dialog"
	[showButtons]="false"
	(cancel)="onCloseHelp()"
>
	<div class="help-dialog__wrapper"
		 data-cy="help-dialog-wrapper"
	>
		<h2 class="help-dialog__header"
			data-cy="help-dialog-header"
		>{{ 'header.help' | translate }}</h2>

		<app-custom-scroll
			class="content-scroller"
			[alwaysShowScroll]="true"
			[buttonsVisible]="false"
			data-cy="content-scroller"
		>
			<div class="spoilers"
				 (click)="onSpoilerClick($event)"
				 data-cy="spoilers"
			>
				<div *ngFor="let spoiler of spoilers; let i = index; trackBy: trackByFn"
					 class="spoiler"
					 [@slideUpDown]="spoiler.state"
					 data-cy="spoiler"
				>
					<div
						 class="spoiler__header spoiler__header_{{ spoiler.state }}"
						 [attr.data-index]="i"
						 data-cy="spoiler-header"
					>
						{{ spoiler.title | translate }}
					</div>
					<div class="spoiler__body"
						 [innerHTML]="spoiler.content | translate | safeHtml"
						 data-cy="spoiler-body"
					></div>
				</div>
			</div>

		</app-custom-scroll>
	</div>

</app-one-button-custom>
