import { Component, OnInit } from '@angular/core';
import { daysInMonth, daysListInMonth, MONTH_BY_KEY, startOfDay, WEEK_DAYS_BY_KEY } from '@app/util/utils';
import { IDayItem } from '../../interfaces/iday-item';
import { CalendarService } from '../../services/calendar.service';

/**
 * Компонент выбора даты типа popup диалога.
 */
@Component({
	selector: 'app-calendar-date-selector',
	templateUrl: './calendar-date-selector.component.html',
	styleUrls: ['./calendar-date-selector.component.scss']
})
export class CalendarDateSelectorComponent implements OnInit {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Список дней недели по ключам в локализации.
	 */
	readonly WEEK_DAYS_BY_KEY = WEEK_DAYS_BY_KEY;

	/**
	 * Список месяцев по ключам в локализации.
	 */
	readonly MONTH_BY_KEY = MONTH_BY_KEY;

	/**
	 * Сервис компонента ввода даты.
	 */
	calendarService: CalendarService;

	/**
	 * Выбранная дата пользователем.
	 * При подтверждении она будет зафиксирована в данном компоненте.
	 */
	selectedDate: Date;

	/**
	 * Месяц, который отображается в календаре.
	 */
	displayMonth: number;

	/**
	 * Год, который отображается в календаре.
	 */
	displayYear: number;

	/**
	 * Список дней, которые отображаются в месячном списке в календаре.
	 */
	days: Array<IDayItem>;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Активная дата в календаре.
	 * @private
	 */
	private activeDate: Date;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Слушатель нажатия кнопок выбора месяца.
	 *
	 * @param {MouseEvent} event Событие нажатия.
	 */
	onClickMonthHandler(event: MouseEvent): void {
		event.stopPropagation();

		if (!event.target) {
			return;
		}

		const attr = parseInt((event.target as HTMLElement).getAttribute('direction'), 10);
		if (!isNaN(attr)) {
			this.activeDate = new Date(this.activeDate.getFullYear(), this.activeDate.getMonth() + attr);
			this.drawCalendarByActiveDate();
		}
	}

	/**
	 * Слушатель нажатия кнопок выбора дня.
	 *
	 * @param {MouseEvent} event Событие нажатия.
	 */
	onClickDayHandler(event: MouseEvent): void {
		if (!event.target) {
			return;
		}

		event.stopPropagation();

		const dateAttr = (event.target as HTMLElement).getAttribute('date');
		if (dateAttr) {
			const item = this.days.find(f => f.date === dateAttr);
			if (item && !item.disabledDay) {
				this.days.forEach(f => f.selectedDate = false);
				item.selectedDate = true;
				this.selectedDate = new Date(item.date);
			}
		}
	}

	/**
	 * Слушатель нажатия кнопки выбора даты.
	 */
	onClickSelectHandler(): void {
		if (this.calendarService) {
			this.calendarService.selectDate(this.selectedDate);
		}
	}

	/**
	 * Функция отслеживания изменений в массиве.
	 * @param index Индекс элемента.
	 * @param item Элемент.
	 */
	trackByWeekDayFn = (index, item: string) => index;

	/**
	 * Функция отслеживания изменений в массиве.
	 * @param index Индекс элемента.
	 * @param item Элемент.
	 */
	trackByDaysFn  = (index, item: IDayItem) => index;

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Пропарсить текущую дату и заполнить массив дней.
	 */
	private drawCalendarByActiveDate(): void {
		this.displayMonth = this.activeDate.getMonth();
		this.displayYear = this.activeDate.getFullYear();

		// заполнить массив дней из текущего месяца
		const today = new Date();
		let days: Array<IDayItem> = daysListInMonth(this.displayYear, this.displayMonth + 1)
			.map(day => {
				const curDate = new Date(this.displayYear, this.displayMonth, day);
				const cd = startOfDay(curDate);
				const disabledDay = this.calendarService.calendarConfiguration$$.value.disableDatesLaterThanCurrent
					&& today.getTime() < cd.getTime();

				return {
					label: day,
					date: curDate.toJSON(),
					activeMonth: true,
					currentDate: today.getFullYear() === this.displayYear
						&& today.getMonth() === this.displayMonth
						&& today.getDate() === day,
					selectedDate: this.selectedDate
						&& this.selectedDate.getFullYear() === this.displayYear
						&& this.selectedDate.getMonth() === this.displayMonth
						&& this.selectedDate.getDate() === day,
					disabledDay
				};
			});

		// заполнить дни из предыдущего месяца
		let firstDay = new Date(this.displayYear, this.displayMonth, 1).getDay();
		if (firstDay !== 1) {
			if (firstDay === 0) {
				firstDay = 7;
			}

			const prevMonthCountDays = daysListInMonth(this.displayYear, this.displayMonth);
			days = prevMonthCountDays
				.filter((f, i) => i > prevMonthCountDays.length - firstDay)
				.map(m => {
					return {
						label: m,
						activeMonth: false,
						currentDate: false,
						selectedDate: false,
						disabledDay: false
					};
				})
				.concat(days);
		}

		// заполнить дни из следующего месяца
		const cntDays = daysInMonth(this.displayYear, this.displayMonth + 1);
		const lastDay = new Date(this.displayYear, this.displayMonth, cntDays).getDay();
		if (lastDay !== 0) {
			const arr: Array<IDayItem> = Array.from(Array(7 - lastDay))
				.map((m, i) => {
					return {
						label: i + 1,
						activeMonth: false,
						currentDate: false,
						selectedDate: false,
						disabledDay: false
					};
				});
			days = days.concat(arr);
		}

		this.days = days;
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.selectedDate = this.calendarService.calendarConfiguration$$.value.selectedDate;
		this.activeDate = this.selectedDate ? this.selectedDate : this.calendarService.calendarConfiguration$$.value.initDate;
		this.drawCalendarByActiveDate();
	}

}
