import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Logger } from '@app/core/net/ws/services/log/logger';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { BaseGuard } from '@app/core/guards/base.guard';

/**
 * Контроль доступа к модулям (страницам) приложения на основе информации об авторизации оператора.
 */
@Injectable({
	providedIn: 'root'
})
export class AuthGuard extends BaseGuard implements CanActivate {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {AppStoreService} appStoreService Сервис для работы с хранилищем данных приложения.
	 * @param {StorageService} storageService Сервис для работы с хранилищем данных в браузере.
	 */
	constructor(
		readonly appStoreService: AppStoreService,
		readonly storageService: StorageService
	) {
		super(appStoreService, storageService);
	}

	/**
	 * Проверка возможности перехода на страницу.
	 * @param next Снимок маршрута, который будет активирован.
	 * @param state Снимок текущего маршрута.
	 */
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

		if (this.checkWebAltSession()) {
			this.appStoreService.isLoggedIn$$.next(true);
		}

		Logger.Log.d('AuthGuardService', `canActivate -> ${!!this.appStoreService.isLoggedIn$$.value}`)
			.console();

		return !!this.appStoreService.isLoggedIn$$.value;
	}

}
