import { DatePipe } from '@angular/common';

import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { CancelableApiClient } from '@app/core/net/http/api/api-client';
import { UpdateDrawInfoDraws } from '@app/core/net/http/api/models/update-draw-info';
import { LotteriesDraws } from '@app/core/services/store/draws';
import { ITransactionResponse } from '@app/core/services/transaction/transaction-types';
import { from, Observable, TimeoutError } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

/**
 * Количество миллисекунд в минуте.
 */
export const DurationMinute = 1000 * 60;

/**
 * Количество миллисекунд в часе.
 */
export const DurationHour = DurationMinute * 60;

/**
 * Количество миллисекунд в дне.
 */
export const DurationDay = DurationHour * 24;

/**
 * Количество миллисекунд в году.
 */
export const DurationYear = DurationDay * 365;

/**
 * Короткий формат записи времени.
 */
export const DATE_TEMPLATE_HH_MM = 'HH:mm';

/**
 * Длинный формат записи времени.
 */
export const DATE_TEMPLATE_HH_MM_SS = 'HH:mm:ss';

/**
 * Формат записи даты.
 */
export const DATE_TEMPLATE_DD_MM_YYYY = 'dd.MM.yyyy';

/**
 * Короткий формат записи даты и времени.
 */
export const DATE_TEMPLATE_DD_MM_YYYY_HH_MM = 'dd.MM.yyyy HH:mm';

/**
 * Длинный формат записи даты и времени (с секундами).
 */
export const DATE_TEMPLATE_DD_MM_YYYY_HH_MM_SS = 'dd.MM.yyyy HH:mm:ss';

/**
 * Список дней недели по ключам в локализации.
 */
export const WEEK_DAYS_BY_KEY = [
	'calendar.monday',
	'calendar.tuesday',
	'calendar.wednesday',
	'calendar.thursday',
	'calendar.friday',
	'calendar.saturday',
	'calendar.sunday'
];

/**
 * Список месяцев по ключам в локализации.
 */
export const MONTH_BY_KEY = [
	'calendar.january',
	'calendar.february',
	'calendar.march',
	'calendar.april',
	'calendar.may',
	'calendar.june',
	'calendar.july',
	'calendar.august',
	'calendar.september',
	'calendar.october',
	'calendar.november',
	'calendar.december'
];

/**
 * Создает текущую дату на начало дня - 00:00:00.
 *
 * @param {Date} fromDate Дата, с которой нужно получить начало дня. Иначе будет использована текущая дата.
 */
export const startOfDay = (fromDate?: Date): Date => {
	const date = !!fromDate ? fromDate : new Date();
	date.setHours(0, 0, 0, 0);

	return date;
};

/**
 * Создает текущую дату на конец дня - 23:59:59.
 *
 * @param {Date} fromDate Дата, с которой нужно получить конец дня. Иначе будет использована текущая дата.
 */
export const endOfDay = (fromDate?: Date): Date => {
	const date = !!fromDate ? fromDate : new Date();
	date.setHours(23, 59, 59, 999);

	return date;
};

/**
 * Возвращает количество дней в месяце.
 *
 * @param {number} fullYear Полный номер года (например 2019).
 * @param {number} month Номер месяца в году начиная с 1 (для января) заканчивая 12 (для декабря).
 */
export const daysInMonth = (fullYear: number, month: number): number => {
	return new Date(fullYear, month, 0).getDate();
};

/**
 * Возвращает список дней в месяце в виде массива чисел.
 *
 * @param {number} fullYear Полный номер года (например 2019).
 * @param {number} month Номер месяца в году начиная с 1 (для января) заканчивая 12 (для декабря).
 */
export const daysListInMonth = (fullYear: number, month: number): Array<number> => {
	return Array
		.from(Array(daysInMonth(fullYear, month)))
		.map((v, i) => i + 1);
};

/**
 * Преобразовать timestamp в формат для запроса в ЦС.
 *
 * @param {DatePipe} datePipe Пайп для преобразования даты.
 * @param {number} value Время (timestamp), которое необходимо преобразовать.
 */
export const convertTimestampToCSFormat = (datePipe: DatePipe, value: number): string => {
	return datePipe.transform(value, 'yyyy-MM-dd HH:mm:ss');
};

/**
 * Преобразовать строковую дату из формата ЦС в объект Date.
 * @param csDate Строковая дата в формате ЦС.
 * @constructor
 */
export const CSDateToDateObject = (csDate: string): Date => {
	let parts = csDate.split(' ');
	if (parts.length < 2) {
		parts = csDate.split('T');
	}
	const daysPart = parts[0];
	const daysPartArr = daysPart.split('-');
	const secondsPart = parts[1];
	const secondsPartArr = secondsPart.split(':');

	return new Date(+daysPartArr[0], +daysPartArr[1] - 1, +daysPartArr[2],
		+secondsPartArr[0], +secondsPartArr[1], +secondsPartArr[2]);
};

/**
 * Преобразовать строковую дату из формата ЦС в строку в русской локализации.
 * @param csDate Строковая дата в формате ЦС.
 * @param showSeconds Показывать ли секунды.
 * @constructor
 */
export const CSDateToRusLocale = (csDate: string, showSeconds = false): string => {
	let parts = csDate.split(' ');
	if (parts.length < 2) {
		parts = csDate.split('T');
	}
	const daysPart = parts[0];
	const daysPartArr = daysPart.split('-');
	const secondsPart = parts[1];
	const secondsPartArr = secondsPart.split(':');
	let drawDate = `${daysPartArr[2]}.${daysPartArr[1]}.${daysPartArr[0]} ${secondsPartArr[0]}:${secondsPartArr[1]}`;
	if (showSeconds) {
		drawDate += `:${secondsPartArr[2]}`;
	}

	return drawDate;
};

/**
 * Преобразовать дату из ISO-формата в формат ЦС.
 * @param csDate Строковая дата в ISO-формате
 * @param showTime Показывать ли время
 * @param showSeconds Показывать ли секунды
 * @constructor
 */
export const ISOtoCSDate = (csDate: string, showTime = false, showSeconds = false): string => {
	let parts = csDate.split(' ');
	if (parts.length < 2) {
		parts = csDate.split('T');
	}
	const daysPart = parts[0];
	const daysPartArr = daysPart.split('-');
	const secondsPart = parts[1];
	const secondsPartArr = secondsPart.split(':');
	let drawDate = `${daysPartArr[2]}-${daysPartArr[1]}-${daysPartArr[0]}`;
	if (showTime) {
		drawDate += ` ${secondsPartArr[0]}:${secondsPartArr[1]}`;
		if (showSeconds) {
			drawDate += `:${secondsPartArr[2]}`;
		}
	}

	return drawDate;
};

/**
 * Возвращает идентификатор запроса (requestId) в андроид сервис.
 *
 * @returns {() => string}
 */
const wsRequestId = (): () => string => {
	let cycleCounter = 0;

	return () => {
		cycleCounter++;
		if (cycleCounter > 999) {
			cycleCounter = 1;
		}

		return `${Date.now()}${cycleCounter + 1000}`;
	};
};

/**
 * Возвращает идентификатор запроса (trans_id) в ЦС.
 *
 * @returns {() => string}
 */
const esapRequestId = (): () => string => {
	let cycleCounter = 0;
	let lastId = 0;

	return () => {
		cycleCounter++;
		if (cycleCounter > 255) {
			cycleCounter = (cycleCounter - 255);
		}

		let date = Math.floor(Date.now() / 1000);
		let id = date * 256 + cycleCounter;
		while (lastId > id) {
			date++;
			id = date * 256 + cycleCounter;
		}
		lastId = id;

		return `${lastId}`;
	};
};

/**
 * Функция получение случайного идентификатора запроса в ЦС.
 */
export const getEsapRequestId = esapRequestId();

/**
 * Функция получение случайного идентификатора запроса в андроид-сервис.
 */
export const getWsRequestId = wsRequestId();

/**
 * Найти перевод в кеше сервиса локализации по ключу.
 *
 * @param {Object} source Ссылка на объект кеша.
 * @param {string} key Ключ строки в виде "xxx.yyy.zzz".
 */
export const getTranslatedStringByKey = (source: Object, key: string): string => {
	if (!source || !key) {
		return '';
	}

	const subKeyList = key.split('.');

	let obj = source;
	subKeyList.forEach(k => {
		if (k in obj) {
			obj = obj[k];
		}
	});

	return obj && typeof obj === 'string' ? obj : key;
};

/**
 * Возвращает форматированное представление длительности чего-либо
 * от начала указанного как параметр до текущего времени.
 *
 * @param {number} from начало события в мс
 * @returns {string} результат
 */
export const getElapsed = (from: number): string => {
	const msecPerSecond = 1000;
	const msecPerMinute = msecPerSecond * 60;
	const msecPerHour = msecPerMinute * 60;
	const msecPerDay = msecPerHour * 24;

	let interval = new Date().getTime() - from;

	const days = Math.floor(interval / msecPerDay);
	interval = interval - (days * msecPerDay);

	const hours = Math.floor(interval / msecPerHour);
	interval = interval - (hours * msecPerHour);

	const minutes = Math.floor(interval / msecPerMinute);
	interval = interval - (minutes * msecPerMinute);

	const seconds = Math.floor(interval / msecPerSecond);

	return `${days}d:${hours}h:${minutes}m:${seconds}s`;
};

/**
 * Идентифицирует, является ли входящая строка датой.
 *
 * @param {string} value Строка для проверки на дату.
 * @returns {boolean}
 */
export const isDateString = (value: string): boolean => {
	const regex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/g;

	return regex.test(value);
};

/**
 * Преобразует строку с датой типа "2018-11-28 13:03:05" в ISO формат.
 *
 * @param {string} date входящая строка
 * @returns {string} возвращает форматированную строку либо undefined
 */
export const convertDateToISOFormat = (date: string): string => {
	const tokens = date.split(' ');
	if (tokens.length === 2) {
		return tokens.join('T');
	}

	return undefined;
};

/**
 * Преобразовывает строку в денежном формате "xx.yy" или "xx" в копейки.
 *
 * @param {string} cost входящее значение
 * @returns {number}
 */
export const numberFromStringCurrencyFormat = (cost: string): number => {
	let result;
	const tokens = cost.match(/(\d+)\.(\d{2})/);
	result = (tokens !== null && tokens.length === 3)
		? Number.parseInt(tokens[1], 10) * 100 + Number.parseInt(tokens[2], 10)
		: Number.parseInt(cost, 10) * 100;

	if (Number.isInteger(result)) {
		return result;
	}

	throw Error('Currency format not valid');
};

/**
 * Преобразовывает число копеек к денежному формату "xx.yy".
 *
 * @param {number} cost копейки
 * @returns {string}
 */
export const stringFromNumberCurrencyFormat = (cost: number): string => {
	return (cost / 100).toFixed(2);
};

/**
 * Выравнивает строку к левому краю, остальное место дополняется заданным символом (по умолчанию пробел).
 *
 * @param {number} width Ширина поля для размещения строки.
 * @param {string} text Строка для выравнивания.
 * @param {string} symbol Символ, которым будет заполнена пустое место.
 * @returns {string} Результат.
 */
export const alignLeft = (width: number, text: string, symbol: string = ' '): string => {
	if (width > text.length) {
		const left = symbol.repeat(width - text.length);
		// return text.trim().concat(left);
		// don't trim, because text can has space after format previous step

		return text.concat(left);
	}

	return text;
};

/**
 * Выравнивает строку к праваому краю, остальное место дополняется заданным символом (по умолчанию пробел).
 *
 * @param {number} width Ширина поля для размещения строки.
 * @param {string} text Строка для выравнивания.
 * @param {string} symbol Символ, которым будет заполнена пустое место.
 * @returns {string} Результат.
 */
export const alignRight = (width: number, text: string, symbol: string = ' '): string => {
	if (width > text.length) {
		const right = symbol.repeat(width - text.length);

		// return right.concat(text.trim());
		// don't trim, because text can has space after format previous step
		return right.concat(text);
	}

	return text;
};

/**
 * Выравнивает строку по центру, остальное место дополняется заданным символом (по умолчанию пробел).
 *
 * @param {number} width Ширина поля для размещения строки.
 * @param {string} text Строка для выравнивания.
 * @param {string} symbol Символ, которым будет заполнена пустое место.
 * @returns {string} Результат.
 */
export const alignCenter = (width: number, text: string, symbol: string = ' '): string => {
	if (width > text.length) {
		const center = Math.floor((width - text.length) / 2);
		const left = symbol.repeat(center);
		const right = symbol.repeat(width - (text.length + center));

		return left.concat(text.trim())
			.concat(right);
	}

	return text;
};

/**
 * Выравнивает два значения в строке по краям и заполняет пространство пробелами.
 *
 * @param {string} str1 Первое значение.
 * @param {string} str2 Второе значение.
 * @param {number} maxWidth Максимальная ширина результирующей строки.
 * @param {Object} langHash Хеш с переводами (опционально). Если задан, то строка будет переведена.
 */
export const alignTwoItemsByWidth = (str1: string, str2: string, maxWidth: number, langHash?: Object): string => {
	const c1 = str1 && str1.length > 0 ? (langHash ? getTranslatedStringByKey(langHash, str1) : str1) : '';
	const c2 = str2 && str2.length > 0 ? (langHash ? getTranslatedStringByKey(langHash, str2) : str2) : '';
	const cnt = maxWidth - c1.length - c2.length;

	return `${c1}${' '.repeat(cnt > 0 ? cnt : 0)}${c2}`;
};

/**
 * Функция перезагрузки приложения в случае критических ошибок..
 */
export const Exit = (): void => {
	window.open(location.origin, '_self');
};

/**
 * Генерирует случайное число в диапазоне от min до max.
 *
 * @param {number} min Минимальное значение
 * @param {number} max Максимальное значение
 */
export const generateRandomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * Генерирует случайное число в диапазоне от min до max, исключая заданное число exclude.
 *
 * @param {number} min Минимальное значение
 * @param {number} max Максимальное значение
 * @param {number} exclude Число, которое не должно быть сгенерировано.
 */
export const generateRandomNumberWithExclude = (min: number, max: number, exclude: number): number | undefined => {
	if (Number.isInteger(min) && Number.isInteger(max) && Number.isInteger(exclude)) {
		let result;
		do {
			result = generateRandomNumber(min, max);
		} while (result === exclude);

		return result;
	}

	return undefined;
};

/**
 * Пропарсить ссылку и получить ее в объекте по частям.
 *
 * @param {string} url Ссылка, которую необходимо разобрать на запчасти.
 */
export const parseUrl = (url: string): {
	href: string,
	protocol: string,
	user_info: string,
	domain: string,
	port: string,
	path: string,
	page: string,
	file_extension: string,
	query: string,
	anchor: string,
	origin: string
} => {
	const str = '\\(?(?:(http|https|ftp):\\/\\/)?(?:((?:[^\\W\\s]|\\.|-|[:])+)@)?((?:www.)?' +
		'(?:[^\\W\\s]|\\.|-)+[.][^\\W\\s]{2,4}|localhost(?=\\/)|\\d{1,3}\\.\\d{1,3}\\.\\d{1,' +
		'3}\\.\\d{1,3})(?::(\\d*))?([\\/]?[^\\s?]*[\\/])*(?:\\/?([^\\s\\n?\\[\\]{}#]*(?:(?=\\' +
		'.))|[^\\s\\n?\\[\\]{}.#]*)?([.][^\\s?#]*)?)?(?:\\?([^\\s\\n#\\[\\]]*))?([#][^\\s\\n]*)?\\)?';
	const urlRegex = new RegExp(str);
	const match = url.match(urlRegex);

	return {
		href: match[0],
		protocol: match[1],
		user_info: match[2],
		domain: match[3],
		port: match[4],
		path: match[5],
		page: match[6],
		file_extension: match[7],
		query: match[8],
		anchor: match[9],
		origin: `${match[1]}://${match[3]}`
	};
};

/**
 * Конвертирует (конкатенирует) параметры объекта в строку.
 *
 * @param {any} param Объект с параметрами.
 * @returns {string | number} Проверенный объект параметра.
 */
export const convertObjectToString = (param: any): string => {
	let value = '';
	if (param === null || param === undefined) {
		throw new Error(`convertObjectToString param: undefined`);
	}

	if (typeof param === 'number' || typeof param === 'string') {
		value = `${param}`;
	} else if (Array.isArray(param)) {
		value = param.reduce((p, c) => p.concat(convertObjectToString(c)), '');
	} else if (typeof param === 'object') {
		Object.keys(param)
			.forEach(k => value = value.concat(convertObjectToString(param[k])));
	} else {
		value = param;
	}

	return value;
};

/**
 * Конвертирует Enum в массив значений.
 *
 * @param value Передаваемый Enum.
 * @returns {Array<number | string>}
 */
export const enumToValuesArray = (value: any): Array<number | string> => {
	const allKeys = Object.keys(value);
	const enumKeys = allKeys.slice(allKeys.length / 2);

	return enumKeys.map(elem => value[elem]);
};

/**
 * Форматирование суммы в строку с двумя знаками после запятой.
 * @param amount Сумма для форматирования.
 */
export const formattedAmount = (amount: number): string => {
	return !!amount ? amount.toFixed(2) : '0.00';
};

/**
 * Преобразовать произвольную строку в хеш-код типа "+3231312344".
 * Вернет строку в виде набора цифр с символом "+" или "-" в начале.
 *
 * @param {string} srcStr Исходная строка для преобразования.
 */
export const calculateStringHash = (srcStr: string): string => {
	const r = Array.from(srcStr)
		.reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0), 0);

	return r > 0 ? `+${r}` : `${r}`;
};

/**
 * Возвращает актуальный список тиражей из заданного списка по заданной игре.
 * Сортировка тиражей - от ближайшего к дальнему.
 *
 * @param {LotteriesDraws} draws Хранилище тиражей.
 * @param {LotteryGameCode} gameCode Код игры.
 */
export const selectActualDraws = (draws: LotteriesDraws, gameCode: LotteryGameCode): Array<UpdateDrawInfoDraws> => {
	let arr = draws.getDraws(gameCode);
	if (!arr) {
		return undefined;
	}

	const now =  Date.now();
	arr = arr.filter(f => now < new Date(f.draw.sale_edate).getTime());
	arr.sort((a, b) => new Date(a.draw.sale_edate).getTime() - new Date(b.draw.sale_edate).getTime());

	return arr;
};

/**
 * Определить ключ имени игры по запросу.
 *
 * @param {CancelableApiClient} request Запрос для соответствующей игры.
 * @param {ITransactionResponse} response Ответ от сервера.
 */
export const getGameNameKeyByLotteryCode = (request: CancelableApiClient, response?: ITransactionResponse): string => {
	switch (request.gameCode) {
		case LotteryGameCode.Zabava:
			return 'lottery.zabava.loto_zabava';
		case LotteryGameCode.MegaLot:
			return 'lottery.megalot.megalot_name';
		case LotteryGameCode.Sportprognoz:
			return 'lottery.sportprognoz.game_name';
		case LotteryGameCode.Gonka:
			return 'lottery.race_for_money.loto_gonka';
		case LotteryGameCode.Tip:
			return 'lottery.tiptop.game_tip_name';
		case LotteryGameCode.Top:
			return 'lottery.tiptop.game_top_name';
		case LotteryGameCode.TML_BML:
			return 'lottery.tmlbml.game_name';
		case LotteryGameCode.Kare:
			return 'lottery.kare.game_name';
		default:
			if (response && Array.isArray(response.ticket)) {
				const t = response.ticket.find(f => !!f['game_name']);

				return t ? t['game_name'] : '--';
			}
			break;
	}

	return '-';
};

/**
 * Обновить параметр объекта по строке с цепочкой параметров.
 *
 * @param {any} srcObj Объект, для обновления.
 * @param {string} chain Цепочка параметров, заданных через точку: "xxx.yyy.zzz".
 * @param {any} value Значение параметра, которое нужно обновить.
 */
export const updateObjectByPropertyChain = (srcObj: any, chain: string, value: any): any => {
	if (chain && chain.length > 0) {
		if (chain.includes('.')) {
			const arr = chain.split('.');
			const newObjName = arr.shift();

			// создать пустой объект
			if (!srcObj[newObjName]) {
				srcObj[newObjName] = {};
			}

			updateObjectByPropertyChain(srcObj[newObjName], arr.join('.'), value);
		} else {
			srcObj[chain] = value;
		}
	}

	return srcObj;
};

/**
 * Возможные значения для форматов билетов
 */
export enum TicketFormat {
	/**
	 * Картинка шириной 504px
	 */
	PNG_504 = 'PNG_504',
	/**
	 * Картинка шириной 384px
	 */
	PNG_384 = 'PNG_384',
	/**
	 * Текстовый формат билета
	 */
	TEXT_32 = 'TEXT_32'
}

/**
 * Перечисление стилей кнопок, доступных к использованию для зеленой кнопки.
 * В качестве значения используется имя класса, описанное в стиле компонента.
 * Возможные варианты:
 * - {@link GreenButtonStyle.green_fill green_fill} - кнопка с обычной зеленой заливкой (по умолчанию)
 * - {@link GreenButtonStyle.dark_green_fill dark_green_fill} - кнопка с темной заливкой
 * - {@link GreenButtonStyle.light_green_fill light_green_fill} - кнопка со светлой радиальной заливкой
 * - {@link GreenButtonStyle.rect_light_green_fill rect_light_green_fill} - кнопка со светлой прямоугольной заливкой
 * - {@link GreenButtonStyle.gold gold} - золотая кнопка
 */
export enum GreenButtonStyle {
	green_fill			= 'button_theme_green',
	dark_green_fill			= 'button_theme_dark-green',
	light_green_fill		= 'button_theme_light-green',
	rect_light_green_fill	= 'button_theme_rect-light-green',
	ToggleButton			= 'button_theme_toggle',
	gold					= 'button_theme_gold'
}

/**
 * Список возможных действий с лотереями.
 * - {@link REGISTER} - регистрация ставки
 * - {@link REGISTER_PARTIAL} - частичная регистрация: XX из YYY, где XX > 0
 * - {@link CANCEL} - отмена ставки
 * - {@link CANCEL_AUTO} - автоотмена ставки
 * - {@link PAYMENT} - выплата ставки
 * - {@link PRINT_BONUS} - печать бонусных билетов
 */
export enum TransactionAction {
	REGISTER			= 'register',
	REGISTER_PARTIAL	= 'register-partial',
	PAYMENT				= 'payment',
	CANCEL				= 'cancel',
	CANCEL_AUTO			= 'cancel-auto',

	PRINT_BONUS			= 'print-bonus'
}

/**
 * Обработчик ошибок камеры.
 * @param error Переданная ошибка.
 */
export const cameraErrorsHandler = (error: string | Error | TimeoutError): string => {
	if (error) {
		const strError = (typeof error === 'string') ? error : `${error.name}: ${error.message}`;

		return strError.includes('NotAllowedError') ? 'scanner.allow_camera' :
			strError.includes('NotReadableError') ? 'scanner.close_other_apps' :
				strError.includes('TimeoutError') ? 'scanner.access_timeout' :
					strError.includes('NoCameraAvailableError') ? 'scanner.no_camera_available' :
						strError.includes('NoLicenseKeyError') ? 'scanner.no_license_key' : strError;
	}

	return '';
};

/**
 * Режим ввода пользователя
 */
export enum UserInputMode {
	/**
	 * Со сканера
	 */
	Scanner = 0,
	/**
	 * Вручную
	 */
	Manual = 1
}

/**
 * Закодированный ключ заголовка запроса
 */
const httpHeaderKey = atob('eA') + atob('LQ') + atob('ZQ') + atob('bA') + atob('aQ') + atob('bg') + atob('ZQ')
	+ atob('LQ') + atob('ZQ') + atob('cw') + atob('YQ') + atob('cA') + atob('LQ') + atob('dA') + atob('ZQ') +
	atob('bQ') + atob('cA') + atob('bA') + atob('YQ') + atob('dA') + atob('ZQ');

/**
 * Закодированное значение заголовка запроса для получения xml-шаблона билета
 */
const httpHeaderValue = atob('dA') + atob('cg') + atob('dQ') + atob('ZQ');

/**
 * Закодированный ключ заголовка запроса для сервиса TRS
 */
const httpHeaderTrsKey = atob('eA') + atob('LQ') + atob('ZQ') + atob('bA') + atob('aQ') + atob('bg') + atob('ZQ')
	+ atob('LQ') + atob('ZQ') + atob('cw') + atob('YQ') + atob('cA') + atob('LQ') + atob('dA') + atob('cg') +
	atob('cw');

/**
 * Объект заголовка запроса для получения xml-шаблона билета
 */
export const secretHeader = {[httpHeaderKey]: httpHeaderValue};

/**
 * Объект заголовка запроса для сервиса TRS
 */
export const secretHeader2 = {[httpHeaderTrsKey]: httpHeaderValue};

/**
 * Функция кодирования unicode-строки в base64
 * @param str Строка для кодирования
 */
export const b64EncodeUnicode = (str: string) => {
	// first we use encodeURIComponent to get percent-encoded UTF-8,
	// then we convert the percent encodings into raw bytes which
	// can be fed into btoa.
	return btoa(encodeURIComponent(str)
		.replace(/%([0-9A-F]{2})/g, (match, p1) => {
			return String.fromCharCode(+`0x${p1}`);
		}));
};

/**
 * Функция создания изображения как DOM-элемента по ссылке на изображение
 * @param url Ссылка на изображение
 */
const createImage = (url: string): Observable<HTMLImageElement> => {
	return new Observable<HTMLImageElement>(observer => {
		const loadedImage = new Image();
		loadedImage.onload = () => {
			observer.next(loadedImage);
			observer.complete();
		};
		loadedImage.onerror = () => {
			observer.error();
			observer.complete();
		};
		loadedImage.src = url;
	});
};

/**
 * Функция загрузки изображения по ссылке
 * @param url Ссылка на изображение
 * @param addHeader
 */
export const loadImage = (url: string, addHeader = false): Observable<HTMLImageElement> => {

	if (url.indexOf('data:') === 0) {
		return createImage(url);
	}

	return from(fetch(new Request(url, addHeader ? { headers: secretHeader2} : {})))
		.pipe(
			concatMap(response => from(response.arrayBuffer())),
			map(buffer => {
				const bytes = new Uint8Array(buffer);
				let rawImage = '';
				for (const byte of bytes) {
					rawImage += String.fromCharCode(byte);
				}

				return rawImage;
			}),
			concatMap(rawImage => createImage(`data:image/png;base64,${btoa(rawImage)}`))
		);
};

/**
 * Получить номер телефона без кода страны и первого нуля.
 */
export function getShortPlayerPhone(phone: string): string {
	return phone.split('')
		.filter(char => /\d/.test(char))
		.join('')
		.substring(3);
}

export function ukrDate(d: Date): string {
	const dd = d.getDate().toString().padStart(2, '0');
	const mm = (d.getMonth() + 1).toString().padStart(2, '0');
	const yyyy = d.getFullYear().toString().padStart(2, '0');
	return `${dd}.${mm}.${yyyy}`;
}

export function ukrDateTime(d: Date): string {
	const hh = d.getHours().toString().padStart(2, '0');
	const mins = d.getMinutes().toString().padStart(2, '0');
	const ss = d.getSeconds().toString().padStart(2, '0');
	return `${ukrDate(d)} ${hh}:${mins}:${ss}`;
}

export function twoCol(col1: string, col2: string, maxLen: number): string {
	return col1 + col2.padStart(maxLen - col1.length, ' ');
}
