/**
 * Константы ролей на терминале.
 */
export enum TerminalRoles {
	OPERATOR		= 0,
	RETAILER		= 1,
	ENGINEER		= 2,
	CASHIER			= 3,
	HEAD_RETAILER	= 4,
	FLASHCARD		= 5,
	OPERATOR_ZABAVA	= 6,
	RESTRICTED		= 10,
	CHANGE_PASSWD	= 15,
	GUEST			= 12345
}

/**
 * Модель для хранения  данных о операторе терминала.
 */
export class Operator {

	/**
	 * Для ACCESS_LIST - мапинг уровня доступа (число) в название роли как это реализовано в sx-410:
	 * [user-role]
	 * 0=operator
	 * 1=retailer
	 * 2=ehgineer
	 * 3=cashier
	 * 4=head-retailer
	 * 5=flashcard
	 * 6=operator-zabava
	 * 10=restricted
	 * 13=change-passwd
	 */
	static readonly userRolesMap = new Map<number, string>([
		[0, 'operator'],
		[1, 'retailer'],
		[2, 'ehgineer'],
		[3, 'cashier'],
		[4, 'head-retailer'],
		[5, 'flashcard'],
		[6, 'operator-zabava'],
		[10, 'restricted'],
		[13, 'change-passwd']
	]);

	/**
	 * Конструктор.
	 *
	 * @param _userId
	 * @param _sessionId
	 * @param _operCode
	 * @param _access_level
	 */
	constructor(
		private readonly _userId: string,
		private readonly _sessionId: string,
		private readonly _operCode: string,
		private readonly _access_level: number
	) {
		// TODO: see - https://jira.emict.net/browse/ALT-385
		// if (this._access_level >= 10) {
		// 	this._access_level -= 10;
		// }
	}

	/**
	 * Идентифицирует наличие достаточного кол-ва данных для подтверждения что оператор зарегистрирован.
	 *
	 * @returns {boolean}
	 */
	isValid(): boolean {
		return !!(this._userId && this._sessionId && this._operCode);
	}

	/**
	 * Возвращает идентификатор пользователя.
	 *
	 * @returns {string}
	 */
	get userId(): string {
		return this._userId;
	}

	/**
	 * Возвращает идентификатор сессии.
	 *
	 * @returns {string}
	 */
	get sessionId(): string {
		return this._sessionId;
	}

	get operCode(): string {
		return this._operCode;
	}

	/**
	 * Возвращает код уровня доступа.
	 *
	 * @returns {number}
	 */
	get accessLevel(): number {
		return this._access_level;
	}

	get isManager(): boolean {
		return this._access_level === TerminalRoles.RETAILER;
	}

	get isOperator(): boolean {
		return this._access_level === TerminalRoles.OPERATOR;
	}

}
