<div class="buttons-group"
	[ngClass]="[isDisabled ? 'buttons-group_disabled' : '',
				'buttons-group_' + buttonTextStyle,
				'buttons-group_theme_' + buttonSetStyle
				]"
	 data-cy="buttons-group"
	>

	<div *ngIf="buttonSetLabel"
		class="buttons-group__title blinking-inv"
		[ngClass]="'buttons-group__title_theme_' + buttonSetStyle"
		[ngStyle]="{opacity: titleBlinking ? 0 : 1}"
		[innerHTML]="buttonSetLabel"
		data-cy="buttons-group-title"
		>
	</div>

	<div class="buttons-group__items"
		 [ngClass]="'buttons-group__items_theme_' + buttonSetStyle"
		 data-cy="buttons-group-items"
	>
		<button *ngFor="let button of buttons; let i = index; trackBy: trackByFn"
			type="button"
			class="buttons-group__item"
			[ngClass]="'buttons-group__item_theme_' + buttonSetStyle"
			data-cy="buttons-group-item"
			[attr.bg-index]="i"
			[class.buttons-group__item_selected]="i <= selectedIndex"
			[class.buttons-group__item_disabled]="button.disabled || isDisabled"
			[class.buttons-group__item_first]="i === 0"
			[class.buttons-group__item_last]="i === buttons.length - 1"
			(click)="onButtonClickHandler(i)"
			>
			{{ isNeededTranslation ? (button.t | translate) : button.t }}
		</button>
	</div>

</div>
