import { Profile, ProfileKey } from '@app/util/profile';
import { DurationYear } from '@app/util/utils';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { GetImageReq, GetImageResp } from '@app/core/net/http/api/models/get-image';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { BarcodeTag } from '@app/tickets-print/parser/tags/barcode';
import { ImgTag } from '@app/tickets-print/parser/tags/img';
import { LineTag, PrintLine } from '@app/tickets-print/parser/tags/line';
import { Barcode, Format, Image, PrintData, Text } from '@app/core/net/ws/api/models/print/print-models';
import { PRINT_IMG_CACHE_PREFIX, ResponseCacheService } from '@app/core/services/response-cache.service';
import { QRMapTag } from '@app/tickets-print/parser/tags/qrmap';
import { QRTag } from '@app/tickets-print/parser/tags/qr';

/**
 * Интерфейс-модель для хранения печатаемого текста
 */
interface TextValue {
	/**
	 * Печатаемый текст
	 */
	value?: string;
}

/**
 * Интерфейс-модель для хранения данных о параметрах печатаемой картинки
 */
interface FutureImage {
	/**
	 * Ширина картинки
	 */
	width: string;
	/**
	 * Позиции картинки в печатаемом документе
	 */
	positions: Array<number>;
}

/**
 * Интерфейс-модель для хранения данных о требуемых к печати картинках
 */
interface BundleOfImages {
	/**
	 * Печатаемые картинки
	 */
	future: Map<string, FutureImage>;
	/**
	 * Запросы на получение картинок
	 */
	request: Map<string, GetImageReq>;
}

/**
 * Теги, которые могут быть обработаны в Print-сервисе
 */
export type PrintTag = LineTag | BarcodeTag | QRMapTag;

/**
 * Модель реализующая функционал по трансформации готового шаблона билета в формат данных для печати
 * согласно API для Print-сервиса.
 *
 * {@link https://confluence.emict.net/display/ALT/Printer+Service}
 */
export class Print {

	/**
	 * Массив печатаемых тегов
	 */
	printTags: Array<PrintTag> = [];

	/**
	 * Массив печатаемых данных
	 */
	printData: Array<PrintData> = [];

	/**
	 * Размер пакета картинок для отправки в Print-сервис
	 * @private
	 */
	private readonly sizeOfBundle = 50;

	/**
	 * Последний тип форматирования данных для печати
	 * @private
	 */
	private readonly lastFormat: Format = new Format();

	/**
	 * Массив пакетов картинок для отправки в Print-сервис
	 * @private
	 */
	private readonly bundlesOfImages: Array<BundleOfImages> = [{future: new Map(), request: new Map()}];

	/**
	 * Индекс текущего пакета картинок для отправки в Print-сервис
	 * @private
	 */
	private bundleIndex = 0;

	/**
	 * Конструктор класса
	 * @param appStoreService Сервис для работы с хранилищем приложения
	 * @param lotteryCode Код лотереи
	 * @param responseCacheService Сервис для работы с кэшем ответов
	 */
	constructor(
		private readonly appStoreService: AppStoreService,
		private readonly lotteryCode: LotteryGameCode,
		private readonly responseCacheService: ResponseCacheService
	) {}

	/**
	 * Обрабатывает линию шаблона билета и создает на ее основе данные для форматирования текста и картинок при печати.
	 *
	 * @param {LineTag} line линия шаблона билета
	 */
	private createFormat(line: LineTag): void {
		Logger.Log.d('Printed', 'line: %s, \n last format: %s', line, this.lastFormat)
			.console();
		const format = new Format(
			line.align,
			line.bold,
			line.width,
			line.spacing
		);

		if (format.hasDifference(this.lastFormat)) {
			Logger.Log.d('Printed', 'created format: %s', format)
				.console();
			this.printData.push(format);
		}
	}

	/**
	 * Обрабатывает линию шаблона билета и создает на ее основе данные для печати текста и картинок.
	 *
	 * @param {LineTag} tag линия шаблона билета
	 */
	private processLineTag(tag: LineTag): void {
		Logger.Log.d('Printed', 'processLineTag, tag: %s', tag)
			.console();

		const text: TextValue = {};
		for (const content of tag.getData()) {
			this.processLine(content, text);
		}

		if (tag.nolf === '0') {
			text.value = text.value ? `${text.value}\n` : '\n';
		}

		this.createText(text);
	}

	/**
	 * Обрабатывает модель с данными о тексте и картинках и формирует данные для печати.
	 *
	 * @param {PrintLine} line Модель с данными о тексте и картинках
	 * @param {TextValue} text Результирующий текст
	 */
	private processLine(line: PrintLine, text: TextValue): void {
		if (typeof line === 'string') {
			text.value = text.value ? text.value + line : line;
		} else if (line instanceof ImgTag) {
			this.createText(text);
			this.createImage(line);
		} else if (line instanceof QRTag) {
			this.createText(text);
			const rnd = Math.floor(Math.random() * 1000000);
			const format = new Image(line.getImagePart(), `qrcode_${rnd}_${line.getKey()}_${line.getRow()}_${Date.now()}`);
			this.printData.push(format);
		}
	}

	/**
	 * Создает представление для печати текста.
	 *
	 * @param {TextValue} text Модель текста
	 */
	private createText(text: TextValue): void {
		if (text.value) {
			const format = new Text(text.value);
			this.printData.push(format);
			text.value = null;
		}
	}

	/**
	 * Создает представление для печати картинки.
	 *
	 * @param {ImgTag} img Модель картинки
	 */
	private createImage(img: ImgTag): void {
		const getImageReq = new GetImageReq(
			this.appStoreService,
			this.lotteryCode,
			img.getPath()
		);

		if (this.bundlesOfImages[this.bundleIndex].future.size >= this.sizeOfBundle) {
			this.bundlesOfImages.push({future: new Map(), request: new Map()});
			this.bundleIndex++;
		}

		const key = PRINT_IMG_CACHE_PREFIX + img.getPath();
		const item = this.bundlesOfImages[this.bundleIndex].future.get(key);
		if (item) {
			item.positions.push(this.printData.push(null) - 1);
		} else {
			this.bundlesOfImages[this.bundleIndex].future.set(key, {
				width: img.width,
				positions: [this.printData.push(null) - 1]
			});
		}

		this.bundlesOfImages[this.bundleIndex].request.set(key, getImageReq);
	}

	/**
	 * Создает баркод в формате для печати.
	 *
	 * @param {BarcodeTag} line Данные баркода
	 */
	private createBarcode(line: BarcodeTag): void {
		let text: string;
		for (const content of line.getBarcodeData()) {
			text = text ? text + content : content;
		}

		if (text) {
			const format = new Barcode(text, line.subscript);
			this.printData.push(format);
		}
	}

	/**
	 * Обрабатывает объект, содержащий данные для форматирования на печать.
	 *
	 * @param {PrintTag} tag Объект, содержащий данные для форматирования на печать
	 */
	private processPrintTag(tag: PrintTag): void {
		if (tag instanceof LineTag) {
			this.createFormat(tag);
			this.processLineTag(tag);
		}

		if (tag instanceof BarcodeTag) {
			this.createBarcode(tag);
		}
	}

	/**
	 * Запрашивает данные о списке картинок для печати. Поиск осуществляется в памяти, Storage-сервисе или ЦС.
	 *
	 * @param {BundleOfImages} bundle объект содержащий информацию о требуемых к печати картинках.
	 * @returns {Promise<void>}
	 */
	private getImages(bundle: BundleOfImages): Promise<void> {
		return this.responseCacheService
			.esapAPI<GetImageResp>(GetImageResp, bundle.request, DurationYear * 10)
			.then(getImageResp => {
				if (getImageResp.length !== bundle.future.size) {
					throw new Error(`size of requested images ${bundle.future.size} wasn't equal size of received: ${getImageResp.length}`);
				}

				for (const item of getImageResp) {
					const futureImg = bundle.future.get(item.key);
					if (!futureImg) {
						throw new Error(`image with key: ${item.key}, don't found in futureImages`);
					}
					for (const position of futureImg.positions) {
						this.printData[position] = new Image((item.value as GetImageResp).image_data, item.key, futureImg.width);
					}
				}
			});
	}

	/**
	 * Запускает процесс подготовки (создания согласно API для Print-сервиса)
	 * шаблона билета (предварительно обработанного на предыдущих стадиях) к выводу на печать.
	 *
	 * @returns {Promise<Array<PrintData>>} Данные для печати.
	 */
	formatPrintData(): Promise<Array<PrintData>> {
		for (const tag of this.printTags) {
			this.processPrintTag(tag);
		}

		Profile.startCheckPoint(ProfileKey.GetImages);
		let chain = Promise.resolve(null);
		for (const bundle of this.bundlesOfImages) {
			chain = chain.then(() => {
				return this.getImages(bundle);
			});
		}

		return chain
			.then(() => {
				Profile.stopCheckPoint(ProfileKey.GetImages);
				Logger.Log.d('Printed', 'print data: %s', this.printData)
					.console();

				return this.printData;
			})
			.catch(error => {
				throw error;
			});
	}
}
