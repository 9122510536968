import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject, Subscription } from 'rxjs';
import { CalendarDateSelectorComponent } from '../components/calendar-date-selector/calendar-date-selector.component';
import { ICalendarConfiguration } from '../interfaces/icalendar-configuration';

/**
 * Сервис компонента ввода даты.
 */
@Injectable({
	providedIn: 'root'
})
export class CalendarService {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Конфигурация компонента.
	 */
	calendarConfiguration$$ = new BehaviorSubject<ICalendarConfiguration>(undefined);

	/**
	 * Объект с новой датой, выбранной пользователем.
	 */
	dateSelected$$ = new Subject<Date>();

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Ссылка на компонент выбора даты.
	 * @private
	 */
	private componentRef: ComponentRef<CalendarDateSelectorComponent>;

	/**
	 * Подписка на клик по документу.
	 * @private
	 */
	private clickSubscription: Subscription;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {ComponentFactoryResolver} componentFactoryResolver Ссылка на фабрику компонентов.
	 * @param {ApplicationRef} appRef Ссылка на контейнер приложения.
	 * @param {Injector} injector Ссылка на инжектор зависимостей.
	 */
	constructor(
		private readonly componentFactoryResolver: ComponentFactoryResolver,
		private readonly appRef: ApplicationRef,
		private readonly injector: Injector
	) {}

	/**
	 * Показать компонент ввода даты.
	 * @param {ICalendarConfiguration} configuration Конфигурация компонента.
	 */
	showCalendar(configuration: ICalendarConfiguration): void {
		if (this.componentRef) {
			this.hideCalendar();
		} else {
			// создать компонент выбора даты
			this.componentRef = this.componentFactoryResolver
				.resolveComponentFactory(CalendarDateSelectorComponent)
				.create(this.injector);
			this.componentRef.instance.calendarService = this;
			this.appRef.attachView(this.componentRef.hostView);
			const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
				.rootNodes[0] as HTMLElement;
			configuration.left = null;
			configuration.top = null;
			// if (window.innerWidth > 840) {
			// 	const zoom = Math.min(window.innerWidth / ALT_WIDTH, window.innerHeight / ALT_HEIGHT);
			// 	domElem.style.zoom = zoom.toString();
			// } else {
			// 	configuration.left = null;
			// }
			document.body.appendChild(domElem);

			// if (configuration.left > window.innerWidth) {
			// 	configuration.left = 0;
			// }
			this.calendarConfiguration$$.next(configuration);

			// подписаться на клик по документу
			this.unsubscribeFromClick();
			this.clickSubscription = fromEvent(document, 'click')
				.subscribe(v => {
					if (v.target !== configuration.targetElement) {
						this.hideCalendarByMouseClick(v as MouseEvent);
					}
				});
		}
	}

	/**
	 * Скрыть компонент ввода даты.
	 */
	hideCalendar(): void {
		if (this.componentRef) {
			this.appRef.detachView(this.componentRef.hostView);
			this.componentRef.destroy();
			this.componentRef = undefined;
		}

		this.unsubscribeFromClick();
	}

	/**
	 * Обработать выбранную дату и закрыть календарь.
	 *
	 * @param {Date} date Выбранная дата.
	 */
	selectDate(date: Date): void {
		this.dateSelected$$.next(date);
		this.hideCalendar();
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Спрятать календарь, если был клик вне окна.
	 *
	 * @param {MouseEvent} event Событие клика.
	 */
	private hideCalendarByMouseClick(event: MouseEvent): void {
		const calendarElements = document.getElementsByTagName('app-calendar-date-selector')[0];
		const calendarElement = calendarElements.lastChild as Element;
		if (calendarElement) {
			const mx = event.clientX;
			const my = event.clientY;
			const rect = calendarElement.getBoundingClientRect();
			if (mx > rect.left && my > rect.top && mx < rect.left + rect.width && my < rect.top + rect.height) {
				return;
			}

			this.hideCalendar();
		}
	}

	/**
	 * Отписаться от клика по документу.
	 * @private
	 */
	private unsubscribeFromClick(): void {
		if (this.clickSubscription) {
			this.clickSubscription.unsubscribe();
		}
	}
}
