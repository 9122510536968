import { ChangeDetectorRef, Component } from '@angular/core';
import { ErrorDialogComponent } from '../../types';

/**
 * Диалог ошибки с одной кнопкой.
 */
@Component({
	selector: 'app-one-button-error',
	templateUrl: './one-button-error.component.html',
	styleUrls: ['./one-button-error.component.scss']
})
export class OneButtonErrorComponent extends ErrorDialogComponent  {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {ChangeDetectorRef} changeDetector Детектор обнаружения изменений.
	 */
	constructor(
		readonly changeDetector: ChangeDetectorRef
	) {
		super(changeDetector);
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------



}
