<label class="pin" data-cy="pin">
	<span *ngIf="title"
		  class="pin__title"
		  [class.pin__title_error]="errorState"
		  data-cy="pin-title"
	>{{ errorState ? errorTitle : title }}</span>
	<span class="pin__wrapper"
		  data-cy="pin-wrapper"
	>
		<span class="pin__inner"
			  data-cy="pin-inner"
		>
			<input appOnlyNumbers
				#inputElement
				autocomplete="on"
				pattern="\d*"
				inputmode="numeric"
				class="pin__input"
				data-cy="pin-input"
				[type]="type"
				[class.pin__input_error]="errorState"
				[attr.maxlength]="pinCodeLength"
				[(ngModel)]="value"
				(ngModelChange)="onModelChange($event)"
				(focus)="onFocusHandler($event)"
				(blur)="onBlurHandler($event)"
				(click)="onClickHandler($event)"
				(input)="onInputHandler($event)"
			/>
			<span class="pin__padding pin__padding_left"
				  data-cy="pin-padding"
			></span>
			<span class="pin__spacers"
				  data-cy="pin-spacers"
			>
				<span *ngFor="let spacer of spacers; trackBy: trackByFn"
					  class="pin__spacer"
					  [class.pin__spacer_error]="errorState"
					  data-cy="pin-spacer"
				></span>
			</span>
			<span class="pin__padding pin__padding_right"
				  data-cy="pin-padding"
			>
				<button *ngIf="showClearBtn && (errorState || (alwaysShowClearBtn && !!value))"
						class="pin__clear"
						data-cy="pin-clear"
						type="button"
						(click)="onClearHandler()"
				><i class="material-icons pin__clear-icon"
					data-cy="pin-clear-icon"
				>cancel</i></button>
			</span>
		</span>
	</span>
</label>
