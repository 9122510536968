import { CommonModule, DatePipe } from '@angular/common';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { BarcodeReaderService } from '@app/core/barcode/barcode-reader.service';
import { DialogContainerComponent } from '@app/core/dialog/components/dialog-container.components';
import { NoneButtonsErrorComponent } from '@app/core/dialog/components/none-buttons-error/none-buttons-error.component';
import { OneButtonErrorComponent } from '@app/core/dialog/components/one-button-error/one-button-error.component';
import { TransactionDialogComponent } from '@app/core/dialog/components/transaction-dialog/transaction-dialog.component';
import { TwoButtonsErrorComponent } from '@app/core/dialog/components/two-buttons-error/two-buttons-error.component';
import { TwoButtonsWithDropdownComponent } from '@app/core/dialog/components/two-buttons-with-dropdown/two-buttons-with-dropdown.component';
import { DialogContainerDirective } from '@app/core/dialog/directives/dialog-container.directive';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { AppErrorHandler } from '@app/core/error/handler';
import { HttpService } from '@app/core/net/http/services/http.service';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { GameResultsService } from '@app/core/services/results/game-results.service';
import { TransactionService } from '@app/core/services/transaction/transaction.service';
import { ConfirmTwoButtonsComponent } from '@app/core/dialog/components/confirm-two-buttons/confirm-two-buttons.component';
import { LogService } from '@app/core/net/ws/services/log/log.service';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { PrintService } from '@app/core/net/ws/services/print/print.service';
import { ResponseCacheService } from '@app/core/services/response-cache.service';
import { AuthService } from '@app/core/services/auth.service';
import { ReportsService } from '@app/core/services/report/reports.service';
import { LotteriesService } from '@app/core/services/lotteries.service';
import { PeripheralService } from '@app/core/services/peripheral.service';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule
	],
    exports: [
        DialogContainerDirective,
        DialogContainerComponent
    ],
	declarations: [
		DialogContainerDirective,
		DialogContainerComponent,
		OneButtonErrorComponent,
		NoneButtonsErrorComponent,
		TwoButtonsErrorComponent,
		TwoButtonsWithDropdownComponent,
		TransactionDialogComponent,
		ConfirmTwoButtonsComponent
	],
	entryComponents: [
		OneButtonErrorComponent,
		TwoButtonsErrorComponent,
		NoneButtonsErrorComponent,
		TwoButtonsWithDropdownComponent,
		TransactionDialogComponent,
		ConfirmTwoButtonsComponent
	],
	providers: [
		AuthService,
		DialogContainerService,
		AppStoreService,
		StorageService,
		LogService,
		PrintService,
		HttpService,
		BarcodeReaderService,
		GameResultsService,
		LotteriesService,
		ReportsService,
		PeripheralService,

		DatePipe,

		TransactionService,
		ResponseCacheService,
		{
			provide: ErrorHandler,
			useClass: AppErrorHandler
		}
	]
})
export class CoreModule {
	constructor(
		@Optional() @SkipSelf() parentModule: CoreModule
	) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import it in the AppModule only');
		}
	}
}
