/**
 * Интерфейс, базовый, для модели ошибок.
 */
export interface IError {
	/**
	 * Сообщение об ошибке.
	 */
	message: string;
	/**
	 * Детальное описание ошибки.
	 */
	messageDetails?: string;
	/**
	 * Код ошибки.
	 */
	code?: number;
}

/**
 * Интерфейс, для модели ошибок сети.
 */
export class NetError implements IError {
	/**
	 * Сообщение об ошибке.
	 */
	message: string;
	/**
	 * Детальное описание ошибки.
	 */
	messageDetails: string;
	/**
	 * Код ошибки.
	 */
	code: number;

	/**
	 * Конструктор.
	 *
	 * @param {string} message Сообщение об ошибке.
	 * @param {string} messageDetails Детальное описание ошибки.
	 * @param {number} code Код ошибки.
	 */
	constructor(
		message: string,
		messageDetails?: string,
		code?: number
	) {
		this.message = message;
		this.messageDetails = messageDetails;
		this.code = code;
	}
}

/**
 * Интерфейс, для модели ошибок связанных с обработкой ответов от ЦС.
 */
export class ApiError extends NetError {
}

/**
 * Интерфейс, для модели ошибок связанных работой приложения
 */
export class AppError extends Error implements IError {
	/**
	 * Прототип класса ошибки.
	 */
	__proto__: Error;

	/**
	 * Код ошибки.
	 */
	code: number;

	/**
	 * Конструктор класса ошибки.
	 * @param message Сообщение об ошибке.
	 * @param code Код ошибки.
	 */
	constructor(message: string, code: number) {
		super(message);
		this.__proto__ = new.target.prototype;
		this.code = code;
	}
}

/**
 * Интерфейс, для модели ошибок связаных отменой последней транзакции
 */
export class CancelError extends Error implements IError {

	/**
	 * Прототип класса ошибки.
	 */
	__proto__: Error;

	/**
	 * Код ошибки.
	 */
	code: number;

	/**
	 * Конструктор класса ошибки.
	 * @param error Объект ошибки.
	 */
	constructor(error: IError) {
		super(error.message);
		this.__proto__ = new.target.prototype;
		this.code = error.code;
	}
}
