import { environment } from '@app/env/environment';
import { Logger } from '@app/core/net/ws/services/log/logger';

/**
 * Интерфейс для установки указателя на начало или конец замера времени.
 */
interface ProfileCheckPoint {
	/**
	 * Время начала замера.
	 */
	start: number;
	/**
	 * Время конца замера.
	 */
	end: number;
	/**
	 * Общее время затраченное на выполнение блока кода.
	 */
	total: number;
}

/**
 * Ключи для замера времени.
 */
export enum ProfileKey {
	/**
	 * Время, затраченное на запрос покупки билета.
	 */
	BuyRequest					= 'request time',
	/**
	 * Время, затраченное на получение шаблона билета.
	 */
	GetTicketTemplate			= 'time to getting ticket template',
	/**
	 * Время, затраченное на подготовку билета к печати.
	 */
	PrepareTicketForPrinting	= 'time to prepare ticket for printing',
	/**
	 * Время, затраченное на получение изображений для билета.
	 */
	GetImages					= 'time to getting images for the ticket',
	/**
	 * Время, затраченное на парсинг шаблона билета.
	 */
	ParseTicketTemplate			= 'time to parse ticket template',
	/**
	 * Время, затраченное на печать билета.
	 */
	PrintTicket					= 'time to print ticket',
	/**
	 * Время, затраченное на получение данных для билета из памяти.
	 */
	GetDataFromMemory			= 'time to getting data from memory',
	/**
	 * Время, затраченное на получение данных для билета из хранилища браузера.
	 */
	GetDataFromStorage			= 'time to getting data from storage',
	/**
	 * Время, затраченное на получение данных для билета из удаленного хранилища.
	 */
	GetDataFromCS				= 'time to getting data from remote'
}

/**
 * Примитивный счетчик кол-ва времени затраченного на выполнение
 * блока кода между startCheckPoint и stopCheckPoint.
 */
export class Profile {
	/**
	 * Точки замера времени.
	 * @private
	 */
	private static readonly CheckPoints: Map<string, ProfileCheckPoint> = new Map();

	/**
	 * Стартовое время замера времени.
	 * @private
	 */
	private static Start: number;

	/**
	 * Конечное время замера времени.
	 * @private
	 */
	private static End: number;

	/**
	 * Старт замера времени по некоторому ключу.
	 *
	 * @param {string} name Имя ключа.
	 */
	static startCheckPoint(name: string | ProfileKey): void {
		if (environment.enableProfile) {
			const point = Profile.CheckPoints.get(name);
			if (point) {
				point.start = performance.now();
			} else {
				Profile.CheckPoints.set(name, {
					start: performance.now(),
					end: undefined,
					total: 0
				});
			}
		}
	}

	/**
	 * Стоп замера времени по некоторому ключу.
	 *
	 * @param {string} name Имя ключа.
	 */
	static stopCheckPoint(name: string | ProfileKey): void {
		if (environment.enableProfile) {
			const point = Profile.CheckPoints.get(name);
			if (point) {
				point.end = performance.now();
				point.total += (point.end - point.start);
			}
		}
	}

	/**
	 * Вывод результатов.
	 */
	static getResult(): void {
		if (environment.enableProfile) {
			if (Profile.CheckPoints && Profile.Start && Profile.End) {
				Profile.CheckPoints.forEach((value, key) => {
					Logger.Log.i('Profiler', `@profile result -> ${key}, took: ${Math.floor(value.total)} ms`)
						.console();
				});
				Logger.Log.i('Profiler', `@profile total time elapsed -> ${Math.floor(Profile.End - Profile.Start)} ms`)
					.console();
			}
		}
	}

	/**
	 * Подготовка счетчика к работе.
	 */
	static start(): void {
		if (environment.enableProfile) {
			Profile.Start = performance.now();
			Profile.CheckPoints.clear();
		}
	}

	/**
	 * Остановка счетчика.
	 */
	static end(): void {
		if (environment.enableProfile) {
			Profile.End = performance.now();
		}
	}
}
