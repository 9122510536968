import { init, Tag } from '@app/tickets-print/parser/tag';
import { IGetParam } from '@app/tickets-print/parser/iget-param';

/**
 * Модель xml тега <qr>
 */
export class QRTag extends Tag {

	/**
	 * Индекс изображения
	 * @private
	 */
	private readonly key: number;

	/**
	 * Номер строки в изображении
	 * @private
	 */
	private readonly row: number;

	/**
	 * Часть изображения
	 * @private
	 */
	private imagePart: string;

	/**
	 * Создание и инициализация объекта класса на основе xml атрибутов
	 * @param param Параметры
	 * @param attributes Атрибуты
	 */
	constructor(param: IGetParam, attributes: NamedNodeMap) {
		super();
		init(this, attributes);
	}

	/**
	 * Получение индекса изображения
	 */
	getKey(): number {
		return this.key;
	}

	/**
	 * Получение текущего номера строки в изображении
	 */
	getRow(): number {
		return this.row;
	}

	/**
	 * Установка части изображения
	 * @param imgPart часть изображения
	 */
	setImagePart(imgPart: string): void {
		this.imagePart = imgPart;
	}

	/**
	 * Получение части изображения
	 */
	getImagePart(): string {
		return this.imagePart.substr(22);
	}
}
