import { Subscriber } from 'rxjs';
import { IError } from '../../../error/types';
import { PrinterInfo, PrinterState } from '@app/core/net/ws/api/models/print/print-models';

/**
 *  Интерфейс модели запроса к периферийным сервисам.
 */
export interface IRequest {
	/**
	 * Идентификатор запроса.
	 */
	requestId: string;
	/**
	 * Идентификатор приложения.
	 */
	appId: string;
	/**
	 * Действие в запросе.
	 */
	readonly action: string;
}

/**
 *  Интерфейс модели тела ответа от периферийных сервисов.
 */
export interface IResponse {
	/**
	 * Идентификатор запроса.
	 */
	requestId: string;
	/**
	 * Код ошибки.
	 */
	errorCode: number;
	/**
	 * Описание ошибки.
	 */
	errorDesc: string;
	/**
	 * Состояние принтера.
	 */
	state?: PrinterState;
}

/**
 * Интерфейс модели ответа на запрос информации о принтере.
 */
export interface IPrinterInfoResponse extends IResponse {
	/**
	 * Информация о принтере.
	 */
	printerInfo?: PrinterInfo;
}

/**
 *  Интерфейс модели уведомлений от периферийных сервисов.
 */
export interface INotification {
	/**
	 * Уведомление от сервиса.
	 */
	event: string;
}

/**
 *  Интерфейс модели ответа от периферийных сервисов.
 */
export interface IMessage {
	/**
	 * Ответ от периферийного сервиса.
	 */
	response?: IResponse;
	/**
	 * Уведомление от периферийного сервиса.
	 */
	notification?: INotification;
	/**
	 * Пинг-сообщение от периферийного сервиса.
	 */
	heartbeat?: string;
}

/**
 * Интерфейс наблюдателя за ходом выполнения запроса к андроид-сервисам
 */
export interface IApiResponseObserver {
	/**
	 * Вызывается при получении ответа от сервиса.
	 * @param response Ответ от сервиса.
	 */
	onResult(response: IResponse): void;

	/**
	 * Вызывается при получении ошибки от сервиса.
	 * @param error Ошибка от сервиса.
	 */
	onError(error: IError): void;
}

/**
 * Интерфейс наблюдателя за событиями уведомлений от сервисов.
 */
export interface IApiNotificationObserver {
	/**
	 * Вызывается при получении уведомления от сервиса.
	 * @param notify Уведомление от сервиса.
	 */
	onNotify(notify: INotification): void;
}

/**
 *  Интерфейс модели подписки на уведомления от сервисов.
 */
export interface ISubscription {
	/**
	 * Отписаться от уведомлений.
	 */
	unsubscribe(): void;
}

/**
 * Интерфейс наблюдателя за ответом от запроса к ЦС.
 */
export interface ApiResponseObserver extends Subscriber<IResponse> {
	/**
	 * Время получения ответа от ЦС.
	 */
	timestamp: number;
}

/**
 *  Модель тела запроса к сервисам.
 */
export class ApiRequest implements IRequest {
	/**
	 * Идентификатор запроса.
	 */
	requestId: string;

	/**
	 * Конструктор модели тела запроса к сервисам.
	 * @param appId Идентификатор приложения.
	 * @param action Выполняемое действие.
	 * @param docId Идентификатор документа.
	 */
	constructor(
		public appId: string,
		public readonly action: string,
		public docId?: string
	) {}
}

/**
 *  Модель данных используемых в запросах к сервисам.
 */
export class KeyValue {
	/**
	 * Ключ.
	 */
	key: string;
	/**
	 * Значение.
	 */
	value: string;
}
