import { Component, ElementRef, Input } from '@angular/core';
import { TopBarService } from '../../services/top-bar.service';

/**
 * Компонент панели статуса периферийных устройств.
 */
@Component({
	selector: 'app-status-panel',
	templateUrl: './status-panel.component.html',
	styleUrls: ['./status-panel.component.scss']
})
export class StatusPanelComponent  {

	/**
	 * Признак отображения панели.
	 */
	@Input()
	showStatusPanel: boolean;

	/**
	 * Текст в статус-панели.
	 */
	@Input()
	statusPanelText: string;

	/**
	 * Установка иконки, для которой показывается панель с текстом
	 * @param value Ссылка на элемент, для которого показывается панель с текстом
	 */
	@Input()
	set iconTarget(value: ElementRef) {
		if (value) {
			const el = value.nativeElement as HTMLElement;
			const rect = el.getBoundingClientRect();
			this.leftPosition = rect.left + 60;
		}
	}

	/**
	 * Позиция панели с текстом.
	 */
	leftPosition = 0;

	/**
	 * Конструктор компонента.
	 *
	 * @param {TopBarService} topBarService	Сервис верхней панели.
	 */
	constructor(
		private readonly topBarService: TopBarService
	) {}

	/**
	 * Обработчик клика по панели.
	 */
	onClickPanelHandler(): void {
		this.topBarService.hideStatusPanel();
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------



}
