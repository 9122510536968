<svg class="cross-image" data-cy="cross-image" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
	<g>
		<path class="cross-path" data-cy="cross-path"
			d="M14.1225 12.0005L23.5602 2.56278C24.1466 1.97693 24.1466 1.02635 23.5602
				0.440498C22.9739 -0.145856 22.0243 -0.145856 21.438 0.440498L12.0002 9.8782L2.56205 0.440498C1.97569 -0.145856
				1.02612 -0.145856 0.439766 0.440498C-0.146589 1.02635 -0.146589 1.97693 0.439766 2.56278L9.87797 12.0005L0.439766
				21.4382C-0.146589 22.024 -0.146589 22.9746 0.439766 23.5605C0.732943 23.8531 1.11717 23.9997 1.50091
				23.9997C1.88464 23.9997 2.26887 23.8531 2.56205 23.56L12.0002 14.1223L21.438 23.56C21.7311 23.8531
				22.1154 23.9997 22.4991 23.9997C22.8828 23.9997 23.2671 23.8531 23.5602 23.56C24.1466 22.9741 24.1466
				22.0235 23.5602 21.4377L14.1225 12.0005Z">
		</path>
	</g>
</svg>
