// -----------------------------
//  Пути в приложении
// -----------------------------

/**
 * Путь к авторизации.
 */
export const URL_AUTH = 'auth';

/**
 * Путь к лотереям.
 */
export const URL_LOTTERIES = 'lotteries';

/**
 * Путь к акциям.
 */
export const URL_ACTIONS = 'actions';

/**
 * Путь к демо.
 */
export const URL_DEMO = 'demo';

/**
 * Путь к результатам.
 */
export const URL_RESULTS = 'results';

/**
 * Путь к стартовой странице лотереи.
 */
export const URL_INIT = 'init';

/**
 * Путь к странице подтверждения продажи билетов в лотерее.
 */
export const URL_REGISTRY = 'registry';

/**
 * Путь к сканеру.
 */
export const URL_SCANNER = 'scanner';

/**
 * Путь к лотерее "Мегалот".
 */
export const URL_MEGALOT = 'megalot-lottery';

/**
 * Путь к лотерее "Стирачки".
 */
export const URL_TMLBML = 'tmlbml';

/**
 * Путь к лотерее "ТипТоп".
 */
export const URL_TIPTOP = 'tiptop-lottery';

/**
 * Путь к лотерее "Гонка".
 */
export const URL_GONKA = 'gonka-lottery';

/**
 * Путь к лотерее "Забава".
 */
export const URL_ZABAVA = 'zabava-lottery';

/**
 * Путь к странице сканирования бланков
 */
export const URL_BLANKS = 'blanks';

/**
 * Путь к лотерее "СпортПрогноз".
 */
export const URL_SPORTPROGNOZ = 'sportprognoz';

/**
 * Путь к электронным моментальным лотереям.
 */
export const URL_INSTANT = 'instant-lotteries';

/**
 * Путь к лотерее "Каре".
 */
export const URL_KARE = 'kare';

/**
 * Путь модулю работы с билетами.
 */
export const URL_TICKETS = 'tickets';

/**
 * Путь к странице проверки билетов.
 */
export const URL_CHECK = 'check';

/**
 * Путь к странице возврата выплаты выигрыша.
 */
export const URL_PAYMENT = 'payment';

/**
 * Путь к странице успешной выплаты выигрыша и перечислению выигрыша на карту лояльности
 */
export const URL_PAYMENT_FINISH = 'payment-finish';

/**
 * Путь к странице выплаты бонуса.
 */
export const URL_BONUS_PAYMENT = 'bonus-payment';

/**
 * Путь к странице 404
 */
export const URL_NOT_FOUND = 'not-found';

/**
 * Путь к странице списка
 */
export const URL_LIST = 'list';

/**
 * Путь к странице отмены последней транзакции
 */
export const URL_CANCEL_LAST = 'cancel-last';

/**
 * Путь к странице лога действий
 */
export const URL_ACTION_LOG = 'action-log';

/**
 * Путь к странице раздела "Другое"
 */
export const URL_OTHER = 'other';

/**
 * Путь к странице бумажных моментальных лотерей
 */
export const URL_PAPER_INSTANT_LOTTERY = 'paper-instant-lottery';
// export const URL_REPORTS = 'reports';

/**
 * Путь к странице отчетов
 */
export const URL_REPORTING = 'reporting';

/**
 * Путь к странице финансовых отчетов
 */
export const URL_FINANCE = 'finance';

/**
 * Путь к странице отчетов по операторам и их сменам
 */
export const URL_OPERATOR_SHIFT = 'operator-shift';

/**
 * Путь к пустой странице
 */
export const URL_EMPTY = 'empty';

// -----------------------------
//  URL parameters keys
// -----------------------------
/**
 * Параметр для передачи в URL штрих-кода билета.
 */
export const PARAM_BARCODE				= 'barcode';

/**
 * Параметр для передачи в URL признака ручного клика по меню.
 */
export const PARAM_MANUAL_MENU_CLICK	= 'manualMenuClick';

/**
 * Параметр для передачи в URL акционной ставки
 */
export const PARAM_ACTION_BET			= 'actionBet';

/**
 * Параметр для передачи в URL демо-ставки
 */
export const PARAM_DEMO_BET			= 'demoBet';


/**
 * Создает объект с параметром.
 *
 * @param {string} name Имя параметра.
 * @param {string} value Значение параметра.
 * @returns {any}
 */
export const createUrlParam = (name: string, value: string | boolean | number): any => {
	const result = {};
	result[name] = value;

	return result;
};

// -----------------------------
//
// -----------------------------

/**
 * Правила для отображения центрального меню.
 */
const RULES_CENTRAL_MENU = [
	`/${URL_LOTTERIES}`,
	`/${URL_ACTIONS}`,
	`/${URL_TICKETS}**`,
	`/${URL_REPORTING}**`,
	`/${URL_REPORTING}/${URL_OTHER}**`,
	`/${URL_RESULTS}**`,
	`/${URL_OTHER}**`
];

/**
 * Проверяет, должно ли отображаться центральное меню.
 * @param path Путь.
 */
export const isEnabledCentralMenu = (path: string): boolean => {
	const shortList = RULES_CENTRAL_MENU.filter(p => p.indexOf('**') !== -1)
		.map(v => v.split('**')[0]);

	return RULES_CENTRAL_MENU.indexOf(path) !== -1 || shortList.filter(p => path.startsWith(p)).length > 0;
};
