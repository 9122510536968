<div class="modal-dialog-background" data-cy="two-buttons-modal">
	<div class="modal-dialog-container"
		 data-cy="modal-dialog-container"
	>

		<!-- DIALOG TITLE -->
		<div class="modal-alt-title"
			 [ngClass]="isInfoDialog ? 'modal-alt-title_info' : 'modal-alt-title_error'"
			 data-cy="modal-alt-title"
		>{{ title | translate }}</div>

		<!-- DIALOG MESSAGE -->
		<div
			class="modal-alt-message"
			data-cy="modal-alt-message"
			[innerHTML]="message | translate"
			>
		</div>

		<!-- EXTRA TEXT FOR ERROR CODE -->
		<div
			class="modal-alt-extra"
			data-cy="modal-alt-extra"
			*ngIf="extra"
			>
			{{ extra | translate }}
		</div>

		<!-- ERROR CODE -->
		<div
			class="modal-alt-code"
			data-cy="modal-alt-code"
			*ngIf="code"
			>
			{{ code_translate | translate }}
			<span>№{{ code }}</span>
		</div>

		<!-- ПАНЕЛЬ КНОПОК -->
		<div class="modal-buttons-container"
			 [class.buttons-vertical]="config?.buttonsOrientation"
			 data-cy="modal-buttons-container"
		>

			<!-- ВТОРИЧНАЯ КНОПКА (НАЗАД) -->
			<button type="button"
				class="button button_theme_light-green modal-button"
					data-cy="modal-button"
				(click)="clickSecond()"
				>
				{{ buttons.second.text | translate }}
			</button>

			<!-- ПЕРВИЧНАЯ КНОПКА (ПОДТВЕРЖДЕНИЕ) -->
			<button type="button"
				class="button button_theme_green modal-button"
					data-cy="modal-button"
				(click)="clickFirst()"
				>
				{{ buttons.first.text | translate }}
			</button>

		</div>

	</div>
</div>

<app-dialog-container></app-dialog-container>
