import { IsNotEmpty } from 'class-validator';

import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { ApiClient } from '@app/core/net/http/api/api-client';
import { IResponse } from '@app/core/net/http/api/types';

// -----------------------------
//  Request
// -----------------------------

/**
 * Модель для вызова API GetImage
 */
export class GetImageReq extends ApiClient {

	/**
	 * Конструктор
	 * @param {AppStoreService} appStoreService централизованное хранилище данных
	 * @param {LotteryGameCode} code код лотереи
	 * @param {string} imageId идентификатор картинки
	 */
	constructor(appStoreService: AppStoreService, code: LotteryGameCode, imageId: string) {
		super(appStoreService, appStoreService.Settings.getEsapActionUrl(code, EsapActions.GetImage));

		this.params.append(EsapParams.IMAGE_ID, imageId);
		this.params.append(EsapParams.TYPE, 'png');
	}

}

// -----------------------------
//  Response
// -----------------------------

/**
 * Модель ответа на запрос API GetImage
 */
export class GetImageResp implements IResponse {
	/**
	 * Идентификатор транзакции
	 */
	client_trans_id: string;

	/**
	 * Код ошибки
	 */
	err_code: number;

	/**
	 * Описание ошибки
	 */
	err_descr: string;

	/**
	 * Идентификатор картинки
	 */
	@IsNotEmpty()
	image_id: string;

	/**
	 * Тип картинки
	 */
	@IsNotEmpty()
	image_type: string;

	/**
	 * Содержимое картинки
	 */
	@IsNotEmpty()
	image_data: string;
}
