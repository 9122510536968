/**
 * Список состояний поля ввода:
 * - {@link Clear} - незаполненное поле
 * - {@link OkInput} - корректный баркод
 * - {@link BadInput} - некорректный баркод
 */
export enum TmlBmlInputFieldStates {
	Clear		= 'clear',
	BadInput	= 'bad-input',
	OkInput		= 'ok-input'
}
