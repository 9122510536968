import { EventEmitter, HostListener, Input, Output, Directive } from '@angular/core';

/**
 * Модель параметра иконки.
 * Задает URL, ширину и высоту иконки.
 */
export interface IIconProperties {
	/**
	 * URL иконки.
	 */
	url: string;
	/**
	 * Ширина иконки.
	 */
	width: number;
	/**
	 * Высота иконки.
	 */
	height: number;
}

/**
 * Модель события {@link MslButtonComponent.action action}.
 */
export interface IAction {
	/**
	 * Признак, что кнопка находится в выбранном состоянии или в фокусе (будет немного отличаться стиль).
	 */
	selected: boolean;
}

/**
 * Выравнивание текста.
 */
export type textAlign = 'left' | 'center' | 'right';

/**
 * Базовый компонент - кнопка.
 */
@Directive()
export abstract class MslButtonComponent {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Признак, указывающий на неактивность кнопки в данный момент времени (true).
	 */
	@Input()
	isDisabled = false;

	/**
	 * Признак, указывающий на возможность изменять статус кнопки на противоположный
	 * (с нажатого состояния в ненажатое или наоборот).
	 */
	@Input()
	isToggleButton = false;

	/**
	 * Признак, что кнопка находится в выбранном состоянии или в фокусе (будет немного отличаться стиль).
	 */
	@Input()
	selected = false;

	/**
	 * Выравнивание метки кнопки.
	 */
	@Input()
	textAlign: textAlign;

	/**
	 * Иконка для кнопки.
	 * Задается моделью {@link IIconProperties}.
	 */
	@Input()
	iconProperties: IIconProperties;

	/**
	 * Указывает, что кнопка будет использована для submit на форме.
	 */
	@Input()
	isSubmitButton = false;

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Событие по нажатию на кнопку.
	 */
	@Output()
	readonly action = new EventEmitter<IAction>();

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Слушатель события нажатия по кнопке.
	 * @param event Передаваемое событие.
	 */
	@HostListener('click', ['$event'])
	onClickHandler(event): void {
		event.preventDefault();

		if (this.isDisabled) {
			return;
		}

		if (this.isToggleButton) {
			this.selected = !this.selected;
		}

		this.sendEvent();
	}

	/**
	 * Слушатель события зажатия левой кнопки мыши на кнопке.
	 * @param event Передаваемое событие.
	 */
	@HostListener('press', ['$event'])
	onPressHandler(event): void {
		event.preventDefault();

		if (!this.isDisabled) {
			this.sendEvent();
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Сгенерировать событие "action".
	 */
	private sendEvent(): void {
		// при выполнении действия убрать фокус с элемента
		if (document.activeElement) {
			(document.activeElement as HTMLElement).blur();
		}

		this.action.emit({
			selected: this.selected
		});
	}

}
