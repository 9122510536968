import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Компонент кнопок навигации по результатам тиражей.
 */
@Component({
	selector: 'app-results-navigation-buttons',
	templateUrl: './results-navigation-buttons.component.html',
	styleUrls: ['./results-navigation-buttons.component.scss']
})
export class ResultsNavigationButtonsComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------
	/**
	 * Флаг, разрешающий кнопку "Назад".
	 */
	@Input()
	isEnabledGoBack = true;

	/**
	 * Флаг, разрешающий кнопку "Вперед".
	 */
	@Input()
	isEnabledGoForward = true;

	// -----------------------------
	//  Output properties
	// -----------------------------
	/**
	 * Событие, которое будет вызвано при нажатии на кнопку "Назад".
	 */
	@Output()
	readonly goBack = new EventEmitter();

	/**
	 * Событие, которое будет вызвано при нажатии на кнопку "Вперед".
	 */
	@Output()
	readonly goForward = new EventEmitter();

	/**
	 * Событие, которое будет вызвано при нажатии на кнопку "Перезагрузить".
	 */
	@Output()
	readonly reload = new EventEmitter();

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
}
