import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { startOfDay } from '@app/util/utils';
import { CalendarTheme } from '../enums/calendar-theme.enum';
import { ICalendarConfiguration } from '../interfaces/icalendar-configuration';
import { CalendarService } from '../services/calendar.service';

/**
 * Директива для управления отображением компонентом выбора даты.
 */
@Directive({
	selector: '[appCalendarDateInput]'
})
export class CalendarDateInputDirective implements OnInit, OnDestroy {

	// -----------------------------
	//  Input properties
	// -----------------------------
	/**
	 * Геттер для выбранной даты.
	 */
	@Input()
	get selectedDate(): Date {
		return this.configuration ? this.configuration.selectedDate : undefined;
	}

	/**
	 * Сеттер для выбранной даты.
	 * @param value Значение даты.
	 */
	set selectedDate(value: Date) {
		if (this.configuration) {
			this.configuration.selectedDate = value;
		}
	}

	/**
	 * Текущая активная дата.
	 * По умолчанию - это текущий день.
	 */
	@Input()
	currentDate;

	/**
	 * Задает цветовую тему календаря.
	 */
	@Input()
	calendarTheme: CalendarTheme = CalendarTheme.Green;

	/**
	 * Абсолютные ли сдвиги?
	 */
	@Input()
	absolutePaddings = false;

	/**
	 * Задать сдвиг сверху
	 */
	@Input()
	topPadding = 0;

	/**
	 * Задать сдвиг слева.
	 */
	@Input()
	leftPadding = 0;

	/**
	 * Задать флаг показа оверлея.
	 */
	@Input()
	showOverlay = true;

	/**
	 * Задать текст титульной кнопки.
	 */
	@Input()
	showTitleText: string;

	/**
	 * Задать флаг отключения дат в окне выбора, если они позднее текущей даты.
	 */
	@Input()
	disableDatesLaterThanCurrent: boolean;

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Событие генерируется в случае, если пользователь изменил дату.
	 */
	@Output()
	readonly dateChanged = new EventEmitter<Date>();

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	/**
	 * Конфигурация календаря.
	 * @private
	 */
	private configuration: ICalendarConfiguration;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор директивы.
	 *
	 * @param {ElementRef} elementRef Ссылка на элемент, к которому применяется директива
	 * @param {Renderer2} renderer Объект для работы с DOM в Angular
	 * @param {CalendarService} calendarService Сервис для работы с календарем
	 */
	constructor(
		private readonly elementRef: ElementRef,
		private readonly renderer: Renderer2,
		private readonly calendarService: CalendarService
	) {}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		// создать конфигурацию
		const rect: DOMRect = this.elementRef.nativeElement.getBoundingClientRect();
		const realTop = this.absolutePaddings
			? this.topPadding
			: rect.top + rect.height + this.topPadding + (!!this.showTitleText ? 56 : 0);
		const realLeft = this.absolutePaddings
			? this.leftPadding
			: rect.left + this.leftPadding;
		this.configuration = {
			id: Date.now(),
			initDate: this.currentDate ? this.currentDate : startOfDay(),
			selectedDate: this.selectedDate ? this.selectedDate : undefined,
			calendarTheme: this.calendarTheme,
			targetElement: this.elementRef.nativeElement,
			top: realTop,
			left: realLeft,
			showOverlay: this.showOverlay,
			showTitleText: this.showTitleText,
			disableDatesLaterThanCurrent: this.disableDatesLaterThanCurrent
		};

		// подписаться на клик элемента, по которому будет открыт календарь
		fromEvent(this.elementRef.nativeElement, 'click')
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(() => {
				this.calendarService.showCalendar(this.configuration);
			});

		// подписаться на событие выбора даты
		// событие отправляем только на соответствующий компонент по ID
		this.calendarService.dateSelected$$
			.pipe(
				takeUntil(this.unsubscribe$$),
				filter(() => !!this.calendarService.calendarConfiguration$$.value
					&& this.calendarService.calendarConfiguration$$.value.id === this.configuration.id)
			)
			.subscribe(date => {
				this.configuration.selectedDate = date;
				this.dateChanged.emit(date);
			});
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.calendarService.hideCalendar();

		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

}
