import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Интерфейс для события нажатия на кнопку печати.
 */
export interface IPrintButtonAction {
	/**
	 * Количество копий.
	 */
	copiesCount: number;
}

/**
 * Компонент - кнопка печати.
 */
@Component({
	selector: 'app-print-button',
	templateUrl: './print-button.component.html',
	styleUrls: ['./print-button.component.scss']
})
export class PrintButtonComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Признак, указывающий на необходимость отображения элемента ввода количества копий.
	 */
	@Input()
	showCopiesCounter = false;

	/**
	 * Количество копий.
	 */
	@Input()
	copiesCount = 1;

	/**
	 * Количество копий.
	 */
	@Input()
	maxCopiesCount = 5;

	/**
	 * Признак, указывающий, что кнопку невозможно нажимать.
	 */
	@Input()
	isDisabled = false;

	/**
	 * Признак, указывающий на необходимость отображения иконки принтера на кнопке печати.
	 */
	@Input()
	showPrintIcon = false;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие, срабатывающее при нажатии на кнопку печати.
	 * Отправляет объект типа {@link IPrintButtonAction}.
	 */
	@Output()
	readonly printAction = new EventEmitter<IPrintButtonAction>();

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Признак, указывающий, что кнопка "меньше" неактивна.
	 */
	btnLessDisabled = true;

	/**
	 * Признак, указывающий, что кнопка "больше" неактивна.
	 */
	btnMoreDisabled = false;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Обработчик ввода количества копий.
	 * @param offset Начальное смещение для определения статуса кнопок "меньше" и "больше".
	 */
	onClickCopiesCountHandler(offset: number): void {
		const newCount = this.copiesCount + offset;
		if (newCount > 0 && newCount <= this.maxCopiesCount) {
			this.copiesCount += offset;
			this.btnLessDisabled = (newCount + offset <= 0);
			this.btnMoreDisabled = (newCount + offset > this.maxCopiesCount);
		}
	}

	/**
	 * Обработчик нажатия на кнопку печати.
	 */
	onClickPrintHandler(): void {
		this.printAction.emit({
			copiesCount: this.copiesCount
		});
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------



}
