import { Injectable } from '@angular/core';

import { interval, Observable, Subject, Subscription, TimeoutError, timer } from 'rxjs';
import { catchError, filter, timeout } from 'rxjs/operators';

import { Logger } from '@app/core/net/ws/services/log/logger';
import { RoutedTransport, RoutedTransportType } from '@app/core/net/ws/routed-transport';
import {
	ApiRequest,
	IApiResponseObserver,
	IPrinterInfoResponse,
	IResponse,
	ISubscription
} from '@app/core/net/ws/api/types';
import { ApiError, IError } from '@app/core/error/types';
import { PrintReq, PrintResp } from '@app/core/net/ws/api/models/print/print';
import {
	DEFAULT_PRINTER_TIMEOUT,
	DocStatusChanged,
	PrintData,
	PrinterEvents,
	PrinterState, StateChanged
} from '@app/core/net/ws/api/models/print/print-models';

/**
 * Сервис, реализующий функционал по взаимодействию с Print-сервисом.
 */
@Injectable({
	providedIn: 'root'
})
export class PrintService extends RoutedTransport {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная, содержащая состояние принтера.
	 */
	statusEvent$$: Subject<PrinterState> = new Subject();

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Подписка на события принтера.
	 * @private
	 */
	private eventSubscription: ISubscription;

	/**
	 * Подписка на состояние принтера.
	 * @private
	 */
	private stateSubscription: ISubscription;

	/**
	 * Состояние принтера.
	 * @private
	 */
	private printerState: PrinterState = PrinterState.Disconnected;

	/**
	 * Подписка на таймер ожидания ответа от принтера.
	 * @private
	 */
	private timeoutSubscription: Subscription;

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Признак занятости принтера.
	 */
	printerIsBusy = false;

	/**
	 * Последний напечатанный документ.
	 */
	lastDocID: string;

	/**
	 * Конструктор сервиса.
	 */
	constructor() {
		super(RoutedTransportType.Printer);
	}

	/**
	 * Инициализация, на этапе загрузки терминала, и подключение к Print-сервису.
	 *
	 * @param {string} url Ссылка на Print-сервис.
	 * @param {string} retryTimeout Таймер ожидания подключения.
	 */
	init(url: string, retryTimeout: number): void {
		Logger.Log.i('PrintService', `starting initialization ${url}`)
			.console();

		this.create(url, retryTimeout);

		this.stateSubscription = this.stateMonitor({
			ready: () => {
				this.printerState = PrinterState.Connected;
				this.statusEvent$$.next(this.printerState);
			},
			error: () => {
				this.printerState = PrinterState.Disconnected;
				this.statusEvent$$.next(this.printerState);
			}
		});

		interval(3000)
			.pipe(
				filter(() => !this.printerIsBusy)
			)
			.subscribe(() => {
				this.sendApiRequest(new ApiRequest('ua.msl.alt.service.printer', 'status'), {
					onResult: (response: IResponse) => {
						console.log('Socket status response: ', response);
					},
					onError: (error: IError) => {
						console.log('Error getting printer status:', error);
					}
				});
			});
		// this.printerStateSubscription = this.wsObserver$$.subscribe(ev => {
		// 	const socketData = JSON.parse(ev.data);
		// 	if (socketData?.notification?.state) {
		// 		this.printerState = socketData.notification.state;
		// 		this.statusEvent$$.next(socketData.notification.state);
		// 	}
		// });
		// this.eventSubscription = this.listenNotify(PrinterEvents.DocStatusChanged, {
		// 	onNotify: (notify: StateChanged) => {
		// 		// this.printerState = notify.state;
		// 		// this.statusEvent$$.next(this.printerState);
		// 	}
		// });
		this.eventSubscription = this.listenNotify(PrinterEvents.StateChanged, {
			onNotify: (notify: StateChanged) => {
				console.log('notify =', notify);
				this.printerState = notify.state;
				this.statusEvent$$.next(this.printerState);
			}
		});
	}

	/**
	 * Возвращает состояние принтера.
	 */
	getStatus(): PrinterState {
		return this.printerState;
	}

	/**
	 * Идентифицирует подключен ли принтер.
	 *
	 * @returns {boolean}
	 */
	isReady(): boolean {
		return this.printerState !== PrinterState.OffLine && this.printerState !== PrinterState.Disconnected;
	}

	/**
	 * Отправляет на Print-сервис данные для печати.
	 *
	 * @param {Array<PrintData>} data Данные для печати в формате API Print Service.
	 * @param {number} timeOut Таймаут на ожидание уведомления о событии printComplete - смотри API Print Service.
	 * @param {number} count Количество копий (по умолчанию не задано, т.е. эквивалентно 1 копии).
	 * @returns {Promise<string>} Идентификатор напечатанного документа - смотри API Print Service.
	 */
	printDocument(data: Array<PrintData>, timeOut?: number, count?: number): Promise<string> {
		const apiTimout = timeOut ? timeOut : DEFAULT_PRINTER_TIMEOUT;
		const printCount = count ? count : 1;

		for (const control of data) {
			if (control.control === 'barcode') {
				control.height = '96';
			}
		}

		Logger.Log.i('PrintService', `printDocument -> start print with timeout: ${apiTimout}, total copies: ${printCount}`)
			.console();

		// перед печатью сразу проверить статус
		if (!this.isReady()) {
			return Promise.reject<string>(new Error('dialog.printer_not_ready_info'));
		}

		return new Promise<string>((resolve, reject) => {
			const resolverSubject = new Subject<string>();
			resolverSubject.subscribe(v => {
				Logger.Log.i('PrintService', `printDocument -> resolve: ${v}`)
					.console();
				if (this.timeoutSubscription) {
					this.timeoutSubscription.unsubscribe();
					this.timeoutSubscription = undefined;
				}
				resolve(v);
			}, err => {
				Logger.Log.e('PrintService', `printDocument -> reject: %s`, err)
					.console();
				if (this.timeoutSubscription) {
					this.timeoutSubscription.unsubscribe();
					this.timeoutSubscription = undefined;
				}
				reject(err);
			});
			this.sendDataToPrinterService(data, apiTimout, printCount, resolverSubject);
			if (!this.timeoutSubscription) {
				this.timeoutSubscription = timer(30000)
					.subscribe(() => {
						reject({message: 'dialog.printer_time_out'});
						this.timeoutSubscription.unsubscribe();
						this.timeoutSubscription = undefined;
					});
			}
		});
	}

	/**
	 * Отправляет на Print-сервис запрос информации о принтере.
	 */
	getPrinterInfo(): Observable<IPrinterInfoResponse> {
		return new Observable<IPrinterInfoResponse>(observer => {
			this.sendApiRequest(new ApiRequest('ua.msl.alt.service.printer', 'getPrinterInfo'), {
				onResult: (response: IPrinterInfoResponse) => {
					console.log('Socket status response: ', response);
					observer.next(response);
					observer.complete();
				},
				onError: (error: IError) => {
					console.log('Error getting printer status:', error);
					observer.error(error);
					observer.complete();
				}
			});
		});
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Отправляет на Print-сервис данные для печати.
	 *
	 * @param {Array<PrintData>} data Данные для печати в формате API Print Service.
	 * @param {number} timeOut Таймаут на ожидание уведомления о событии printComplete.
	 * @param {number} count Количество копий.
	 * @param {Subject<string>} resolverSubject Объект для отправки результата в Promise.
	 */
	private sendDataToPrinterService(data: Array<PrintData>, timeOut: number, count: number, resolverSubject: Subject<string>): void {
		Logger.Log.i('PrintService', `sendDataToPrinterService -> timeout: ${timeOut}, print copies left: ${count}`)
			.console();

		new Promise<IResponse>((resolve, reject) => {
			const print = new PrintReq(data);
			const apiObserver: IApiResponseObserver = {onResult: resolve, onError: reject};
			this.sendApiRequest(print, apiObserver, timeOut);
		})
			.then(response => {
				Logger.Log.i('PrintService', `sendDataToPrinterService -> data is ready to print %s`, response)
					.console();

				this.printerNotificationListener(response as PrintResp, timeOut)
					.subscribe(v => {
						Logger.Log.i('PrintService', `printerNotificationListener -> print copy ${v}`)
							.console();
					}, err => {
						Logger.Log.e('PrintService', `printerNotificationListener -> ERROR: %s`, err)
							.console();
						resolverSubject.error(err);
					}, () => {
						Logger.Log.i('PrintService', `printerNotificationListener -> print copy finished`)
							.console();

						// если не все копии отпечатаны - повторяем
						if (count > 1) {
							this.sendDataToPrinterService(data, timeOut, count - 1, resolverSubject);
						} else {
							Logger.Log.i('PrintService', `sendDataToPrinterService -> all copies printed!`)
								.console();
							resolverSubject.next('copyIsPrinted');
						}
					});
			})
			.catch(error => {
				Logger.Log.e('PrintService', `can't send data to printer service: %s`, error)
					.console();
			});
	}

	/**
	 * Слушатель изменения статусов принтера.
	 *
	 * @param {PrintResp} response Ответ на запрос печати.
	 * @param {number} timeOut Таймаут на ожидание уведомления о событии printComplete.
	 */
	private printerNotificationListener(response: PrintResp, timeOut: number): Observable<string> {
		Logger.Log.i('PrintService', `printer notification listener is launched`)
			.console();

		let subscription: ISubscription;

		return new Observable<string>(observer => {
			subscription = this.listenNotify(PrinterEvents.DocStatusChanged, {
				onNotify: (notify: DocStatusChanged) => {
					try {
						Logger.Log.i('PrintService', `printer notification got notification: %s`, notify)
							.console();

						if (notify.docId && notify.docId === response.docId) {
							if (notify.docStatus) {
								switch (notify.docStatus) {
									case 'printComplete':
										Logger.Log.i('PrintService', `print complete: %s, will listener stopped`, notify)
											.console();

										subscription.unsubscribe();
										observer.next(response.docId);
										observer.complete();
										break;

									case 'printError':
										Logger.Log.e('PrintService', `print error: %s, will listener stopped`, notify)
											.console();
										this.printerIsBusy = false;

										subscription.unsubscribe();
										if (notify.errorDesc) {
											observer.error(new ApiError(notify.errorDesc ? notify.errorDesc : '',
												undefined, notify.errorCode
											));
										} else {
											observer.error('dialog.printer_unknown_error');
										}
										break;

									case 'printing':
									case 'in-queue':
									case 'in_queue': // TODO в логе имеется этот статус
										this.lastDocID = notify.docId;
										break;

									default:
										Logger.Log.e('PrintService', `unknown printer status: %s`, notify)
											.console();
										break;
								}
							}
						}
					} catch (err) {
						Logger.Log.e('PrintService', `Try-catch error: %s`, err)
							.console();
						observer.error('dialog.printer_unknown_error');
					}
				}
			});
		})
			.pipe(
				timeout(timeOut),
				catchError<string, string>(err => {
					Logger.Log.e('PrintService', `printer error: %s, will listener stopped`, err)
						.console();

					if (subscription) {
						subscription.unsubscribe();
					}

					if (err instanceof ApiError) {
						throw err;
					} else if (err instanceof TimeoutError) {
						throw {message: 'dialog.printer_time_out'};
					}

					throw {message: err};
				})
			);
	}
}
