<app-one-button-custom
	#chpDialog
	class="chp-dialog"
	data-cy="chp-dialog-inner"
	[buttonText]="changeStatus === ChangeStatus.Initial ? 'hamburger.confirm' : 'dialog.dialog_button_continue'"
	[buttonWidth]="300"
	[zIndex]="70"
	[buttonDisabled]="form.invalid && changeStatus === ChangeStatus.Initial"
	[showButtons]="changeStatus !== ChangeStatus.Busy"
	[canHide]="changeStatus !== ChangeStatus.Initial"
	[canClose]="changeStatus !== ChangeStatus.Busy"
	(confirm)="onDialogAction()"
	(cancel)="onCancelHandler()"
>
	<h3 class="chp-dialog__header" data-cy="chp-dialog-header">
		{{ 'hamburger.change_password' | translate }}
		<button
			class="button q-button"
			[class.button_theme_light-green]="!rulesDialogShown"
			[class.button_theme_green]="rulesDialogShown"
			data-cy="q-button"
			(click)="onClickHelp()"
		>?</button>
	</h3>

	<form
		class="ch-status"
		[class.is-visible]="changeStatus === ChangeStatus.Initial"
		data-cy="ch-status"
		[formGroup]="form"
	>
		<div class="chp-dialog__fields chp-fields"
			 data-cy="chp-dialog-fields"
		>
			<div class="chp-fields__item chp-fields__item_first"
				 data-cy="chp-fields-item"
			>
				<app-msl-input-with-eye
					#oldPass
					formControlName="oldpass"
					uniqID="oldpass"
					class="eye-input"
					[class.eye-input_green]="form.valid"
					data-cy="eye-input"
					[label]="'hamburger.enter_old_pass'"
					(confirm)="onConfirmOldPass()"
					(focused)="onFocus($event, 'oldPass')"
					(valueChanged)="onChangePass($event, 'oldpass')"
				></app-msl-input-with-eye>
			</div>
			<div class="chp-fields__item"
				 data-cy="chp-fields-item"
			>
				<app-msl-input-with-eye
					#newPass
					formControlName="newpass"
					uniqID="newpass"
					class="eye-input"
					[class.eye-input_green]="form.valid"
					data-cy="eye-input"
					[label]="'hamburger.enter_new_pass' | translate"
					[isDisabled]="form?.controls['oldpass']?.invalid"
					(confirm)="onConfirmNewPass()"
					(focused)="onFocus($event, 'newPass')"
					[errorState]="errorState1"
					(valueChanged)="onChangePass($event, 'newpass')"
					[minLength]="6"
					[maxLength]="8"
				></app-msl-input-with-eye>
				<div class="hints"
					 data-cy="hints"
				>
					<div class="hint"
						 *ngIf="form.controls['newpass']?.errors?.oldPasswords"
						 data-cy="hint"
					>
						{{ 'hamburger.old_password_entered' | translate }}
					</div>
					<div class="hint"
						 *ngIf="form.controls['newpass']?.errors?.asOpCode"
						 data-cy="hint"
					>
						{{ 'hamburger.pass_not_eq_opcode' | translate }}
					</div>
					<div class="hint"
						 *ngIf="form.controls['newpass']?.errors?.minlength"
						 data-cy="hint"
					>
						{{ 'hamburger.length_limit' | translate }}
					</div>
					<div class="hint"
						 *ngIf="form.controls['newpass']?.errors?.sameSuccDigits"
						 data-cy="hint"
					>
						{{ 'hamburger.no_same_succ_digits' | translate }}
					</div>
					<div class="hint"
						 *ngIf="form.controls['newpass']?.errors?.progressiveSuccDigits"
						 data-cy="hint"
					>
						{{ 'hamburger.no_progressive_succ_digits' | translate }}
					</div>
					<div class="hint"
						 *ngIf="form?.errors?.newPasswordsNotSame"
						 data-cy="hint"
					>
						{{ 'hamburger.passwords_not_same' | translate }}
					</div>
				</div>
			</div>
			<div class="chp-fields__item"
				 data-cy="chp-fields-item"
			>
				<app-msl-input-with-eye
					#newPass2
					formControlName="newpass2"
					uniqID="newpass2"
					class="eye-input"
					[class.eye-input_green]="form.valid"
					data-cy="eye-input"
					[label]="'hamburger.repeat_new_pass' | translate"
					[isDisabled]="form?.controls['newpass']?.invalid"
					(confirm)="onConfirmRepeatPass()"
					(focused)="onFocus($event, 'newPass2')"
					[errorState]="errorState2"
					(valueChanged)="onChangePass($event, 'newpass2')"
					[minLength]="6"
					[maxLength]="8"
				></app-msl-input-with-eye>
				<div class="hints" data-cy="hints">
					<div class="hint"
						 *ngIf="form.controls['newpass2']?.errors?.oldPasswords"
						 data-cy="hint"
					>
						{{ 'hamburger.old_password_entered' | translate }}
					</div>
					<div class="hint"
						 *ngIf="form.controls['newpass2']?.errors?.asOpCode"
						 data-cy="hint"
					>
						{{ 'hamburger.pass_not_eq_opcode' | translate }}
					</div>
					<div class="hint"
						 *ngIf="form.controls['newpass2']?.errors?.minlength"
						 data-cy="hint"
					>
						{{ 'hamburger.length_limit' | translate }}
					</div>
					<div class="hint"
						 *ngIf="form.controls['newpass2']?.errors?.sameSuccDigits"
						 data-cy="hint"
					>
						{{ 'hamburger.no_same_succ_digits' | translate }}
					</div>
					<div class="hint"
						 *ngIf="form.controls['newpass2']?.errors?.progressiveSuccDigits"
						 data-cy="hint"
					>
						{{ 'hamburger.no_progressive_succ_digits' | translate }}
					</div>
					<div class="hint"
						 *ngIf="form?.errors?.newPasswordsNotSame"
						 data-cy="hint"
					>
						{{ 'hamburger.passwords_not_same' | translate }}
					</div>
				</div>
			</div>
		</div>
	</form>

	<div class="ch-status ch-status_busy"
		 [class.is-visible]="changeStatus === ChangeStatus.Busy"
		 data-cy="ch-status"
	>
		<div class="circle-arc"
			 data-cy="circle-arc"
		></div>
	</div>

	<div class="ch-status {{ changeStatus }}"
		 [class.is-visible]="changeStatus === ChangeStatus.Success || changeStatus === ChangeStatus.Error"
		 data-cy="ch-status"
	>
		{{ changePasswordResult | translate }}
	</div>


</app-one-button-custom>


<div class="rules-dialog-bg" *ngIf="rulesDialogShown" (click)="onCloseRulesDialog()"></div>
<div class="rules-dialog js-rules-dialog" *ngIf="rulesDialogShown">
	<app-close-button (action)="onCloseRulesDialog()" class="rules-dialog__close"></app-close-button>
	<h4 class="rules-dialog__title">{{ 'hamburger.pass_requirements' | translate }}</h4>
	<ul class="pass-rules">
		<li class="pass-rule" [class.pass-rule_error]="form.controls['newpass']?.errors?.asOpCode">
			{{ 'hamburger.pass_not_eq_opcode' | translate }}
		</li>
		<li class="pass-rule" [class.pass-rule_error]="form.controls['newpass']?.errors?.minlength">
			{{ 'hamburger.length_limit' | translate }}
		</li>
		<li class="pass-rule" [class.pass-rule_error]="form.controls['newpass']?.errors?.sameSuccDigits">
			{{ 'hamburger.no_same_succ_digits' | translate }}
		</li>
		<li class="pass-rule" [class.pass-rule_error]="form.controls['newpass']?.errors?.progressiveSuccDigits">
			{{ 'hamburger.no_progressive_succ_digits' | translate }}
		</li>
	</ul>
</div>
