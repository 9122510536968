<div class="switch-button">
	<label *ngFor="let currentValue of values; let i = index; trackBy: trackByFn" class="switch-button__item">
		<input type="radio"
			   class="switch-button__input"
			   name="switch_radio"
			   [value]="currentValue"
			   (change)="onChangeHandler($event)"
			   [attr.checked]="(currentValue === value) || null"
		>
		<span class="switch-button__title switch-button__title_first"
			  [class.switch-button__title_disabled]="isDisabled"
		>{{ titles[i] | translate }}</span>
	</label>
</div>
