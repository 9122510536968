/**
 * Базовая модель для контейнеров всех XML тегов.
 */
export class Tag {
	/**
	 * Тег, обозначающий строку.
	 */
	static readonly LINE = 'line';

	/**
	 * Тег, обозначающий секцию.
	 */
	static readonly SECTION = 'section';

	/**
	 * Тег, обозначающий преобразование.
	 */
	static readonly CONVERT = 'convert';

	/**
	 * Тег, обозначающий условие.
	 */
	static readonly IF = 'if';

	/**
	 * Тег, обозначающий дополнительное условие.
	 */
	static readonly ELIF = 'elif';

	/**
	 * Тег, обозначающий конец условия.
	 */
	static readonly ENDIF = 'endif';

	/**
	 * Тег, обозначающий начало условия.
	 */
	static readonly THEN = 'then';

	/**
	 * Тег, обозначающий "иначе" в условии.
	 */
	static readonly ELSE = 'else';

	/**
	 * Тег, обозначающий штрих-код.
	 */
	static readonly BARCODE = 'barcode';

	/**
	 * Тег, обозначающий параметр.
	 */
	static readonly PARAM = 'param';

	/**
	 * Тег, обозначающий изображение.
	 */
	static readonly IMG = 'img';

	/**
	 * Тег, обозначающий установку значения переменной.
	 */
	static readonly SET = 'set';

	/**
	 * Тег, обозначающий объявление QR-кода.
	 */
	static readonly QRMAP = 'qrmap';

	/**
	 * Тег, обозначающий вывод строки QR-код.
	 */
	static readonly QR = 'qr';
}

/**
 * Начальная инициализация тега.
 * Выполняет копирование атрибутов в инстанс тега.
 *
 * @param {Tag} tag Инстанс тега.
 * @param {NamedNodeMap} attributes Атрибуты тега.
 */
export const init = (tag: Tag, attributes: NamedNodeMap): void => {
	if (attributes) {
		for (const key of Object.keys(attributes)) {
			const attribute = attributes[key];
			tag[attribute.name] = attribute.value;
		}
	}
};
