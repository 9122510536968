import { Injectable } from '@angular/core';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { AuthService } from '@app/core/services/auth.service';
import { from, Observable } from 'rxjs';
import { BOChangePasswordReq, BOChangePasswordResp } from '@app/core/net/http/api/models/bo-change-password';
import { HttpService } from '@app/core/net/http/services/http.service';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { EsapParams } from '@app/core/configuration/esap';
import { TranslateService } from '@ngx-translate/core';

/**
 * Сервис по смене пароля.
 */
@Injectable({
	providedIn: 'root'
})
export class ChangePasswordService {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {AppStoreService} appStoreService
	 * @param {AuthService} authService
	 * @param {HttpService} httpService
	 * @param {DialogContainerService} dialogContainerService
	 * @param {TranslateService} translateService
	 */
	constructor(
				private readonly appStoreService: AppStoreService,
				private readonly authService: AuthService,
				private readonly httpService: HttpService,
				private readonly dialogContainerService: DialogContainerService,
				private readonly translateService: TranslateService) { }

	/**
	 * Метод по смене пароля
	 */
	changePassword(oldPasswd: string, newPasswd: string): Observable<BOChangePasswordResp> {
		const request = new BOChangePasswordReq(this.appStoreService, this.authService.loginOperator_val, oldPasswd, newPasswd);
		request.params.set(EsapParams.LANG, this.translateService.currentLang);

		return (from(this.httpService.sendApi(request)) as Observable<BOChangePasswordResp>);
	}

}
