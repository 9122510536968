/**
 * Типы данных поддерживаемые логгером
 */
export type FormatType = string | number | boolean | object;

/**
 * Интерфейс для вывода логов в браузер (консоль разработчика)
 */
export interface IConsole {
	/**
	 * Вывод сообщения в консоль
	 */
	console(): void;
}

/**
 * Интерфейс для вывода логируемой информации разделенной по типам
 */
export interface ILogger {
	/**
	 * Вывод отладочного сообщения в консоль
	 * @param tag Тэг сообщения
	 * @param format Формат сообщения
	 * @param args Параметры сообщения
	 */
	d(tag: string, format: string, ...args: Array<FormatType>): IConsole;

	/**
	 * Вывод информационного сообщения в консоль
	 * @param tag Тэг сообщения
	 * @param format Формат сообщения
	 * @param args Параметры сообщения
	 */
	i(tag: string, format: string, ...args: Array<FormatType>): IConsole;

	/**
	 * Вывод сообщения об ошибке в консоль
	 * @param tag Тэг сообщения
	 * @param format Формат сообщения
	 * @param args Параметры сообщения
	 */
	e(tag: string, format: string, ...args: Array<FormatType>): IConsole;
}

/**
 * Модель, хранящая синглтон логгер-сервиса
 */
export class Logger {

	/**
	 * Ссылка на объект логгер-сервиса
	 * @private
	 */
	private static _logger: ILogger;

	/**
	 * Получение логгер-сервиса
	 */
	static get Log(): ILogger {
		return Logger._logger;
	}

	/**
	 * Установка логгер-сервиса
	 */
	static set Log(value: ILogger) {
		Logger._logger = value;
	}

	/**
	 * Функция для преобразования объекта в строку
	 * @param object Объект для преобразования
	 * @constructor
	 */
	static ToString(object: any): string {
		return JSON.stringify(object);
	}
}
