import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { IIconProperties } from '@app/shared/components/msl-button.component';

import { timer } from 'rxjs';

/**
 * Директива для простых манипуляций с элементами DOM.
 */
@Directive({
	selector: '[appMslComponent]'
})
export class MslComponentDirective implements OnInit {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Задать ширину элемента.
	 *
	 * @param {number} value Ширина в пикселях
	 */
	@Input()
	set width(value: number | string) {
		if (value) {
			this.renderer.setStyle(this.elementRef.nativeElement, 'width', isNaN(Number(value)) ? value : `${value}px`);
		}
	}

	/**
	 * Задать высоту элемента.
	 *
	 * @param {number} value Высота в пикселях
	 */
	@Input()
	set height(value: number | string) {
		if (value) {
			this.renderer.setStyle(this.elementRef.nativeElement, 'height', isNaN(Number(value)) ? value : `${value}px`);
		}
	}

	/**
	 * Задать максимальную высоту элемента.
	 *
	 * @param {number} value Высота в пикселях
	 */
	@Input()
	set maxHeight(value: number) {
		if (value) {
			this.renderer.setStyle(this.elementRef.nativeElement, 'max-height', `${value}px`);
		}
	}

	/**
	 * Задать иконку.
	 *
	 * @param {IIconProperties} value Свойства иконки
	 */
	@Input()
	set icon(value: IIconProperties) {
		if (value) {
			this.renderer.setStyle(this.elementRef.nativeElement,
				'background',
				`url(${value.url}) no-repeat center`);
			this.renderer.setStyle(this.elementRef.nativeElement,
				'background-size',
				`${value.width}px ${value.height}px`);
		}
	}

	/**
	 * Задать выравнивание метки кнопки.
	 *
	 * @param {string} value Выравнивание
	 */
	@Input()
	set textAlign(value: string) {
		if (value) {
			this.renderer.setStyle(this.elementRef.nativeElement, 'text-align', value);
		}
	}

	/**
	 * Пересчитать ширину контейнера на основе {@link HTMLElement.getBoundingClientRect getBoundingClientRect}
	 * и вычесть заданное значение.
	 *
	 * @param {number} value Значение, которое необходимо вычесть.
	 */
	@Input()
	set calcWidth(value: number) {
		if (Number.isInteger(value)) {
			const rect = (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect();
			this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${rect.width - value}px`);
		}
	}

	/**
	 * Задать Z-упорядочивание
	 *
	 * @param {number} value Значение Z-упорядочивания
	 */
	@Input()
	set zIndex(value: number) {
		if (value) {
			this.renderer.setStyle(this.elementRef.nativeElement, 'z-index', value);
		}
	}

	/**
	 * Признак, указывающий на необходимость масштабирования контейнера относительно базового (1366x768).
	 */
	@Input()
	scaleContainer = false;

	/**
	 * Задает определенную ширину элемента в пикселях при получении фокуса.
	 */
	@Input()
	inputWidthOnFocus = 0;

	/**
	 * Признак, указывающий на необходимость показывать элемент в видимой части окна прокрутки.
	 */
	@Input()
	scrollTo = false;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор директивы.
	 *
	 * @param {ElementRef} elementRef Элемент, к которому применяется директива
	 * @param {Renderer2} renderer Объект для работы с DOM в Angular
	 */
	constructor(
		private readonly elementRef: ElementRef,
		private readonly renderer: Renderer2
	) {}

	/**
	 * Обработчик события получения фокуса.
	 */
	@HostListener('focus', [])
	onFocusHandler(): void {
		if (this.inputWidthOnFocus > 0) {
			this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${this.inputWidthOnFocus}px`);
		}
	}

	/**
	 * Обработчик события потери фокуса.
	 */
	@HostListener('blur', [])
	onBlurHandler(): void {
		this.renderer.setStyle(this.elementRef.nativeElement, 'width', `100%`);
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		if (this.scaleContainer) {
			this.renderer.setStyle(this.elementRef.nativeElement, 'transform', `scale(${window.innerWidth / 1366})`);
		}

		if (this.scrollTo) {
			const options: ScrollIntoViewOptions = {behavior: 'smooth'};
			timer(10)
				.subscribe(() => (this.elementRef.nativeElement as HTMLElement).scrollIntoView(options));
		}
	}

}
