import {Component, Input, OnInit} from '@angular/core';
import {ITransactionTicket} from "@app/core/services/transaction/transaction-types";

@Component({
  selector: 'app-expenditure-receipt',
  templateUrl: './expenditure-receipt.component.html',
  styleUrls: ['./expenditure-receipt.component.scss']
})
export class ExpenditureReceiptComponent {
	private currentDate = new Date();

	localeDate = this.currentDate.toLocaleDateString();

	localeTime = this.currentDate.toLocaleTimeString();

	sum = '';

	@Input() set winSum(v: number) {
		this.sum = v.toFixed(2);
	}
}
