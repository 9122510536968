<div
	class="game-logo"
	data-cy="game-logo"
	[ngClass]="[
				!!model?.logoItemClass ? 'game-logo_game-code_' + model.code : '',
				!!logoViewStyle ? logoViewStyle : ''
				]">

	<div class="game-logo__left"
		 [ngClass]="'game-logo__left_game-code_' + model?.code"
		 data-cy="game-logo-left"
	>
		<img class="game-logo__image"
			 data-cy="game-logo-image"
			 [src]="model?.imgUrl"
			 [ngClass]="'game-logo__image_game-code_' + model?.code"
			 [alt]="model?.title | translate" />
	</div>
	<div class="game-logo__right"
		 data-cy="game-logo-right"
	>
		<div class="game-logo__title"
			 data-cy="game-logo-title"
		>{{ model?.title | translate }}</div>
	</div>
</div>
