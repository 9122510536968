import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrinterIconComponent } from './components/printer-icon/printer-icon.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule
	],
	declarations: [
		PrinterIconComponent
	],
	exports: [
		PrinterIconComponent
	]
})
export class PrinterStatusModule {}
