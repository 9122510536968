import { ApiRequest } from '@app/core/net/ws/api/types';
import { LogData } from '@app/core/net/ws/api/models/log/log-models';

// -----------------------------
//  Request
// -----------------------------

/**
 * Модель для вызова API Log Service - log
 */
export class LogReq extends ApiRequest {

	/**
	 * Данные для логирования
	 */
	data: LogData;

	/**
	 * Конструктор модели
	 */
	constructor() {
		super('com.msl.alt.web.terminal', 'log');
	}
}
