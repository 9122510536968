<div class="buttons-group"
	[ngClass]="[isDisabled ? 'buttons-group_disabled' : '',
				'buttons-group_' + buttonTextStyle,
				'buttons-group_theme_' + buttonGroupStyle
				]"
	 data-cy="buttons-group"
	>

	<div *ngIf="buttonGroupLabel"
		class="buttons-group__title blinking-inv"
		[ngClass]="'buttons-group__title_theme_' + buttonGroupStyle"
		[ngStyle]="{opacity: titleBlinking ? 0 : 1}"
		[innerHTML]="buttonGroupLabel"
		data-cy="buttons-group-title"
		>
	</div>

	<div class="buttons-group__items"
		 [ngClass]="'buttons-group__items_theme_' + buttonGroupStyle"
		 data-cy="buttons-group-items"
		 (click)="onButtonClickHandler($event)"
	>
		<button *ngFor="let button of buttons; trackBy: trackByFn"
			type="button"
			class="buttons-group__item"
			[ngClass]="'buttons-group__item_theme_' + buttonGroupStyle"
			data-cy="buttons-group-item"
			[attr.bg-index]="button.index"
			[class.buttons-group__item_selected]="button.selected"
			[class.buttons-group__item_disabled]="button.disabled || isDisabled"
			[class.buttons-group__item_first]="button.index === 0"
			[class.buttons-group__item_last]="button.index === buttons.length - 1"
			>
			{{ isNeededTranslation ? (button.label | translate) : button.label }}
		</button>
	</div>

</div>
