import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChangeLogComponent } from './components/change-log/change-log.component';
import { ChangeLogService } from './services/change-log.service';
import { SharedModule } from '@app/shared/shared.module';

/**
 * Модуль для информирования пользователя о текущей версии и возможностях терминала.
 */
@NgModule({
	imports: [
		CommonModule,
		SharedModule,
	],
	declarations: [
		ChangeLogComponent
	],
	exports: [
	],
	entryComponents: [
		ChangeLogComponent
	],
	providers: [
		ChangeLogService
	]
})
export class SystemInfoModule {}
