import { init, Tag } from '@app/tickets-print/parser/tag';

/**
 * Индекс штрих-кода
 */
type Subscript = '0' | '1';

/**
 * Модель xml тега <barcode>
 */
export class BarcodeTag extends Tag {

	/**
	 * Индекс штрих-кода
	 */
	readonly subscript: Subscript = '1';

	/**
	 * Содержимое тега
	 * @private
	 */
	private readonly barcodeData: Array<string> = [];

	/**
	 * Создание и инициализация на основе xml атрибутов
	 * @param {NamedNodeMap} attributes xml атрибуты
	 */
	constructor(attributes: NamedNodeMap) {
		super();
		init(this, attributes);
	}

	/**
	 * Установка содержимого тега
	 * @param {string} content Содержимое тега
	 */
	setText(content: string): void {
		this.barcodeData.push(content);
	}

	/**
	 * Установка параметров, тег <param>
	 * @param {string} content Содержимое тега
	 */
	setParam(content: string): void {
		this.barcodeData.push(content);
	}

	/**
	 * Возвращает содержимое тега
	 * @returns {string[]}
	 */
	getBarcodeData(): Array<string> {
		return this.barcodeData;
	}
}
