<div class="dropdown-list-component">
	<div class="dlc-wrapper">

		<!-- ЗАГОЛОВОК -->
		<div
			class="dlc-title"
			(click)="onClickTitleHandler()"
			>
			<label class="dlc-title__text">{{ (!!selectedItem && changeTitle && !isExpandedList ? selectedItem.label : titleText) | translate }}</label>
			<i class="material-icons dlc-title__icon">{{ isExpandedList ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
		</div>

		<!-- СПИСОК -->
		<div
			class="dlc-list"
			*ngIf="isExpandedList"
			(click)="onClickListItemHandler($event)"
			>
			<ng-container *ngFor="let item of dataProvider; let i = index; trackBy: trackByFn">
				<label
					class="dlc-item"
					[class.dlc-item_first]="i === 0"
					[class.dlc-item_last]="i === dataProvider.length - 1"
					[class.dlc-item_selected]="item.value === selectedItem?.value"
					[attr.value]="item.value"
					>
					{{ item.label | translate }}
				</label>
			</ng-container>
		</div>

	</div>
</div>
