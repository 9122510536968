import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, Subscription, timer } from 'rxjs';
import { URL_EMPTY, URL_OTHER, URL_REPORTING } from '@app/util/route-utils';
import { DurationHour, DurationMinute } from '@app/util/utils';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { DialogError, DialogInfo, ErrorCode } from '@app/core/error/dialog';
import { IError, NetError } from '@app/core/error/types';
import { HttpService } from '@app/core/net/http/services/http.service';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { Operator, TerminalRoles } from '@app/core/services/store/operator';
import { BarcodeReaderService } from '@app/core/barcode/barcode-reader.service';
import { LotteriesService } from '@app/core/services/lotteries.service';
import { ReportsService } from '@app/core/services/report/reports.service';
import { ApplicationAppId } from '@app/core/services/store/settings';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { StorageGetResp } from '@app/core/net/ws/api/models/storage/storage-get';
import { StorageKeys } from '@app/core/net/ws/api/models/storage/storage-models';
import { BonusPayTransactionService } from '@app/core/services/transaction/bonus-pay-transaction.service';
import { ChangeLogService } from '@app/sys-info/services/change-log.service';
import { HamburgerMenuService } from '@app/hamburger/services/hamburger-menu.service';
import { TotalCheckStorageService } from '@app/total-check/services/total-check-storage.service';
import { LogOutService } from '@app/logout/services/log-out.service';
import { ResendAuth2Req } from '@app/core/net/http/api/models/resend-auth-2';
import { IResponse } from '@app/core/net/http/api/types';
import { BOAuth2Req } from '@app/core/net/http/api/models/bo-auth-2';
import { SetPosReq, SetPosResp } from '@app/core/net/http/api/models/set-pos';
import { environment } from '@app/env/environment';
import { BOGetSIDReq, BOGetSIDResp } from '@app/core/net/http/api/models/bo-get-sid';
import { BOGetClientListReq } from '@app/core/net/http/api/models/bo-get-client-list';
import { PrintService } from '@app/core/net/ws/services/print/print.service';
import { IPrinterInfoResponse } from '@app/core/net/ws/api/types';
import { concatMap } from 'rxjs/operators';

// -----------------------------
//  Constants
// -----------------------------
/**
 * Константа для контроля присутствия оператора.
 */
const PRESENCE_CONTROL_TIMEOUT = DurationHour * 24;

/**
 * Таймаут для контроля неактивности оператора.
 */
const INACTIVITY_TIMEOUT = DurationMinute * 15;

/**
 * Таймаут для возможности повторной отправки SMS.
 */
export const SMS_TIMEOUT = 30000;

/**
 * Количество попыток отправки SMS.
 */
export const SMS_ATTEMPTS = 3;

/**
 * Сервис авторизации (терминала) и аутентификации оператора.
 */
@Injectable({
	providedIn: 'root'
})
export class AuthService {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Логин оператора.
	 */
	loginOperator_val: string;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Таймаут для контроля присутствия оператора (в минутах).
	 * @private
	 */
	private presenceControlTimeoutMin: number;

	/**
	 * Время для контроля присутствия оператора.
	 * @private
	 */
	private presenceControlTime: number;

	/**
	 * Подписка на таймер для контроля присутствия оператора.
	 * @private
	 */
	private presenceControlTimerSubscription: Subscription;

	/**
	 * Подписка на таймер для контроля неактивности оператора.
	 * @private
	 */
	private inactivityTimerSubscription: Subscription;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {Router} router Сервис маршрутизации.
	 * @param {AppStoreService} appStoreService Сервис хранилища приложения.
	 * @param {HttpService} httpService Сервис HTTP-запросов.
	 * @param {DialogContainerService} dialogInfoService Сервис диалоговых окон.
	 * @param {LotteriesService} lotteriesService Сервис лотерей.
	 * @param {ReportsService} reportsService Сервис отчетов.
	 * @param {TranslateService} translate Сервис переводов.
	 * @param {BarcodeReaderService} barcodeReaderService Сервис для работы со сканером штрих-кодов.
	 * @param {TotalCheckStorageService} totalCheckStorageService Сервис хранилища чеков.
	 * @param {StorageService} storageService Сервис браузерного хранилища.
	 * @param {ChangeLogService} changeLogService Сервис журнала изменений.
	 * @param {HamburgerMenuService} hamburgerMenuService Сервис гамбургер-меню.
	 * @param {BonusPayTransactionService} bonusPayTransactionService Сервис транзакций бонус-платежей.
	 * @param {LogOutService} logOutService Сервис выхода из системы.
	 * @param printService Сервис печати.
	 */
	constructor(
		private readonly router: Router,
		private readonly appStoreService: AppStoreService,
		private readonly httpService: HttpService,
		private readonly dialogInfoService: DialogContainerService,
		private readonly lotteriesService: LotteriesService,
		private readonly reportsService: ReportsService,
		private readonly translate: TranslateService,
		private readonly barcodeReaderService: BarcodeReaderService,
		private readonly totalCheckStorageService: TotalCheckStorageService,
		private readonly storageService: StorageService,
		private readonly changeLogService: ChangeLogService,
		private readonly hamburgerMenuService: HamburgerMenuService,
		private readonly bonusPayTransactionService: BonusPayTransactionService,
		private readonly logOutService: LogOutService,
		private readonly printService: PrintService
	) {}

	/**
	 * Процедура первого шага двухфакторной авторизации.
	 *
	 * @param {string} login Логин.
	 * @param {string} pass Пароль.
	 */
	auth1(login: string, pass: string): Promise<IResponse> {
		Logger.Log.i('AuthService', `login -> perform login...`)
			.console();

		this.totalCheckStorageService.clearHistory();
		this.hamburgerMenuService.deactivateCancelButton();

		this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.authorization_wait', {top: 50});

		Logger.Log.i('InitService', `loginOperator -> ${login}`)
			.console();

		return new Promise<IResponse>((resolve, reject) => {
			const boGetSID = new BOGetSIDReq(this.appStoreService, login, pass);
			this.httpService.sendApi(boGetSID)
				.then((boGetSidResp: BOGetSIDResp) => {

					this.appStoreService.operator.next(new Operator(boGetSidResp.user_id, boGetSidResp.sid, login, TerminalRoles.GUEST));

					const boGetClientList = new BOGetClientListReq(this.appStoreService);

					return this.httpService.sendApi(boGetClientList);
				})
				.then(resolve)
				.catch(reject);
		});
	}


	/**
	 * Процедура второго шага двухфакторной авторизации.
	 * @param {string} smsCode Код авторизации, который пользователь получил по альтернативному каналу двухфакторной авторизации.
	 * @param {string} multiUserID Идентификатор пользователя, который обнаружен на пользовательском компьютере.
	 * 							   Используется для отслеживания нескольких аккаунтов от одного и того же пользователя.
	 */
	auth2(smsCode: string, multiUserID?: string): Promise<IResponse> {
		const boAuth2 = new BOAuth2Req(this.appStoreService, smsCode, multiUserID);

		return this.httpService.sendApi(boAuth2);
	}

	/**
	 * Процедура третьего шага авторизации.
	 */
	auth3(): void {
		this.appStoreService.isLoggedIn$$.next(true);
		this.loadingDataChain()
			.subscribe({
				next: this.afterLoadingDataChain.bind(this),
				error: (error: IError) => {
					Logger.Log.e('AuthService', 'authTerminal -> ERROR: %s', error)
						.console();
					// Какая бы ни была ошибка, мы не можем работать с траблами на беке (и не только на беке)
					const dialogError = new DialogError(error.code || ErrorCode.OperAuth, error.message, error.messageDetails);
					this.dialogInfoService.showOneButtonError(dialogError, {
						click: this.logoutOperator.bind(this),
						text: 'dialog.dialog_button_continue'
					});
				}
			});
	}

	/**
	 * Отправить информацию о точке продажи
	 */
	setPos(): Promise<SetPosResp> {
		const setPosReq = new SetPosReq(this.appStoreService, this.appStoreService.Settings.termCode);

		return this.httpService.sendApi(setPosReq);
	}

	/**
	 * Метод повторной отправки СМС
	 */
	resendSMS(): Promise<IResponse> {
		Logger.Log.i('AuthService', `Resend SMS`)
			.console();

		this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.sending_sms');
		const resendAuth2 = new ResendAuth2Req(this.appStoreService);

		return this.httpService.sendApi(resendAuth2);
	}

	/**
	 * Процедура логаута оператора.
	 */
	logoutOperator(): void {
		Logger.Log.i('AuthService', `logoutOperator -> perform logout...`)
			.console();
		this.stopPresenceTimer();
		this.logOutService.logoutOperator();
	}

	/**
	 * Контроль присутствия оператора
	 */
	presenceControl(): void {
		if (this.appStoreService.isLoggedIn$$.value) {
			this.updatePresenceTime();
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Завершение загрузки и синхронизации цепочки данных после входа в систему.
	 */
	private afterLoadingDataChain(): void {
		this.setPresenceTimer(true);
		this.startPeripheralServices();
		this.checkLatestRunningVersionAndStore();
		this.changeLogService.requestChangelog();
		this.printService.getPrinterInfo()
			.subscribe((printInfoResp: IPrinterInfoResponse) => {
				localStorage.setItem('PRINTER_INFO', JSON.stringify(printInfoResp.printerInfo));
			});
	}

	/**
	 * Проверить последнюю версию приложения и сохранить ее в хранилище.
	 */
	private checkLatestRunningVersionAndStore(): void {
		Logger.Log.i('AuthService', `get the latest changelog version from storage...`)
			.console();

		// проверить незавершенную бонусную транзакцию
		// TODO: в дальнейшем перенести этот вызов до момета авторизации
		this.bonusPayTransactionService.checkAndCancelUnfinishedBonusTransaction()
			.subscribe();

		this.storageService.get(ApplicationAppId, [StorageKeys.LatestRunningVersion])
			.then(response => {
				Logger.Log.i('AuthService', `got version data from storage: %s`, response)
					.console();

				const item = (response as StorageGetResp).data.find(f => f.key === StorageKeys.LatestRunningVersion);
				let needToSaveCurrentVersion = false;

				// проверить существует ли версия в хранилище
				if (item && item.value) {
					const storageVersion = item.value;
					if (this.changeLogService.isNewSoftwareVersions(environment.version, storageVersion)) {
						Logger.Log.i('AuthService', `it's first time to launch this software`)
							.console();

						needToSaveCurrentVersion = true;
					}
				} else {
					needToSaveCurrentVersion = true;
				}

				// сохранить версию и показать изменения
				if (needToSaveCurrentVersion) {
					const data = {key: StorageKeys.LatestRunningVersion, value: environment.version};
					this.storageService.put(ApplicationAppId, [data])
						.then(putResponse => {
							Logger.Log.i('AuthService', `version %s successfully stored: %s`, putResponse)
								.console();

							this.changeLogService.showChangeLog(true);
						})
						.catch(error => {
							Logger.Log.e('AuthService', `can't store software version (%s): %s`, data, error)
								.console();
						});
				}
			})
			.catch(error => {
				Logger.Log.e('AuthService', `can't get the latest changelog version: %s`, error)
					.console();
			});
	}

	/**
	 * Выполнение цепочки действий от момента успешной авторизации оператора.
	 * Включает в себя обновление тиражей, отчетов, подключения периферийных сервисов
	 * и сервиса присутствия оператора на рабочем месте.
	 *
	 * @returns {Promise<void>}
	 */
	private loadingDataChain(): Observable<boolean> {
		return from(this.setupUserLanguage())
			.pipe(
				concatMap(() => from(this.lotteriesReload())),
				concatMap(() => from(this.reportsReload())),
				concatMap(() => from(this.routeLotteries()))
			);
	}

	/**
	 * Получить из хранилища дефолтный язык конкретного пользователя.
	 * Ключ задается в виде {@link StorageKeys.UserLanguage}_login
	 */
	private async setupUserLanguage(): Promise<void> {
		const key = `${StorageKeys.UserLanguage}_${this.loginOperator_val}`;
		let lang = 'ua';

		return this.storageService.get(ApplicationAppId, [key])
			.then(response => {
				Logger.Log.i('AuthService', `got default user language from storage: %s`, response)
					.console();

				const item = (response as StorageGetResp).data.find(f => f.key === key);
				if (item && item.value) {
					lang = item.value;
				}

				this.translate.use(lang);
			})
			.catch((error: IError) => {
				Logger.Log.e('AuthService', `operator login ERROR: %s`, error)
					.console();

				this.translate.use(lang);
			});
	}

	/**
	 * Запустить периферийные сервисы.
	 */
	private startPeripheralServices(): void {
		// если юзер - инженер, то не запускаем для него сервис баркодридера
		if (this.appStoreService.operator.value.accessLevel !== TerminalRoles.ENGINEER) {
			this.barcodeReaderService.startDetector();
		}
	}

	/**
	 * Перейти к домашнему экрану.
	 */
	private routeLotteries(): Promise<boolean> {
		const mi = this.appStoreService.menu.firstVisibleCentralMenuItem;
		// ВНИМАНИЕ КОСТЫЛЬ!
		// Если access_level = 1, то открываем по-умолчанию раздел Другое
		// https://jira.emict.net/browse/CS-4524
		const path = this.appStoreService.operator.value.accessLevel === 1 ? `${URL_REPORTING}/${URL_OTHER}` :
			mi ? mi.path : URL_EMPTY;

		return this.router.navigate([path]);
	}

	/**
	 * Выполняет процедура обновления тиражей на терминале из {@link LotteriesService.reload}.
	 *
	 * {@link https://confluence.emict.net/pages/viewpage.action?pageId=47022093}
	 * @returns {Promise<IError>}
	 */
	private lotteriesReload(): Promise<IError> {
		return this.lotteriesService.reload()
			.then((error: IError) => {
				if (error) {
					return new Promise<IError>(resolve => {
						const msg = error.code ? error.code : ErrorCode.DrawsService;
						this.dialogInfoService.showOneButtonError(new DialogError(msg, 'dialog.draws_update_error'), {
							click: resolve,
							text: 'dialog.dialog_button_continue'
						});
					});
				}
			});
	}

	/**
	 * Процедура обновления отчетов на терминале.
	 *
	 * @returns {Promise<IError>}
	 */
	private async reportsReload(): Promise<any> {
		return this.reportsService.reload()
			.then((error: IError) => {
				if (error) {
					return new Promise<IError>(resolve => {
						this.dialogInfoService.showOneButtonError(
							new DialogError(error.code
								? error.code
								: ErrorCode.ReportService, 'dialog.report_update_error'),
							{
								click: resolve,
								text: 'dialog.dialog_button_continue'
							}
						);
					});
				}
			})
			.catch((error: IError) => {
				return new Promise<IError>(resolve => {
					if (error instanceof NetError) {
						this.dialogInfoService.showOneButtonError(
							error,
							{
								click: () => {
									resolve(null);
								},
								text: 'dialog.dialog_button_continue'
							}
						);
					} else {
						this.dialogInfoService.showOneButtonError(
							new DialogError(error.code
								? error.code
								: ErrorCode.ReportService, 'dialog.report_update_error'),
							{
								click: resolve,
								text: 'dialog.dialog_button_continue'
							}
						);
					}
				});
			});
	}

	/**
	 * Обработчик таймера присутствия оператора.
	 * @private
	 */
	private presenceTimerHandler(): void {
		if (this.presenceControlTime > Date.now()) {
			Logger.Log.i('AuthService', 'presence timer is outdated')
				.console();

			this.presenceControlTimerSubscription.unsubscribe();
			this.setPresenceTimer(false);
		} else {
			Logger.Log.i('AuthService', 'presence timer is fire')
				.console();

			this.presenceControlTimerSubscription.unsubscribe();
			this.setInactivityTimer();

			this.translate.get('dialog.presence_timer_fire', {value: this.presenceControlTimeoutMin})
				.subscribe(value => {
					this.dialogInfoService.showOneButtonInfo('dialog.attention', new DialogInfo(value), {
						click: () => {
							this.inactivityTimerSubscription.unsubscribe();
							this.setPresenceTimer(true);
						},
						text: 'dialog.dialog_button_continue'
					});
				});
		}
	}

	/**
	 * Запуск механизма контроля присутствия оператора на рабочем месте.
	 *
	 * @param {boolean} clean Очистка таймера.
	 */
	private setPresenceTimer(clean: boolean): void {
		if (clean) {
			this.updatePresenceTime();
		}

		this.presenceControlTimerSubscription = timer(new Date(this.presenceControlTime))
			.subscribe(this.presenceTimerHandler.bind(this));
	}

	/**
	 * Обновить время присутствия оператора на рабочем месте.
	 */
	private updatePresenceTime(): void {
		this.presenceControlTime = Date.now() + PRESENCE_CONTROL_TIMEOUT;
		this.presenceControlTimeoutMin = PRESENCE_CONTROL_TIMEOUT / 1000 / 60;
	}

	/**
	 * Остановить таймер присутствия оператора на рабочем месте.
	 */
	private stopPresenceTimer(): void {
		if (this.presenceControlTimerSubscription) {
			this.presenceControlTimerSubscription.unsubscribe();
		}

		if (this.inactivityTimerSubscription) {
			this.inactivityTimerSubscription.unsubscribe();
		}
	}

	/**
	 * Установка таймера активности, по истечению которого выполняется принудительный логаут.
	 */
	private setInactivityTimer(): void {
		this.inactivityTimerSubscription = timer(new Date(Date.now() + INACTIVITY_TIMEOUT))
			.subscribe(() => {
				Logger.Log.d('AuthService', 'inactivity timer is fire')
					.console();
				this.logoutOperator();
			});
	}
}
