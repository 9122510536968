import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CENTRAL_MENU_ITEM_OTHER, CENTRAL_MENU_ITEM_REPORT } from '@app/core/services/store/menu';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { NavigationMenuComponent } from '@app/shared/components/navigation-menu.component';
import { ReportsService } from '@app/core/services/report/reports.service';

/**
 * Центральное навигационное меню.
 * Отображается на всех экранах, кроме страницы логина и некоторых под-страниц.
 */
@Component({
	selector: 'app-central-menu',
	templateUrl: './central-menu.component.html',
	styleUrls: ['./central-menu.component.scss']
})
export class CentralMenuComponent extends NavigationMenuComponent implements OnInit, OnDestroy {

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {Router} router Сервис маршрутизации
	 * @param {ReportsService} reportService Сервис отчетов
	 * @param {AppStoreService} appStoreService Сервис хранилища приложения
	 */
	constructor(
		readonly router: Router,
		private readonly reportService: ReportsService,
		readonly appStoreService: AppStoreService
	) {
		super();
	}

	/**
	 * Функция для отслеживания изменений в массиве меню.
	 * @param index Индекс элемента
	 * @param item Элемент
	 */
	trackByFn = (index, item: any) => index;

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.menuItems = this.appStoreService.menu.centralMenuList;

		// подписаться на изменение событий роутера
		this.router.events
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(e => {
				if (e instanceof NavigationEnd) {
					this.activateByPath(e.url);
				}
			});

		//
		this.reportService.ready$
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(v => {
				if (v) {
					if (!this.appStoreService.menu.centralMenuList.find(m => m.path === CENTRAL_MENU_ITEM_REPORT.path)) {
						this.appStoreService.menu.centralMenuList.push(CENTRAL_MENU_ITEM_REPORT);
						this.parseMenuItemsByUserRole();
					}

					if (!this.appStoreService.menu.centralMenuList.find(m => m.path === CENTRAL_MENU_ITEM_OTHER.path)) {
						this.appStoreService.menu.centralMenuList.push(CENTRAL_MENU_ITEM_OTHER);
						this.parseMenuItemsByUserRole();
					}
				} else {
					// TODO ??? доделать на случай повторной загрузки с ошибками
				}
			});
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}
}
