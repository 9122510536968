import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

/**
 * Типы компонента ввода.
 */
export type InputComponentType = 'text' | 'password' | 'date' | 'number';

/**
 * Компонент ввода.
 */
@Component({
	selector: 'app-msl-input',
	templateUrl: './msl-input.component.html',
	styleUrls: ['./msl-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: MslInputComponent,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: MslInputComponent,
			multi: true
		}
	]
})
export class MslInputComponent implements   ControlValueAccessor, Validator {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Метка компонента.
	 */
	@Input()
	placeholder: string;

	/**
	 * Тип компонента.
	 */
	@Input()
	type: InputComponentType = 'text';

	/**
	 * Текстовое значение компонента.
	 */
	@Input()
	value = '';

	/**
	 * Задает максимальную длину поля ввода.
	 */
	@Input()
	maxLength: number;

	/**
	 * Задает минимальную длину поля ввода.
	 */
	@Input()
	minLength: number;

	/**
	 * Признак недоступности поля
	 */
	@Input()
	inactive = false;

	/**
	 * Защищено ли поле?
	 */
	@Input()
	secured = false;


	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Кастомное событие <code>mslInputChange</code>.
	 * Генерируется при изменении значения компонента {@link value}.
	 *
	 * @type {EventEmitter<any>}
	 */
	@Output()
	readonly mslInputChange: EventEmitter<string> = new EventEmitter();

	/**
	 * Событие генерируется при нажатии клавиши Enter.
	 */
	@Output()
	readonly enterPressed = new EventEmitter<EventTarget>();

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Ссылка на элемент ввода.
	 */
	@ViewChild('inputElement', { static: true })
	inputElement: ElementRef;

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Унаследованное событие от базового класса.
	 * Вызывается в коллбеке при изменении значения UI-элемента
	 * @param value Передаваемое значение
	 */
	private onChange = (value: string) => {};

	/**
	 * Унаследованное событие от базового класса.
	 */
	private onTouched = () => {};

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Слушатель события <code>input</code>.
	 * Сообщает подписчикам, что компонент изменил свое значение {@link value}.
	 *
	 * @param event Передаваемое событие
	 */
	onInputChangeHandler(event): void {
		this.value = String(event.target.value);
		this.onChange(this.value);
		this.mslInputChange.emit(this.value);
	}

	/**
	 * Слушатель нажатия кнопки Enter на виртуальной клавиатуре.
	 *
	 * @param event Передаваемое событие
	 */
	onEnterPressedHandler(event): void {
		this.enterPressed.emit(event);
	}

	/**
	 * Установить фокус на элементе ввода.
	 */
	setFocus(): void {
		(this.inputElement.nativeElement as HTMLInputElement).focus();
	}

	/**
	 * Обновить значение переменной {@link value} и сгенерировать событие {@link mslInputChange}.
	 *
	 * @param {string} value Новое значение
	 */
	updateValue(value: string): void {
		this.value = value;
		this.mslInputChange.emit(this.value);
	}

	// -----------------------------
	//  ControlValueAccessor
	// -----------------------------
	/**
	 * Метод, который вызывается при изменении значения UI-элемента
	 * @param fn Передаваемая callback-функция
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Метод, который вызывается при касании к UI-элементу
	 * @param fn Передаваемая callback-функция
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	/**
	 * Метод для установки недоступности компонента
	 * @param isDisabled Флаг недоступности
	 */
	setDisabledState(isDisabled: boolean): void {
	}

	/**
	 * Метод для программного присвоения значения компоненту
	 * @param obj Значение
	 */
	writeValue(obj: any): void {
		this.value = obj;
	}

	// -----------------------------
	//  Validator
	// -----------------------------
	/**
	 * Регистрирует функцию обратного вызова для выполнения при изменении входных данных валидатора
	 * @param fn Функция обратного вызова
	 */
	registerOnValidatorChange(fn: () => void): void {
	}

	/**
	 * Валидация компонента
	 * @param control Компонент
	 */
	validate(control: AbstractControl): ValidationErrors | null {
		return control.valid ? undefined : control.errors;
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
}
