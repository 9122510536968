import { ElementRef } from '@angular/core';
import { StatusIconType } from './enums/status-icon-type.enum';
import { TopBarService } from './services/top-bar.service';

/**
 * Базовый класс для иконок, отображающих алерты и статусы.
 */
export abstract class BaseAlertIcon {
	/**
	 * Список типов иконок.
 	 */
	readonly StatusIconType = StatusIconType;

	/**
	 * Ссылка на собственный DOM-элемент.
	 */
	readonly elementRef: ElementRef;

	/**
	 * Сервис верхней панели.
	 */
	readonly topBarService: TopBarService;

	/**
	 * Обработчик клика по кнопке с иконкой.
	 *
	 * @param {StatusIconType} iconType Тип иконки.
	 * @param {string} statusText Текст статуса.
	 * @param {ElementRef} iconTarget Ссылка на DOM-элемент иконки.
	 */
	onClickIconHandler(iconType: StatusIconType, statusText: string, iconTarget: ElementRef): void {
		if (this.topBarService.statusPanelItem$$.value) {
			this.topBarService.hideStatusPanel();
		} else {
			this.topBarService.statusPanelItem$$.next({iconType, statusText, iconTarget});
		}
	}
}
