/**
 * Интерфейс, описывающий параметры кеша.
 * Используется в конструкторе кеша.
 */
interface CacheProperties {
	/** Максимальная длина кеша в байтах. */
	maxLength: number;

	/** Максимальное время жизни элемента кеша в милисеундах. */
	maxAge: number;

	/** Функция, подсчитывающая длину кеша. */
	lengthFunction: Function;
}

/**
 * Интерфейс, описывающий элемент кеша.
 */
interface CacheItem<S> {
	/** Данные типа {@link S} */
	data: S;

	/** Временная метка. */
	time: number;
}

/**
 * Интерфейс, описывающий функции кеша.
 */
interface ICache<T, S> {
	/** Получить элемент кеша по ключу. */
	get(key: T): S;

	/** Задать элемент кеша по ключу. */
	set(key: T, value: S): Map<T, CacheItem<S>>;

	/** Удалить элемент кеша по ключу. */
	del(key: T): boolean;

	/** Получить элементы кеша в виде пары ключ-значение. */
	entries(): IterableIterator<[T, CacheItem<S>]>;

	/** Получить список ключей. */
	keys(): IterableIterator<T>;

	/** Получить список значений кеша. */
	values(): IterableIterator<CacheItem<S>>;

	/** Очистить все элементы. */
	clear(): void;

	/** Получить количество элементов кеша. */
	size(): number;
}

/**
 * Вытесняющий кеш.
 * Позволяет ограничивать размер кеша по длине и времени хранения.
 */
export class Cache<T, S> implements ICache<T, S> {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Текущий размер кеша в байтах.
	 *
	 * @returns {number}
	 */
	get currentLength(): number {
		return this._currentLength;
	}

	/**
	 * Геттер, возвращающий функцию подсчета длины элемента кеша.
	 *
	 * @returns {Function}
	 */
	get lengthFunction(): Function {
		return this._lengthFunction;
	}

	/**
	 * Сеттер, устанавливающий функцию подсчета длины элемента кеша.
	 * @param value Функция для подсчета длины элемента кеша.
	 */
	set lengthFunction(value: Function) {
		this._lengthFunction = value;
	}

	/**
	 * Геттер максимального размера кеша в байтах.
	 *
	 * @returns {number}
	 */
	get maxLength(): number {
		return this._maxLength;
	}

	/**
	 * Сеттер максимального размера кеша в байтах.
	 * @param value Максимальный размер кеша в байтах.
	 */
	set maxLength(value) {
		this._maxLength = value;
	}

	/**
	 * Геттер максимального времени хранения данных.
	 *
	 * @returns {number}
	 */
	get maxAge(): number {
		return this._maxAge;
	}

	/**
	 * Сеттер максимального времени хранения данных.
	 * @param value Максимальное время хранения данных.
	 */
	set maxAge(value: number) {
		this._maxAge = value;
	}

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Карта, хранящая элементы кеша.
	 * @private
	 */
	private readonly cache: Map<T, CacheItem<S>>;

	/**
	 * Функция, подсчитывающая длину элемента кеша.
	 * @private
	 */
	private _lengthFunction: Function;

	/**
	 * Максимальное время хранения данных.
	 * @private
	 */
	private _maxAge: number;

	/**
	 * Максимальный размер кеша в байтах.
	 * @private
	 */
	private _maxLength: number;

	/**
	 * Текущий размер кеша в байтах.
	 * @private
	 */
	private _currentLength = 0;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор класса.
	 *
	 * @param {CacheProperties} properties Свойства кеша.
	 */
	constructor(
		properties?: CacheProperties
	) {
		this.cache = new Map<T, CacheItem<S>>();

		if (properties) {
			const {maxLength, maxAge, lengthFunction} = properties;
			this.maxLength = maxLength;
			this.maxAge = maxAge;
			this.lengthFunction = lengthFunction;
		}
	}

	/**
	 * Получить элемент кеша по ключу.
	 * @param key Ключ.
	 */
	get(key: T): S {
		return this.cache.has(key) ? this.cache.get(key).data : undefined;
	}

	/**
	 * Задать элемент кеша по ключу.
	 * @param key Ключ.
	 * @param value Значение.
	 */
	set(key: T, value: S): Map<T, CacheItem<S>> {
		this._currentLength += this._lengthFunction(value);
		this.purgeCache();

		return this.cache.set(key, {data: value, time: Date.now()});
	}

	/**
	 * Удалить элемент кеша по ключу.
	 * @param key Ключ.
	 */
	del(key: T): boolean {
		const item = this.cache.get(key);
		if (item) {
			this._currentLength -= this._lengthFunction(item.data);
		}

		return this.cache.delete(key);
	}

	/**
	 * Получить элементы кеша в виде пары ключ-значение.
	 */
	entries(): IterableIterator<[T, CacheItem<S>]> {
		return this.cache.entries();
	}

	/**
	 * Получить список ключей.
	 */
	keys(): IterableIterator<T> {
		return this.cache.keys();
	}

	/**
	 * Получить список значений.
	 */
	values(): IterableIterator<CacheItem<S>> {
		return this.cache.values();
	}

	/**
	 * Очистить кеш.
	 */
	clear(): void {
		this.cache.clear();
	}

	/**
	 * Получить размер кеша.
	 */
	size(): number {
		return this.cache.size;
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Удалить элементы кеша, которые превышают максимальный размер кеша или время хранения.
	 * @private
	 */
	private purgeCache(): void {
		// prepare entries sorted by time
		const arr = Array.from(this.cache.entries());
		arr.sort((a, b) => {
			if (a[1].time > b[1].time) {
				return 1;
			} else if (a[1].time < b[1].time) {
				return -1;
			}

			return 0;
		});

		// remove elements by time
		if (this._maxAge) {
			const oldestTime = new Date().getTime() - this._maxAge;
			const delArr = arr.filter(p => p[1].time < oldestTime);
			delArr.forEach(entry => this.del(entry[0]));
		}

		// remove the most oldest elements to reduce the cache length
		if (this._maxLength && this._currentLength > this._maxLength) {
			do {
				const entry = arr.shift();
				this.del(entry[0]);
			}
			while (arr.length !== 0 || this._currentLength >= this._maxLength);
		}
	}
}
