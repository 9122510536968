import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import {delay, filter, takeUntil} from 'rxjs/operators';

import {
	createUrlParam,
	isEnabledCentralMenu,
	PARAM_MANUAL_MENU_CLICK,
	URL_EMPTY
} from '@app/util/route-utils';
import { AnimationState } from '@app/util/animations';

import { Logger } from '@app/core/net/ws/services/log/logger';
import { BarcodeReaderService } from '@app/core/barcode/barcode-reader.service';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { InitService } from '@app/core/services/init.service';
import { AuthService } from '@app/core/services/auth.service';
import { ReportsService } from '@app/core/services/report/reports.service';
import { HamburgerMenuService } from '@app/hamburger/services/hamburger-menu.service';
import { AppType } from '@app/core/services/store/settings';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import {TerminalRoles} from "@app/core/services/store/operator";
import {CameraService} from "./modules/features/camera/services/camera.service";
import {ITransactionTicket} from "@app/core/services/transaction/transaction-types";
import {TransactionService} from "@app/core/services/transaction/transaction.service";
import {numberFromStringCurrencyFormat} from "@app/util/utils";

/**
 * Дефолтная ширина экрана ALT-терминала.
 */
export const ALT_WIDTH = 1366;

/**
 * Дефолтная высота экрана ALT-терминала.
 */
export const ALT_HEIGHT = 768;

/**
 * Главный компонент приложения.
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [
		'./app.component.scss'
	],
	providers: [
		InitService
	]
})
export class AppComponent implements OnInit, OnDestroy {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Тип приложения
	 */
	readonly AppType = AppType;

	/**
	 * Видимость центрального меню
	 */
	isVisibleCentralMenu: boolean;

	/**
	 * Готовность коммуникационного сервиса
	 */
	communicationReady = true;

	/**
	 * Признак домашней страницы.
	 */
	isHomePage = true;

	/**
	 * Суффикс класса страницы
	 */
	pageSuffix = '';

	accessLevel = TerminalRoles.GUEST;

	isLoggedIn = false;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {AppStoreService} appStoreService Сервис хранилища приложения.
	 * @param {InitService} initService Сервис инициализации приложения.
	 * @param {AuthService} authService Сервис авторизации.
	 * @param {Router} router Сервис маршрутизации.
	 * @param {ActivatedRoute} route Активный маршрут.
	 * @param {BarcodeReaderService} barcodeReaderService Сервис для чтения штрих-кодов со сканера.
	 * @param {ReportsService} reportService Сервис для работы с отчетами.
	 * @param {HamburgerMenuService} hamburgerMenuService Сервис для работы с гамбургер-меню.
	 * @param {LocationStrategy} location Сервис для работы с адресной строкой.
	 * @param cameraService
	 * @param transactionService
	 * @param {Document} document Объект для работы с DOM.
	 */
	constructor(
		readonly appStoreService: AppStoreService,
		readonly initService: InitService,
		readonly authService: AuthService,
		readonly router: Router,
		private readonly route: ActivatedRoute,
		readonly barcodeReaderService: BarcodeReaderService,
		private readonly reportService: ReportsService,
		private readonly hamburgerMenuService: HamburgerMenuService,
		private readonly location: LocationStrategy,
		private readonly cameraService: CameraService,
		private readonly transactionService: TransactionService,
		@Inject(DOCUMENT) private readonly document: Document
	) {
		// check if back or forward button is pressed.
		this.location.onPopState(() => {
			// set isBackButtonClicked to true.
			this.appStoreService.backClicked = true;

			return false;
		});
	}

	/**
	 * Обработчик клика по документу.
	 *
	 * @param event Переданный объект события.
	 */
	@HostListener('document:click', ['$event'])
	documentClick(event: MouseEvent): void {
		this.authService.presenceControl();

		// спрятать меню-гамбургер по клику в произвольное место
		if (!(event.target as Element).classList.contains('tbp-hamburger')
				&& !(event.target as Element).classList.contains('tbp-hamburger__icon')
				&& !(event.target as Element).closest('.hamburger-menu-container')
		) {
			this.hamburgerMenuService.switchMenu(AnimationState.collapsed);
		}
	}

	/**
	 * Изменить состояние меню-гамбургер.
	 *
	 * @param {boolean} isActive Признак активности меню.
	 */
	onActivateHamburgerMenuHandler(isActive: boolean): void {
		this.hamburgerMenuService.switchMenu(isActive ? AnimationState.expanded : AnimationState.collapsed);
	}

	/**
	 * Обработчик клика по кнопке "Домой".
	 * Выбрать первый доступный элемент меню из списка центрального меню.
	 * Если в центральном меню нет элементов, перейти на пустую страницу.
	 */
	onClickGoHomeHandler(): void {
		const mi = this.appStoreService.menu.firstVisibleCentralMenuItem;
		const path = mi ? mi.path : URL_EMPTY;
		Logger.Log.i('AppComponent', `onClickGoHomeHandler -> on click HOME will navigate to path: ${path}`)
			.console();
		this.appStoreService.backClicked = false;
		this.router.navigate([path], {queryParams: createUrlParam(PARAM_MANUAL_MENU_CLICK, 'home')})
			.catch(err => {
				Logger.Log.e('AppComponent', `can't navigate to path: ${err}`)
					.console();
			});
	}

	/**
	 * Обработчик события добавления строки в консоль.
	 * @param logStr Строка для вывода.
	 */
	onConsoleLog(logStr: string): void {
		this.appStoreService.consoleArray.unshift({text: logStr});
	}

	/**
	 * Обработчик события добавления ошибки в консоль.
	 * @param errStr Строка для вывода.
	 */
	onConsoleError(errStr: string): void {
		this.appStoreService.consoleArray.unshift({text: errStr, type: 'error'});
	}

	onImageLoaded(): void {
		this.transactionService.loadedImagesCount$.next(this.transactionService.loadedImagesCount$.value + 1);
		console.log(this.transactionService.loadedImagesCount$.value);
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {

		this.appStoreService.Settings.appType = null;

		// подписаться на изменение событий роутера
		this.router.events
			.pipe(
				delay(50),
				takeUntil(this.unsubscribe$$)
			)
			.subscribe(e => {
				if (e instanceof NavigationEnd) {
					// удалить фокус
					if (document.activeElement) {
						(document.activeElement as HTMLElement).blur();
					}

					const path = e.url.includes('?') ? e.url.split('?')[0] : e.url;

					this.appStoreService.previousURL = this.appStoreService.currentURL;
					this.appStoreService.currentURL = path;
					this.pageSuffix = path.split('/')
						.filter(el => !!el)
						.join('-');
					if (path.includes('registry')) {
						this.pageSuffix += ' page-registry';
					}

					// обновить видимость центрального меню
					this.isVisibleCentralMenu = isEnabledCentralMenu(path);

					// обновить признак домашней страницы
					const mi = this.appStoreService.menu.firstVisibleCentralMenuItem;
					this.isHomePage = mi ? path === mi.path : true;
				}
			});

		this.initService.init();

		this.appStoreService.operator
			.pipe(
				delay(50),
				filter((operator) => !!operator),
				takeUntil(this.unsubscribe$$)
			)
			.subscribe((operator) => {
				this.accessLevel = operator.accessLevel;
			});

		this.appStoreService.isLoggedIn$$
			.pipe(
				delay(50),
				filter((isLoggedIn) => !!isLoggedIn),
				takeUntil(this.unsubscribe$$)
			)
			.subscribe((isLoggedIn) => {
				this.isLoggedIn = isLoggedIn;
			});

		this.cameraService.init();

		// del
		console.log.bind(this.onConsoleLog);
		console.error.bind(this.onConsoleLog);
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.barcodeReaderService.stopDetector();

		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	protected readonly filter = filter;
	protected readonly numberFromStringCurrencyFormat = numberFromStringCurrencyFormat;
}
