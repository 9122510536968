import { Injectable } from '@angular/core';
import * as StringFormatter from 'sprintf-js';
import { environment } from '@app/env/environment';
import { FormatType, IConsole, ILogger, Logger } from '@app/core/net/ws/services/log/logger';
import { SimpleTransport, SimpleTransportType } from '@app/core/net/ws/simple-transport';
import { IResponse } from '@app/core/net/ws/api/types';
import { IError } from '@app/core/error/types';
import { LogReq } from '@app/core/net/ws/api/models/log/log';
import { TransportState } from '@app/core/net/ws/ws-models';
import { AppStoreService } from '@app/core/services/store/app-store.service';

/**
 * Уровни логирования.
 */
enum LogType {
	/**
	 * Отладочный уровень.
	 */
	Debug	= 'DEBUG',
	/**
	 * Информационный уровень.
	 */
	Info	= 'INFO',
	/**
	 * Уровень ошибки.
	 */
	Error	= 'ERROR'
}

/**
 * Интерфейс отложенного лога.
 */
export interface IDefferLogItem {
	/**
	 * Время создания записи.
	 */
	timestamp: number;
	/**
	 * Уровень логирования.
	 */
	level: LogType;
	/**
	 * Тег.
	 */
	tag: string;
	/**
	 * Сообщение.
	 */
	message: string;
}

/**
 * Сервис, реализующий функционал по взаимодействию с Log-сервисом.
 */
@Injectable({
	providedIn: 'root'
})
export class LogService extends SimpleTransport implements ILogger {

	/**
	 * Включить собственный лог.
	 * @private
	 */
	private static readonly enableSelfLog = false;

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Отложенные логи, полученные до момента запуска Log-сервиса.
	 */
	private readonly deferredLogs = new Array<IDefferLogItem>();

	// -----------------------------
	//  Static functions
	// -----------------------------

	/**
	 * Преобразует объекты в строку.
	 *
	 * @param {Array<FormatType>} args Массив аргументов.
	 */
	private static CastToString(args: Array<FormatType>): void {
		try {
			args.forEach((v, i) => {
				args[i] = typeof v === 'object' ? JSON.stringify(v) : `${v}`;
			});
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * Функция, которая ничего не делает.
	 * @private
	 */
	private static noOp(): void {
	}

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 */
	constructor(private readonly appStoreService: AppStoreService) {
		super(SimpleTransportType.Log);
		Logger.Log = this;
	}

	/**
	 * Инициализация, на этапе загрузки терминала, и подключение к Log-сервису.
	 *
	 * @param {string} url Ссылка на Log-сервис.
	 * @param {string} retryTimeout Время ожидания подключения.
	 */
	Init(url: string, retryTimeout: number): void {
		this.Log('starting initialization of log service');
		this.create(url, retryTimeout);
	}

	/**
	 * Отправить в Log-сервис отложенные сообщения.
	 */
	flushDeferredLogs(): void {
		Logger.Log.i('LogService', `flush deferred logs, %s items`, this.deferredLogs.length)
			.console();

		this.deferredLogs.forEach(v => this.log(v.tag, v.level, v.message));
		this.deferredLogs.splice(0);
	}

	// -----------------------------
	//  ILogger
	// -----------------------------

	/**
	 * Уровень логирования DEBUG.
	 *
	 * @param {string} tag модуль, тег
	 * @param {string} format шаблон сообщения
	 * @param {FormatType} args аргументы шаблона
	 * @returns {IConsole} интерфейс для возможности управления выводом в консоль браузера
	 */
	d(tag: string, format: string, ...args: Array<FormatType>): IConsole {
		if (environment.production) {
			return {console: LogService.noOp};
		}

		const message = this.format(format, ...args);
		this.log(tag, LogType.Debug, message);

		this.appStoreService.consoleArray.unshift({text: message});

		return this.console(LogType.Debug, message);
	}

	/**
	 * Уровень логирования INFO.
	 *
	 * @param {string} tag Модуль, тег.
	 * @param {string} format Шаблон сообщения.
	 * @param {FormatType} args Аргументы шаблона.
	 * @returns {IConsole} Интерфейс для возможности управления выводом в консоль браузера.
	 */
	i(tag: string, format: string, ...args: Array<FormatType>): IConsole {
		const message = this.format(format, ...args);
		this.log(tag, LogType.Info, message);

		if (this.getState() === TransportState.ERROR) {
			this.saveDefferLogItem(LogType.Info, tag, message);
		}

		this.appStoreService.consoleArray.unshift({text: message});

		return this.console(LogType.Info, message);
	}

	/**
	 * Уровень логирования ERROR.
	 *
	 * @param {string} tag модуль, тег
	 * @param {string} format шаблон сообщения
	 * @param {FormatType} args аргументы шаблона
	 * @returns {IConsole} интерфейс для возможности управления выводом в консоль браузера
	 */
	e(tag: string, format: string, ...args: Array<FormatType>): IConsole {
		const message = this.format(format, ...args);
		this.log(tag, LogType.Error, message);

		if (this.getState() === TransportState.ERROR) {
			this.saveDefferLogItem(LogType.Error, tag, message);
		}

		this.appStoreService.consoleArray.unshift({text: message, type: 'error'});

		return this.console(LogType.Error, message);
	}

	// -----------------------------
	//  SimpleTransport
	// -----------------------------
	/**
	 * Обработчик получения сообщения от веб-сокета (пустой, унаследованный).
	 * @param response Сообщение от веб-сокета.
	 * @protected
	 */
	protected onResult(response: IResponse): void {
		// if (LogService.debug) {
		//   console.log('Log service response: ' + response.requestId);
		// }
	}

	/**
	 * Обработчик логирования ошибки.
	 * @param error Полученная ошибка.
	 * @protected
	 */
	protected onError(error: IError): void {
		if (!environment.production) {
			console.error(`Log service receive error, code: ${error.code}, message: ${error.message}`);
		}
	}

	/**
	 * Обработчик логирования сообщения.
	 * @param message Переданное сообщение.
	 * @constructor
	 * @protected
	 */
	protected Log(message): void {
		if (!environment.production && LogService.enableSelfLog && !environment.terminal) {
			console.log(message);
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Сохранение сообщения в очередь.
	 * @param level Уровень логирования.
	 * @param tag Модуль, тег.
	 * @param message Сообщение для логирования.
	 * @private
	 */
	private saveDefferLogItem(level: LogType, tag: string, message: string): void {
		this.deferredLogs.push({timestamp: Date.now(), level, tag, message});
	}

	/**
	 * Отправка запроса на Log-сервис.
	 *
	 * @param {string} tag Модуль, тег.
	 * @param {LogType} type Уровень логирования.
	 * @param {string} message Сообщение для логирования.
	 */
	private log(tag: string, type: LogType, message: string): void {
		const log = new LogReq();
		log.data = {
			timestamp: `${Date.now()}`,
			moduleName: tag,
			typeMessage: type,
			message
		};

		this.sendApiRequest(log);
	}

	/**
	 * Форматирование сообщения.
	 * @param fmt Формат сообщения.
	 * @param args Аргументы формата.
	 * @private
	 */
	private format(fmt: string, ...args: Array<FormatType>): string {
		if (args && args.length > 0) {
			LogService.CastToString(args);

			return StringFormatter.sprintf(fmt, ...args);
		}

		return fmt;
	}

	/**
	 * Позволяет вывести сообщение в консоль браузера.
	 *
	 * @param {LogType} type Уровень логирования.
	 * @param {string} message Сообщение для логирования.
	 * @returns {IConsole} Интерфейс для возможности управления выводом в консоль браузера.
	 */
	private console(type: LogType, message: string): IConsole {
		if (!environment.production && !environment.terminal) {
			switch (type) {
				case LogType.Debug:
					return {console: console['debug'].bind(console, message)};
				case LogType.Info:
					return {console: console['info'].bind(console, message)};
				case LogType.Error:
					return {console: console['error'].bind(console, message)};
				default:
					break;
			}
		} else {
			return {console: LogService.noOp};
		}
	}
}
