import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

	@Input() appOnlyNumbers = true;

  	constructor() { }

	@HostListener('input', ['$event']) onInputChange(event: Event): void {
		if (this.appOnlyNumbers) {
			const inputElement = event.target as HTMLInputElement;
			const initialValue = inputElement.value;

			inputElement.value = initialValue.replace(/[^0-9]*/g, '');
			if (initialValue !== inputElement.value) {
				event.stopPropagation();
			}
		}
	}
}
