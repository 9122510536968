import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { TopBarPanelComponent } from './components/top-bar-panel/top-bar-panel.component';
import { LogOutModule } from '../log-out/log-out.module';
import { PrinterStatusModule } from '../printer-status/printer-status.module';
import { TopBarService } from './services/top-bar.service';
import { StatusPanelComponent } from './components/status-panel/status-panel.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		LogOutModule,
		PrinterStatusModule
	],
	declarations: [
		TopBarPanelComponent,
		StatusPanelComponent
	],
	exports: [
		TopBarPanelComponent
	],
	providers: [
		TopBarService
	]
})
export class TopBarModule {}
