import { Component, OnInit, ViewChild } from '@angular/core';
import { OneButtonCustomComponent } from '@app/shared/components/one-button-custom/one-button-custom.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { AppType } from '@app/core/services/store/settings';

/**
 * Интерфейс для разворачивающегося блока
 */
interface Spoiler {
	/**
	 * Заголовок блока
	 */
	title: string;
	/**
	 * Содержимое блока
	 */
	content: string;
	/**
	 * Состояние блока
	 */
	state: string;
}

/**
 * Компонент для отображения справки
 */
@Component({
	selector: 'app-help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.scss'],
	animations: [
		trigger('slideUpDown', [
			state('down', style({
				maxHeight: '9000px',
			})),
			state('up', style({
				maxHeight: '50px',
			})),
			transition('up => down', animate('400ms ease-in-out')),
			transition('down => up', animate('400ms ease-in-out'))
		])
	]
})
export class HelpComponent implements OnInit {

	/**
	 * Список блоков справки
	 */
	spoilers: Array<Spoiler>;

	/**
	 * Ссылка на диалоговое окно
	 */
	@ViewChild('helpDialog', {static: false}) helpDialog: OneButtonCustomComponent;

	/**
	 * Тип приложения
	 */
	readonly AppType = AppType;

	/**
	 * Конструктор компонента
	 * @param translateService Сервис для работы с мультиязычностью
	 * @param appStoreService Сервис для работы с хранилищем
	 */
	constructor(
		private readonly translateService: TranslateService,
		readonly appStoreService: AppStoreService
	) {}

	/**
	 * Функция для отслеживания изменений в массиве
	 * @param index Индекс элемента
	 */
	trackByFn(index: number): number {
		return index;
	}

	/**
	 * Показать диалоговое окно справки
	 */
	showDialog(): void {
		this.translateTexts();
		this.helpDialog.show();
	}

	/**
	 * Обработчик клика по блоку справки
	 * @param event Передаваемое событие
	 */
	onSpoilerClick(event): void {
		if (event.target.classList.contains('spoiler__header')) {
			const spIndex = event.target.dataset.index;
			this.spoilers[spIndex].state = this.spoilers[spIndex].state === 'up' ? 'down' : 'up';
		}
	}

	/**
	 * Функция, которая возвращает переводы для справки
	 * @private
	 */
	private translateTexts(): void {
		this.translateService.get('main_navigation.games')
			.subscribe(lotteries => {
				this.spoilers = [
					{
						title: 'hamburger.help.responsible',
						content: 'hamburger.help.text1',
						state: 'up'
					},
					{
						title: 'hamburger.help.about',
						content: 'hamburger.help.text2',
						state: 'up'
					},
					{
						title: `${lotteries}: ${this.translateService.instant('lottery.zabava.loto_zabava')}`,
						content: 'hamburger.help.text3',
						state: 'up'
					},
					{
						title: `${lotteries}: ${this.translateService.instant('lottery.tiptop.game_tip_name')}`,
						content: 'hamburger.help.text4',
						state: 'up'
					},
					{
						title: `${lotteries}: ${this.translateService.instant('lottery.tiptop.game_top_name')}`,
						content: 'hamburger.help.text5',
						state: 'up'
					},
					{
						title: `${lotteries}: ${this.translateService.instant('hamburger.help.eml')}`,
						content: 'hamburger.help.text6',
						state: 'up'
					},
					{
						title: `${lotteries}: ${this.translateService.instant('hamburger.help.bml')}`,
						content: 'hamburger.help.text7',
						state: 'up'
					},
					{
						title: `${lotteries}: ${this.translateService.instant('lottery.megalot.megalot_name')}`,
						content: 'hamburger.help.text8',
						state: 'up'
					},
					{
						title: `${lotteries}: ${this.translateService.instant('lottery.race_for_money.loto_gonka')}`,
						content: 'hamburger.help.text9',
						state: 'up'
					}
				];
			});
	}

	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.translateTexts();
	}

	/**
	 * Обработчик события закрытия диалогового окна
	 */
	onCloseHelp(): void {
		this.spoilers.forEach(elem => elem.state = 'up');
	}

}
