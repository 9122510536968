import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from '@app/env/environment';

console.log(`${'-'.repeat(80)}\nWeb Lottery Terminal v. ${environment.version}\n${'-'.repeat(80)}\n~~~ Start App`);

if (environment.production) {
	enableProdMode();
}

(async () => {
	const response = await fetch('/config-alt-web.json');
	const config = await response.json();

	environment['scanditKey'] = config.scanditKey;

	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.then(ref => {
			// ensure Angular destroys itself on hot reloads.
			if (window['ngRef']) {
				window['ngRef'].destroy();
			}

			window['ngRef'] = ref;
		})
		.catch(err => {
			// otherwise, log the boot error
			console.error(err);
		});
})();
