import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { URL_LOTTERIES } from '@app/util/route-utils';
import { AnimationState, hamburgerAnimation } from '@app/util/animations';
import { CENTRAL_MENU_ITEM_OTHER, CENTRAL_MENU_ITEM_REPORT } from '@app/core/services/store/menu';
import { ApplicationAppId, AppType, Language } from '@app/core/services/store/settings';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { AppError, IError } from '@app/core/error/types';
import { StorageKeys } from '@app/core/net/ws/api/models/storage/storage-models';
import { NavigationMenuComponent } from '@app/shared/components/navigation-menu.component';
import { BarcodeReaderService } from '@app/core/barcode/barcode-reader.service';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { ReportsService } from '@app/core/services/report/reports.service';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { AuthService } from '@app/core/services/auth.service';
import { ChangeLogService } from '@app/sys-info/services/change-log.service';
import { HamburgerMenuService } from '@app/hamburger/services/hamburger-menu.service';
import { TransactionService } from '@app/core/services/transaction/transaction.service';
import { IDropdownListItem } from '@app/shared/components/drop-down-list/drop-down-list.component';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { CancelFlag } from '@app/core/services/transaction/transaction-types';
import { DialogError } from '@app/core/error/dialog';
import { ChangePasswordComponent } from '@app/hamburger/components/change-password/change-password.component';
import { HelpComponent } from '@app/hamburger/components/help/help.component';
import { environment } from '@app/env/environment';
import { AnimationEvent } from '@angular/animations';

/**
 * Компонент "Меню-гамбургер".
 * Содержит основные элементы для навигации по терминалу.
 */
@Component({
	selector: 'app-hamburger-menu',
	templateUrl: './hamburger-menu.component.html',
	styleUrls: ['./hamburger-menu.component.scss'],
	animations: [
		hamburgerAnimation
	]
})
export class HamburgerMenuComponent extends NavigationMenuComponent implements OnInit, OnDestroy {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Тип приложения
	 */
	readonly AppType = AppType;

	/**
	 * Версия сборки
	 */
	buildVersion = '';

	/**
	 * Компонент смены пароля
	 */
	@ViewChild('chpDialog', {static: false}) chpDialog: ChangePasswordComponent;

	/**
	 * Компонент справки
	 */
	@ViewChild('helpDialog', {static: false}) helpDialog: HelpComponent;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {HamburgerMenuService} hamburgerMenuService Сервис меню-гамбургер
	 * @param {TranslateService} translate Сервис переводов
	 * @param {Router} router Сервис маршрутизации
	 * @param {ReportsService} reportService Сервис отчетов
	 * @param {AppStoreService} appStoreService Сервис хранилища приложения
	 * @param {StorageService} storageService Сервис браузерного хранилища
	 * @param {AuthService} authService Сервис авторизации
	 * @param {ChangeLogService} changeLogService Сервис журнала изменений
	 * @param {BarcodeReaderService} barcodeReaderService Сервис для работы считывателя штрих-кодов
	 * @param {TransactionService} transactionService Сервис транзакций
	 * @param {DialogContainerService} dialogContainerService Сервис диалоговых окон
	 */
	constructor(
		readonly hamburgerMenuService: HamburgerMenuService,
		readonly translate: TranslateService,
		readonly router: Router,
		private readonly reportService: ReportsService,
		readonly appStoreService: AppStoreService,
		private readonly storageService: StorageService,
		private readonly authService: AuthService,
		private readonly changeLogService: ChangeLogService,
		private readonly barcodeReaderService: BarcodeReaderService,
		private readonly transactionService: TransactionService,
		private readonly dialogContainerService: DialogContainerService
	) {
		super();
	}

	/**
	 * Обработчик нажатия на элементе меню.
	 *
	 * @param {MouseEvent} event Передаваемое событие
	 */
	onClickHamburgerMenuItemHandler(event: MouseEvent): void {
		const el: HTMLLIElement = (event.target as HTMLElement).closest('.hamburger-menu__item');
		const idx = el && Number.isInteger(el.value) ? el.value : -1;

		if (idx >= 0) {
			const item = this.menuItems[idx];
			super.onClickMenuItemHandler(event);
			if (!(item.isDisabled || item.dontHideMenu)) {
				this.hamburgerMenuService.switchMenu(AnimationState.collapsed);
			}
		}
	}

	/**
	 * Обработчик нажатия на кнопку "Отменить последнюю транзакцию".
	 */
	onClickCancelLast(): void {
		const subscription = this.showDialogToChooseReasonOfCancel()
			.pipe(filter(f => !!f))
			.subscribe(value => {
				subscription.unsubscribe();
				// this.confirmCancelHandler(value);
				this.cancelTransaction(value);
			});
	}

	/**
	 * Изменить язык на клиенте.
	 *
	 * @param {string} lang Язык, который будет применен.
	 */
	onClickChangeLangHandler(lang: string): void {
		if (this.translate.currentLang !== lang) {

			this.translate.use(lang);
			this.appStoreService.Settings.lang = lang as Language;

			const login = this.authService.loginOperator_val;
			const data = [{
				key: `${StorageKeys.UserLanguage}_${login}`,
				value: lang
			}];

			this.storageService.put(ApplicationAppId, data)
				.then(() => {
					Logger.Log.i('HamburgerMenuComponent', `language (%s) stored for user (%s)`, lang, login)
						.console();
				})
				.catch((error: IError) => {
					Logger.Log.e('HamburgerMenuComponent', `can't store default language (%s)`, error)
						.console();
				});

			this.changeLogService.requestChangelog();
		}
	}

	/**
	 * Вывести диалоговое окно с информацией об изменении в версии ПО на данном терминале.
	 */
	onClickAboutHandler(): void {
		this.hamburgerMenuService.switchMenu(AnimationState.collapsed);
		this.changeLogService.showChangeLog();
	}

	/**
	 * Обработчик клика по кнопке смены пароля.
	 */
	onClickChangePasswordHandler(): void {
		this.chpDialog.showDialog();
	}

	/**
	 * Обработчик клика по кнопке "Помощь".
	 */
	onClickHelpHandler(): void {
		this.helpDialog.showDialog();
	}

	/**
	 * Обработчик клика по кнопке "Отчеты".
	 */
	onCloseMenuHandler(): void {
		this.hamburgerMenuService.switchMenu(AnimationState.collapsed);
	}

	/**
	 * Обработчик события начала анимации.
	 * @param event Передаваемое событие
	 */
	animationStarted(event: AnimationEvent): void {
		if (event.toState === 'expanded') {
			(event.element as HTMLElement).style.display = 'block';
		}

	}

	/**
	 * Обработчик события окончания анимации.
	 * @param event Передаваемое событие
	 */
	animationDone(event: AnimationEvent): void {
		if (event.toState === 'collapsed') {
			(event.element as HTMLElement).style.display = 'none';
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Показать диалог с выбором причины отмены последней транзакции.
	 *
	 * @returns {Subject<IDropdownListItem>}
	 */
	private showDialogToChooseReasonOfCancel(): Subject<IDropdownListItem> {
		const CANCEL_FLAGS: Array<IDropdownListItem> = [
			{value: '11', label: 'cancel-transaction.msg_1'},
			{value: '12', label: 'cancel-transaction.msg_2'},
			{value: '13', label: 'cancel-transaction.msg_3'}
		];

		const dialogResult = new Subject<IDropdownListItem>();
		this.dialogContainerService.showTwoButtonsWithDropdown('cancel-transaction.cancel_transaction', '', CANCEL_FLAGS,
			{
				value: undefined,
				label: 'cancel-transaction.select_reason'
			},
			{
				first: {
					text: 'cancel-transaction.cancel'
				},
				second: {
					text: 'header.back',
					click: this.rejectCancelHandler.bind(this)
				}
			},
			dialogResult
		);

		return dialogResult;
	}

	/**
	 * Обработчик отказа от отмены последней транзакции.
	 * @private
	 */
	private rejectCancelHandler(): void {
		Logger.Log.i('HamburgerMenuComponent', `rejectCancelHandler -> user rejected canceling`)
			.console();
		this.routeLotteries();
	}

	/**
	 * Отменить последнюю транзакцию.
	 * @param item Выбранный пользователем элемент списка причин отмены
	 * @private
	 */
	private cancelTransaction(item: IDropdownListItem): void {
		Logger.Log.i('HamburgerMenuComponent',
			`cancelTransaction -> user selected reason: ${this.translate.instant(item.label)} (cancel flag ${item.value})`)
			.console();

		this.transactionService.cancelLast(item.value as CancelFlag)
			.then(this.routeLotteries.bind(this))
			.catch((error: IError) => {
				Logger.Log.e('HamburgerMenuComponent', `cancelTransaction -> last transaction cancel ERROR: ${error.message}`)
					.console();

				// обработать ошибку при отмене последней транзакции
				if (error instanceof AppError) {
					// отобразить ошибку (AppError) отмены последней транзакции
					this.dialogContainerService.showOneButtonError(new DialogError(error.code, error.message), {
						click: () => this.routeLotteries(),
						text: 'dialog.dialog_button_continue'
					});
				} else {
					this.transactionService.setCannotBeUndoneState()
						.then(() => this.routeLotteries())
						.catch(() => this.routeLotteries());
				}
			});
	}

	/**
	 * Перейти на экран лотерей.
	 * @private
	 */
	private routeLotteries(): void {
		this.router.navigate([URL_LOTTERIES])
			.catch(err =>
				Logger.Log.e('HamburgerMenuComponent', `routeLotteries -> can't navigate to lotteries screen: ${err}`)
					.console()
			);
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.hamburgerMenuService.switchMenu(AnimationState.collapsed);
		this.menuItems = this.appStoreService.menu.hamburgerList;

		//
		this.appStoreService.isLoggedIn$$
			.pipe(
				takeUntil(this.unsubscribe$$),
				filter(f => f)
			)
			.subscribe(() => {
				this.parseMenuItemsByUserRole();
				this.buildVersion = environment.version;
			});

		//
		this.reportService.ready$
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(v => {
				if (v) {
					const itemPos = environment.enableActions ? 4 : 3;
					if (!this.appStoreService.menu.hamburgerList.find(m => m.path === CENTRAL_MENU_ITEM_OTHER.path)) {
						this.appStoreService.menu.hamburgerList.splice(itemPos, 0, CENTRAL_MENU_ITEM_OTHER);
						this.parseMenuItemsByUserRole();
					}

					if (!this.appStoreService.menu.hamburgerList.find(m => m.path === CENTRAL_MENU_ITEM_REPORT.path)) {
						this.appStoreService.menu.hamburgerList.splice(itemPos, 0, CENTRAL_MENU_ITEM_REPORT);
						this.parseMenuItemsByUserRole();
					}
				} else {
					// TODO ??? доделать на случай повторной загрузки с ошибками
				}
			});

		// подписаться на изменение событий роутера
		this.router.events
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(e => {
				if (e instanceof NavigationEnd) {
					this.activateByPath(e.url);
				}
			});

		// закрывать меню после получения любого валидного баркода
		this.barcodeReaderService.barcodeSubject$$
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(() => {
				this.hamburgerMenuService.switchMenu(AnimationState.collapsed);
			});
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

}
