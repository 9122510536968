<div class="msl-input-container"
	 [class.msl-input-container_inactive]="inactive"
	 data-cy="msl-input-container"
>
	<input
		#inputElement
		required
		autocomplete="off"
		pattern="[0-9]*"
		inputmode="numeric"
		[ngClass]="value === '' ? 'empty-value' : ''"
		[class.secured]="secured"
		[type]="type"
		[value]="value"
		[attr.maxlength]="maxLength"
		[attr.minlength]="minLength"
		data-cy="msl-input-element"
		(input)="onInputChangeHandler($event)"
	/>
	<label data-cy="msl-input-label">
		<span data-cy="msl-input-placeholder">{{ placeholder }}</span>
	</label>
</div>
