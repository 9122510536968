import { ApiRequest, IResponse, KeyValue } from '@app/core/net/ws/api/types';

/**
 * Модель для вызова API Storage Service - put
 */
export class StoragePutReq extends ApiRequest {
	/**
	 * Данные для сохранения
	 */
	data: Array<KeyValue>;

	/**
	 * Конструктор модели
	 * @param appId Идентификатор приложения
	 */
	constructor(appId: string) {
		super(appId, 'put');
	}
}

/**
 * Модель ответа на запрос API Storage Service - put
 */
export interface StoragePutResp extends IResponse {
	/**
	 * Сохраненные данные
	 */
	data: Array<KeyValue>;
}
