import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GreenButtonStyle } from '@app/util/utils';

/**
 * Компонент диалогового окна с одной кнопкой.
 */
@Component({
	selector: 'app-one-button-custom',
	templateUrl: './one-button-custom.component.html',
	styleUrls: ['./one-button-custom.component.scss']
})
export class OneButtonCustomComponent  {

	/**
	 * Флаг, разрешающий закрытие окна по клику на крестик.
	 */
	@Input()
	canClose = true;

	/**
	 * Флаг, разрешающий закрытие окна по клику на основную кнопку.
	 */
	@Input()
	canHide = true;

	/**
	 * Текст на кнопке.
	 */
	@Input()
	buttonText = '';

	/**
	 * Деактивирована ли кнопка.
	 */
	@Input()
	buttonDisabled = false;

	/**
	 * Ширина окна.
	 */
	@Input()
	width = undefined;

	/**
	 * Высота окна.
	 */
	@Input()
	height = undefined;

	/**
	 * Флаг, отвечающий за отображение кнопок.
	 */
	@Input()
	showButtons = true;

	/**
	 * Ширина кнопки.
	 */
	@Input()
	buttonWidth = 210;

	/**
	 * Позиция окна по Z-оси.
	 */
	@Input()
	zIndex = 85;

	/**
	 * Стиль кнопки.
	 */
	@Input()
	buttonStyle: GreenButtonStyle = GreenButtonStyle.green_fill;

	/**
	 * Флаг, отвечающий за отображение окна.
	 */
	@Input()
	isVisible = false;

	/**
	 * Внутренний отступ окна.
	 */
	@Input()
	padding = undefined;

	/**
	 * Событие, которое будет вызвано при нажатии на кнопку.
	 */
	@Output()
	readonly confirm: EventEmitter<any> = new EventEmitter();

	/**
	 * Событие, которое будет вызвано при нажатии на крестик.
	 */
	@Output()
	readonly cancel: EventEmitter<any> = new EventEmitter();

	// -----------------------------
	//  Public properties
	// -----------------------------

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Обработчик нажатия на кнопку.
	 * @param evt Передаваемое событие.
	 */
	onConfirm(evt): void {
		if (this.canHide) {
			this.hide();
		}
		this.confirm.emit(evt);
	}

	/**
	 * Обработчик нажатия на крестик.
	 * @param evt Передаваемое событие.
	 */
	onCancel(evt): void {
		this.hide();
		this.cancel.emit(evt);
	}

	/**
	 * Показать окно.
	 */
	show(): void {
		this.isVisible = true;
	}

	/**
	 * Скрыть окно.
	 */
	hide(): void {
		this.isVisible = false;
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------

}
