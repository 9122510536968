import { Logger } from '@app/core/net/ws/services/log/logger';
import { convertDateToISOFormat, isDateString } from '@app/util/utils';
import {
	registerDecorator,
	ValidationArguments,
	ValidationOptions,
	ValidatorConstraint,
	ValidatorConstraintInterface
} from 'class-validator';

/**
 * Декоратор для проверки является ли указанное поле датой и приведение к нужному формату даты в таком случае.
 */
@ValidatorConstraint({async: true})
export class IsDateFormatConstraint implements ValidatorConstraintInterface {

	/**
	 * Функция проверки в декораторе.
	 * @param value Значение поля.
	 * @param args Дополнительные параметры.
	 */
	validate(value: any, args?: ValidationArguments): Promise<boolean> | boolean {
		if (typeof value === 'string') {
			if (!isDateString(value)) {
				const iso = convertDateToISOFormat(value);
				if (!Number.isNaN(Date.parse(iso))) {
					args.object[args.property] = iso;
					Logger.Log.d('IsDateFormatConstraint', `set: ${args.property} to: ${iso}`)
						.console();

					return true;
				}
			} else {
				return true;
			}
		}

		return false;
	}

}

/**
 * Декоратор для проверки является ли указанное поле денежным форматом.
 */
@ValidatorConstraint({async: true})
export class IsCurrencyFormatConstraint implements ValidatorConstraintInterface {

	/**
	 * Функция проверки в декораторе.
	 * @param value Значение поля.
	 * @param args Дополнительные параметры.
	 */
	validate(value: any, args?: ValidationArguments): Promise<boolean> | boolean {
		if (typeof value === 'string') {
			const tokens = value.match(/(\d+)\.(\d{2})/);
			if (tokens !== null && tokens.length === 3) {
				return true;
			}
		}

		return false;
	}
}

/**
 * Регистрация декоратора для проверки поля на соответствие формату даты.
 *
 * @returns {(object: Object, propertyName: string) => void}
 */
export const IsDateFormat = (): (object: Object, propertyName: string) => void => {
	const options: ValidationOptions = { message: 'invalid date format' };

	return (object: Object, propertyName: string) => {
		registerDecorator({
			target: object.constructor,
			propertyName,
			options,
			constraints: [],
			validator: IsDateFormatConstraint
		});
	};
};

/**
 * Регистрация декоратора для проверки поля на соответствие денежному формату
 *
 * @returns {(object: Object, propertyName: string) => void}
 */
export const IsCurrencyFormat = (): (object: Object, propertyName: string) => void => {
	const options: ValidationOptions = {message: 'invalid currency format'};

	return (object: Object, propertyName: string) => {
		registerDecorator({
			target: object.constructor,
			propertyName,
			options,
			constraints: [],
			validator: IsCurrencyFormatConstraint
		});
	};
};
