<div class="results-navigation-button-container" data-cy="results-navigation-button-container">

	<!-- КНОПКА НАЗАД -->
	<button type="button"
			class="button button_theme_light-green rnbc-prev"
			data-cy="rnbc-prev"
			[attr.disabled]="!isEnabledGoBack || null"
			(click)="goBack.emit()"
	>
		{{ 'lottery.before' | translate }}
	</button>

	<!-- КНОПКА ВПЕРЕД -->
	<button type="button"
			class="button button_theme_light-green rnbc-next"
			data-cy="rnbc-next"
			[attr.disabled]="!isEnabledGoForward || null"
			(click)="goForward.emit()"
	>
		{{ 'lottery.later' | translate }}
	</button>

</div>
