import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { HamburgerMenuComponent } from './components/hamburger-menu/hamburger-menu.component';
import { HamburgerMenuService } from './services/hamburger-menu.service';
import { SystemInfoModule } from '@app/sys-info/system-info.module';
import { CoreModule } from '@app/core/core.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpComponent } from './components/help/help.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule.forChild(),
		SystemInfoModule,
		CoreModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule
	],
	declarations: [
		HamburgerMenuComponent,
		ChangePasswordComponent,
		HelpComponent
	],
	exports: [
		HamburgerMenuComponent
	],
	providers: [
		HamburgerMenuService
	]
})
export class HamburgerModule {}
