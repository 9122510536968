import { Injectable, NgModule } from '@angular/core';
import { PreloadingStrategy, Route, RouterModule, Routes } from '@angular/router';

import { Observable, of } from 'rxjs';

import { InitGuard } from '@app/core/guards/init.guard';
import { AuthGuard } from '@app/core/guards/auth.guard';

import {
	URL_ACTIONS,
	URL_AUTH,
	URL_EMPTY,
	URL_GONKA,
	URL_INSTANT,
	URL_KARE,
	URL_LOTTERIES,
	URL_MEGALOT,
	URL_NOT_FOUND,
	URL_RESULTS,
	URL_SPORTPROGNOZ,
	URL_TICKETS,
	URL_TIPTOP,
	URL_TMLBML,
	URL_ZABAVA, URL_REPORTING
} from '@app/util/route-utils';
import { NavigationGuard } from '@app/core/guards/navigation.guard';

/**
 * Кастомная стратегия загрузки модулей. При наличии свойства <code>data.preload = true</code> модуль будет предзагружен.
 */
@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
	/**
	 * Метод для предзагрузки модуля.
	 * @param route Маршрут.
	 * @param load Функция обратного вызова для загрузки модуля.
	 * @return Observable<any> Результат загрузки модуля.
	 */
	preload(route: Route, load: () => Observable<any>): Observable<any> {
		return route.data && route.data.preload ? load() : of(null);
	}
}

/**
 * Список основных маршрутов.
 */
export const ROUTES: Routes = [

	// -----------------------------
	//  Auth
	// -----------------------------

	{
		path: URL_AUTH,
		loadChildren: () => import('@app/auth/auth.module')
			.then(m => m.AuthModule),
		canActivate: [
			InitGuard
		],
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Lotteries/Results
	// -----------------------------

	{
		path: URL_LOTTERIES,
		loadChildren: () => import('@app/lotteries/lotteries.module')
			.then(m => m.LotteriesModule),
		data: {
			mode: URL_LOTTERIES
		},
		canActivate: [
			AuthGuard
		],
		canDeactivate: [
			NavigationGuard
		]
	},
	{
		path: URL_RESULTS,
		loadChildren: () => import('@app/lotteries/lotteries.module')
			.then(m => m.LotteriesModule),
		data: {
			mode: URL_RESULTS
		},
		canActivate: [
			AuthGuard
		],
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Actions
	// -----------------------------

	{
		path: URL_ACTIONS,
		loadChildren: () => import('@app/actions/actions.module')
			.then(m => m.ActionsModule),
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Empty
	// -----------------------------

	{
		path: URL_EMPTY,
		loadChildren: () => import('@app/empty/empty.module')
			.then(m => m.EmptyModule),
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Reports and Other
	// -----------------------------

	{
		path: URL_REPORTING,
		loadChildren: () => import('@app/reporting/reporting.module')
			.then(m => m.ReportingModule),
		data: {
			preload: true,
			// onlyReports: false
		},
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Megalot
	// -----------------------------

	{
		path: `${URL_LOTTERIES}/${URL_MEGALOT}`,
		loadChildren: () => import('@app/megalot/megalot.module')
			.then(m => m.MegalotModule),
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Sportprognoz
	// -----------------------------

	{
		path: `${URL_LOTTERIES}/${URL_SPORTPROGNOZ}`,
		loadChildren: () => import('@app/sportprognoz/sportprognoz.module')
			.then(m => m.SportprognozModule),
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Zabava
	// -----------------------------

	{
		path: `${URL_LOTTERIES}/${URL_ZABAVA}`,
		loadChildren: () => import('@app/zabava/zabava.module')
			.then(m => m.ZabavaModule),
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Gonka
	// -----------------------------

	{
		path: `${URL_LOTTERIES}/${URL_GONKA}`,
		loadChildren: () => import('@app/gonka/gonka.module')
			.then(m => m.GonkaModule),
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  TIP/TOP
	// -----------------------------

	{
		path: `${URL_LOTTERIES}/${URL_TIPTOP}`,
		loadChildren: () => import('@app/tiptop/tiptop.module')
			.then(m => m.TiptopModule),
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  TML/BML
	// -----------------------------

	{
		path: `${URL_LOTTERIES}/${URL_TMLBML}`,
		loadChildren: () => import('@app/tmlbml/tmlbml.module')
			.then(m => m.TmlBmlModule),
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Instant
	// -----------------------------

	{
		path: `${URL_LOTTERIES}/${URL_INSTANT}`,
		loadChildren: () => import('@app/instant/instant.module')
			.then(m => m.InstantModule),
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Kare
	// -----------------------------

	{
		path: `${URL_LOTTERIES}/${URL_KARE}`,
		loadChildren: () => import('@app/kare/kare.module')
			.then(m => m.KareModule),
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Tickets
	// -----------------------------

	{
		path: URL_TICKETS,
		canActivate: [
			AuthGuard
		],
		loadChildren: () => import('@app/tickets/tickets.module')
			.then(m => m.TicketsModule),
		canDeactivate: [
			NavigationGuard
		]
	},

	// -----------------------------
	//  Path not found
	// -----------------------------

	{
		path: URL_NOT_FOUND,
		loadChildren: () => import('@app/not-found/not-found.module')
			.then(m => m.NotFoundModule),
		canDeactivate: [
			NavigationGuard
		]
	},
	{
		path: '**',
		redirectTo: URL_NOT_FOUND
	}
];

/**
 * Корневой модуль маршрутизации.
 * Содержит основные маршруты приложения.
 */
@NgModule({
	imports: [
		RouterModule.forRoot(ROUTES, {
    preloadingStrategy: CustomPreloadingStrategy,
    relativeLinkResolution: 'legacy'
})
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule {}
