import { Logger } from '@app/core/net/ws/services/log/logger';
import { init, Tag } from '@app/tickets-print/parser/tag';
import { IGetParam } from '@app/tickets-print/parser/iget-param';
import { IFlowUnit } from '@app/tickets-print/parser/iflow-unit';

/**
 * Модель для директив условной обработки
 */
export class ConditionsUnit extends Tag implements IFlowUnit {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Текущий тег
	 */
	currentTag: string;

	/**
	 * Признак завершения обработки
	 */
	isFinished = false;

	/**
	 * Признак выполнения условия
	 */
	condition: boolean;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Имя параметра
	 * @private
	 */
	private readonly param: string;

	/**
	 * Регулярное выражение
	 * @private
	 */
	private readonly regexp: string;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Создание и инициализация на основе xml атрибутов.
	 *
	 * @param {IGetParam} param Интерфейс доступа к параметрам.
	 * @param {NamedNodeMap} attributes xml Атрибуты.
	 */
	test(param: IGetParam, attributes: NamedNodeMap): void {
		init(this, attributes);

		const regexp = new RegExp(this.regexp);
		const tested = param.getParam(this.param);
		Logger.Log.d('ConditionsUnit', `test, param: ${this.param}, regexp: ${regexp.source}, tested: ${tested.toString()}`)
			.console();

		// if (true) {
		switch (typeof tested) {
			case 'string':
				this.condition = regexp.test(tested);
				Logger.Log.d('ConditionsUnit', `condition: ${this.condition}`)
					.console();
				break;
			case 'number':
				this.condition = regexp.test(tested.toString());
				Logger.Log.d('ConditionsUnit', `condition: ${this.condition}`)
					.console();
				break;
			case 'object':
				const concat = this.objectToString(tested);
				this.condition = regexp.test(concat);
				Logger.Log.d('ConditionsUnit', `regexp: ${regexp.source}, concat: ${concat}, condition: ${this.condition}`)
					.console();
				break;
			default:
				throw new Error(`ConditionsUnit param does"nt string|number|object: ${this.param}, result:${tested}`);
		}
		// } else {
		// 	this.condition = false;
		// 	Logger.Log.d('ConditionsUnit', `condition: ${this.condition}`)
		// 		.console();
		// }
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Преобразует объект в строку
	 * @param {Object} target объект
	 * @returns {string} строка
	 */
	private objectToString(target: Object): string {
		let result: string;
		if (target !== undefined) {
			for (const key of Object.keys(target)) {
				result = result ? `${result}${target[key]}` : target[key];
			}
		}

		return result;
	}

	// -----------------------------
	//  IFlowUnit interface
	// -----------------------------
	/**
	 * Унаследовано от IFlowUnit (пустой метод)
	 */
	clean(): void {
	}
}
