import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { ApiClient } from '@app/core/net/http/api/api-client';
import { LotteryGameCode } from '@app/core/configuration/lotteries';

// -----------------------------
//  Request
// -----------------------------

/**
 * Модель для вызова функции API {@link EsapActions.BonusPay BonusPay} для обычных лотерей.
 */
export class BonusPayReq extends ApiClient {

	/**
	 * Конструктор запроса.
	 *
	 * @param {AppStoreService} appStoreService централизованое хранилище данных
	 * @param {string} barcode Маккод билета.
	 * @param {string} bonusId Идентификатор выплачиваемого бонуса.
	 * @param playerPhone В параметре передается номер телефона игрока, к которому привязан выплачиваемый билет.
	 * @param playerAuthCode В параметре передается код подтверждения, полученный из SMS игроком.
	 */
	constructor(appStoreService: AppStoreService, barcode: string, bonusId: string, playerPhone: string, playerAuthCode: string) {
		const action1 = appStoreService.Settings.getEsapActionUrl(barcode, EsapActions.BonusPay, false);
		const action2 = appStoreService.Settings.getEsapActionUrl(LotteryGameCode.Undefined, EsapActions.BonusPay, false);

		super(appStoreService, !!action2 ? action2 : action1);

		this.params.append(EsapParams.BARCODE, barcode);
		this.params.append(EsapParams.BONUS_ID, bonusId);
		this.params.append(EsapParams.PLAYER_PHONE, playerPhone);
		this.params.append(EsapParams.PLAYER_AUTH_CODE, playerAuthCode);
	}

}

