import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { cameraErrorsHandler, Exit } from '@app/util/utils';
import { DialogContainerService } from '../dialog/services/dialog-container.service';
import { DialogError, ErrorCode } from './dialog';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { ButtonsOrientation } from '@app/core/dialog/types';
import { AppStoreService } from '@app/core/services/store/app-store.service';

/**
 * Глобальный перехватчик ошибок.
 */
@Injectable()
export class AppErrorHandler implements ErrorHandler {

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Сервис для работы с диалоговыми окнами.
	 * @private
	 */
	private readonly dialogInfoService: DialogContainerService;

	/**
	 * Сервис для работы с хранилищем приложения.
	 * @private
	 */
	private readonly appStoreService: AppStoreService;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор.
	 *
	 * @param {Injector} injector Инжектор зависимостей.
	 */
	constructor(
		private readonly injector: Injector
	) {
		this.dialogInfoService = injector.get(DialogContainerService);
		this.appStoreService = injector.get(AppStoreService);
	}

	/**
	 * Перехватчик глобальных ошибок.
	 *
	 * @param {Error} error Ошибка в процессе выполнения кода.
	 */
	handleError(error: Error): void {
		if (error.stack) {
			Logger.Log.e('AppErrorHandler', error.stack)
				.console();
		} else {
			Logger.Log.e('AppErrorHandler', error.message)
				.console();
		}
		console.log('Error: ', error);

		// const cameraErr = cameraErrorsHandler(error.message);
		// if ([
		// 	'scanner.allow_camera', 'scanner.close_other_apps', 'scanner.access_timeout',
		// 	'scanner.no_camera_available', 'scanner.no_license_key'
		// 	].indexOf(cameraErr) !== -1) {
		// 	this.appStoreService.scannerError = cameraErr;
		// } else {
		// 	this.dialogInfoService.showTwoButtonsError(
		// 		new DialogError(ErrorCode.Undefined, 'dialog.undefined_error'), {
		// 			first: {
		// 				text: 'dialog.dialog_button_reload_terminal',
		// 				click: Exit
		// 			},
		// 			second: {
		// 				text: 'dialog.dialog_button_continue_work',
		// 				click: () => this.dialogInfoService.hideAll()
		// 			}
		// 		}, { buttonsOrientation: ButtonsOrientation.Vertical });
		// }
	}
}
