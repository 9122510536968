import { Logger } from '@app/core/net/ws/services/log/logger';
import { init, Tag } from '@app/tickets-print/parser/tag';
import { FormatType, ParamTag } from '@app/tickets-print/parser/tags/param';
import { IGetParam } from '@app/tickets-print/parser/iget-param';

/**
 * Глобальная ли переменная
 */
type Global = 'yes' | 'no';

/**
 * Модель xml тега <set>
 */
export class SetTag extends Tag {

	/**
	 * Название переменной
	 * @private
	 */
	private readonly var: string;

	/**
	 * Глобальная ли переменная
	 * @private
	 */
	private readonly global: Global = 'no';

	/**
	 * Название параметра
	 * @private
	 */
	private readonly param: string;

	/**
	 * Значение переменной
	 * @private
	 */
	private readonly value?: string | number;

	/**
	 * Формат параметра
	 * @private
	 */
	private readonly fmt?: string;

	/**
	 * Тип переменной
	 * @private
	 */
	private readonly type?: FormatType = 'str';

	/**
	 * Создание и инициализация на основе xml атрибутов
	 * @param {IGetParam} param интерфейс доступа к параметрам
	 * @param {NamedNodeMap} attributes xml атрибуты
	 */
	constructor(param: IGetParam, attributes: NamedNodeMap) {
		super();
		init(this, attributes);

		let root;
		root = this.global === 'no' ? param.getSectionParam() : param.getGlobalParams();
		Logger.Log.d('SetTag', `resolve in global: ${this.global}, var: ${this.var}, param: ${this.param}`)
			.console();

		if (this.value == null) {
			const input = param.getParam(this.param);
			if (input != null) {
				const value = ParamTag.ValidateParam(this.param, input, this.type);
				this.value = ParamTag.Format(this.type, this.fmt, value);
			} else {
				this.value = '';
			}
		}
		Logger.Log.d('SetTag', `setting value: ${this.value}`)
			.console();

		const tags = this.var.split('.');
		// console.log('tags = ', tags);
		if (tags.length > 0) {
			this.addElement(0, tags, root, this.value);
		} else {
			throw new Error(`SetTag value: ${this.value} empty or has more then 2 level: ${tags.toString()}`);
		}
	}

	/**
	 * Добавление элемента в объект
	 * @param ind Индекс текущего элемента
	 * @param tTags Массив тегов
	 * @param parent Родительский элемент
	 * @param value Значение
	 * @protected
	 */
	protected addElement(ind: number, tTags: any, parent: any, value: any): void {
		if (ind === tTags.length - 1) {
			if (Array.isArray(parent)) {
				const element = {};
				element[tTags[ind]] = value;
				parent.push(element);
			} else {
				parent[tTags[ind]] = value;
			}
		} else {
			if (parent[tTags[ind]] != null) {
				if (Array.isArray(parent)) {
					const element = {};
					this.addElement(ind + 1, tTags, parent[tTags[ind]], value);
					element[tTags[ind]] = undefined;
					parent.push(element[tTags[ind]]);
				} else {
					this.addElement(ind + 1, tTags, parent[tTags[ind]], value);
				}
			} else {
				parent[tTags[ind]] = {};
				this.addElement(ind + 1, tTags, parent[tTags[ind]], value);
			}
		}
	}
}
