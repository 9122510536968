import { DialogContainerComponent } from './components/dialog-container.components';

/**
 * Контейнер для хранения списка диалоговых окон
 */
export class DialogContainerStorage {

	/**
	 * Окно, находящееся поверх всех остальных.
	 * @private
	 */
	private _top: DialogContainerComponent;

	/**
	 * Следующее окно, начиная с самого первого.
	 * @private
	 */
	private _next: DialogContainerComponent;

	/**
	 * Геттер окна, находящееся поверх всех остальных.
	 *
	 * @returns {DialogContainerComponent}
	 */
	get top(): DialogContainerComponent {
		return this._top;
	}

	/**
	 * Геттер следующего окна, начиная с самого первого.
	 *
	 * @returns {DialogContainerComponent}
	 */
	get next(): DialogContainerComponent {
		return this._next;
	}

	/**
	 * Добавление диалогового окна в список.
	 *
	 * @param {DialogContainerComponent} container Контейнер диалогового окна.
	 */
	addContainer(container: DialogContainerComponent): void {
		container.setParent(this._next);
		this._next = container;
	}

	/**
	 * Установка активного окна.
	 *
	 * @param {DialogContainerComponent} container Контейнер диалогового окна.
	 */
	setTopContainer(container: DialogContainerComponent): void {
		this._top = container;
	}

	/**
	 * Удаление(сокрытие) окна из списка.
	 *
	 * @param {DialogContainerComponent} container Контейнер диалогового окна.
	 * @returns {boolean}
	 */
	delContainer(container: DialogContainerComponent): boolean {
		if (this._top === container) {
			this._next = container;
			this._top = container.getParent();

			return true;
		}

		return false;
	}
}
