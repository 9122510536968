import { PrintTag } from '@app/tickets-print/parser/print';
import { BarcodeTag } from '@app/tickets-print/parser/tags/barcode';
import { IFlowUnit } from '@app/tickets-print/parser/iflow-unit';

/**
 * Модель xml тега <barcode> со всеми дочерними тегами
 */
export class BarcodeUnit implements IFlowUnit {

	/**
	 * Тег <barcode>
	 * @private
	 */
	private readonly barcode: BarcodeTag;

	/**
	 * Создание и инициализация на основе xml атрибутов
	 * @param print Массив тегов для печати
	 * @param attributes XML-атрибуты
	 */
	constructor(print: Array<PrintTag>, attributes: NamedNodeMap) {
		this.barcode = new BarcodeTag(attributes);
		print.push(this.barcode);
	}

	/**
	 * Установка параметров, тег <param>
	 * @param {string} content Содержимое тега
	 */
	setParam(content: string): void {
		this.barcode.setParam(content);
	}

	/**
	 * Установка содержимого тега
	 * @param {string} content Содержимое тега
	 */
	setText(content: string): void {
		this.barcode.setText(content);
	}

	/**
	 * Очистка данных в потоке (унаследован, пустой)
	 */
	clean(): void {
	}
}
