import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import {
	URL_CHECK,
	URL_INIT,
	URL_LOTTERIES, URL_OTHER,
	URL_REPORTING,
	URL_RESULTS,
	URL_TICKETS,
	URL_TMLBML
} from '@app/util/route-utils';

/**
 * Возврат из окон Продажа БМЛ, Сканирование штрих-кода при проверке выигрыша, Результаты, Отчеты, Другое.
 * Смена пароля, Выход из сеанса, Справка в главное окно.
 */
const ALLOWED_SRC = [
	`/${URL_LOTTERIES}/${URL_TMLBML}/${URL_INIT}`,
	`/${URL_TICKETS}/${URL_CHECK}`,
	`/${URL_RESULTS}`,
	`/${URL_REPORTING}`,
	`/${URL_REPORTING}/${URL_OTHER}`
];

/**
 * Разрешенные источники перехода.
 */
const ALLOWED_DST = [`/${URL_LOTTERIES}`];

/**
 * Гард для перехода по лотереям и истории браузера.
 */
@Injectable({
	providedIn: 'root'
})
export class NavigationGuard implements CanDeactivate<any> {
	/**
	 * Конструктор класса.
	 * @param appStoreService Сервис хранилища приложения.
	 */
	constructor(private readonly appStoreService: AppStoreService) {}

	/**
	 * Метод для проверки возможности деактивации компонента.
	 * @param component Компонент, который будет деактивирован.
	 * @param currentRoute Текущий маршрут.
	 * @param currentState Текущее состояние маршрута.
	 * @param nextState Следующее состояние маршрута.
	 */
	canDeactivate(
		component: any,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// will prevent user from going back
		if (this.appStoreService.backClicked) {
			this.appStoreService.backClicked = false;

			if (nextState) {
				const baseURL = `${document.location.protocol}//${document.location.host}`;
				const oSrcURL = new URL(`${baseURL}${currentState.url}`);
				const oDstURL = new URL(`${baseURL}${nextState.url}`);
				const srcURL = oSrcURL.pathname || currentState.url;
				const dstURL = oDstURL.pathname || nextState.url;
				if (dstURL && (ALLOWED_SRC.indexOf(srcURL) > -1) && (ALLOWED_DST.indexOf(dstURL) > -1)) {
					return true;
				}
			}

			// push current state again to prevent further attempts.
			history.pushState(null, null, location.href);

			return false;
		}

		return true;
	}
}
