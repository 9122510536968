import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { IDropdownListItem } from '@app/shared/components/drop-down-list/drop-down-list.component';
import { DialogError, DialogInfo } from '../../error/dialog';
import { NetError } from '../../error/types';
import { NoneButtonsErrorComponent } from '../components/none-buttons-error/none-buttons-error.component';
import { OneButtonErrorComponent } from '../components/one-button-error/one-button-error.component';
import { TransactionDialogComponent } from '../components/transaction-dialog/transaction-dialog.component';
import { TwoButtonsErrorComponent } from '../components/two-buttons-error/two-buttons-error.component';
import { TwoButtonsWithDropdownComponent } from '../components/two-buttons-with-dropdown/two-buttons-with-dropdown.component';
import { DialogContainerStorage } from '../storage';
import { ConfirmTwoButtonsComponent } from '../components/confirm-two-buttons/confirm-two-buttons.component';
import { Logger } from '@app/core/net/ws/services/log/logger';
import {
	Dialog,
	DialogConfig,
	ErrorDialogComponent,
	IDialog,
	IDialogBindButton,
	IDialogBindButtons,
	TransactionDialog,
	TransactionInfoComponent
} from '../types';

/**
 * Интерфейс для обновления диалогового окна.
 */
interface IUpdateDialogItem {
	/**
	 * Сообщение в диалоговом окне.
	 */
	message?: string;

	/**
	 * Подробности сообщения в диалоговом окне.
	 */
	details?: string;

	/**
	 * Дополнительная информация в диалоговом окне.
	 */
	extra?: string;

	/**
	 * Прогресс выполнения операции в диалоговом окне.
	 */
	progress?: number;

	/**
	 * Счетчик изображений в диалоговом окне.
	 */
	imageCounter?: number;
}

/**
 * Сервис по управлению деревом (списком) диалоговых окон.
 */
@Injectable({
	providedIn: 'root'
})
export class DialogContainerService {

	/**
	 * Открыто ли диалоговое окно.
	 */
	isPopupOpen = false;

	/**
	 * Ссылка на диалог для транзакции покупки билета.
	 */
	get transactionDialog(): TransactionDialog {
		return this._transactionDialog;
	}

	/**
	 * Возвращает контейнер в котором хранится дерево диалоговых окон.
	 *
	 * @returns {DialogContainerStorage}
	 */
	get container(): DialogContainerStorage {
		return this._container;
	}

	/**
	 * Контейнер в котором создаются диалоговые окна.
	 * @private
	 */
	private readonly _container: DialogContainerStorage = new DialogContainerStorage();

	/**
	 * Ссылка на диалог для транзакции покупки билета.
	 * @private
	 */
	private _transactionDialog: TransactionDialog;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Скрывает топовое диалоговое окно.
	 */
	hideActive(): void {
		if (this._container.top) {
			this._transactionDialog = undefined;
			this._container.top.clean();
			this.isPopupOpen = false;
		}
	}

	/**
	 * Скрывает все диалоговые окна.
	 */
	hideAll(): void {
		while (this._container.top) {
			this.hideActive();
		}
		this.isPopupOpen = false;
	}

	/**
	 * Показывает диалоговое окно ошибки без кнопок.
	 *
	 * @param {NetError | DialogError} error Тип содержимого диалогового окна.
	 * @param {DialogConfig} config Конфигурация диалогового окна.
	 * @param {string} extra Дополнительная текстовая информация.
	 * @returns {IDialog} Экземпляр контейнера диалогового окна ограниченный интерфейсом.
	 */
	showNoneButtonsError(error: NetError | DialogError, config?: DialogConfig, extra?: string): IDialog {
		this.setTopMode(config);
		const dialog = this._container.next.loadComponent(NoneButtonsErrorComponent) as NoneButtonsErrorComponent;
		this.isPopupOpen = true;

		return this.setErrorDialog(dialog, error, config, extra);
	}

	/**
	 * Показывает диалоговое информационное окно без кнопок.
	 *
	 * @param {string} title Заголовок диалогового окна.
	 * @param {string | DialogInfo} message сообщение
	 * @param {DialogConfig} config конфигурация диалогового окна
	 * @returns {IDialog} экземпляр контейнера диалогового окна ограниченный интерфейсом
	 */
	showNoneButtonsInfo(title: string, message: string | DialogInfo, config?: DialogConfig): IDialog {
		this.setTopMode(config);
		const dialog = this._container.next.loadComponent(NoneButtonsErrorComponent) as NoneButtonsErrorComponent;
		this.isPopupOpen = true;

		return this.setInfoDialog(dialog, title, message, config);
	}

	/**
	 * Показывает диалоговое окно ошибки с одной кнопкой.
	 *
	 * @param {NetError | DialogError} error тип содержимого диалогового окна
	 * @param {IDialogBindButton} button конфигурация кнопки
	 * @param {DialogConfig} config конфигурация диалогового окна
	 * @param {string} extra дополнительная текстовая информация
	 * @returns {IDialog} экземпляр контейнера диалогового окна ограниченный интерфейсом
	 */
	showOneButtonError(error: NetError | DialogError, button: IDialogBindButton, config?: DialogConfig, extra?: string): IDialog {
		this.setTopMode(config);
		const dialog = this._container.next.loadComponent(OneButtonErrorComponent) as OneButtonErrorComponent;
		dialog.buttons = this.updateButtonsAction(button);
		this.isPopupOpen = true;

		return this.setErrorDialog(dialog, error, config, extra);
	}

	/**
	 * Показывает диалоговое информационное окно с одной кнопкой.
	 *
	 * @param {string} title Заголовок диалогового окна.
	 * @param {string | DialogInfo} message сообщение
	 * @param {IDialogBindButton} button конфигурация кнопки
	 * @param {DialogConfig} config конфигурация диалогового окна
	 * @returns {IDialog} контейнера диалогового окна ограниченный интерфейсом
	 */
	showOneButtonInfo(title: string, message: string | DialogInfo, button: IDialogBindButton, config?: DialogConfig): IDialog {
		this.setTopMode(config);
		const dialog = this._container.next.loadComponent(OneButtonErrorComponent) as OneButtonErrorComponent;
		dialog.buttons = this.updateButtonsAction(button);
		this.isPopupOpen = true;

		return this.setInfoDialog(dialog, title, message, config);
	}

	/**
	 * Показывает диалоговое окно ошибки с двумя кнопками.
	 *
	 * @param {NetError | DialogError} error тип содержимого диалогового окна
	 * @param {IDialogBindButtons} buttons конфигурация кнопок
	 * @param {DialogConfig} config конфигурация диалогового окна
	 * @param {string} extra дополнительная текстовая информация
	 * @returns {IDialog} экземпляр контейнера диалогового окна ограниченный интерфейсом
	 */
	showTwoButtonsError(error: NetError | DialogError, buttons: IDialogBindButtons, config?: DialogConfig, extra?: string): IDialog {
		this.setTopMode(config);
		const dialog = this._container.next.loadComponent(TwoButtonsErrorComponent) as TwoButtonsErrorComponent;
		dialog.buttons = this.updateButtonsAction(buttons);
		this.isPopupOpen = true;

		return this.setErrorDialog(dialog, error, config, extra);
	}

	/**
	 * Показывает диалоговое информационное окно с двумя кнопками.
	 *
	 * @param {string} title Заголовок диалогового окна.
	 * @param {string | DialogInfo} message Сообщение.
	 * @param {IDialogBindButtons} buttons конфигурация кнопок
	 * @param {DialogConfig} config конфигурация диалогового окна
	 * @returns {IDialog} контейнера диалогового окна ограниченый интерфейсом
	 */
	showTwoButtonsInfo(title: string, message: string | DialogInfo, buttons: IDialogBindButtons, config?: DialogConfig): IDialog {
		this.setTopMode(config);
		const dialog = this._container.next.loadComponent(TwoButtonsErrorComponent) as TwoButtonsErrorComponent;
		dialog.buttons = this.updateButtonsAction(buttons);

		this.isPopupOpen = true;

		return this.setInfoDialog(dialog, title, message, config);
	}

	/**
	 * Показывает диалоговое информационное окно с двумя кнопками и выпадающим списком.
	 * Оператор может выбрать любой элемент из списка и нажать кнопку подтверждения или отмены.
	 * Результат выбора будет возвращен в параметр <code>result</code>.
	 *
	 * @param {string} title Заголовок диалогового окна.
	 * @param {string | DialogInfo} message Сообщение.
	 * @param {Array<IDropdownListItem>} list Список элементов выпадающего списка.
	 * @param {IDropdownListItem} defaultItem Элемент выпадающего списка по умолчанию.
	 * @param {IDialogBindButtons} buttons конфигурация кнопок
	 * @param {Subject<IDropdownListItem>} result Поток, в который будет передан результат выбора.
	 * @param {DialogConfig} config конфигурация диалогового окна
	 * @returns {IDialog}
	 */
	showTwoButtonsWithDropdown(
		title: string,
		message: string | DialogInfo,
		list: Array<IDropdownListItem>,
		defaultItem: IDropdownListItem,
		buttons: IDialogBindButtons,
		result: Subject<IDropdownListItem>,
		config?: DialogConfig
	): IDialog {
		this.setTopMode(config);
		const dialog = this._container.next.loadComponent(TwoButtonsWithDropdownComponent) as TwoButtonsWithDropdownComponent;
		dialog.buttons = this.updateButtonsAction(buttons);

		dialog.itemsList = list;
		dialog.defaultItem = defaultItem;
		dialog.result = result;
		this.isPopupOpen = true;

		return this.setInfoDialog(dialog, title, message, config);
	}

	/**
	 * Показывает информационное окно по выполняемой транзакции.
	 *
	 * @param {string} title Заголовок диалогового окна.
	 * @param {string | DialogInfo} message сообщение
	 * @param {DialogConfig} config конфигурация диалогового окна
	 * @returns {IDialog} экземпляр контейнера диалогового окна ограниченный интерфейсом
	 */
	showTransactionDialog(title: string, message: string | DialogInfo, config?: DialogConfig): TransactionDialog {
		this.setTopMode(config);
		const dialog = this._container.next.loadComponent(TransactionDialogComponent) as TransactionDialogComponent;
		this._transactionDialog = this.setTransactionDialog(dialog, title, message, config);
		this.isPopupOpen = true;

		return this.transactionDialog;
	}

	/**
	 * Показывает диалоговое информационное окно подтверждения с двумя кнопками.
	 *
	 * @param {string} title Заголовок диалогового окна.
	 * @param {string | DialogInfo} message Сообщение.
	 * @param {string} extra Дополнительное сообщение.
	 * @param {IDialogBindButtons} buttons Конфигурация кнопок
	 * @param {DialogConfig} config конфигурация диалогового окна
	 * @returns {IDialog} контейнера диалогового окна ограниченный интерфейсом
	 */
	showConfirmTwoButtons(
		title: string,
		message: string | DialogInfo,
		extra: string,
		buttons: IDialogBindButtons,
		config?: DialogConfig
	): IDialog {
		this.setTopMode(config);
		const dialog = this._container.next.loadComponent(ConfirmTwoButtonsComponent) as ConfirmTwoButtonsComponent;
		dialog.buttons = this.updateButtonsAction(buttons);

		dialog.extra = extra;
		this.isPopupOpen = true;

		return this.setInfoDialog(dialog, title, message, config);
	}

	/**
	 * Обновить открытый диалог с транзакцией.
	 *
	 * @param {IUpdateDialogItem} item Параметры обновления диалога.
	 */
	updateTransactionDialog(item: IUpdateDialogItem): void {
		if (!this.transactionDialog) {
			return;
		}

		if (item.message) {
			this.transactionDialog.dialogComponent.message = item.message;
		}

		if (item.details) {
			this.transactionDialog.dialogComponent.messageDetails = item.details;
		}

		if (item.extra) {
			this.transactionDialog.dialogComponent.extraMessage$$.next(item.extra);
		} else if (item.extra === '') {
			this.transactionDialog.dialogComponent.extra = undefined;
		}

		if (item.progress) {
			this.transactionDialog.dialogComponent.progress$$.next(item.progress);
		}

		if (Number.isInteger(item.imageCounter)) {
			this.transactionDialog.dialogComponent.imageCounter$$.next(item.imageCounter);
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Обновить действия кнопок в диалоговом окне
	 * @param buttons Передаваемый объект с кнопками
	 * @private
	 */
	private updateButtonsAction(buttons: IDialogBindButton | IDialogBindButtons): IDialogBindButtons {
		if (buttons.hasOwnProperty('second')) {
			const btns = buttons as IDialogBindButtons;

			return {
				first: {
					text: btns.first.text,
					click: () => {
						this.isPopupOpen = false;
						if (btns.first?.click) {
							btns.first.click();
						}
					}
				},
				second: {
					text: btns.second.text,
					click: () => {
						this.isPopupOpen = false;
						if (btns.second?.click) {
							btns.second.click();
						}
					}
				}
			};
		}
		const btns = buttons as IDialogBindButton;

		return {
			first: {
				text: btns.text,
				click: () => {
					this.isPopupOpen = false;
					if (btns.click) {
						btns.click();
					}
				}
			}
		};
	}

	/**
	 * Задать параметры диалога с ошибкой.
	 *
	 * @param {ErrorDialogComponent} dialog Объект диалогового окна
	 * @param {NetError | DialogError} error Объект ошибки
	 * @param {DialogConfig} config Конфигурация диалогового окна
	 * @param {string} extra Дополнительная информация
	 * @returns {IDialog}
	 */
	private setErrorDialog(dialog: ErrorDialogComponent, error: NetError | DialogError, config?: DialogConfig, extra?: string): IDialog {
		dialog.title = 'dialog.attention';
		dialog.message = error.message;
		dialog.messageDetails = error.messageDetails;
		dialog.code = error.code;
		dialog.code_translate = 'dialog.dialog_error_code';
		dialog.extra = extra;
		dialog.isInfoDialog = false;
		dialog.config = config;

		this.setHideMode(dialog, config);
		dialog.changeDetector.detectChanges();

		Logger.Log.e('DialogContainerService', `detected error: %s`, error)
			.console();

		return new Dialog(dialog.container);
	}

	/**
	 * Задать параметры информационного диалога.
	 *
	 * @param {ErrorDialogComponent} dialog  Объект диалогового окна
	 * @param {string} title Заголовок диалогового окна
	 * @param {string | DialogInfo} message Сообщение диалогового окна
	 * @param {DialogConfig} config Конфигурация диалогового окна
	 * @returns {IDialog}
	 */
	private setInfoDialog(dialog: ErrorDialogComponent, title: string, message: string | DialogInfo, config?: DialogConfig): IDialog {
		dialog.title = title;
		dialog.message = typeof message === 'string' ? message : message.message;
		dialog.messageDetails = (message as DialogInfo).messageDetails;
		dialog.isInfoDialog = true;
		dialog.config = config;

		this.setHideMode(dialog, config);
		dialog.changeDetector.detectChanges();

		return new Dialog(dialog.container);
	}

	/**
	 * Спрятать все диалоговые окна, если нет конфигурации или в конфигурации указано одно окно наверху.
	 * @param config Конфигурация диалогового окна
	 * @private
	 */
	private setTopMode(config: DialogConfig): void {
		if (!config || config.singleTop) {
			this.hideAll();
		}
	}

	/**
	 * Задать параметры диалога транзакции.
	 *
	 * @param {ErrorDialogComponent} dialog Объект диалогового окна
	 * @param {string} title Заголовок диалогового окна
	 * @param {string | DialogInfo} message Сообщение диалогового окна
	 * @param {DialogConfig} config Конфигурация диалогового окна
	 * @returns {IDialog}
	 */
	private setTransactionDialog(
		dialog: TransactionInfoComponent,
		title: string,
		message: string | DialogInfo,
		config?: DialogConfig
	): TransactionDialog {
		dialog.title = title;
		dialog.message = typeof message === 'string' ? message : message.message;
		dialog.messageDetails = (message as DialogInfo).messageDetails;
		dialog.isInfoDialog = true;
		// dialog.progress$$.next(0);

		this.setHideMode(dialog, config);

		return new TransactionDialog(dialog);
	}

	/**
	 * Настроить параметры скрытия диалога с экрана.
	 *
	 * @param {ErrorDialogComponent} dialog Инстанс диалога с ошибкой.
	 * @param {DialogConfig} config Конфигурация диалога.
	 */
	private setHideMode(dialog: ErrorDialogComponent, config: DialogConfig): void {
		if (!config || config.hideOnClick) {
			dialog.hide = true;
		}

		// если задан авто клик для первой кнопки, сделать подписку на таймер
		if (config && config.firstButtonAutoClickTimeOut && dialog.buttons && dialog.buttons.first) {
			this.setInactivityTimer(config.firstButtonAutoClickTimeOut, dialog.buttons.first);
		}

		// если задан авто клик для второй кнопки, сделать подписку на таймер
		if (config && config.secondButtonAutoClickTimeOut && dialog.buttons && dialog.buttons.second) {
			this.setInactivityTimer(config.secondButtonAutoClickTimeOut, dialog.buttons.second);
		}
	}

	/**
	 * Подписаться на таймер автоклика на кнопки диалога (на первую или вторую).
	 *
	 * @param {number} inactivityTimeout Время ожидания рекакции юзера на кнопку.
	 * @param {IDialogBindButton} button Кнопка, по которой будет произведен клик.
	 */
	private setInactivityTimer(inactivityTimeout: number, button: IDialogBindButton): void {
		timer(inactivityTimeout)
			.subscribe(() => {
				if (button.click) {
					button.click();
				}
			});
	}

}
