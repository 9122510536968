import {Component, Input, OnInit} from '@angular/core';
import {ITicketRange, ITicketSerie, ITransactionTicket} from "@app/core/services/transaction/transaction-types";

@Component({
  selector: 'app-fiscal-receipt',
  templateUrl: './fiscal-receipt.component.html',
  styleUrls: ['./fiscal-receipt.component.scss']
})
export class FiscalReceiptComponent implements OnInit {
  private currentDate = new Date();

  localeDate = this.currentDate.toLocaleDateString();

  localeTime = this.currentDate.toLocaleTimeString();

  macOfCheck = '';

  @Input() gameName = '';

  @Input() tickets: Array<ITransactionTicket> = [];

  get sum(): string {
	  let tSum = 0;
	  for (const ticket of this.tickets) {
		  tSum += parseFloat(ticket.bet_sum);
	  }
	  return tSum.toFixed(2);
  }

  private getRC(range: Array<ITicketRange>): number {
	let rCount = 0;
	for (const item of range) {
		rCount += item.count;
	}
	return rCount;
  }

  getRangeCount(range: Array<ITicketRange>): string {
	  return this.getRC(range).toFixed(2);
  }

  getRangePrice(serie: ITicketSerie): string {
	  const tSum = this.getRC(serie.range) * serie.ticket_price;
	  return tSum.toFixed(2);
  }

  randomString(len = 20): string {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';

		for (let i = 0; i < len; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length);
			result += characters.charAt(randomIndex);
		}

		return result;
	}

	ngOnInit(): void {
	  this.macOfCheck = this.randomString();
	}
}
