import { PrintTag } from '@app/tickets-print/parser/print';
import { ImgTag } from '@app/tickets-print/parser/tags/img';
import { LineTag } from '@app/tickets-print/parser/tags/line';
import { IFlowUnit } from '@app/tickets-print/parser/iflow-unit';
import { QRTag } from '@app/tickets-print/parser/tags/qr';

/**
 * Модель xml тега <line> со всеми дочерними тегами
 */
export class LineUnit implements IFlowUnit {

	/**
	 * Объект тега <line>
	 * @private
	 */
	private line: LineTag;

	/**
	 * Конструктор класса LineUnit
	 * @param print Массив тегов для печати
	 * @param attributes xml-атрибуты
	 */
	constructor(print: Array<PrintTag>, attributes: NamedNodeMap) {
		this.line = new LineTag(attributes);
		print.push(this.line);
	}

	/**
	 * Установка содержимого тега
	 * @param {string} content Содержимое тега
	 */
	setText(content: string): void {
		this.line.setText(content);
	}

	/**
	 * Установка параметров, тег <param>
	 * @param {string} content Значение параметра
	 */
	setParam(content: string): void {
		this.line.setParam(content);
	}

	/**
	 * Установка картинки, тег <img>
	 * @param {string} content Содержимое тега
	 */
	setImage(content: ImgTag): void {
		this.line.setImage(content);
	}

	/**
	 * Установка QR-кода, тег <qr>
	 * @param content Содержимое тега
	 */
	setQR(content: QRTag): void {
		this.line.setQR(content);
	}

	/**
	 * Очистка содержимого тега
	 */
	clean(): void {
		this.line = null;
	}
}
