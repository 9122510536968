import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Cache } from '@app/util/cache';
import { Profile, ProfileKey } from '@app/util/profile';
import { DurationDay } from '@app/util/utils';
import { validateResponse } from '@app/util/validator';

import { IRequest, IResponse } from '@app/core/net/http/api/types';
import { HttpService } from '@app/core/net/http/services/http.service';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { StorageGetResp } from '@app/core/net/ws/api/models/storage/storage-get';
import { ApplicationAppId } from '@app/core/services/store/settings';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';

/**
 * Интерфейс модели хранения данных с учетом времени.
 */
interface CacheTimeoutValue {
	/**
	 * Время жизни записи в кеше.
	 */
	time: number;

	/**
	 * Данные записи в кеше.
	 */
	data: IResponse | string;
}

/**
 * Интерфейс модели хранения данных.
 */
interface KeyValue<T> {
	/**
	 * Ключ записи.
	 */
	key: string;

	/**
	 * Значение записи.
	 */
	value: T | string;
}

/**
 * Наибольший размер кеша в байтах.
 */
const CacheMaxSize = 2 * 1024 * 1024;

/**
 * Время жизни элемента кеша.
 */
const CacheItemLifeTime = DurationDay;

/**
 * Префикс ключа кеша для хранения данных о картинке.
 */
export const PRINT_IMG_CACHE_PREFIX = 'print_img_';

/**
 * Префикс ключа кеша для хранения данных о шаблоне печати.
 */
export const PRINT_TEMPLATE_CACHE_PREFIX = 'print_template_';

/**
 * Префикс ключа кеша для хранения данных о шаблоне отчета.
 */
export const REPORT_TEMPLATE_CACHE_PREFIX = 'report_template_config_';

/**
 * Сервис предназначен для кеширования и временного хранения информации, загружаемой из ЦС.
 * Запрашиваемая информация
 */
@Injectable({
	providedIn: 'root'
})
export class ResponseCacheService {

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Переменная для хранения кеша.
	 * @private
	 */
	private readonly cache: Cache<string, string>;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {StorageService} storageService Сервис для работы с хранилищем браузера.
	 * @param {HttpService} httpService Сервис для работы с http запросами.
	 * @param {DialogContainerService} dialogContainerService Сервис для работы с диалоговыми окнами.
	 */
	constructor(
		private readonly storageService: StorageService,
		private readonly httpService: HttpService,
		private readonly dialogContainerService: DialogContainerService
	) {
		this.cache = new Cache<string, string>({
			maxLength: CacheMaxSize,
			maxAge: CacheItemLifeTime,
			lengthFunction: (value: string) => value.length
		});
	}

	/**
	 * Выполняет поиск ответа на запрос из заданого списка, по его ключу:
	 * - в контейнере закешированых ответов (в оперативной памяти)
	 * - в Storage-сервисе
	 * - отправляет подготовленный запрос в ЦС и сохраняет ответ в памяти и Storage-сервисе
	 *
	 * @param T Тип запроса
	 * @param {Map<string, IRequest>} data Список ключей хранения и данных сопоставимых этим ключам.
	 * @param {number} lifeTime Время жизни (хранения) в мс.
	 * @returns {Promise<KeyValue<T extends IResponse>[]>}
	 */
	esapAPI<T extends IResponse>(T, data: Map<string, IRequest>, lifeTime: number): Promise<Array<KeyValue<T>>> {
		const result: Array<KeyValue<T>> = [];
		const storageKeys: Array<string> = [];

		// проверить пакет данных в памяти и заполнить "result" существующими данными
		Logger.Log.i('ResponseCacheService', `esapAPI -> check data package in MEMORY (${data.size} items)`)
			.console();

		Profile.startCheckPoint(ProfileKey.GetDataFromMemory);
		data.forEach((value, key) => {
			const cached = this.cache.get(key);
			if (cached) {
				this.addToResult<T>(T, result, key, cached);
				data.delete(key);
			} else {
				storageKeys.push(key);
			}
		});
		Profile.stopCheckPoint(ProfileKey.GetDataFromMemory);

		// если не все данные из пакета нашлись в памяти, достать остальные из хранилища
		if (storageKeys.length > 0) {
			Profile.startCheckPoint(ProfileKey.GetDataFromStorage);
			Logger.Log.i('ResponseCacheService', `esapAPI -> can't find ${storageKeys.length} items in MEMORY, will check STORAGE`)
				.console();

			return this.storageService.get(ApplicationAppId, storageKeys, null, 10000, 5)
				.then((storageGetResp: StorageGetResp) => {
					// обработать ответ хранилища и заполнить "result" существующими данными
					if (storageGetResp.data && storageGetResp.data.length > 0) {
						for (const item of storageGetResp.data) {
							this.cache.set(item.key, item.value);
							this.addToResult<T>(T, result, item.key, item.value);
							data.delete(item.key);
						}
					}
					Profile.stopCheckPoint(ProfileKey.GetDataFromStorage);

					// оставшиеся данные грузим из ЦС
					if (data.size > 0) {
						Logger.Log.i('ResponseCacheService', `esapAPI -> can't find ${data.size} items in STORAGE, will get it in CS`)
							.console();

						let chain = Promise.resolve(null);
						data.forEach((request, key) => {
							chain = chain.then(() => {
								return this.getFromRemote<T>(T, result, request, key, lifeTime);
							});
						});

						return chain
							.then(() => {
								return Promise.resolve(result);
							})
							.catch(error => {
								throw error;
							});
					}

					return Promise.resolve(result);
				});
		}

		return Promise.resolve(result);
	}

	/**
	 * Ищет ответ на запрос, по его ключу:
	 * - в контейнере закешированых ответов (памяти)
	 * - в Storage-сервисе
	 * - отправляет подготовленный запрос и сохраняет ответ в памяти и Storage-сервисе.
	 *
	 * @param {string} url урл запроса
	 * @param {HttpHeaders} headers опциональные параметры
	 * @param {string} storageKey ключ хранения
	 * @param {number} lifeTime время жизни(хранения) в мс
	 * @returns {Promise<string>}
	 */
	httpGET(url: string, headers: HttpHeaders, storageKey: string, lifeTime: number): Promise<string> {
		Logger.Log.i('ResponseCacheService', `httpGET -> check data by key "${storageKey}" in MEMORY`)
			.console();

		// проверить память
		const cached = this.cache.get(storageKey);
		if (cached) {
			const cacheTimeoutValue = JSON.parse(cached) as CacheTimeoutValue;
			if (cacheTimeoutValue.time > new Date().getTime()) {
				return Promise.resolve(cacheTimeoutValue.data as string);
			}
		}

		// в памяти нет - запросит в хранилище
		Logger.Log.i('ResponseCacheService', `httpGET -> no data in MEMORY, check key "${storageKey}" in STORAGE`)
			.console();

		return this.storageService.get(ApplicationAppId, [storageKey], null, 10000, 5)
			.then((storageGetResp: StorageGetResp) => {
				// проерить ответ в хранилище
				if (storageGetResp.data && storageGetResp.data.length > 0) {
					const val = storageGetResp.data[0];
					if (val.key && val.key === storageKey) {
						const cacheTimeoutValue = JSON.parse(val.value) as CacheTimeoutValue;
						if (cacheTimeoutValue.time > Date.now()) {
							this.cache.set(storageKey, val.value);

							return cacheTimeoutValue.data as string;
						}
					}
				}

				// в хранилище нет - отправить GET запрос по указанной ссылке
				Logger.Log.i('ResponseCacheService', `httpGET -> no data "${storageKey}" in STORAGE, will get it by URL: "${url}"`)
					.console();

				return this.httpService.sendGet(url, headers)
					.then((response: string) => {

						// запрос успешно выполнен - сохранить в хранилище
						const cacheTimeoutValue = {time: new Date().getTime() + lifeTime, data: response};
						const data = [{
							key: storageKey,
							value: JSON.stringify(cacheTimeoutValue)
						}];

						return this.storageService.put(ApplicationAppId, data, 10000, 5)
							.then(() => {
								this.cache.set(storageKey, data[0].value);

								return cacheTimeoutValue.data;
							});
					});
			});
	}

	/**
	 * Очищает закешированные данные, по ключу в Storage-сервисе и из памяти.
	 *
	 * @param {string} storageKey Ключ хранения.
	 * @returns {Promise<void>}
	 */
	cleanUp(storageKey: string): Promise<void> {
		Logger.Log.i('ResponseCacheService', `cleanup storage/memory data by key "${storageKey}"`)
			.console();

		// удалить из памяти
		this.cache.del(storageKey);

		// удалить из хранилища
		return this.storageService.del(ApplicationAppId, [storageKey], 10000, 5)
			.then(() => {
				this.cache.del(storageKey);
			});
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Добавляет в результат запроса данные из кеша.
	 * @param T extends IResponse Тип данных.
	 * @param result Результат запроса.
	 * @param key Ключ.
	 * @param data Данные.
	 * @private
	 */
	private addToResult<T extends IResponse>(T, result: Array<KeyValue<T>>, key: string, data: string): void {
		const cacheTimeoutValue = JSON.parse(data) as CacheTimeoutValue;
		if (cacheTimeoutValue.time > new Date().getTime()) {
			if (typeof cacheTimeoutValue.data !== 'string') {
				const value: T | string = cacheTimeoutValue.data as T | string;
				result.push({key, value});
			}
			// validateResponseSync<T>(T, cacheTimeoutValue.data, {
			//   onSucceed: value => result.push({
			//     key: key,
			//     value: value
			//   }),
			//   onFailed: error => {
			//     Logger.Log.e('ResponseCache', 'esapAPI, got key: %s, error: %s', key, error.message).console();
			//     throw error;
			//   }
			// });
		}
	}

	/**
	 * Получить данные из удаленного источника.
	 * @param T extends IResponse Тип данных.
	 * @param result Результат запроса.
	 * @param request Запрос.
	 * @param key Ключ данных для получения.
	 * @param lifeTime Время жизни данных.
	 * @private
	 */
	private getFromRemote<T extends IResponse>(
		T,
		result: Array<KeyValue<T>>,
		request: IRequest,
		key: string,
		lifeTime: number
	): Promise<T> {
		Profile.startCheckPoint(ProfileKey.GetDataFromCS);

		// обновить в диалоге счетчик картинок
		if (!!this.dialogContainerService.transactionDialog) {
			const imageCounter = this.dialogContainerService.transactionDialog.dialogComponent.imageCounter$$.value + 1;
			this.dialogContainerService.updateTransactionDialog({imageCounter});
		}

		return this.httpService.sendApi(request)
			.then(esapResponse => {
				return validateResponse<T>(T, esapResponse);
			})
			.then(response => {
					const cacheTimeoutValue = {time: new Date().getTime() + lifeTime, data: response};
					const data = [{
						key,
						value: JSON.stringify(cacheTimeoutValue)
					}];

					return this.storageService.put(ApplicationAppId, data, 10000, 5)
						.then(() => {
							this.cache.set(key, data[0].value);
							result.push({key, value: response});
							Profile.stopCheckPoint(ProfileKey.GetDataFromCS);

							return cacheTimeoutValue.data;
						});
				}
			);
	}
}
