import { ActionUrl, Barcode, CsConfig, Protocols, Report } from '@app/core/configuration/cs';
import { EsapActions } from '@app/core/configuration/esap';
import { Lotteries, LotteryGameCode } from '@app/core/configuration/lotteries';
import { IActionUrl } from '@app/core/net/http/api/types';
import { KeyValue } from '@app/core/net/ws/api/types';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { HardwareInfoData, StorageKeys } from '@app/core/net/ws/api/models/storage/storage-models';
import { StorageHardwareInfoResp } from '@app/core/net/ws/api/models/storage/storage-hardware-info';
import { parseUrl } from '@app/util/utils';
import { ITransactionTicketTemplate } from '@app/tickets-print/interfaces/itransaction-ticket-template';

/**
 * Идентификатор приложения.
 */
export const ApplicationAppId = 'ua.msl.lottery_terminal';

/**
 * Веб-конфиг
 */
const WEB_CONFIG = '/config-alt-web.json';

/**
 * Тип переменных, которые могут быть инициализированы в конфигурации.
 */
type TermInitVariable = (value: string) => void;

/**
 * Интерфейс для инициализации переменных в конфигурации.
 */
interface TermInitKeyMapping {
	/**
	 * Инициализация переменной.
	 */
	initialize: TermInitVariable;
	/**
	 * Значение по умолчанию.
	 */
	default: string;
}

/**
 * Тип, содержащий возможные языки приложения.
 */
export type Language = 'en' | 'ua' | 'ru';

/**
 * Список возможных языков приложения.
 */
export const LanguageList = [
	{value: 'ua', label: 'УКР'},
	{value: 'ru', label: 'РУС'}
	// {value: 'en', label: 'ENG'} // ALT-169
];

/**
 * Список возможных идентификаторов в секции Protocols.
 */
export enum ProtocolsType {
	/**
	 * ESAP-протокол.
	 */
	ESAP = 'ESAP'
}

/**
 * Список возможных типов приложения (пока-что ALTTerminal только)
 */
export enum AppType {
	/**
	 * АЛЬТ-терминал
	 */
	ALTTerminal = 'altterminal'
}

/**
 * Модель контейнера с настройками приложения.
 */
export class Settings {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Возвращает модель данных с ответом на запрос {@link StorageHardwareInfoReq} и хранит информацию
	 * об аппаратном обеспечении терминала.
	 */
	get hardwareInfo(): HardwareInfoData {
		return this._hardwareInfo;
	}

	// ---

	/**
	 * Возвращает строку для уникальной идентификации терминала.
	 * Получается на основе модели {@link StorageHardwareInfoResp}.
	 */
	get termUid(): string {
		return this._termUid;
	}

	// ---

	/**
	 * Геттер признака наличия авторизации терминала в ЦС.
	 */
	get isTermAuth(): boolean {
		return this._isTermAuth;
	}

	/**
	 * Сеттер признака наличия авторизации терминала в ЦС.
	 * @param value Признак наличия авторизации терминала в ЦС.
	 */
	set isTermAuth(value: boolean) {
		this._isTermAuth = value;
	}

	// ---
	/**
	 * Геттер ссылки на сервис для работы с сетью.
	 */
	get communicationServiceUrl(): string {
		return this._communicationServiceUrl;
	}

	/**
	 * Сеттер ссылки на сервис для работы с сетью.
	 * @param value Ссылка на сервис для работы с сетью.
	 */
	set communicationServiceUrl(value: string) {
		this._communicationServiceUrl = value;
	}

	/**
	 * Геттер ссылки на сервис печати.
	 */
	get printServiceUrl(): string {
		return this._printServiceUrl;
	}

	/**
	 * Сеттер ссылки на сервис печати.
	 * @param value Ссылка на сервис печати.
	 */
	set printServiceUrl(value: string) {
		this._printServiceUrl = value;
	}

	/**
	 * Геттер языка приложения.
	 */
	get lang(): Language {
		return this._lang;
	}

	/**
	 * Сеттер языка приложения.
	 * @param value Язык приложения.
	 */
	set lang(value: Language) {
		this._lang = value;
	}

	/**
	 * Геттер кода терминала.
	 */
	get termCode(): string {
		return this._termCode;
	}

	/**
	 * Сеттер кода терминала.
	 * @param tc Код терминала.
	 */
	set termCode(tc: string) {
		this._termCode = tc;
	}

	/**
	 * Геттер идентификатора клиента.
	 */
	get clientID(): string {
		return this._clientID;
	}

	/**
	 * Сеттер идентификатора клиента.
	 * @param clID Идентификатор клиента.
	 */
	set clientID(clID: string) {
		this._clientID = clID;
	}

	/**
	 * Геттер языка по умолчанию.
	 */
	get defaultLang(): Language {
		return this._defaultLang;
	}

	/**
	 * Геттер ссылки на ЦС.
	 */
	get csEnvironmentUrl(): string {
		return this._csEnvironmentUrl;
	}

	/**
	 * Геттер типа приложения.
	 */
	get appType(): AppType {
		return this._appType;
	}

	/**
	 * Сеттер типа приложения.
	 * @param at Тип приложения.
	 */
	set appType(at: AppType) {
		this._appType = at;
	}

	/**
	 * Геттер ключа для сканирования.
	 */
	get scanditKey(): string {
		return this._scanditKey;
	}

	/**
	 * Сеттер ключа для сканирования.
	 * @param key Ключ для сканирования.
	 */
	set scanditKey(key: string) {
		this._scanditKey = key;
	}

	/**
	 * Геттер базового URL для сервиса TRS.
	 */
	get trsBaseURL(): string {
		return this._trsBaseURL;
	}

	/**
	 * Сеттер базового URL для сервиса TRS.
	 * @param key Базовый URL для сервиса TRS.
	 */
	set trsBaseURL(key: string) {
		this._trsBaseURL = key;
	}

	/**
	 * Конфигурация отчетов на терминале в виде списка моделей {@link Report}.
	 */
	readonly esapReports: Array<Report> = [];

	/**
	 * Список возможных типов баркодов в системе.
	 */
	readonly esapBarcodes: Array<Barcode> = [];

	/**
	 * Список кодов моментальных (ЭМЛ) игр из конфигурации терминала.
	 */
	readonly instantLotteryCodes: Array<number> = [];

	/**
	 * Обязательные способы продажи лотерей
	 */
	readonly sellMethods = new Map([
		[LotteryGameCode.Zabava, {sms: false, printer: true}],
		[LotteryGameCode.MegaLot, {sms: false, printer: true}],
		[LotteryGameCode.Tip, {sms: false, printer: true}],
		[LotteryGameCode.Top, {sms: false, printer: true}],
		[LotteryGameCode.Gonka, {sms: false, printer: true}],
		[LotteryGameCode.TML_BML, {sms: false, printer: false}],
		[LotteryGameCode.Sportprognoz, {sms: false, printer: true}],
		[LotteryGameCode.Kare, {sms: false, printer: true}]
	]);

	/**
	 * Содержит информацию о клиенте в виде строки.
	 */
	clientInfo: string;

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Конфигурация различных протоколов {@link Protocols}.
	 */
	private readonly esapProtocolSettings = new Map<string, Protocols>();

	/**
	 * Соответствие между действиями по каждой лотерее и URL-адресами для выполнения этих действий.
	 * @private
	 */
	private readonly esapActionToUrlMappingByLottery: Map<number, Map<string, string>> = new Map();

	/**
	 * Признак того, что терминал авторизован.
	 * @private
	 */
	private _isTermAuth: boolean;

	/**
	 * Ссылка на сервис для работы с сетью.
	 * @private
	 */
	private _communicationServiceUrl: string;

	/**
	 * Ссылка на сервис для работы с печатью.
	 * @private
	 */
	private _printServiceUrl: string;

	/**
	 * Ссылка на центральную систему.
	 * @private
	 */
	private _csEnvironmentUrl: string;

	/**
	 * Код терминала.
	 * @private
	 */
	private _termCode: string;

	/**
	 * Информация об оборудовании.
	 * @private
	 */
	private _hardwareInfo: HardwareInfoData;

	/**
	 * Идентификатор терминала.
	 * @private
	 */
	private _termUid: string;

	/**
	 * Язык приложения по умолчанию.
	 * @private
	 */
	private _defaultLang: Language;

	/**
	 * Текущий язык приложения.
	 * @private
	 */
	private _lang: Language;

	/**
	 * Тип приложения.
	 * @private
	 */
	private _appType: AppType;

	/**
	 * Идентификатор клиента.
	 * @private
	 */
	private _clientID: string;

	/**
	 * Ключ для работы с компонентом Scandit.
	 * @private
	 */
	private _scanditKey: string;

	/**
	 * Ссылка на сервис для формирования картинок билетов.
	 * @private
	 */
	private _trsBaseURL: string;

	/**
	 * Последний запрошенный URL.
	 * @private
	 */
	private _lastRequestedURL: string;

	/**
	 * Начальные данные терминала, читаются из Storage-сервиса.
	 */
	private readonly _termInitKeysMapping = new Map<string, TermInitKeyMapping>([
		[StorageKeys.TermCodeKey, {
			initialize: (value: string) => this._termCode = value,
			default: ''
		}],
		[StorageKeys.CsEnvironmentUrlKey, {
			initialize: (value: string) => {
				this._csEnvironmentUrl = WEB_CONFIG;
			},
			default: WEB_CONFIG
			// default: 'https://dev.cs.emict.net:6443/config.json'
			// default: 'https://cs.test.msl.ua/config.json'
			// default: 'assets/config.json'
		}],
		[StorageKeys.DefaultLangKey, {
			initialize: (value: string) => this._defaultLang = value as Language,
			default: 'ua'
		}]
	]);

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Пропарсить ответ Storage-сервиса на запрос {@link StorageHardwareInfoReq}.
	 * Заполняет два параметра {@link hardwareInfo} и {@link termUid}.
	 *
	 * @param {StorageHardwareInfoResp} response Ответ от Storage-сервиса, который необходимо пропарсить.
	 * @returns {boolean} Возвращает true, если полученный ответ корректный или false, если заполнены не все поля.
	 */
	parseHardwareInfoResponse(response: StorageHardwareInfoResp): boolean {
		let uid = '';
		if (Array.isArray(response.data) && response.data.length === 1) {
			this._hardwareInfo = response.data[0];

			if (!!response.data[0].androidId) {
				uid += response.data[0].androidId;
			}

			if (!!response.data[0].deviceId) {
				uid += response.data[0].deviceId;
			}

			if (Array.isArray(response.data[0].networkInterface)) {
				response.data[0].networkInterface
					.forEach((val: KeyValue) => {
						uid += val.value;
					});
			}

			this._termUid = uid;
			// environment.terminal = !!response.data[0].androidSdk;

			Logger.Log.i('InitService', `parseHardwareInfoResponse -> parsed terminal UID: ${this.termUid}`)
				.console();

			return true;
		}

		return false;
	}

	/**
	 * Возвращает список ключей параметров инициализации терминала.
	 *
	 * @returns {Array<string>}
	 */
	getKeysForAppInit(): Array<string> {
		return Array.from(this._termInitKeysMapping.keys());
	}

	/**
	 * Инициализирует параметры терминала значениями из Storage-сервиса.
	 *
	 * @param {Array<KeyValue>} data Данные, полученные из Storage-сервиса.
	 * @returns {Array<KeyValue>}
	 */
	populateInitialSetting(data: Array<KeyValue>): Array<KeyValue> {
		const insertions: Array<KeyValue> = [];
		this._termInitKeysMapping.forEach((value, key) => {
			const item: KeyValue = data.find(p => p.key === key);
			if (item) {
				value.initialize(item.value);
			} else {
				value.initialize(value.default);
				insertions.push({
					key,
					value: value.default
				});
			}
		});

		return insertions;
	}

	/**
	 * Запускает процедуру обработки конфигурации терминала полученой из ЦС.
	 *
	 * @param {CsConfig} config Десериализированная конфигурация терминала.
	 * @returns {string} Ошибка парсинга конфигурации.
	 */
	populateEsapActionsMapping(config: CsConfig): string {
		// обработать секцию "lotteries"
		if (Array.isArray(config.lotteries)) {
			config.lotteries.forEach(lottery => {
				lottery.codes.forEach(code => {
					lottery.actions.forEach(action => {
						this.setEsapAction(code, action);
					});
				});
			});
		}

		// обработать секцию "barcodes"
		if (Array.isArray(config.barcodes)) {
			this.esapBarcodes.splice(0);
			this.esapBarcodes.push(...config.barcodes);
		}

		// обработать секцию "generalActions"
		if (Array.isArray(config.generalActions)) {
			// проверяем наличие обязательных экшенов
			EsapActions.general.forEach(mandatoryAction => {
				const item: ActionUrl = config.generalActions.find(p => p.action === mandatoryAction);
				if (!item) {
					return `can't find action: ${mandatoryAction} in general section`;
				}
			});

			// под кодом "0" запоминает хешмап экшенов из секции "generalActions"
			config.generalActions.forEach(action => this.setEsapAction(0, action));
		} else {
			return `can't find generalAction section`;
		}

		// пропарсить секцию "protocols"
		this.esapProtocolSettings.clear();
		if (Array.isArray(config.protocols)) {
			config.protocols.forEach(v => this.esapProtocolSettings.set(v.id, v));
		}

		// пропарсить секцию "reports"
		if (Array.isArray(config.reports)) {
			this.esapReports.splice(0);
			this.esapReports.push(...config.reports);
		}

		// наполнить массив кодами моментальных лотерей (ЭМЛ и ТМЛ)
		const instantLotoCodes = this.getLotteryCodesByEsapActionName(EsapActions.EInstantRegBet);
		this.instantLotteryCodes.splice(0);
		this.instantLotteryCodes.push(
			...instantLotoCodes,
			...this.getLotteryCodesByEsapActionName(EsapActions.PaperInstantRegBet)
		);

		for (const ilCode of instantLotoCodes) {
			this.sellMethods.set(ilCode, {sms: false, printer: true});
		}

		// установить лицензионный ключ для компонента scandit
		this._scanditKey = config.scanditKey;
		const objTRS = config.generalActions.find(elem => elem.action === 'TRS');
		this._trsBaseURL = objTRS?.url || '/trs';
	}

	/**
	 * Возвращает объект соответствие URL, для выполнения действия в ЦС, имени действия, для конкретной лотереи.
	 *
	 * @param {LotteryGameCode | string} type Код лотереи или регулярное выражение баркода (при проверке билетов).
	 * @param {string} action Имя экшена.
	 * @param {boolean} throwException Признак, указывающий на необходимость сделать исключение на случай, если объект не найден.
	 * @returns {IActionUrl} Объект соответствия URL -> ACTION.
	 */
	getEsapActionUrl(type: LotteryGameCode | string, action: string, throwException = true): IActionUrl | undefined {
		let url: string;
		if (typeof type === 'string') {
			// поиск по баркоду
			this.esapBarcodes.forEach(barcode => {
				if (type.match(barcode.regexp)) {
					const item: ActionUrl = barcode.actions.find(keyval => keyval.action === action);
					if (item) {
						url = item.url;
					}
				}
			});
		} else if (typeof type === 'number') {
			// поиск по коду лотереи
			const lottery = this.esapActionToUrlMappingByLottery.get(type);
			if (lottery) {
				url = lottery.get(action);
			}
		}

		if (url) {
			this._lastRequestedURL = url;

			return {action, url};
		} else if (this._lastRequestedURL) {

			return {action, url: this._lastRequestedURL};
		}

		if (throwException) {
			Logger.Log.e('Settings', `getEsapActionUrl -> can't find URL by '${type}' for action '${action}'`)
				.console();

			throw new Error(`url by ${type} for action: ${action} not found!`);
		}
	}

	/**
	 * Возвращает массив кодов, соответствующий имени экшена.
	 *
	 * @param {string} actionName Имя экшена.
	 */
	getLotteryCodesByEsapActionName(actionName: string): Array<number> {
		return Array.from(this.esapActionToUrlMappingByLottery)
			.filter(f =>
				Array.from(f[1].keys())
					.filter(ff => ff === actionName).length > 0)
			.map(m => m[0]);
	}

	/**
	 * Возвращает объект протокола {@link Protocols} по определенному типу {@link ProtocolsType}.
	 *
	 * @param {ProtocolsType} type Тип протокола.
	 */
	getProtocolByType(type: ProtocolsType): Protocols {
		return this.esapProtocolSettings.get(type.toString());
	}

	/**
	 * Создает полную ссылку на шаблон билета на основе частичной ссылки из ответа на покупку билета.
	 *
	 * @param {string} path Частичная ссылка.
	 * @returns {ITransactionTicketTemplate}
	 */
	loadingUrlFactory(path: string): ITransactionTicketTemplate {
		const requestUrl = parseUrl(this.csEnvironmentUrl);
		const port = !!requestUrl.port ? `:${requestUrl.port}` : '';
		const url = `${requestUrl.origin}${port}/${path}`;

		return {path, url};
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Устанавливает соответствие урлу, для выполнения действия в ЦС, имени действия, для конкретной лотереи.
	 *
	 * @param {number} code Код лотереи.
	 * @param {ActionUrl} item Объект ссылки с действием типа {@link ActionUrl}.
	 */
	private setEsapAction(code: number, item: ActionUrl): void {
		let map = this.esapActionToUrlMappingByLottery.get(code);
		if (!map) {
			this.esapActionToUrlMappingByLottery.set(code, new Map());
			map = this.esapActionToUrlMappingByLottery.get(code);
		}

		map.set(item.action, item.url);
		Lotteries.setLottery(code);
	}

}
