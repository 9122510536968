/**
 * Список возможных лотерей.
 * - {@link Undefined} - неопределенная лотерея
 * - {@link MegaLot} - тиражная лотерея "Мегалот"
 * - {@link Sportprognoz} - тиражная лотерея "Спортпрогноз"
 * - {@link Zabava} - тиражная лотерея "Лото Забава"
 * - {@link Tip} - тиражная лотерея "Тип"
 * - {@link Top} - тиражная лотерея "Топ"
 * - {@link Gonka} - тиражная лотерея "Гонка на гроши"
 * - {@link TML_BML} - бумажные и типографские моментальные лотереи
 * - {@link Kare} - тиражная лотерея "Каре"
 */
export enum LotteryGameCode {
	Undefined			= 0,
	MegaLot				= 1,
	Sportprognoz		= 2,
	Zabava				= 3,
	Tip					= 71,
	Top					= 72,
	Gonka				= 13,
	TML_BML				= 100,
	Kare				= 52
}

/**
 * Список групп электронных моментальных лотерей.
 * - {@link Regular} - обычные тиражные лотереи
 * - {@link EInstant} - ЭМЛ типа "Швидкограй"
 * - {@link VBL} - ЭМЛ типа "Золотий триумф"
 */
export enum LotteriesGroupCode {
	Regular				= 0,
	EInstant			= 1,
	VBL					= 2
}

/**
 * Список статусов тиражей:
 * - {@link DRST_BET_MULTI} - Регистрация многотиражных ставок
 * - {@link DRST_BET} - Регистрация ставок
 * - {@link SALES_AND_PAYS} - Продажа и выплата выигрышей
 */
export enum DrawsStatus {
	DRST_BET_MULTI		= 'DRST_BET_MULTI',
	DRST_BET			= 'DRST_BET',
	SALES_AND_PAYS		= 'SALES AND PAYS'
}

/**
 * Модель для связи кода лотереи с ключом по которому она доступна в Storage-сервисе.
 */
export interface LotteryInfo {
	/**
	 * Код лотереи.
	 */
	code: LotteryGameCode;
	/**
	 * Ключ по которому лотерея доступна в Storage-сервисе.
	 */
	key: string;
}

/**
 * Класс для работы со списком лотерей.
 */
export class Lotteries {

	/**
	 * Список лотерей.
	 * @private
	 */
	private static lotteriesInfo: Array<LotteryInfo>;

	/**
	 * Список моментальных лотерей.
	 * @private
	 */
	private static readonly instantLotteries: Map<LotteriesGroupCode, Array<LotteryGameCode>> = new Map();

	/**
	 * Получение списка лотерей на основе данных конфигурационного файла, полученного из ЦС.
	 *
	 * @returns {Array<LotteryInfo>}
	 */
	private static getLotteriesInfo(): Array<LotteryInfo> {
		return Lotteries.lotteriesInfo;
	}

	/**
	 * Заполнение списка лотерей доступных на терминале.
	 *
	 * @param {LotteryGameCode} code Код лотереи.
	 */
	static setLottery(code: LotteryGameCode): void {
		if (!Lotteries.lotteriesInfo) {
			Lotteries.lotteriesInfo = [];
		}

		if (!Lotteries.getKeyByGameCode(code)) {
			Lotteries.lotteriesInfo.push({code, key: `lottery_${code}_draws`});
		}
	}

	/**
	 * Список ключей, по всем лотереям (под которыми они хранятся в Storage-сервисе).
	 *
	 * @returns {Array<string>} Список ключей.
	 */
	static getKeysForLotteriesInit(): Array<string> {
		const keys = [];
		Lotteries.getLotteriesInfo()
			.forEach(item => {
				keys.push(item.key);
			});

		return keys;
	}

	/**
	 * Получение кода лотереи на основе ключа (под которым она хранится в Storage-сервисе).
	 *
	 * @param {string} key ключ в Storage-сервисе
	 * @returns {LotteryGameCode} Код лотереи.
	 */
	static getGameCodeByKey(key: string): LotteryGameCode {
		const item = Lotteries.getLotteriesInfo()
			.find(value => value.key === key);
		if (item) {
			return item.code;
		}

		return LotteryGameCode.Undefined;
	}

	/**
	 * Получение ключа по лотерее (под которым она хранится в Storage-сервисе).
	 *
	 * @param {LotteryGameCode} code Код лотереи.
	 * @returns {string} Ключ в Storage-сервисе.
	 */
	static getKeyByGameCode(code: LotteryGameCode): string {
		const item = Lotteries.getLotteriesInfo()
			.find(value => value.code === code);
		if (item) {
			return item.key;
		}

		return undefined;
	}

	/**
	 * Сообщает реализована ли лотерея, по коду, на терминале.
	 *
	 * @param {LotteryGameCode} code Код лотереи.
	 * @returns {boolean} Возвращает true, если лотерея есть или false - в противном случае.
	 */
	static hasLottery(code: LotteryGameCode): boolean {
		const item = Lotteries.getLotteriesInfo()
			.find(value => value.code === code);

		return !!item;
	}

	/**
	 * Возвращает список кодов электронных моменталок на основе заданной группы.
	 *
	 * @param {LotteriesGroupCode} groupId Группа.
	 * @returns {Array<LotteryGameCode>} Список кодов лотерей моменталок.
	 */
	static getInstantLotteriesCodes(groupId: LotteriesGroupCode): Array<LotteryGameCode> {
		return Lotteries.instantLotteries.has(groupId) ? Lotteries.instantLotteries.get(groupId) : []; // TODO should return undefined
	}

	/**
	 * Регистрация электронной моменталки в указанной группе.
	 *
	 * @param {LotteryGameCode} code кодов моменталки
	 * @param {LotteriesGroupCode} groupId группе
	 */
	static setInstantLottery(code: LotteryGameCode, groupId: LotteriesGroupCode): void {
		if (Lotteries.instantLotteries.has(groupId)) {
			const group = Lotteries.instantLotteries.get(groupId);
			group.push(code);
		} else {
			Lotteries.instantLotteries.set(groupId, [code]);
		}
	}

	/**
	 * Очистка списка электронных моменталок.
	 */
	static clearInstantLotteries(): void {
		Lotteries.instantLotteries.clear();
	}

	// static lotteryInstanceOfGroup(code: LotteryGameCode, groupId: LotteriesGroupCode): boolean {
	// 	const lotteryList  = Lotteries.getInstantLotteriesCodes(groupId);
	// 	if (lotteryList) {
	// 		return lotteryList.indexOf(code) !== -1;
	// 	}
	//
	// 	return false;
	// }
}
