import { Pipe, PipeTransform } from '@angular/core';

/**
 * Список слов, подлежащих склонению.
 * - {@link Event} - слово "Событие"
 * - {@link Mark} - слово "Отметка"
 * - {@link Mark2} - слово "Отметку"
 * - {@link Numbers} - слово "Число"
 */
export enum DeclensionWords {
	Event = 'lottery.decline_words.event',
	Mark = 'lottery.decline_words.mark',
	Mark2 = 'lottery.decline_words.mark2',
	Numbers = 'lottery.decline_words.numbers'
}

/**
 * Пайп, корректирующий написание слова, стоящего рядом с числом.
 */
@Pipe({
	name: 'declineWord'
})
export class DeclineWordPipe implements PipeTransform {

	transform(value: string, ...args: any[]): string {
		const cases = [2, 0, 1, 1, 1, 2];
		const mod100 = args[0] % 100;
		const mod10 = args[0] % 10;
		const index = (mod100 > 4 && mod100 < 20) ? 2 : cases[(mod10 < 5) ? mod10 : 5];

		const words = Object.values(DeclensionWords);
		if (words.includes(value as DeclensionWords)) {
			return `${value}_${index}`;
		}

		return value;
	}

}
