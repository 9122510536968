<div class="hamburger-menu-container"
	 data-cy="hamburger-menu-container"
	 [@openClose]="hamburgerMenuService.stateExpression"
	 (@openClose.start)="animationStarted($event)"
	 (@openClose.done)="animationDone($event)"
>
	<div class="hamburger-close"
		 data-cy="hamburger-close"
	>
		<app-close-button
			class="hamburger-close__button"
			data-cy="hamburger-close-button"
			(action)="onCloseMenuHandler()"
		></app-close-button>
	</div>
	<div class="hamburger-menu-main"
		 data-cy="hamburger-menu-main"
	>
		<ul class="hamburger-menu"
			data-cy="hamburger-menu"
			(click)="onClickHamburgerMenuItemHandler($event)"
		>

			<!-- ОСНОВНОЙ СПИСОК С МАРШРУТАМИ -->
			<ng-container *ngFor="let menu of menuItems; trackBy: trackByIndex; let i = index">
				<li *ngIf="menu.isVisible"
					class="hamburger-menu__item"
					data-cy="hamburger-menu-item"
					[value]="i"
				>
					<span class="hamburger-menu__text"
						  [class.hamburger-menu__text_active]="menu.isActive"
						  data-cy="hamburger-menu-text"
					>{{ menu.label | translate }}</span>
				</li>
			</ng-container>
		</ul>

		<!-- ОТМЕНА ОПЕРАЦИИ -->
		<button class="cancel-last"
				[class.cancel-last_disabled]="hamburgerMenuService.cancelButtonDisabled"
				data-cy="cancel-last"
				type="button"
				(click)="onClickCancelLast()"
		>{{ 'header.cancel_transaction' | translate }}</button>

		<!-- СМЕНА ЯЗЫКА -->
		<div class="lang-switcher"
			 data-cy="lang-switcher"
		>
			<label class="lang-switcher__lang"
				   data-cy="lang-switcher-lang"
			>
				<input [checked]="translate.currentLang === 'ru'"
					   (click)="onClickChangeLangHandler('ru')"
					   class="lang-switcher__input"
					   type="radio"
					   name="lang"
					   data-cy="lang-switcher-input"
				>
				<span class="lang-switcher__radio" data-cy="lang-switcher-radio"></span>
				<span class="lang-switcher__title" data-cy="lang-switcher-title">Русский язык</span>
			</label>
			<label class="lang-switcher__lang" data-cy="lang-switcher-lang">
				<input [checked]="translate.currentLang === 'ua'"
					   (click)="onClickChangeLangHandler('ua')"
					   class="lang-switcher__input"
					   data-cy="lang-switcher-input"
					   type="radio"
					   name="lang"
				>
				<span class="lang-switcher__radio"
					  data-cy="lang-switcher-radio"
				></span>
				<span class="lang-switcher__title"
					  data-cy="lang-switcher-title"
				>Українська мова</span>
			</label>
		</div>

		<!-- СМЕНА ПАРОЛЯ -->
		<button class="change-password"
				data-cy="change-password"
				[class.change-password_disabled]="appStoreService?.operator?.value?.isManager"
				type="button"
				(click)="onClickChangePasswordHandler()"
		>{{ 'hamburger.change_password' | translate }}
			<span class="change-password__icon-1"
				  [class.change-password__icon-1_disabled]="appStoreService?.operator?.value?.isManager"
				  data-cy="change-password-icon-1"
			>
				<i class="material-icons change-password__icon-2"
				   [class.change-password__icon-2_disabled]="appStoreService?.operator?.value?.isManager"
				   data-cy="change-password-icon-2"
				>https</i>
			</span>
		</button>
	</div>
	<div class="sys-info"
		 data-cy="sys-info"
	>
		<div class="hm-help"
			 data-cy="hm-help"
			 (click)="onClickHelpHandler()"
		>
			{{ 'header.help' | translate }}
			<div class="hm-help__q"
				 data-cy="hm-help-q"
			>?</div>
		</div>
		<div class="hm-system-info"
			 (click)="onClickAboutHandler()"
			 data-cy="hm-system-info"
		>
			{{ 'header.build_version_2' | translate:{value: buildVersion} }}
			<i class="material-icons">info</i>
		</div>

	</div>

</div>

<app-change-password
	#chpDialog
	class="chp-dialog"
	[class.chp-dialog_visible]="true"
	data-cy="chp-dialog"
></app-change-password>
<app-help #helpDialog
	data-cy="help-dialog"
></app-help>
