import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

/**
 * Кастомная конфигурация HammerJS.
 */
@Injectable()
export class HammerConfig extends HammerGestureConfig {
	/**
	 * Переопределение свойств HammerJS.
	 */
	overrides = {
		swipe: {direction: Hammer.DIRECTION_ALL}
	};
}
