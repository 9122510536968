import { KeyValue } from '@app/core/net/ws/api/types';

/**
 * Фиксированный URl Storage-сервиса.
 */
export const STORAGE_SERVICE_URL = 'ws://127.0.0.1:2223';

/**
 * Смотри описание API Storage Service
 */
export interface HardwareInfoData {

	/**
	 * Идентификатор устройства типа "FORESEE_64GB_SSD_I37392J013512".
	 */
	androidId: string;

	/**
	 * Список сетевых интерфейсов.
	 */
	networkInterface: Array<KeyValue>;

	/**
	 * Версия ПО.
	 */
	softwareVersion: string;

	/**
	 * Версия SDK.
	 */
	androidSdk?: string;

	/**
	 * Идентификатор устройства.
	 */
	deviceId: string;

	/**
	 * Серийный номер сим-карты.
	 */
	simSerialNumber: string;

}

/**
 * Список ключей, по которым можно получить доступ к данным в хранилище терминала.
 * - {@link UserLanguage} - язык пользователя
 * - {@link LatestRunningVersion} - последняя версия сборки (не UI), по которой был успешный логин
 * - {@link StorageTransactionKey} - последняя выполненная транзакция
 * - {@link BonusTransactionState} - состояние последней транзакции по выплате бонуса
 * - {@link GameResultsBaseKey} - результаты по игре (формируется как ключ + код игры)
 * - {@link DefaultLangKey} - язык по умолчанию
 * - {@link CsEnvironmentUrlKey} - ссылка на файл конфигурации
 * - {@link TermCodeKey} - код терминала
 * - {@link ServiceUrlKey} - ключ для получения URL одного из сервисов
 */
export enum StorageKeys {
	UserLanguage			= 'UserLanguageKey',
	LatestRunningVersion	= 'LatestRunningVersion',
	StorageTransactionKey	= 'last_transaction',
	BonusTransactionState	= 'last_bonus_transaction',
	GameResultsBaseKey		= 'game_results_base_key_',

	DefaultLangKey			= 'init_DefaultLang',
	CsEnvironmentUrlKey		= 'init_CsEnvironmentUrl',
	TermCodeKey				= 'init_TermCode',

	ServiceUrlKey			= 'url',
	Operator				= 'operator'
}
