import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OneButtonCustomComponent } from '@app/shared/components/one-button-custom/one-button-custom.component';
import { MslInputWithEyeComponent } from '@app/shared/components/msl-input-with-eye/msl-input-with-eye.component';
import { timer } from 'rxjs';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { ChangePasswordService } from '@app/hamburger/services/change-password.service';
import { finalize } from 'rxjs/operators';
import { BOChangePasswordResp } from '@app/core/net/http/api/models/bo-change-password';
import { ApiError, NetError } from '@app/core/error/types';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { AuthService } from '@app/core/services/auth.service';
import { TransactionService } from '@app/core/services/transaction/transaction.service';

/**
 * Статус смены пароля
 */
export enum ChangeStatus {
	/**
	 * Начальное состояние
	 */
	Initial = 'ch-status_initial',
	/**
	 * Ожидание выполнения запроса
	 */
	Busy = 'ch-status_busy',
	/**
	 * Успешное выполнение запроса
	 */
	Success = 'ch-status_success',
	/**
	 * Ошибка выполнения запроса
	 */
	Error = 'ch-status_error'
}

/**
 * Компонент смены пароля
 */
@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

	// -----------------------------
	//  Input properties
	// -----------------------------
	// @Input()
	// newPassDisabled = false;

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Список статусов смены пароля
	 */
	readonly ChangeStatus = ChangeStatus;

	/**
	 * Статус смены пароля
	 */
	changeStatus: ChangeStatus = ChangeStatus.Initial;

	/**
	 * Результат смены пароля
	 */
	changePasswordResult = '';

	/**
	 * Диалог смены пароля
	 */
	@ViewChild('chpDialog', {static: false}) chpDialog: OneButtonCustomComponent;

	/**
	 * Поле ввода для старого пароля
	 */
	@ViewChild('oldPass', {static: false}) oldPass: MslInputWithEyeComponent;

	/**
	 * Поле ввода для нового пароля
	 */
	@ViewChild('newPass', {static: false}) newPass: MslInputWithEyeComponent;

	/**
	 * Поле ввода для повтора нового пароля
	 */
	@ViewChild('newPass2', {static: false}) newPass2: MslInputWithEyeComponent;

	/**
	 * Объект формы для отправки запроса на смену пароля
	 */
	form: FormGroup;

	/**
	 * Флаг, показывающий, был ли отображен диалог с правилами пароля
	 */
	rulesDialogShown = false;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {FormBuilder} formBuilder Сервис для создания форм
	 * @param {ChangePasswordService} changePasswordService Сервис для смены пароля
	 * @param {DialogContainerService} dialogContainerService Сервис для отображения диалогов
	 * @param {AuthService} authService Сервис для работы с авторизацией
	 * @param {TransactionService} transactionService Сервис для работы с транзакциями
	 */
	constructor(
				private readonly formBuilder: FormBuilder,
				private readonly changePasswordService: ChangePasswordService,
				private readonly dialogContainerService: DialogContainerService,
				private readonly authService: AuthService,
				private readonly transactionService: TransactionService) {	}

	/**
	 * Геттер для лейбла поля ввода нового пароля
	 */
	get newpassLabel(): string {
		return this.getPassLabel('newpass', 'hamburger.enter_new_pass');
	}

	/**
	 * Геттер для лейбла поля повторного ввода нового пароля
	 */
	get newpass2Label(): string {
		return this.getPassLabel('newpass2', 'hamburger.repeat_new_pass');
	}

	/**
	 * Геттер для еррор-стейта второго поля ввода
	 */
	get errorState1(): boolean {
		return this.checkState('newpass');
	}

	/**
	 * Геттер для еррор-стейта третьего поля ввода
	 */
	get errorState2(): boolean {
		return this.checkState('newpass2');
	}

	/**
	 * Метод для показа однокнопочного диалога
	 */
	showDialog(): void {
		this.chpDialog.show();
		// так как компонент уже создан, то придется показать клавиатуру по таймеру
		const tmr = timer(200)
			.subscribe(() => {
				this.oldPass.setFocus();
				tmr.unsubscribe();
			});
	}

	/**
	 * Обработчик подтверждения ввода старого пароля
	 */
	onConfirmOldPass(): void {
		if (!this.newPass.isDisabled) {
			this.newPass.setFocus();
		}
	}

	/**
	 * Обработчик подтверждения ввода нового пароля
	 */
	onConfirmNewPass(): void {
		if (!this.newPass2.isDisabled) {
			this.newPass2.setFocus();
		}
	}

	/**
	 * Обработчик подтверждения повтора ввода нового пароля
	 */
	onConfirmRepeatPass(): void {
		this.onDialogAction();
	}

	/**
	 * Обработчик отправки формы на смену пароля
	 */
	onDialogAction(): void {
		if (this.form.valid && this.changeStatus === ChangeStatus.Initial) {
			this.changeStatus = ChangeStatus.Busy;
			this.transactionService.setLastUnCanceled();
			this.changePasswordService.changePassword(this.form.controls['oldpass'].value, this.form.controls['newpass'].value)
				.pipe(finalize(() => this.setDefaults()))
				.subscribe({
					next: (response: BOChangePasswordResp) => {
						if (response.err_code) {
							this.changeStatus = ChangeStatus.Error;
							this.changePasswordResult = response.err_descr;
						} else {
							this.changeStatus = ChangeStatus.Success;
							this.changePasswordResult = 'hamburger.password_has_been_changed';
						}
					},
					error: (error) => {
						this.changeStatus = ChangeStatus.Error;
						this.changePasswordResult = error instanceof ApiError || error instanceof NetError ?
							error.message : (error.err_descr || 'dialog.cant_change_password');
					}
				});
		} else {
			this.changeStatus = ChangeStatus.Initial;
		}
	}

	/**
	 * Обработчик установки фокуса на элемент ввода
	 */
	onFocus(event: Event, elemName: string): void {

	}

	/**
	 * Обработчик изменения ввода в поле пароля
	 */
	onChangePass(v: string, control: string): void {
	}

	/**
	 * Обработчик закрытия диалога по отмене
	 */
	onCancelHandler(): void {
		this.changeStatus = ChangeStatus.Initial;
		this.setDefaults();
	}

	/**
	 * Обработчик нажатия на кнопку помощи
	 */
	onClickHelp(): void {
		this.rulesDialogShown = true;
	}

	/**
	 * Обработчик закрытия диалога с правилами
	 */
	onCloseRulesDialog(): void {
		this.rulesDialogShown = false;
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Метод установки начальных значений
	 */
	private setDefaults(): void {
		this.oldPass.secured = true;
		this.newPass.isDisabled = true;
		this.newPass.secured = true;
		this.newPass2.isDisabled = true;
		this.newPass2.secured = true;
		this.form.reset();
	}

	/**
	 * Метод проверки стейта контрола
	 * @param controlName Имя контрола
	 */
	private checkState(controlName: string): boolean {
		return this.form && this.form.controls[controlName] && this.form.controls[controlName].value &&
			(
				this.form.controls[controlName].invalid ||
				(this.form.errors && this.form.errors.newPasswordsNotSame)
			);
	}

	/**
	 * Метод, возвращающий лейбел для контролов ввода пароля и повтора пароля
	 * @param controlName Имя контрола
	 * @param defaultLabel Дефолтный лейбел
	 */
	private getPassLabel(controlName: string, defaultLabel: string): string {
		if (this.form.controls[controlName] && this.form.controls[controlName].value) {

			if (this.form.controls[controlName].errors) {
				return 'hamburger.incorrect_password_entered';
			}

			if (this.form.errors) {
				if (this.form.errors.oldPasswords) {
					return 'hamburger.old_password_entered';
				}

				if (this.form.errors.newPasswordsNotSame) {
					return 'hamburger.passwords_not_same';
				}
			}

			// дефолтный лейбел
			return defaultLabel;
		}

		// дефолтный лейбел
		return defaultLabel;
	}

	/**
	 * Валидатор формы смены пароля
	 */
	private validatePasswords(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const formGroup = control as FormGroup;
			if ((formGroup.controls['newpass'].value === formGroup.controls['newpass2'].value)
				&& (formGroup.controls['oldpass'].value !== formGroup.controls['newpass'].value)) {
				return null;
			}

			const oldPasswords = formGroup.controls['oldpass'].value === formGroup.controls['newpass'].value
				&& formGroup.controls['oldpass'].value;
			const newPasswordsNotSame = formGroup.controls['newpass'].value !== formGroup.controls['newpass2'].value
				&&  !!formGroup.controls['newpass'].value && !!formGroup.controls['newpass2'].value;

			return oldPasswords || newPasswordsNotSame ? {oldPasswords, newPasswordsNotSame} : null;
		};
	}

	/**
	 * Валидатор совпадения с кодом оператора
	 */
	private notAsOpCode(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			return control.value === this.authService.loginOperator_val ? { asOpCode: {value: control.value} } : null;
		};
	}

	/**
	 * Валидатор совпадения со старым паролем
	 */
	private oldPassword(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			return this.form && this.form.controls['oldpass'] && this.form.controls['oldpass'].value &&
			(control.value === this.form.controls['oldpass'].value) ? { oldPasswords: {value: control.value} } : null;
		};
	}

	/**
	 * Валидатор на одинаковые цифры, идущие подряд
	 * Пароль не должен содержать двух и более одинаковых цифр подряд.
	 */
	private notSameSuccDigits(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			if (control && control.value) {
				const charsArr = control.value.split('');
				for (let i = 1; i < charsArr.length; i++) {
					if (charsArr[i] === charsArr[i - 1]) {
						return {
							sameSuccDigits: {value: control.value}
						};
					}
				}
			}

			return null;
		};
	}

	/**
	 * Валидатор по правилу:
	 * Пароль не должен содержать более трех цифр подряд в возрастающем или убывающем порядке.
	 */
	private notProgressiveSuccDigits(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			if (control && control.value) {
				const charsArr = control.value.split('');
				let counter = 1;
				let diff: number;
				// сначала ищем в возрастающем порядке
				for (let i = 1; i < charsArr.length; i++) {
					diff = charsArr[i] - charsArr[i - 1];
					if (diff === 1) {
						counter++;
					}
					if (diff !== 1) {
						counter = 1;
					}
					if (counter > 3) {
						return {
							progressiveSuccDigits: {value: control.value}
						};
					}
				}

				// потом - в убывающем
				counter = 1;
				for (let i = 1; i < charsArr.length; i++) {
					diff = charsArr[i] - charsArr[i - 1];
					if (diff === -1) {
						counter++;
					}
					if (diff !== -1) {
						counter = 1;
					}
					if (counter > 3) {
						return {
							progressiveSuccDigits: {value: control.value}
						};
					}
				}
			}

			return null;
		};
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Событие инициализации компонента
	 */
	ngOnInit(): void {
		const passValidators = [
			Validators.required,
			this.notAsOpCode(),
			Validators.minLength(6),
			this.notSameSuccDigits(),
			this.notProgressiveSuccDigits(),
			this.oldPassword()
		];
		this.form = this.formBuilder.group({
			oldpass: ['', [Validators.required]],
			newpass: ['',  passValidators],
			newpass2: ['', passValidators]
		}, {
			validators: [this.validatePasswords()]
		});
	}

}
