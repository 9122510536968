import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CameraComponent} from "./components/camera/camera.component";
import {CamerasSelectorComponent} from "./components/cameras-selector/cameras-selector.component";
import {CameraService} from "./services/camera.service";
import {SharedModule} from "@app/shared/shared.module";

@NgModule({
	declarations: [
		CameraComponent,
		CamerasSelectorComponent
	],
	imports: [
		CommonModule,
		SharedModule
	],
	exports: [
		CameraComponent
	],
	providers: [
		CameraService
	]
})
export class CameraModule { }
