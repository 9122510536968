import { Type } from 'class-transformer';
import { ArrayNotEmpty, IsNotEmpty, IsUrl, ValidateNested } from 'class-validator';
import { IActionUrl } from '../net/http/api/types';
import { EsapActions, EsapParams } from './esap';

/**
 * Интерфейс модели для проверки конфигурации ЦС. Конфигурации лотерей
 */
export interface ILottery {
	/**
	 * Коды лотерей
	 */
	codes: Array<number>;
	/**
	 * Действия по лотереям
	 */
	actions: Array<string>;
}

/**
 * Интерфейс модели для проверки конфигурации ЦС. Проверки билетов
 */
export interface IBarcode {
	/**
	 * Регулярное выражение для проверки билета
	 */
	regexp: string;
	/**
	 * Действия по проверке билета
	 */
	actions: Array<string>;
}

/**
 * Интерфейс модели для проверки конфигурации ЦС. Отчеты
 */
export interface IReports {
	/**
	 * Конфигурация отчетов
	 */
	config: string;
	/**
	 * Версия конфигурации
	 */
	version: number;
}

/**
 * Схема данных, параметров команд ЦС
 */
export class ActionUrl implements IActionUrl {
	/**
	 * Ссылка на ендпоинт
	 */
	@IsUrl()
	url: string;

	/**
	 * Название действия
	 */
	@IsNotEmpty()
	action: string;
}

/**
 * Схема данных, секции лотерей, файла конфигурации ЦС.
 */
export class Lottery {
	/**
	 * Коды лотерей
	 */
	@ArrayNotEmpty()
	codes: Array<number>;

	/**
	 * Ссылки на действия по лотереям
	 */
	@Type(() => ActionUrl)
	@ValidateNested()
	@ArrayNotEmpty()
	actions: Array<ActionUrl>;
}

/**
 * Массив содержит объекты, содержащие информацию для обработки штрих-кодов терминалом в соответствии с форматом штрих-кода.
 * Каждый объект соответствует определенному формату.
 * Формат штрих-кода определяется по соответствию штрих-кода одному из регулярных выражений.
 */
export class Barcode {
	/**
	 * Регулярное выражение для определения формата штрих-кода.
	 * При определении формата считанного штрих-кода терминал последовательно проходит по элементам массива "barcodes"
	 * и проверяет штрихкод на соответствие указанному регулярному выражению. Как только соответствие найдено,
	 * поиск прекращается, текущий объект используется для дальнейшей обработки.
	 */
	@IsNotEmpty()
	regexp: string;

	/**
	 * Код лотереи, которой соответствует штрихкод.
	 * Присутствует в объекте в случае, когда формату штрих-кода соответствует одна конкретная лотерея.
	 */
	game_code?: number;

	/**
	 * Номер группы (capture) в регулярном выражении, которая содержит код лотереи.
	 * Присутствует в объекте в случае, когда найденному формату штрих-кода соответствует несколько лотерей.
	 * При этом код лотереи в явном виде присутствует в штрих-коде.
	 */
	game_code_cap?: number;

	/**
	 * Ключ лотереи, которой соответствует штрихкод.
	 * Присутствует в объекте в случае, когда формат штрихкода соответствует группе лотерей,
	 * но код лотереи однозначно распознать невозможно, например, электронные моментальные лотереи.
	 * Значение параметра определяет идентификатор (ключ) группы лотерей.
	 * Поддерживаются следующие ключи:
	 * - lottery.instant.loto_momentary - электронные моментальные лотереи.
	 */
	game_key?: BarcodeGameKey | string;

	/**
	 * Массив объектов, определяющий URL для операций, доступных для данного штрихкода.
	 * Поддерживаемые операции:
	 * - {@link EsapActions.WinCheck WinCheck}
	 * - {@link EsapActions.WinPay WinPay}
	 */
	@Type(() => ActionUrl)
	@ValidateNested()
	@ArrayNotEmpty()
	actions: Array<ActionUrl>;
}

/**
 * Список ключей лотерей {@link Barcode.game_key game_key}.
 */
export enum BarcodeGameKey {
	/**
	 * Электронные моментальные лотереи.
	 */
	LotteryInstantLotoMomentary = 'lottery.instant.loto_momentary',

	/**
	 * Неизвестная лотерея.
	 */
	Unknown = ''
}

/**
 * Массив содержит объекты со ссылками на конфигурацию отдельных пунктов меню операций.
 * Каждому пункту меню операций соответствует отдельный файл в массиве reports.
 * Последовательность объектов в массиве reports определяет последовательность отображения пунктов меню операций.
 */
export class Report {
	/**
	 * Ссылка на конфигурацию отчета.
	 */
	@IsNotEmpty()
	config: string;

	/**
	 * Версия отчета.
	 */
	@IsNotEmpty()
	version: number;

	/**
	 * Идентификатор отчета.
	 */
	// @IsNotEmpty()
	id: string;

	/**
	 * Имя 'ACTION' для запросов к ЦС при выполнении операций.
	 * Опциональный параметр. По умолчанию используется {@link EsapActions.GetReportData}.
	 */
	requestAction = EsapActions.GetReportData;

	/**
	 * Название аргумента запроса, в который подставляется идентификатор операции или отчета.
	 * Опциональный параметр. По умолчанию используется {@link EsapParams.REPORT_TYPE}.
	 */
	requestArgType = EsapParams.REPORT_TYPE;

	/**
	 * Название аргумента запроса, содержащего входные параметры, введенные оператором.
	 * Опциональный параметр. По умолчанию используется {@link EsapParams.REPORT_PARAMS}.
	 */
	requestArgParams = EsapParams.REPORT_PARAMS;
}

/**
 * Параметры протоколов соединения с ЦС.
 */
export class ProtocolsParam {
	/**
	 * Таймаут установки соединения (секунд).
	 */
	@IsNotEmpty()
	connectTimeout: number;

	/**
	 * Таймаут ожидания ответа (секунд).
	 */
	@IsNotEmpty()
	responseTimeout: number;

	/**
	 * Время ожидания (секунд) до следующей попытки выполнения запроса.
	 */
	retryDelay?: number; // TODO исправить название!

	/**
	 * Максимальное количество повторов.
	 */
	@IsNotEmpty()
	maxAttempt: number;
}

/**
 * Схема данных, секции протокол, файла конфигурации ЦС.
 */
export class Protocols {
	/**
	 * Идентификатор протокола.
	 */
	@IsNotEmpty()
	id: string;
	/**
	 * Параметры протокола.
	 */
	@Type(() => ProtocolsParam)
	@ValidateNested()
	@IsNotEmpty()
	params: ProtocolsParam;
}

/**
 * Схема данных файла конфигурации ЦС (модель).
 */
export class CsConfig {
	/**
	 * Список лотерей.
	 */
	@Type(() => Lottery)
	@ValidateNested()
	@ArrayNotEmpty()
	lotteries: Array<Lottery>;

	/**
	 * Список штрих-кодов.
	 */
	@Type(() => Barcode)
	@ValidateNested()
	@ArrayNotEmpty()
	barcodes: Array<Barcode>;

	/**
	 * Список ссылок на эндпоинты с действиями.
	 */
	@Type(() => ActionUrl)
	@ValidateNested()
	@ArrayNotEmpty()
	generalActions: Array<ActionUrl>;

	/**
	 * Список отчетов.
	 */
	@Type(() => Report)
	@ValidateNested()
	@ArrayNotEmpty()
	reports: Array<Report>;

	/**
	 * Список протоколов.
	 */
	@ArrayNotEmpty()
	protocols: Array<Protocols>;

	/**
	 * Ключ для работы со сканером штрих-кодом.
	 */
	scanditKey: string;
}
