import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { ApiClient } from '../api-client';
import { IResponse } from '../types';

// -----------------------------
//  Request
// -----------------------------

/**
 * Модель для вызова API GetReportData
 */
export class GetReportDataReq extends ApiClient {

	/**
	 * Конструктор запроса.
	 *
	 * @param {AppStoreService} appStoreService централизованное хранилище данных
	 * @param {string} reportId идентификатор отчета
	 * @param {string} params параметры запроса
	 * @param {string} actionName Имя экшена для данного отчета.
	 * @param {string} typeName Имя типа отчета для данного отчета.
	 * @param {string} paramName Имя параметра для данного отчета.
	 */
	constructor(
		appStoreService: AppStoreService,
		reportId: string,
		params: string,
		actionName = EsapActions.GetReportData,
		typeName = EsapParams.REPORT_TYPE,
		paramName = EsapParams.REPORT_PARAMS
	) {
		super(appStoreService, appStoreService.Settings.getEsapActionUrl(LotteryGameCode.Undefined, actionName));

		if (appStoreService.hasOperator()) {
			this.params.append(EsapParams.USER_ID, appStoreService.operator.getValue().userId);
			this.params.append(EsapParams.SID, appStoreService.operator.getValue().sessionId);
		}

		this.params.append(typeName, reportId);
		this.params.append(paramName, params);
	}

}

// -----------------------------
//  Response
// -----------------------------

/**
 * Модель ответа на запрос API GetReportData
 */
export class GetReportDataResp implements IResponse {
	/**
	 * Идентификатор транзакции.
	 */
	client_trans_id: string;

	/**
	 * Код ошибки.
	 */
	err_code: number;

	/**
	 * Описание ошибки.
	 */
	err_descr: string;

	/** Контент ответа для отчетов. */
	report: string;

	/** Контент ответа для финансовых операций. */
	finance_data: string;
}
