import { BehaviorSubject } from 'rxjs';

import { IPrintButtonAction } from '@app/shared/components/print-button/print-button.component';

import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { IDrawing, IDrawingResult } from '@app/core/net/http/api/models/get-draw-results';

/**
 * Ширина печати в символах.
 */
export const PrintWidth = 32;

/**
 * Список полей, которые можно использовать в шаблоне {@link PrintResultTemplate}.
 * - {@link LotteryName} - на входе 1 параметр (номер лотереи), в шаблон уйдет 1 параметр: строка "Лотерея" + номер лотереи.
 * - {@link DrawNumber} - на входе 1 параметр (номер тиража), в шаблон уйдет 1 параметра: строка "Тираж" + номер тиража.
 * - {@link DrawDate} - на входе 1 параметр (дата), в шаблон уйдет 1 параметр: строка "Дата" + дата.
 * - {@link NextDrawJackPot} - на входе 1 параметр (сумма), в шаблон уйдет 2 параметра: строка "Джекпот..." + сумма.
 * - {@link WinCombWithLines} - на входе 1 параметр (массив строк с комбинациями), в шаблон уйдет 1 параметр (с линиями сверху и снизу).
 * - {@link WinCombWOLines} - на входе 1 параметр (массив строк с комбинациями), в шаблон уйдет 1 параметр (без линий).
 * - {@link Line} - на входе нет параметров, в шаблон уйдет 1 параметр - линия из знаков "-".
 * - {@link OneColumnTable} -
 * - {@link TwoColumnTable} - на входе 2 параметра (две колонки), в шаблон уйдет 1 параметр с выравниванием по краям.
 * - {@link TwoColumnTableFast} - аналогично {@link TwoColumnTable}, но без локализации и форматирования чисел.
 * - {@link TwoColumnTableFast} - двойная линия
 * - {@link TextCenter} - текст по центру
 * - {@link TwoColumnTableCustom} - кастомные 2 колонки
 * - {@link DashedLine} - кастомные 2 колонки
 */
export enum PrintResultTypes {
	LotteryName,
	DrawNumber,
	DrawDate,
	NextDrawJackPot,
	WinCombWithLines,
	WinCombWOLines,
	Line,
	OneColumnTable,
	TwoColumnTable,
	TwoColumnTableFast,
	TwoColumnTableCustom,
	DoubleLine,
	TextCenter,
	DashedLine
}

/**
 * Шаблон для печати результатов на термопринтере.
 */
export const PrintResultTemplate = `
	<REPORTING>
		<REPORT>
			<OUTPUT>
				<PRINTER>
					<SECTION number="${PrintResultTypes.LotteryName}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.DrawNumber}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.DrawDate}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.WinCombWithLines}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.WinCombWOLines}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.Line}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.TwoColumnTable}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.TwoColumnTableFast}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.OneColumnTable}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.NextDrawJackPot}">"$d01 - $d02#n"</SECTION>
					<SECTION number="${PrintResultTypes.DoubleLine}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.TextCenter}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.TwoColumnTableCustom}">"$d01#n"</SECTION>
					<SECTION number="${PrintResultTypes.DashedLine}">"$d01#n"</SECTION>
				</PRINTER>
			</OUTPUT>
		</REPORT>
	</REPORTING>`;

/**
 * Модель элемента для печати на термопринтере.
 */
export interface IResultPrintItem {
	/**
	 * Тип элемента печати.
	 */
	key: PrintResultTypes;

	/**
	 * Список значений, которые будут преобразованы в печатные данные.
	 */
	value?: Array<string>;
}

/**
 * Модель данных, отправляемая по кнопке "ПЕЧАТЬ РЕЗУЛЬТАТОВ".
 */
export interface IPrintDataResults extends IPrintButtonAction {
	/**
	 * XML-шаблон.
	 */
	printTemplate: string;

	/**
	 * Данные для внедрения в XML-шаблон.
	 */
	printData: Array<IResultPrintItem>;
}

/**
 * Интерфейс сервиса для работы с результатами игр.
 */
export interface IGameResultsService {
	/**
	 * Последние загруженные данные.
	 */
	readonly lastLoadedResults$: BehaviorSubject<Array<IDrawingResult>>;

	/**
	 * Есть ли точная дата фильтра.
	 */
	exactDate: boolean;

	/**
	 * Можно ли перейти назад.
	 */
	isEnabledGoBack: boolean;

	/**
	 * Можно ли перейти вперед.
	 */
	isEnabledGoForward: boolean;

	/**
	 * Дата фильтра.
	 */
	filterDate: Date;

	/**
	 * Лимит показа результатов тиражей.
	 */
	drawLimit: number;

	/**
	 * Сколько кешировать тиражей
	 */
	cachedResultsCount: number;

	/**
	 * Сколько кешировать тиражей в фильтре
	 */
	cachedFilteredCount: number;

	/**
	 * Массив-замена локального хранилища
	 */
	localStore: Array<IDrawingResult>;

	/**
	 * Терминал ли?
	 */
	isTerminal: boolean;

	/**
	 * Перейти на следующую или предыдущую страницу с результатами по указанной игре.
	 *
	 * @param {LotteryGameCode} gameCode Код игры.
	 * @param {Number} page Номер страницы.
	 */

	navigateTo(gameCode: LotteryGameCode, page?: number): Promise<Array<IDrawingResult>>;

	/**
	 * Распечатать результаты по игре на термопринтере.
	 *
	 * @param {IPrintDataResults} printData
	 */
	printLastResults(printData: IPrintDataResults): void;
}

/**
 * Модель для отображения общей информации по результатам на вьюхах.
 */
export interface IAbstractViewResults {
	/**
	 * Номер тиража.
	 */
	drawNumber: string;

	/**
	 * Дата тиража.
	 */
	drawDate: string;

	/**
	 * Дополнительная информация по тиражу (например джекпот).
	 */
	extraInfo: string;
}

/**
 * Общая модель отображения данных по тиржам (дополненная модель {@link IDrawing} из ответа сервиса).
 */
export interface IDrawingView extends IDrawing {
	/**
	 * Пропарсенный аналог параметра {@link IDrawing.win_comb} в виде массива.
	 */
	winComb: Array<string>;
}

/**
 * Добавляет строки типа {@link IResultPrintItem} в список для последующего вывода на печать.
 *
 * @param {IDrawing} item Элемент, который необходимо прпарсить.
 * @param {Array<IResultPrintItem>} printItems Общий список, в который нужно добавить новый элемент.
 * @param {boolean} addLine Признак, указывающий на необходимость добавление линии выше блока.
 * @param {boolean} addName Признак, указывающий на необходимость добавление названия секции тиража.
 * @param {IResultPrintItem} header Заголовок для таблицы (опционально).
 * @param {Array<IResultPrintItem>} additionalLines Дополнительные линии, после названия.
 */
export const addWinCatRows = (
	item: IDrawing,
	printItems: Array<IResultPrintItem>,
	addLine = true,
	addName = true,
	header?: IResultPrintItem,
	additionalLines?: Array<IResultPrintItem>
): void => {
	if (addLine) {
		printItems.push({key: PrintResultTypes.Line, value: undefined});
	}

	if (addName) {
		printItems.push({key: PrintResultTypes.OneColumnTable, value: [item.name]});
	}

	if (additionalLines) {
		printItems.push(...additionalLines);
	}

	if (header) {
		printItems.push(header);
	}

	if (Array.isArray(item.win_cat)) {
		item.win_cat.forEach(v => {
			printItems.push({key: PrintResultTypes.TwoColumnTable, value: [v.name, v.win_sum]});
		});
	}
};
