import { Injectable } from '@angular/core';

import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { DurationDay, DurationMinute } from '@app/util/utils';
import { validateResponse, validateResponseSync } from '@app/util/validator';

import { CsConfig } from '@app/core/configuration/cs';
import { DrawsStatus, Lotteries, LotteryGameCode } from '@app/core/configuration/lotteries';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { ErrorCode } from '@app/core/error/dialog';
import { AppError, IError, NetError } from '@app/core/error/types';
import { HttpService } from '@app/core/net/http/services/http.service';
import { KeyValue } from '@app/core/net/ws/api/types';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { LotteriesDraws } from '@app/core/services/store/draws';
import { ApplicationAppId } from '@app/core/services/store/settings';
import {
	UpdateDrawInfoDraws,
	UpdateDrawInfoItem,
	UpdateDrawInfoLotteries,
	UpdateDrawInfoLottery,
	UpdateDrawInfoReq,
	UpdateDrawInfoResp
} from '@app/core/net/http/api/models/update-draw-info';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { StorageGetResp } from '@app/core/net/ws/api/models/storage/storage-get';

/**
 * Модель, которая будет использована в процессе подписки/обработки на таймере обновления тиражей.
 */
interface IFeatureUpdateItem {

	/**
	 * Код игры, по которой будет запущен таймер.
	 */
	gameCode: LotteryGameCode;

	/**
	 * Дата окончания продаж - время, когда сработает таймер.
	 */
	endSaleDate: Date;

}

/**
 * Сервис, управляющий лотереями и тиражами.
 * Основное назначение - загрузка, обновление и синхронизация тиражей между ЦС и хранилищем.
 */
@Injectable()
export class LotteriesService {

	// -----------------------------
	//  Public properties
	// -----------------------------

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Хранилище моделей лотерей, используемое в целях обновления и синхронизации в данном сервисе.
	 */
	private readonly _lotteries: Map<LotteryGameCode, UpdateDrawInfoLottery> = new Map();

	/**
	 * Хранилище подписок на фоновое обновление тиражей по коду лотереи.
	 */
	private readonly featureUpdate: Map<LotteryGameCode, Subscription> = new Map();

	/**
	 * Функция, которая будет вызвана при успешном выполнении промиса
	 * @private
	 */
	private resolve: (value?: (PromiseLike<IError> | IError)) => void;

	/**
	 * Функция, которая будет вызвана при неуспешном выполнении промиса
	 * @private
	 */
	private reject: (reason?: any) => void;

	/**
	 * Объект ошибки при выполнении запросов на сервер
	 * @private
	 */
	private error: IError;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {AppStoreService} appStoreService Сервис хранилища приложения.
	 * @param {StorageService} storageService Сервис браузерного хранилища.
	 * @param {HttpService} httpService Сервис для работы с HTTP.
	 * @param {DialogContainerService} dialogInfoService Сервис для работы с диалоговыми окнами.
	 */
	constructor(
		private readonly appStoreService: AppStoreService,
		private readonly storageService: StorageService,
		private readonly httpService: HttpService,
		private readonly dialogInfoService: DialogContainerService
	) {}

	/**
	 * Запускает процедуру обновления параметров тиражей по всем лотереям указанным в конфигурационном файле,
	 * полученном из ЦС. Структуру конфиг файла смотри {@link CsConfig}.
	 * Параметры тиражей (лотерей) синхронизируются с ЦС и сохраняются в хранилище и памяти терминала.
	 *
	 * {@link https://confluence.emict.net/pages/viewpage.action?pageId=47022093}
	 * @return {Promise<IError>}
	 */
	reload(): Promise<IError> {
		return new Promise<IError>((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;

			this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.update_lotteries_wait_info');

			Lotteries.clearInstantLotteries();
			if (this.appStoreService.Draws) {
				// ------------------------------------------------------------------------------------ TODO удалить - дохлая ветка
				this._lotteries.clear();
				for (const gameCode of this.appStoreService.Draws.getLotteriesCodes()) {
					this.cleanFeatureUpdateByGameCode(gameCode);
					this._lotteries.set(gameCode, this.appStoreService.Draws.getLottery(gameCode));
				}

				const draws = this.getDrawsForUpdate();
				this.updateFromCS(draws, false);
				// ----------------------------------------------------------------------------------------------------------------
			} else {
				this.loadFromStorage();
			}
		});
	}

	/**
	 * Отменяет подписку на все таймеры обновления тиражей.
	 * Подписка должна быть отменена в случае, если пользователь разлогинился.
	 */
	cleanAllFeatureUpdates(): void {
		Logger.Log.i('LotteriesService', `will stopped %s auto update timers of draws`, this.featureUpdate.size)
			.console();

		this.featureUpdate.forEach((subscription, gameCode) => {
			if (subscription) {
				subscription.unsubscribe();
				this.featureUpdate.delete(gameCode);
			}
		});

		Logger.Log.i('LotteriesService', `all timers was stopped, now timer count is: %s`, this.featureUpdate.size)
			.console();
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Загрузка данных лотерей (тиражей) из Storage-сервиса.
	 */
	private loadFromStorage(): void {
		Logger.Log.i('LotteriesService', `loadFromStorage -> load draws from storage...`)
			.console();

		this.appStoreService.Draws = new LotteriesDraws();
		this.storageService.get(ApplicationAppId, Lotteries.getKeysForLotteriesInit(), null, 5000, 5)
			.then((storageData: StorageGetResp) => {
				Logger.Log.i('LotteriesService', `loadFromStorage -> loading draws from storage OK: %s`, storageData)
					.console();

				if (storageData.data) {
					storageData.data.forEach((item: KeyValue) => {
						const gameCode = Lotteries.getGameCodeByKey(item.key);
						if (gameCode !== LotteryGameCode.Undefined) {
							validateResponseSync<UpdateDrawInfoLottery>(UpdateDrawInfoLottery, JSON.parse(item.value), {
								onSucceed: updateDrawInfoLottery => {
									Logger.Log.i('LotteriesService', `loadFromStorage -> restored draws for lottery (${gameCode}) OK`)
										.console();

									this._lotteries.set(gameCode, updateDrawInfoLottery);
								},
								onFailed: errors => {
									Logger.Log.e('LotteriesService', `loadFromStorage -> restored draws FAILED for lottery (${gameCode})`)
										.console();

									this.error = new AppError(errors.message, ErrorCode.DrawsServiceGet);
								}
							});
						}
					});
				}

				const draws = this.getDrawsForUpdate();
				this.updateFromCS(draws, false);
			})
			.catch((error: IError) => {
				Logger.Log.e('LotteriesService', `loadFromStorage -> loading draws from storage ERROR: %s`, error)
					.console();

				this.error = new AppError(error.message, ErrorCode.DrawsServiceGet);
				this.updateFromCS([], false);
			});
	}

	/**
	 * Сохранение данных лотерей (тиражей) в Storage-сервисе.
	 *
	 * @param {LotteryGameCode} gameCode Код лотереи.
	 * @param {UpdateDrawInfoLottery} lottery Модель данных о лотерее.
	 * @returns {Promise<void>}
	 */
	private storeDraws(gameCode: LotteryGameCode, lottery: UpdateDrawInfoLottery): Promise<void> {
		const gameStoredKey = Lotteries.getKeyByGameCode(gameCode);
		if (gameStoredKey) {
			if (lottery.draws && lottery.draws.length > 0) {
				return this.storageService.put(ApplicationAppId, [{key: gameStoredKey, value: JSON.stringify(lottery)}], 5000, 5)
					.then(() => {
						Logger.Log.i('LotteriesService', 'draws for lottery (%s), STORED: %s', gameCode, lottery.draws)
							.console();

						this.featureUpdateEndOfSaleCurrentDraw(gameCode, lottery.draws);
						this.appStoreService.Draws.setLottery(gameCode, lottery);
						this._lotteries.delete(gameCode);
					})
					.catch((error: IError) => {
						Logger.Log.e('LotteriesService', 'storing draws ERROR: %s', error)
							.console();
						this.error = new AppError(error.message, ErrorCode.DrawsServiceSave);
					});
			}

			return this.storageService.del(ApplicationAppId, [gameStoredKey], 5000, 5)
				.then(() => {
					Logger.Log.i('LotteriesService', 'draws for lottery (%s), DELETED', gameCode)
						.console();

					this.cleanFeatureUpdateByGameCode(gameCode);
					this.appStoreService.Draws.delLottery(gameCode);
					this._lotteries.delete(gameCode);
				})
				.catch((error: IError) => {
					Logger.Log.e('LotteriesService', 'deleting draws ERROR: %s', error)
						.console();
					this.error = new AppError(error.message, ErrorCode.DrawsServiceDel);
				});
		}
	}

	/**
	 * Получение тиражей для процедуры синхронизации тиражей с ЦС.
	 *
	 * @param {LotteryGameCode} gameCode Код лотереи, по которой нужно загрузить тиражи. Если не задано, то загрузить по всем играм.
	 * @returns {Array<UpdateDrawInfoItem>} Список тиражей и их версий для данной лотереи (доступных сейчас терминалу).
	 */
	private getDrawsForUpdate(gameCode?: LotteryGameCode): Array<UpdateDrawInfoItem> {
		const lotteryArr = gameCode
			? [this._lotteries.get(gameCode)]
			: Array.from(this._lotteries.values());

		return lotteryArr
			.filter(udil => !!udil.draws)
			.map(udil => udil.draws)
			.reduce((p, c) => p.concat(c), [])
			.map(draws => {
				return {
					c: Number.parseInt(draws.draw.code, 10),
					v: Number.parseInt(draws.draw.version, 10)
				};
			});
	}

	/**
	 * Сортировка тиражей по дате окончания приема ставок на тираж.
	 *
	 * @param {Array<UpdateDrawInfoDraws>} draws Список тиражей.
	 */
	private sortDrawsBySaleEndDate(draws: Array<UpdateDrawInfoDraws>): void {
		draws.sort((a, b) => {
			const aDate = Date.parse(a.draw.sale_edate);
			const bDate = Date.parse(b.draw.sale_edate);
			if (aDate === bDate) {
				return 0;
			}

			return aDate > bDate ? 1 : -1;
		});
	}

	/**
	 * Запуск таймера обновления тиражей.
	 * Тиражи автоматически, в фоне, обновляются в случае когда
	 * текущее время совпадает с временем окончания приема ставок на тираж.
	 *
	 * @param {LotteryGameCode} gameCode Код лотереи.
	 * @param {Array<UpdateDrawInfoDraws>} draws Тиражи.
	 * @see https://confluence.emict.net/pages/viewpage.action?pageId=47022093
	 */
	private featureUpdateEndOfSaleCurrentDraw(gameCode: LotteryGameCode, draws: Array<UpdateDrawInfoDraws>): void {
		const draw = draws.find(value => {
			return value.draw.status_code === DrawsStatus.DRST_BET || value.draw.status_code === DrawsStatus.SALES_AND_PAYS;
		});

		if (draw) {
			const endSaleDate = new Date(draw.draw.sale_edate);
			const now = Date.now();

			// setTimeout can fire incorrectly(early) ... in this case, if draw.sale_edate > 15 days, we skip setting updating timer
			if (endSaleDate.getTime() > now && endSaleDate.getTime() - now < DurationDay * 15) {
				Logger.Log.i('LotteriesService', `draws for lottery (%s) will be updated on %s`,
					gameCode,
					endSaleDate.toLocaleString()
				)
				.console();

				// очистить старый таймер на данную игру
				this.cleanFeatureUpdateByGameCode(gameCode);

				// создаем подписку с задержкой на дату - "endSaleDate"
				// TODO проверить! с функцией "timer(endSaleDate)" таймер срабатывал сразу
				const timerItem: IFeatureUpdateItem = {gameCode, endSaleDate};
				const timerSubscription = of(timerItem)
					.pipe(delay(endSaleDate))
					.subscribe(v => this.featureUpdateSubscriptionHandler(v));
				this.featureUpdate.set(gameCode, timerSubscription);
			}
		}
	}

	/**
	 * Обработчик подписки на таймер автоматического обновления тиражей.
	 *
	 * @param {IFeatureUpdateItem} featureUpdateItem Модель {@link IFeatureUpdateItem} которая эмитируется в процессе срабатывания таймера.
	 */
	private featureUpdateSubscriptionHandler(featureUpdateItem: IFeatureUpdateItem): void {
		Logger.Log.i('LotteriesService', `auto update timer for lottery (%s) ACTIVATED!`, featureUpdateItem.gameCode)
			.console();

		// проверка на случай если пользователь разлогинен // TODO можно убрать, таймеры чистятся после разлогина
		if (!this.appStoreService.Draws) {
			Logger.Log.e('LotteriesService', `draws is empty! skip updating lottery (%s)`, featureUpdateItem.gameCode)
				.console();

			return;
		}

		this.cleanFeatureUpdateByGameCode(featureUpdateItem.gameCode);
		this._lotteries.set(featureUpdateItem.gameCode, this.appStoreService.Draws.getLottery(featureUpdateItem.gameCode));

		// skip updating if timer fire early than one minute before or after date
		if (Math.abs(featureUpdateItem.endSaleDate.getTime() - Date.now()) < DurationMinute) {
			Logger.Log.i('LotteriesService', `update draws for lottery (%s)`, featureUpdateItem.gameCode)
				.console();

			const draws = this.getDrawsForUpdate(featureUpdateItem.gameCode);
			this.updateFromCS(draws, true, featureUpdateItem.gameCode);
		} else {
			Logger.Log.e('LotteriesService', `skip updating lottery (%s) later than 1 minute`, featureUpdateItem.gameCode)
				.console();
		}
	}

	/**
	 * Отмена подписки на таймер автоматического обновления тиражей по определенной лотереи.
	 *
	 * @param {LotteryGameCode} gameCode Код лотереи, по которой будет отменена подписка на обновление тиражей.
	 */
	private cleanFeatureUpdateByGameCode(gameCode: LotteryGameCode): void {
		const timerSubscription = this.featureUpdate.get(gameCode);
		if (timerSubscription) {
			Logger.Log.i('LotteriesService', `subscription for lottery (%s) on draws auto update was REMOVED`, gameCode)
				.console();

			timerSubscription.unsubscribe();
			this.featureUpdate.delete(gameCode);
		}
	}

	/**
	 * Процедура синхронизации тиражей, для конкретной лотереи, присутствующих на терминале
	 * с тиражами полученными для этой лотереи из ЦС.
	 *
	 * @param {LotteryGameCode} gameCode Код лотереи.
	 * @param {UpdateDrawInfoLottery} remoteLottery Тиражи полученые из ЦС.
	 * @returns {UpdateDrawInfoLottery} Результрующий набор данных(тиражи) по лотерее.
	 */
	private synchronizeDraws(gameCode: LotteryGameCode, remoteLottery: UpdateDrawInfoLottery): UpdateDrawInfoLottery {
		let localLottery = this._lotteries.get(gameCode);
		if (localLottery && localLottery.draws) {
			for (const remoteDraw of remoteLottery.draws) {
				const index = localLottery.draws.findIndex(draw => draw.draw.code === remoteDraw.draw.code);
				if (index > -1) {
					if (remoteDraw.draw.remove) {
						localLottery.draws.splice(index, 1);
						Logger.Log.i('LotteriesService', 'remove draw %s: for lottery code %s', remoteDraw.draw.code, gameCode)
							.console();
					} else {
						localLottery.draws[index] = remoteDraw;
						Logger.Log.i('LotteriesService', 'replace draw %s: for lottery code %s', remoteDraw.draw.code, gameCode)
							.console();
					}
				} else {
					if (!remoteDraw.draw.remove) {
						localLottery.draws.push(remoteDraw);
						Logger.Log.i('LotteriesService', 'add draw %s: for lottery code %s', remoteDraw.draw.code, gameCode)
							.console();
					}
				}
			}
		} else {
			for (const remoteDraw of remoteLottery.draws) {
				if (remoteDraw.draw && remoteDraw.draw.version) {
					if (!localLottery) {
						localLottery = new UpdateDrawInfoLottery();
						localLottery.lott_extra = remoteLottery.lott_extra;
						localLottery.lott_name = remoteLottery.lott_name;
						localLottery.lott_code = remoteLottery.lott_code;
						localLottery.currency = remoteLottery.currency;
						localLottery.draws = [];
					}
					localLottery.draws.push(remoteDraw);
				}
			}
			Logger.Log.i('LotteriesService', 'add draws for lottery code %s', gameCode)
				.console();
		}

		return localLottery;
	}

	/**
	 * Процедура выполняющая запрос в ЦС, на получение списка лотерей и их тиражей,
	 * для синхронизации данных между ЦС и терминалом.
	 *
	 * @param {Array<UpdateDrawInfoItem>} updateDraws Список текущих (на терминале) тиражей и их версий.
	 * @param {boolean} backGround Должно ли обновление выполнятся в фоне.
	 * @param {LotteryGameCode} lotteryCode Если указан код лотереи то обновление тиражей происходит только для этой лотереи.
	 */
	private updateFromCS(updateDraws: Array<UpdateDrawInfoItem>, backGround: boolean, lotteryCode?: LotteryGameCode): void {
		Logger.Log.i('LotteriesService', `updateFromCS -> try to download the lottery with code: %s`, lotteryCode ? lotteryCode : 'ALL')
			.console();

		const updateDrawInfo = new UpdateDrawInfoReq(this.appStoreService, updateDraws, lotteryCode);
		this.httpService.sendApi(updateDrawInfo)
			.then(response => {
				return validateResponse<UpdateDrawInfoResp>(UpdateDrawInfoResp, response)
					.then(updateDrawInfoResp => {
						Logger.Log.i('LotteriesService', 'updateFromCS -> getting draws OK: %s', updateDrawInfoResp)
							.console();

						return this.updateLotteries(updateDrawInfoResp.lotteries);
					})
					.catch((error: IError) => {
						Logger.Log.e('LotteriesService', 'updateFromCS -> validation draws response ERROR: %s', error)
							.console();
						error.code = ErrorCode.DrawsCsValidate;
						this.terminate(error);
					});
			})
			.catch((error: IError) => {
				Logger.Log.e('LotteriesService', 'updateFromCS -> getting draws ERROR: %s', error)
					.console();

				if (!backGround) {
					if (error instanceof NetError) {
						if (!error.code) {
							error.code = ErrorCode.DrawsCsGet;
						}
					}
					this.terminate(error);
				}
			});
	}

	/**
	 * Процедура синхронизации тиражей присутствующих на терминале с тиражами полученными из ЦС.
	 *
	 * @param {Array<UpdateDrawInfoLotteries>} lotteries Список моделей лотерей.
	 * @returns {Promise<void>}
	 */
	private updateLotteries(lotteries: Array<UpdateDrawInfoLotteries>): Promise<void> {
		let chain = Promise.resolve(null);
		lotteries.forEach(lottery => {
			const code = Number.parseInt(lottery.lottery.lott_code, 10);
			if (Lotteries.hasLottery(code)) {
				if (lottery.lottery.draws && lottery.lottery.draws.length > 0) {
					const synchronized = this.synchronizeDraws(code, lottery.lottery);
					if (synchronized) {
						this.sortDrawsBySaleEndDate(synchronized.draws);
						chain = chain.then(() => {
							return this.storeDraws(code, synchronized);
						});
					}
				} else {
					Logger.Log.e('LotteriesService', `getting draws ERROR for lottery (%s)`, lottery.lottery.lott_code)
						.console();
					this.error = new AppError(`lottery (${lottery.lottery.lott_code}) don't have draws`, ErrorCode.DrawsCsGet);
				}
			}
		});

		return chain
			.then(() => {
				this._lotteries.forEach((value, gameCode) => {
					this.featureUpdateEndOfSaleCurrentDraw(gameCode, value.draws);
					this.appStoreService.Draws.setLottery(gameCode, value);
				});

				this._lotteries.clear();
				this.finish();

				Logger.Log.i('LotteriesService', 'all downloads for lotteries are finished')
					.console();
			})
			.catch((error: IError) => {
				Logger.Log.e('LotteriesService', 'error on updating draws, message: %s', error.message)
					.console();
				this.reject(new AppError(`error on updating draws, message: ${error.message}`, ErrorCode.DrawsCsGet));
			});
	}

	/**
	 * Функция успешного завершения синхронизации тиражей лотереи с тиражами полученными из ЦС. Прячет диалог загрузки.
	 * @private
	 */
	private finish(): void {
		this.dialogInfoService.hideActive();
		this.resolve(this.error);
	}

	/**
	 * Функция неуспешного завершения синхронизации тиражей лотереи с тиражами полученными из ЦС. Также прячет диалог загрузки.
	 * @param error
	 * @private
	 */
	private terminate(error: IError): void {
		this.dialogInfoService.hideActive();
		this.reject(error);
	}
}
