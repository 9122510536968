import { EsapParams } from '@app/core/configuration/esap';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { CancelFlag } from '@app/core/services/transaction/transaction-types';
import { ApiClient } from '../api-client';

/**
 * Модель для вызова API CancelPrint
 */
export class CancelPrintReq extends ApiClient {

	/**
	 * Название действия
	 */
	static readonly Action = 'CancelPrint';

	/**
	 * Конструктор.
	 *
	 * @param {AppStoreService} appStoreService централизованное хранилище данных
	 * @param {string} url урл к сервису отмены
	 * @param {string} transactionId идентификатор транзакции
	 * @param {string} userId идентификатор пользователя
	 * @param {string} sessionId идентификатор сессии
	 * @param {CancelFlag} flag флаг отмены
	 * @param {LotteryGameCode} code код лотереи
	 * @param {string[]} ticketIds список идентификаторов билетов
	 */
	constructor(
		appStoreService: AppStoreService,
		url: string,
		transactionId: string,
		userId: string,
		sessionId: string,
		code: LotteryGameCode,
		flag: CancelFlag,
		ticketIds: Array<string>
	) {
		super(appStoreService, {url, action: CancelPrintReq.Action});

		this.params.append(EsapParams.CNL_TRANS_ID, transactionId);
		this.params.append(EsapParams.CNL_FLAG, flag);
		this.params.append(EsapParams.GAME_CODE, code.toString());
		this.params.append(EsapParams.CHECK_ID_LIST, ticketIds.join(','));

		this.params.append(EsapParams.USER_ID, userId);
		this.params.append(EsapParams.SID, sessionId);
	}

}
