import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { ApiClient } from '@app/core/net/http/api/api-client';
import { LotteryGameCode } from '@app/core/configuration/lotteries';

// -----------------------------
//  Request
// -----------------------------

/**
 * Модель для вызова функции API {@link EsapActions.CancelBonus CancelBonus}.
 */
export class CancelBonusReq extends ApiClient {

	/**
	 * Конструктор запроса.
	 *
	 * @param {AppStoreService} appStoreService централизованое хранилище данных
	 * @param {string} bonusId Идентификатор выплачиваемого бонуса.
	 */
	constructor(appStoreService: AppStoreService, bonusId: string) {
		// const action1 = undefined;//appStoreService.Settings.getEsapActionUrl(barcode, EsapActions.CancelBonus, false);
		const action2 = appStoreService.Settings.getEsapActionUrl(LotteryGameCode.Undefined, EsapActions.CancelBonus, false);

		super(appStoreService, action2);

		this.params.append(EsapParams.CNL_BONUS_ID, bonusId);
		this.params.append(EsapParams.CNL_FLAG, '1');
	}

}

