import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import {
	alignCenter,
	alignTwoItemsByWidth,
	convertTimestampToCSFormat, CSDateToDateObject,
	DATE_TEMPLATE_DD_MM_YYYY_HH_MM,
	endOfDay,
	getTranslatedStringByKey
} from '@app/util/utils';

import { ReportsService } from '@app/core/services/report/reports.service';

import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { GetDrawResultsReq, GetDrawResultsResp, IDrawingResult } from '@app/core/net/http/api/models/get-draw-results';
import { HttpService } from '@app/core/net/http/services/http.service';
import { IResponse } from '@app/core/net/ws/api/types';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { AppType } from '@app/core/services/store/settings';
import { TransactionService } from '@app/core/services/transaction/transaction.service';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import {
	IGameResultsService,
	IPrintDataResults,
	PrintResultTypes,
	PrintWidth
} from '@app/core/services/results/results-utils';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';

import { MslCurrencyPipe } from '@app/shared/pipes/msl-currency.pipe';
import { DialogError } from '@app/core/error/dialog';
import { LogOutService } from '@app/logout/services/log-out.service';
import { IError } from '@app/core/error/types';

/**
 * Сервис для работы с результатами игр.
 * Содержит основную бизнес-логику для навигации, хранения и печати результатов на термопринтере.
 */
@Injectable({
	providedIn: 'root'
})
export class GameResultsService implements IGameResultsService {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Найдена ли точная дата?
	 */
	exactDate: boolean;

	/**
	 * Результаты для показа.
	 */
	readonly lastLoadedResults$ = new BehaviorSubject<Array<IDrawingResult>>(undefined);

	/**
	 * Возможность вернуться назад.
	 */
	isEnabledGoBack = true;

	/**
	 * Возможность пойти вперед.
	 */
	isEnabledGoForward = true;

	/**
	 * Дата в фильтре.
	 */
	filterDate: Date;

	/**
	 * Лимит показа тиражей.
	 */
	drawLimit = 6;

	/**
	 * Сколько кешировать тиражей
	 */
	cachedResultsCount = 6;

	/**
	 * Сколько кешировать тиражей в фильтре
	 */
	cachedFilteredCount = 6;

	/**
	 * Массив-замена локального хранилища
	 */
	localStore: Array<IDrawingResult> = [];

	/**
	 * Терминал ли?
	 */
	isTerminal = this.appStoreService.Settings.appType === AppType.ALTTerminal;

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Лотереи с пагинацией в фильтре результатов.
	 */
	private readonly pagedFiltersLotteries = new Map([]);

	/**
	 * Признак конца данных, запрошенных из ЦС.
	 * Если хотели запросить CACHED_RESULTS_COUNT результатов, а пришло меньше, то это конец
	 * и больше запросов к ЦС не делаем.
	 */
	private readonly csDataEnd = false;

	/**
	 * Массив закешированных результатов.
	 */
	private storageDataArr: Array<IDrawingResult> = [];

	/**
	 * Закешированные фильтры.
	 */
	private readonly cachedFilters = {};

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {AppStoreService} appStoreService
	 * @param {TransactionService} transactionService
	 * @param {DialogContainerService} dialogInfoService
	 * @param {HttpService} httpService
	 * @param {ReportsService} reportsService
	 * @param {DatePipe} datePipe
	 * @param {TranslateService} translateService
	 * @param {StorageService} storageService
	 * @param {MslCurrencyPipe} mslCurrencyPipe
	 * @param logoutService
	 */
	constructor(
		private readonly appStoreService: AppStoreService,
		private readonly transactionService: TransactionService,
		private readonly dialogInfoService: DialogContainerService,
		private readonly httpService: HttpService,
		private readonly reportsService: ReportsService,
		private readonly datePipe: DatePipe,
		private readonly translateService: TranslateService,
		private readonly storageService: StorageService,
		private readonly mslCurrencyPipe: MslCurrencyPipe,
		private readonly logoutService: LogOutService
	) {}

	// -----------------------------
	//  IGameResultsService
	// -----------------------------

	/**
	 * Имплементация метода {@link IGameResultsService.printLastResults printLastResults}.
	 */
	printLastResults(data: IPrintDataResults): void {
		Logger.Log.i('GameResultsService', `print results: %s`, data)
			.console();

		if (data && data.printTemplate && data.printData) {
			const lang = this.translateService.store.translations['ua'];
			let printString = '';
			data.printData.forEach((printItem, idx) => {

				let args = printItem.value;
				printString += `${idx > 0 ? '\n' : ''}${printItem.key}`;

				// пропарсить параметры
				switch (printItem.key) {
					case PrintResultTypes.LotteryName:
						printString += `|${alignCenter(PrintWidth, getTranslatedStringByKey(lang , args[0]))}`;
						args = undefined;
						break;

					case PrintResultTypes.DrawNumber:
						printString += `|${alignTwoItemsByWidth('lottery.draw', args[0], PrintWidth, lang)}`;
						args = undefined;
						break;

					case PrintResultTypes.DrawDate:
						const date = this.datePipe.transform(new Date(args[0]), DATE_TEMPLATE_DD_MM_YYYY_HH_MM);
						printString += `|${alignTwoItemsByWidth('lottery.drawDate', date, PrintWidth, lang)}`;
						args = undefined;
						break;

					case PrintResultTypes.Line:
						printString += `|${'-'.repeat(PrintWidth)}#n`;
						args = undefined;
						break;
					case PrintResultTypes.DashedLine:
						printString += `|${'- '.repeat(Math.floor(PrintWidth / 2))}#n`;
						args = undefined;
						break;

					case PrintResultTypes.DoubleLine:
						printString += `|${'='.repeat(PrintWidth)}#n`;
						args = undefined;
						break;

					case PrintResultTypes.OneColumnTable:
						break;

					case PrintResultTypes.TextCenter:
						const alignStrings = args.map(arg => getTranslatedStringByKey(lang , arg));
						printString += `|${alignCenter(PrintWidth, alignStrings.join(' '))}`;
						args = undefined;
						break;

					case PrintResultTypes.NextDrawJackPot:
						printString += `|${getTranslatedStringByKey(lang , 'lottery.next_draw_jackpot')}`;
						break;

					case PrintResultTypes.TwoColumnTable:
						if (args[0].length > 19) {
							const args0Arr = args[0].split(' ');
							if (args0Arr.length === 1) {
								printString += `|${alignTwoItemsByWidth(args[0], this.mslCurrencyPipe.transform(args[1]), PrintWidth, lang)}`;
							} else {
								const lastWord = args0Arr.pop();
								const firstWords = args0Arr.join(' ');
								printString += `|${firstWords}\n`;
								printString += `${printItem.key}|${alignTwoItemsByWidth(lastWord, this.mslCurrencyPipe.transform(args[1]), PrintWidth, lang)}`;
							}
						} else {
							printString += `|${alignTwoItemsByWidth(args[0], this.mslCurrencyPipe.transform(args[1]), PrintWidth, lang)}`;
						}
						args = undefined;
						break;

					case PrintResultTypes.TwoColumnTableFast:
						printString += `|${alignTwoItemsByWidth(args[0], args[1], PrintWidth)}`;
						args = undefined;
						break;

					case PrintResultTypes.TwoColumnTableCustom:
						printString += `|${alignTwoItemsByWidth(getTranslatedStringByKey(lang, args[0]), args[1], PrintWidth)}`;
						args = undefined;
						break;

					case PrintResultTypes.WinCombWithLines:
						const arr = Array.isArray(printItem.value)
							? printItem.value.map(m => getTranslatedStringByKey(lang, m))
							: [];
						printString += `|${'-'.repeat(PrintWidth)}#n${arr.join(' ')}#n${'-'.repeat(PrintWidth)}`;
						args = undefined;
						break;

					case PrintResultTypes.WinCombWOLines:
						const arrWoLines = Array.isArray(printItem.value)
							? printItem.value.map(m => getTranslatedStringByKey(lang, m))
							: [];
						printString += `|${arrWoLines.join(' ')}`;
						args = undefined;
						break;

					default:
						break;
				}

				if (Array.isArray(args)) {
					printString = printItem.value.reduce((p, c) => `${p}|${getTranslatedStringByKey(lang, c)}`, printString);
				}
			});

			this.reportsService.printXMLContentFromReport(data.printTemplate, printString, data.copiesCount);
		}
	}

	/**
	 * Имплементация метода {@link IGameResultsService.navigateTo navigateTo}.
	 */
	async navigateTo(gameCode: LotteryGameCode, page: number = 1): Promise<Array<IDrawingResult>> {
		// запросить из кеша результаты
		this.storageDataArr = await this.getStorageDataArray(gameCode);
		// результаты для показа
		let dataToShow: Array<IDrawingResult>;
		// фильтрованный массив результатов
		let filteredDataArr: Array<IDrawingResult>;

		// Если мы в фильтре, то
		if (this.filterDate) {
			// сформировать дату запроса
			this.filterDate.setHours(23, 59, 59);
			// получить ее ТС
			const filterDateTS = (new Date(this.filterDate)).getTime();
			// получить результаты не новее, чем она
			filteredDataArr = this.storageDataArr.filter(elem =>
				(new Date(elem.drawing_date_begin)).getTime() <= filterDateTS);
			// привести дату запроса к виду YYYY-MM-DD 23:59:59
			const csFilterDate = convertTimestampToCSFormat(this.datePipe, filterDateTS);
			// а для ГнГ или Каре еще и нижнюю дату сформировать (вида YYYY-MM-DD 00:00:00)
			// и отфильтровать по ней
			let csFilterDateMin;
			if (this.pagedFiltersLotteries.get(gameCode)) {
				csFilterDateMin = `${csFilterDate.split(' ')[0]} 00:00:00`;
				filteredDataArr = filteredDataArr.filter(elem =>
					(new Date(elem.drawing_date_begin)).getTime() >= (new Date(csFilterDateMin)).getTime());
			} else {
				csFilterDateMin = undefined;
			}

			// переменная - лимит показа результатов
			// если пришел пустой массив, то равна какому-то малому числу
			let localDrawLimit = 1;
			// если фильтр пустой
			if (filteredDataArr.length === 0) {
				// проверяем нет ли чего в кеше, если вообще ничего, то создаем пустой объект для текущей лотереи
				this.cachedFilters[gameCode] = this.cachedFilters[gameCode] || {};
				// если в фильтр-кеше нет результатов по выбранной дате
				if (!this.cachedFilters[gameCode][csFilterDate]) {
					// получить результаты из ЦС и занести в фильтр-кеш
					this.cachedFilters[gameCode][csFilterDate] = await this.makeCSRequest(gameCode,
						this.cachedFilteredCount, csFilterDate, csFilterDateMin);
				}
				// если массив в фильтр-кеше не пустой
				if (this.cachedFilters[gameCode][csFilterDate].length) {
					// установить лимит показа результатов
					// Если не найдена точная дата, то показываем на 2 меньше
					// так как нужно оставить место для текста над результатами
					localDrawLimit = csFilterDate.split(' ')[0] === this.cachedFilters[gameCode][csFilterDate][0].drawing_date_begin.split(' ')[0] ?
						this.drawLimit : this.drawLimit - 2;
				}
				// вырезаем страницу результатов (подмассив) из фильтр-кеша
				dataToShow = this.cachedFilters[gameCode][csFilterDate].slice((page - 1) * localDrawLimit, page * localDrawLimit);
				// точная дата нашлась, если мы не отнимали 2
				this.exactDate = localDrawLimit === this.drawLimit;
				// назад можем пойти если данные для показа равны лимиту и не дошли до конца фильтр-кеша
				// но ТОЛЬКО в ГнГ
				this.isEnabledGoBack = this.pagedFiltersLotteries.get(gameCode) && (dataToShow.length === localDrawLimit) &&
					(page * localDrawLimit < this.cachedFilters[gameCode][csFilterDate].length);
				// вперед можем пойти, если страница - не первая
				// но ТОЛЬКО в ГнГ
				this.isEnabledGoForward = page !== 1;
			} else {
				// установить лимит показа результатов
				// Если не найдена точная дата, то показываем на 2 меньше
				// так как нужно оставить место для текста над результатами
				localDrawLimit = csFilterDate.split(' ')[0] === filteredDataArr[0].drawing_date_begin.split(' ')[0] ?
					this.drawLimit : this.drawLimit - 2;
				// вырезаем страницу результатов (подмассив) из отфильтрованного массива
				dataToShow = filteredDataArr.slice((page - 1) * localDrawLimit, page * localDrawLimit);
				// если результатов для показа меньше чем лимит или мы уже в конце
				if ((dataToShow.length < localDrawLimit) || (page * localDrawLimit === filteredDataArr.length)) {

					// получаем еще CACHED_RESULTS_COUNT результатов и заносим их в хранилище
					await this.addResultsBeforeDate(gameCode, this.getOldestDate());

					// снова фильтруем
					filteredDataArr = this.storageDataArr.filter(elem =>
						(new Date(elem.drawing_date_begin)).getTime() <= filterDateTS);
					if (this.pagedFiltersLotteries.get(gameCode)) {
						filteredDataArr = filteredDataArr.filter(elem =>
							(new Date(elem.drawing_date_begin)).getTime() >= (new Date(csFilterDateMin)).getTime());
					}
					// снова вырезаем страницу
					dataToShow = filteredDataArr.slice((page - 1) * localDrawLimit, page * localDrawLimit);
				}
				// точная дата нашлась, если мы не отнимали 2
				this.exactDate = localDrawLimit === this.drawLimit;
				// назад можем пойти если данные для показа равны лимиту и не дошли до конца фильтр-кеша
				// но ТОЛЬКО в ГнГ
				this.isEnabledGoBack = this.pagedFiltersLotteries.get(gameCode) && (dataToShow.length === localDrawLimit) &&
					(page * localDrawLimit < filteredDataArr.length);
				// вперед можем пойти, если страница - не первая
				this.isEnabledGoForward = page !== 1;
			}
		} else {
			// вырезаем страницу результатов (подмассив) из полного массива результатов
			dataToShow = this.storageDataArr.slice((page - 1) * this.drawLimit, page * this.drawLimit);
			// если мы достигли конца
			if (((dataToShow.length < this.drawLimit) || (page * this.drawLimit === this.storageDataArr.length))
				&& !this.csDataEnd) {

				// получаем еще CACHED_RESULTS_COUNT результатов и заносим их в хранилище
				await this.addResultsBeforeDate(gameCode, this.getOldestDate());

				// вырезаем страницу для показа
				dataToShow = this.storageDataArr.slice((page - 1) * this.drawLimit, page * this.drawLimit);
			}
			// назад можем пойти если данные для показа равны лимиту и не дошли до конца полного массива
			this.isEnabledGoBack = dataToShow.length === this.drawLimit;
			// вперед можем пойти, если страница - не первая
			this.isEnabledGoForward = page !== 1;
		}

		// возвращаем данные для показа
		return dataToShow;
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Сохраняет результаты в хранилище
	 */
	private putStorageDataArray(gameCode: LotteryGameCode, storageDataArr: Array<IDrawingResult>): Promise<IResponse> {
		this.localStore = storageDataArr;

		return new Promise<IResponse>(resolve => resolve(null));
	}

	/**
	 * Делает запрос к ЦС.
	 */
	private async makeCSRequest(
		gameCode: LotteryGameCode, total?: number,
		dateMax?: string, dateMin?: string): Promise<Array<IDrawingResult>> {
		// подготавливаем запрос к ЦС
		const request = new GetDrawResultsReq(this.appStoreService, gameCode, total || this.drawLimit, dateMax, dateMin);

		return new Promise<Array<IDrawingResult>>(resolve => {
			this.httpService.sendApi(request)
				.then((CSResponse: GetDrawResultsResp) => {
					Logger.Log.i('GameResultsService', `results for game (%s) is LOADED: %s`, gameCode, CSResponse)
						.console();
					resolve(CSResponse && CSResponse.drawing_result ? CSResponse.drawing_result.filter(elem => !!elem) : []);
				})
				.catch((error: IError) => {
					this.dialogInfoService.showOneButtonError(new DialogError(error.code, error.message, error.messageDetails), {
						click: () => {
							if (error.code && (error.code === 4313) || (error.code === 4318)) {
								this.logoutService.logoutOperator();
							}
						},
						text: 'dialog.dialog_button_continue'
					});
				});
		});
	}

	/**
	 * Получает результаты из хранилища.
	 */
	private async getStorageDataArray(gc: LotteryGameCode): Promise<Array<IDrawingResult>> {
		return new Promise(resolve => resolve(this.localStore));
	}

	/**
	 * Добавляет результаты из ЦС к самой выбранной дате.
	 */
	private async addResultsBeforeDate(gameCode: LotteryGameCode, toDate: string): Promise<void> {
		// toDate - ЦСовский формат даты: YYYY-MM-DD 23:59:59
		// уменьшаем ее на 1 секунду
		const toDateObj = CSDateToDateObject(toDate);
		const newDateMax = convertTimestampToCSFormat(this.datePipe, toDateObj.getTime() - 1000);
		// получаем еще CACHED_RESULTS_COUNT результатов
		const newDataArr = await this.makeCSRequest(gameCode, this.cachedResultsCount, newDateMax);
		// объеденяем их с массивом, полученным из хранилища
		this.storageDataArr = [...this.storageDataArr, ...newDataArr];
		// заносим в хранилище
		await this.putStorageDataArray(gameCode, this.storageDataArr);
	}

	/**
	 * Возвращает самую старую дату.
	 */
	private getOldestDate(): string {
		// если в полном массиве хоть что-то есть
		if (this.storageDataArr.length) {
			// берем наистарейшую дату

			return this.storageDataArr[this.storageDataArr.length - 1].drawing_date_begin;
		}

		// // а если нет, то наиновейшую
		// const oldestDateObj = new Date();
		const oldestDateObj = endOfDay();
		// // ставим ей конец дня
		// oldestDateObj.setHours(23, 59, 59);

		// привести дату запроса к виду YYYY-MM-DD 23:59:59
		return convertTimestampToCSFormat(this.datePipe, oldestDateObj.getTime());
	}
}
