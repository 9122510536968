import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { ApiClient } from '@app/core/net/http/api/api-client';
import { IResponse } from '@app/core/net/http/api/types';

// -----------------------------
//  Request
// -----------------------------

/**
 * Модель для вызова экшена API {@link EsapActions.GetDrawResults GetDrawResults}.
 * Предоставляет данные о результатах розыгрыша тиражей.
 */
export class GetDrawResultsReq extends ApiClient {

	/**
	 * Конструктор запроса.
	 *
	 * @param {AppStoreService} appStoreService Централизовано е хранилище данных.
	 * @param {LotteryGameCode} gameCode Код игры.
	 * @param {number} maxDrawCount Максимальное количество тиражей в ответе.
	 * @param {number} drawingDateMax Параметр {@link EsapParams.DRAWING_DATE_MAX DRAWING_DATE_MAX}
	 * @param {number} drawingDateMin Параметр {@link EsapParams.DRAWING_DATE_MIN DRAWING_DATE_MIN}
	 */
	constructor(
		appStoreService: AppStoreService,
		gameCode: LotteryGameCode,
		maxDrawCount: number,
		drawingDateMax?: string,
		drawingDateMin?: string
	) {
		super(appStoreService, appStoreService.Settings.getEsapActionUrl(LotteryGameCode.Undefined, EsapActions.GetDrawResults));

		this.params.append(EsapParams.LOTTERY, `${gameCode}`);
		this.params.append(EsapParams.MAX_DRAW_COUNT, `${maxDrawCount}`);

		if (drawingDateMax) {
			this.params.append(EsapParams.DRAWING_DATE_MAX, `${drawingDateMax}`);
		}

		if (drawingDateMin) {
			this.params.append(EsapParams.DRAWING_DATE_MIN, `${drawingDateMin}`);
		}

		if (appStoreService.hasOperator()) {
			this.params.append(EsapParams.USER_ID, appStoreService.operator.value.userId);
			this.params.append(EsapParams.SID, appStoreService.operator.value.sessionId);
		}
	}

}

// -----------------------------
//  Response
// -----------------------------

/**
 * Модель информации о выигрышных категориях.
 */
export interface IWinCat {
	/** Название выигрышной категории. */
	name: string;

	/** Сумма выигрыша по категории. */
	win_sum: string;

	/** Количество выигрышей (опционально). */
	win_cnt: string;
}

/**
 * Модель результата события {@link IDrawingSPDataEvent}.
 */
export interface IDrawingSPDataEventResult {
	/** Счет матча. */
	score: string;

	/** Результат - 1 (ничья), 2 (победа первой команды) или 4 (победа второй команды). */
	result: number;

	/** Распределение ставок в % разделитель ";", например: "30;30;40". */
	distribution: string;
}

/**
 * Модель события из {@link IDrawingSPData}.
 */
export interface IDrawingSPDataEvent {
	/** Номер события в программе тиража. */
	number: number;

	/** Название турнира. */
	tournament: string;

	/** Первая команда. */
	firstTeam: string;

	/** Вторая команда. */
	secondTeam: string;

	/** Дата и время начала матча. */
	eventDate: string;

	/** Прогноз в процентах победы первой команды. */
	t1: string;

	/** Прогноз в процентах ничьей. */
	x: string;

	/** Прогноз в процентах победы второй команды. */
	t2: string;

	/** Информация о результате, это поле присутствует только для состоявшихся событий. */
	result: IDrawingSPDataEventResult;
}

/**
 * Модель дополнительных данных для лоторей "Спортпрогноз".
 */
export interface IDrawingSPData {
	/** Программа тиража в виде массива элементов с информацией о событиях, включенных в тираж. */
	events: Array<IDrawingSPDataEvent>;
}

/**
 * Модель результата розыгрыша.
 */
export interface IDrawing {

	/** Название дополнительного розыгрыша (конкурса). Для основного розыгрыша может не указываться. */
	name: string;

	/** Выигрышная комбинация. */
	win_comb: string;

	/** Опциональная дополнительная информация о розыгрыше в виде текста, например, сумма Джекпота следующего тиража. */
	extra_info: string;

	/** Массив информации о выигрышных категориях. */
	win_cat: Array<IWinCat>;

	/** Дополнительные данные для лоторей "Спортпрогноз". */
	data: IDrawingSPData;
}

/**
 * Модель результата розыгрыша тиража.
 */
export interface IDrawingResult {

	/** Код тиража. */
	draw_code: number;

	/** Название тиража. */
	draw_name: string;

	/** Дата/время начала розыгрыша тиража. */
	drawing_date_begin: string;

	/** Дата/время окончания розыгрыша тиража. */
	drawing_date_end: string;

	/** Дата окончания выплаты выигрышей. */
	winning_date_end: string;

	/**
	 * Массив результатов розыгрышей.
	 * Может содержать более одного элемента, если кроме основного розыгрыша
	 * в тираже есть дополнительные, например «Парочка» в Лото Забава.
	 * Первый элемент массива — это результаты основного розыгрыша.
	 */
	drawing: Array<IDrawing>;
}

/**
 * Модель ответа на запрос экшена API {@link EsapActions.GetDrawResults GetDrawResults}.
 */
export class GetDrawResultsResp implements IResponse {
	/**
	 * Идентификатор транзакции.
	 */
	client_trans_id: string;

	/**
	 * Результат операции.
	 */
	err_code: number;

	/**
	 * Текст ошибки.
	 */
	err_descr: string;

	/**
	 * Идентификатор клиента.
	 */
	client_id: string;

	/**
	 * Идентификатор пользователя.
	 */
	user_id: number;

	/** Идентификатор сессии пользователя. */
	sid: string;

	/** Дата и время выполнения операции. */
	time_stamp: string;

	/** Код лотереи. */
	lott_code: string;

	/** Название лотереи */
	lott_name: string;

	/** Дата/время начала розыгрыша последнего тиража в диапазоне, определенном в запросе. */
	drawing_date_max: string;

	/** Дата/время начала розыгрыша первого тиража в диапазоне, определенном в запросе. */
	drawing_date_min: string;

	/**
	 * Количество тиражей в статусе «выплата выигрышей» по лотерее, у которых дата/время попадает в диапазон, определенный в запросе
	 */
	draw_count: string;

	/**
	 * Массив объектов, содержащих данные о результатах розыгрыша тиражей.
	 * Тиражи в массиве отсортированы по времени розыгрыша в порядке убывания.
	 * Количество тиражей не превышает значение, определенное параметром MAX_DRAW_COUNT запроса
	 * и может быть меньше значения draw_count.
	 */
	drawing_result: Array<IDrawingResult>;

	/**
	 * Наименование валюты, в которой возвращены суммы выигрышей.
	 */
	currency: string;

	/**
	 * Код языка, на котором возвращены текстовые данные.
	 */
	lang: string;
}
