<div
	class="alert-icon-container"
	[class.alert]="printerState === PrinterState.Disconnected"
	(click)="onClickIconHandler(StatusIconType.Printer, parseStatus, elementRef)"
>

	<!-- КОНТЕНТ ИКОНКИ -->
	<div class="aic-content">
		<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
			<path
				d="M22.1 7.22222H3.9C1.69 7.22222 0 9.1 0 11.5556V20.2222H5.2V26H20.8V20.2222H26V11.5556C26 9.1 24.31 7.22222 22.1
				7.22222ZM18.2 23.1111H7.8V15.8889H18.2V23.1111ZM22.1 13C21.32 13 20.8 12.4222 20.8 11.5556C20.8 10.6889 21.32
				10.1111 22.1 10.1111C22.88 10.1111 23.4 10.6889 23.4 11.5556C23.4 12.4222 22.88 13 22.1 13ZM20.8 0H5.2V5.77778H20.8V0Z"
			>
			</path>
		</svg>
	</div>

	<!-- СТАТУСЫ -->
	<div class="aic-normal" *ngIf="printerState === PrinterState.OnLine"></div>
	<svg *ngIf="printerState === PrinterState.Connected || printerState === PrinterState.OffLine" xmlns="http://www.w3.org/2000/svg" class="aic-warning" viewBox="0 0 24 24" width="24" height="24">
		<circle fill="#E3B90B" cx="12" cy="12" r="12"></circle>
		<path fill="#5a5a5a" d="M13.5,18.7c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.4-1.1,0.4s-0.8-0.1-1.1-0.4c-0.3-0.3-0.5-0.6-0.5-1.1s
				0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5s0.8,0.2,1.1,0.5C13.4,18,13.5,18.3,13.5,18.7z M13.4,5.3c0,0.4-0.1,1.6-0.4,
				3.8c-0.2,2.1-0.5,4.2-0.6,6.2h-0.9c-0.2-2-0.4-4-0.6-6.2c-0.2-2.1-0.4-3.4-0.4-3.8c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,
				1-0.4s0.8,0.1,1,0.4C13.3,4.5,13.4,4.8,13.4,5.3z"
		>
		</path>
	</svg>
	<svg *ngIf="printerState === PrinterState.Disconnected" xmlns="http://www.w3.org/2000/svg" class="aic-alert" viewBox="0 0 24 24" width="24" height="24">
		<circle fill="#df5050" cx="12" cy="12" r="12"></circle>
		<path fill="#fff" d="M13.5,18.7c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.4-1.1,0.4s-0.8-0.1-1.1-0.4c-0.3-0.3-0.5-0.6-0.5-1.1s
				0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5s0.8,0.2,1.1,0.5C13.4,18,13.5,18.3,13.5,18.7z M13.4,5.3c0,0.4-0.1,1.6-0.4,
				3.8c-0.2,2.1-0.5,4.2-0.6,6.2h-0.9c-0.2-2-0.4-4-0.6-6.2c-0.2-2.1-0.4-3.4-0.4-3.8c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,
				1-0.4s0.8,0.1,1,0.4C13.3,4.5,13.4,4.8,13.4,5.3z"
		>
		</path>
	</svg>
</div>
