/**
 * Модель запроса для вызова API BOAuth2.
 */
import { ApiClient } from '@app/core/net/http/api/api-client';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { AccessPosListItemGS, IResponse } from '@app/core/net/http/api/types';

/**
 * Класс запроса для авторизации пользователя в бек-офисе.
 */
export class BOAuth2Req extends ApiClient {

	/**
	 * Конструктор запроса.
	 *
	 * @param {AppStoreService} appStoreService Централизованное хранилище данных.
	 * @param {string} auth2Secret Код авторизации, который пользователь получил по альтернативному каналу двухфакторной авторизации
	 * @param {string} multiUserID Идентификатор пользователя, который обнаружен на пользовательском компьютере.
	 * Используется для отслеживания нескольких аккаунтов от одного и того же пользователя
	 */
	constructor(
		appStoreService: AppStoreService,
		auth2Secret: string,
		multiUserID?: string
	) {
		super(appStoreService, appStoreService.Settings.getEsapActionUrl(LotteryGameCode.Undefined, EsapActions.BOAuth2));

		this.params.append(EsapParams.AUTH2_SECRET, auth2Secret);

		if (multiUserID) {
			this.params.append(EsapParams.MULTIUSER_ID, multiUserID);
		}
	}

}

// -----------------------------
//  Response
// -----------------------------

/**
 * Модель ответа на запрос API BOAuth2
 */
export interface BOAuth2Resp extends IResponse {
	/**
	 * Идентификатор клиента
	 */
	client_id?: string;

	/**
	 * Идентификатор пользователя
	 */
	user_id: string;

	/**
	 * Идентификатор созданной сессии
	 */
	sid: string;

	/**
	 * Массив терминалов и точек продаж
	 */
	pos_list?: Array<AccessPosListItemGS>;
}
