import {UpdateDrawInfoLottery} from "@app/core/net/http/api/models/update-draw-info";

/**
 * Mock-тиражи для игры "Каре".
 */
export const DRAWS_FOR_GAME_KARE: UpdateDrawInfoLottery | any = {
	'lottery': {
		'currency': '',
		'draws': [{
			'draw': {
				'bet_sum': '2.00',
				'code': '65532',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2099-12-31 23:59:59',
				'dr_edate': '2099-12-31 23:59:59',
				'num': '',
				'sale_bdate': '2019-08-22 08:10:35',
				'sale_edate': '2099-12-31 23:59:59',
				'serie_code': '2',
				'serie_num': '',
				'status': '',
				'status_code': 'DRST_BET',
				'version': '1',
				'win_bdate': '2099-12-31 23:59:59',
				'win_edate': '2099-12-31 23:59:59'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81306',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 08:13:05',
				'dr_edate': '2019-08-22 08:13:15',
				'num': '220819/002',
				'sale_bdate': '2019-08-22 08:07:45',
				'sale_edate': '2019-08-22 08:12:55',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация ставок',
				'status_code': 'DRST_BET',
				'version': '1',
				'win_bdate': '2019-08-22 08:16:45',
				'win_edate': '2019-09-22 08:16:45'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81307',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 08:18:15',
				'dr_edate': '2019-08-22 08:18:25',
				'num': '220819/003',
				'sale_bdate': '2019-08-22 08:12:55',
				'sale_edate': '2019-08-22 08:18:05',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 08:21:55',
				'win_edate': '2019-09-22 08:21:55'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81308',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 08:23:25',
				'dr_edate': '2019-08-22 08:23:35',
				'num': '220819/004',
				'sale_bdate': '2019-08-22 08:18:05',
				'sale_edate': '2019-08-22 08:23:15',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 08:27:05',
				'win_edate': '2019-09-22 08:27:05'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81309',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 08:28:35',
				'dr_edate': '2019-08-22 08:28:45',
				'num': '220819/005',
				'sale_bdate': '2019-08-22 08:23:15',
				'sale_edate': '2019-08-22 08:28:25',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 08:32:15',
				'win_edate': '2019-09-22 08:32:15'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81310',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 08:33:45',
				'dr_edate': '2019-08-22 08:33:55',
				'num': '220819/006',
				'sale_bdate': '2019-08-22 08:28:25',
				'sale_edate': '2019-08-22 08:33:35',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 08:37:25',
				'win_edate': '2019-09-22 08:37:25'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81311',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 08:38:55',
				'dr_edate': '2019-08-22 08:39:05',
				'num': '220819/007',
				'sale_bdate': '2019-08-22 08:33:35',
				'sale_edate': '2019-08-22 08:38:45',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 08:42:35',
				'win_edate': '2019-09-22 08:42:35'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81312',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 08:44:05',
				'dr_edate': '2019-08-22 08:44:15',
				'num': '220819/008',
				'sale_bdate': '2019-08-22 08:38:45',
				'sale_edate': '2019-08-22 08:43:55',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 08:47:45',
				'win_edate': '2019-09-22 08:47:45'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81313',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 08:49:15',
				'dr_edate': '2019-08-22 08:49:25',
				'num': '220819/009',
				'sale_bdate': '2019-08-22 08:43:55',
				'sale_edate': '2019-08-22 08:49:05',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 08:52:55',
				'win_edate': '2019-09-22 08:52:55'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81314',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 08:54:25',
				'dr_edate': '2019-08-22 08:54:35',
				'num': '220819/010',
				'sale_bdate': '2019-08-22 08:49:05',
				'sale_edate': '2019-08-22 08:54:15',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 08:58:05',
				'win_edate': '2019-09-22 08:58:05'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81315',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 08:59:35',
				'dr_edate': '2019-08-22 08:59:45',
				'num': '220819/011',
				'sale_bdate': '2019-08-22 08:54:15',
				'sale_edate': '2019-08-22 08:59:25',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 09:03:15',
				'win_edate': '2019-09-22 09:03:15'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81316',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 09:04:45',
				'dr_edate': '2019-08-22 09:04:55',
				'num': '220819/012',
				'sale_bdate': '2019-08-22 08:59:25',
				'sale_edate': '2019-08-22 09:04:35',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 09:08:25',
				'win_edate': '2019-09-22 09:08:25'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81317',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 09:09:55',
				'dr_edate': '2019-08-22 09:10:05',
				'num': '220819/013',
				'sale_bdate': '2019-08-22 09:04:35',
				'sale_edate': '2019-08-22 09:09:45',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 09:13:35',
				'win_edate': '2019-09-22 09:13:35'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81318',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 09:15:05',
				'dr_edate': '2019-08-22 09:15:15',
				'num': '220819/014',
				'sale_bdate': '2019-08-22 09:09:45',
				'sale_edate': '2019-08-22 09:14:55',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 09:18:45',
				'win_edate': '2019-09-22 09:18:45'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81319',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 09:20:15',
				'dr_edate': '2019-08-22 09:20:25',
				'num': '220819/015',
				'sale_bdate': '2019-08-22 09:14:55',
				'sale_edate': '2019-08-22 09:20:05',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 09:23:55',
				'win_edate': '2019-09-22 09:23:55'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81320',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 09:25:25',
				'dr_edate': '2019-08-22 09:25:35',
				'num': '220819/016',
				'sale_bdate': '2019-08-22 09:20:05',
				'sale_edate': '2019-08-22 09:25:15',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 09:29:05',
				'win_edate': '2019-09-22 09:29:05'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81321',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 09:30:35',
				'dr_edate': '2019-08-22 09:30:45',
				'num': '220819/017',
				'sale_bdate': '2019-08-22 09:25:15',
				'sale_edate': '2019-08-22 09:30:25',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 09:34:15',
				'win_edate': '2019-09-22 09:34:15'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81322',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 09:35:45',
				'dr_edate': '2019-08-22 09:35:55',
				'num': '220819/018',
				'sale_bdate': '2019-08-22 09:30:25',
				'sale_edate': '2019-08-22 09:35:35',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 09:39:25',
				'win_edate': '2019-09-22 09:39:25'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81323',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 09:40:55',
				'dr_edate': '2019-08-22 09:41:05',
				'num': '220819/019',
				'sale_bdate': '2019-08-22 09:35:35',
				'sale_edate': '2019-08-22 09:40:45',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 09:44:35',
				'win_edate': '2019-09-22 09:44:35'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81324',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 09:46:05',
				'dr_edate': '2019-08-22 09:46:15',
				'num': '220819/020',
				'sale_bdate': '2019-08-22 09:40:45',
				'sale_edate': '2019-08-22 09:45:55',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 09:49:45',
				'win_edate': '2019-09-22 09:49:45'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81325',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 09:51:15',
				'dr_edate': '2019-08-22 09:51:25',
				'num': '220819/021',
				'sale_bdate': '2019-08-22 09:45:55',
				'sale_edate': '2019-08-22 09:51:05',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 09:54:55',
				'win_edate': '2019-09-22 09:54:55'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81326',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 09:56:25',
				'dr_edate': '2019-08-22 09:56:35',
				'num': '220819/022',
				'sale_bdate': '2019-08-22 09:51:05',
				'sale_edate': '2019-08-22 09:56:15',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 10:00:05',
				'win_edate': '2019-09-22 10:00:05'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81327',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 10:01:35',
				'dr_edate': '2019-08-22 10:01:45',
				'num': '220819/023',
				'sale_bdate': '2019-08-22 09:56:15',
				'sale_edate': '2019-08-22 10:01:25',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 10:05:15',
				'win_edate': '2019-09-22 10:05:15'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81328',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 10:06:45',
				'dr_edate': '2019-08-22 10:06:55',
				'num': '220819/024',
				'sale_bdate': '2019-08-22 10:01:25',
				'sale_edate': '2019-08-22 10:06:35',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 10:10:25',
				'win_edate': '2019-09-22 10:10:25'
			}
		}, {
			'draw': {
				'bet_sum': '2.00',
				'code': '81329',
				'data': {
					'bet_sum_max': '20000.00',
					'bet_sum_min': '100.00'
				},
				'dr_bdate': '2019-08-22 10:11:55',
				'dr_edate': '2019-08-22 10:12:05',
				'num': '220819/025',
				'sale_bdate': '2019-08-22 10:06:35',
				'sale_edate': '2019-08-22 10:11:45',
				'serie_code': '2',
				'serie_num': '',
				'status': 'Регистрация многотиражных ставок',
				'status_code': 'DRST_BET_MULTI',
				'version': '1',
				'win_bdate': '2019-08-22 10:15:35',
				'win_edate': '2019-09-22 10:15:35'
			}
		}
		],
		'lott_code': '52',
		'lott_name': 'Каре'
	}
};
