import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { DATE_TEMPLATE_DD_MM_YYYY, DATE_TEMPLATE_HH_MM } from '@app/util/utils';
import { HamburgerMenuService } from '@app/hamburger/services/hamburger-menu.service';
import { AnimationState } from '@app/util/animations';
import { TopBarService } from '../../services/top-bar.service';
import { StatusIconType } from '../../enums/status-icon-type.enum';
import { Router } from '@angular/router';

/**
 * Верхняя панель-заголовок.
 * Отображается на всех экран, кроме страницы логина.
 */
@Component({
	selector: 'app-top-bar-panel',
	templateUrl: './top-bar-panel.component.html',
	styleUrls: ['./top-bar-panel.component.scss']
})
export class TopBarPanelComponent implements OnInit {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Признак, указывающий, что мы на терминале.
	 */
	@Input()
	isTerminal = false;

	/**
	 * Признак, указывающий, что маршрутизатор находится на домашней странице в данный момент времени.
	 */
	@Input()
	isHomePage = false;

	/**
	 * Параметр для отображения имени залогиненого оператора.
	 */
	@Input()
	loginOperator: string;

	/**
	 * Параметр для отображения статуса коммуникационного сервиса.
	 */
	@Input()
	communicationReady: boolean;

	// /**
	//  * Параметр содержит массив языков для отображения в выпадающем списке.
	//  */
	// @Input()
	// langList: Array<IDropdownListItem>;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Данное событие генерируется в момент нажатия кнопки на гамбургер-меню.
	 */
	@Output()
	readonly activateHamburgerMenu = new EventEmitter<boolean>();

	/**
	 * Данное событие генерируется в момент нажатия кнопки "Перейти на домашнюю страницу".
	 */
	@Output()
	readonly clickGoHome = new EventEmitter<boolean>();

	/**
	 * Данное событие генерируется при нажатии на иконку сети.
	 */
	@Output()
	readonly activateCommunicationDialog = new EventEmitter<never>();

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Константы для фаз и состояний анимации.
	 */
	readonly AnimationState = AnimationState;

	/**
	 * Формат даты.
	 */
	readonly dateFormat = DATE_TEMPLATE_DD_MM_YYYY;

	/**
	 * Формат времени.
	 */
	readonly timeFormat = DATE_TEMPLATE_HH_MM;

	/**
	 * Типы иконок статуса.
	 */
	readonly StatusIconType = StatusIconType;

	/**
	 * Наблюдаемая переменная с текущей датой и временем.
	 */
	dateTime$: Observable<Date>;

	/**
	 * Признак, указывающий, что панель статуса отображается.
	 */
	showStatusPanel: boolean;

	/**
	 * Текст для панели статуса.
	 */
	statusPanelText: string;

	/**
	 * Ссылка на элемент иконки статуса.
	 */
	iconTarget: ElementRef;

	/**
	 * Тип иконки статуса.
	 */
	iconType: StatusIconType;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {TopBarService} topBarService Сервис для работы с верхней панелью.
	 * @param {HamburgerMenuService} hamburgerMenuService Сервис для работы с меню-гамбургером.
	 * @param {Router} router Сервис для работы с маршрутизацией.
	 */
	constructor(
		private readonly topBarService: TopBarService,
		readonly hamburgerMenuService: HamburgerMenuService,
		private readonly router: Router
	) {}

	/**
	 * Обработчик клика по кнопке "Домой".
	 */
	onClickGoHomeHandler(): void {
		this.clickGoHome.emit(true);
	}

	/**
	 * Обработчик клика по кнопке активации меню-гамбургер.
	 */
	onClickToggleHamburgerMenuHandler(): void {
		this.activateHamburgerMenu.emit(this.hamburgerMenuService.stateExpression !== AnimationState.expanded);
	}

	/**
	 * Обработчик клика по кнопке диалога коммуникационного сервиса.
	 */
	onClickCommunicationIconHandler(): void {
		if (!this.topBarService.statusPanelItem$$.value) {
			this.activateCommunicationDialog.emit();
		}
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		// обновлять дату/время каждые 30 сек
		this.dateTime$ = timer(0, 30000)
			.pipe(map(() => new Date()));

		//
		this.topBarService.statusPanelItem$$
			.pipe()
			.subscribe(v => {
				if (v) {
					this.showStatusPanel = true;
					this.statusPanelText = v.statusText;
					this.iconTarget = v.iconTarget;
					this.iconType = v.iconType;
				} else {
					this.showStatusPanel = false;
					this.statusPanelText = undefined;
					this.iconTarget = undefined;
				}
			});
	}
}
