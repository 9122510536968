import { Subject } from 'rxjs';
import { getWsRequestId } from '@app/util/utils';
import { ApiError, IError } from '../../error/types';
import { IMessage, IRequest, IResponse } from './api/types';
import { AbstractTransport, TransportState } from '@app/core/net/ws/ws-models';

/**
 * Типы сервисов использующие простой транспорт для передачи сообщений.
 */
export enum SimpleTransportType {
	/**
	 * Тип для Log-сервиса.
	 */
	Log		= 'Log',
	/**
	 * Тип для Display-сервиса.
	 */
	Display	= 'Display'
}

/**
 * Модель простого сетевого транспорта посредством протокола Websocket (механизма для взаимодействия приложения и WS-сервисов).
 * Под "простым" подразумевается что отправитель запроса не ожидает ответа и не будет наблюдать за результатом запроса.
 */
export abstract class SimpleTransport extends AbstractTransport {

	// -----------------------------
	//  Protected functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {SimpleTransportType} type Тип сервиса.
	 */
	protected constructor(type: SimpleTransportType) {
		super(type);

		this.stateMonitor({
			ready: () => {
				this.Log(`${this.Tag}, transport in ready state`);
				// this.heartBeat();
			},
			error: () => {
				this.Log(`${this.Tag}, transport in error state`);
				// if (this.heartbeat) {
				//   this.heartbeat.unsubscribe();
				// }
			}
		});
	}

	/**
	 * Отправка сообщения сервисам согласно спецификации обмена данными между приложением и андроид сервисами.
	 *
	 * @param {IRequest} apiRequest Тело сообщения.
	 * @returns {string} Идентификатор запроса.
	 */
	protected sendApiRequest(apiRequest: IRequest): string {
		this.Log(`${this.Tag}, sendRequest`);

		if (this.ws && this.ws.readyState === WebSocket.OPEN) {
			apiRequest.requestId = getWsRequestId();
			const apiMessage = {request: apiRequest};
			let message: string;
			try {
				message = JSON.stringify(apiMessage);
			} catch (e) {
				const error = new ApiError((e as Error).message, undefined, undefined);
				this.onError(error);

				return error.message;
			}

			this.Log(`${this.Tag}, send request message: ${message}`);
			this.Log(`${this.Tag}, transport bufferedAmount: ${this.ws.bufferedAmount}`);
			this.ws.send(message);

			return apiRequest.requestId;
		}
	}

	// -----------------------------
	//  Abstract functions
	// -----------------------------
	/**
	 * Обработчик получения сообщения от веб-сокета.
	 * @param response Сообщение от веб-сокета.
	 * @protected
	 */
	protected abstract onResult(response: IResponse): void;

	/**
	 * Обработчик получения ошибки от веб-сокета.
	 * @param error Ошибка от веб-сокета.
	 * @protected
	 */
	protected abstract onError(error: IError): void;

	/**
	 * Функция логирования.
	 * @param message Сообщение для логирования.
	 * @constructor
	 * @protected
	 */
	protected abstract Log(message): void;

	// -----------------------------
	//  AbstractTransport
	// -----------------------------
	/**
	 * Функция создания соединения
	 * @param url Адрес сервера
	 * @param retryTimeout Время ожидания перед повторным подключением
	 * @protected
	 */
	protected create(url: string, retryTimeout: number): void {
		this.url = url;
		this.retryTimeout = retryTimeout * 1000;
		this.open();
	}

	/**
	 * Функция открытия соединения
	 * @protected
	 */
	protected open(): void {
		this.Log(`${this.Tag}, try to open  new connection: ${this.url}`);

		this.wsObserver$$ = new Subject();
		this.wsObserver$$.subscribe({
			next: value => this.onMessage(value.data),
			error: err => {
				if (this.isStateChange) {
					this.isStateChange = false;
					this.eventsListeners.next({type: TransportState.ERROR, event: err});
				}
			},
			complete: () => {
				if (this.isStateChange) {
					this.isStateChange = false;
					this.eventsListeners.next({type: TransportState.ERROR, event: new Event('close')});
				}
			}
		});

		this.ws = new WebSocket(this.url);
		this.ws.onmessage = (e: MessageEvent) => this.onWSMessageHandler(e);
		this.ws.onerror = (e: Event) => this.onWSErrorHandler(e);
		this.ws.onclose = (e: CloseEvent) => this.onWSCloseHandler(e);
		this.ws.onopen = (e: Event) => this.onWSOpenHandler(e);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Метод обработки сообщения полученного от сервисов.
	 *
	 * @param {string} message тело сериализованного сообщения
	 */
	private onMessage(message: string): void {
		this.Log(`${this.Tag}, got message: ${message}`);

		let apiMessage: IMessage;
		try {
			apiMessage = JSON.parse(message) as IMessage;
		} catch (e) {
			this.Log(`${this.Tag}, error while parsing message: ${(e as Error).message}`);

			return;
		}

		if (apiMessage.response) {
			if (apiMessage.response.requestId) {
				if (apiMessage.response.errorCode === 0) {
					this.onResult(apiMessage.response);
				} else {
					this.onError(new ApiError(apiMessage.response.errorDesc, undefined, apiMessage.response.errorCode));
				}
			}
		} else {
			this.Log(`${this.Tag}, message type doesn't recognised`);
		}
	}
}
