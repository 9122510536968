import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DATE_TEMPLATE_DD_MM_YYYY } from '@app/util/utils';
import { CalendarTheme } from '../../enums/calendar-theme.enum';
import { CalendarService } from '../../services/calendar.service';

/**
 * Компонент-кнопка для активации календаря и отображения выбранной даты.
 */
@Component({
	selector: 'app-calendar-date-input',
	templateUrl: './calendar-date-input.component.html',
	styleUrls: ['./calendar-date-input.component.scss']
})
export class CalendarDateInputComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Начальная дата.
	 */
	@Input()
	value: Date;

	/**
	 * Текст, который будет показан, если дата не задана.
	 */
	@Input()
	prompt: string;

	/**
	 * Задает цветовую тему календаря.
	 */
	@Input()
	calendarTheme: CalendarTheme = CalendarTheme.Green;

	/**
	 * Задать сдвиг сверху
	 */
	@Input()
	topPadding = 0;

	/**
	 * Задать сдвиг слева.
	 */
	@Input()
	leftPadding = 0;

	/**
	 * Абсолютные ли сдвиги?
	 */
	@Input()
	absolutePaddings = false;

	/**
	 * Задать флаг показа оверлея.
	 */
	@Input()
	showOverlay = true;

	/**
	 * Задать текст титульной кнопки.
	 */
	@Input()
	showTitleText: string;

	/**
	 * Можем ли мы очистить дату?
	 */
	@Input()
	canClearDate = true;

	/**
	 * Задать флаг отключения дат в окне выбора, если они позднее текущей даты.
	 */
	@Input()
	disableDatesLaterThanCurrent = true;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие генерируется в случае если пользователь изменил дату.
	 */
	@Output()
	readonly dateChanged = new EventEmitter<Date>();

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Шаблон даты.
	 */
	readonly DATE_TEMPLATE_DD_MM_YYYY = DATE_TEMPLATE_DD_MM_YYYY;

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Конструктор компонента.
	 * @param calendarService Сервис календаря.
	 */
	constructor(
		private readonly calendarService: CalendarService
	) {}

	/**
	 * Обработчик выбора даты пользователем.
	 *
	 * @param {Date} date Дата, выбранная пользователем.
	 */
	onDateChangedHandler(date: Date): void {
		this.value = date;
		this.dateChanged.emit(date);
	}

	/**
	 * Обработчик нажатия кнопки очистки даты.
	 */
	onClickClearHandler(): void {
		this.value = undefined;
		this.dateChanged.emit(undefined);
		this.calendarService.calendarConfiguration$$.value.selectedDate = undefined; // TODO сомнительно
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------



}
