import { Component, Input } from '@angular/core';

import { MslButtonComponent } from '../msl-button.component';

/**
 * Кнопка регистрации ставок.
 * Компонент сделан на базе обычной кнопки с возможностью вывода суммы в грн.
 * внутри самого компонента.
 */
@Component({
	selector: 'app-register-bet-button',
	templateUrl: './register-bet-button.component.html',
	styleUrls: ['./register-bet-button.component.scss']
})
export class RegisterBetButtonComponent extends MslButtonComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Сумма для вывода.
	 */
	@Input()
	amount: number;

	/**
	 * Минимальная сумма для уменьшенного шрифта.
	 */
	@Input()
	longSumAmount = 10000;

	/**
	 * Уменьшенный размер шрифта.
	 */
	@Input()
	smallFontSize = 24;

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------





}
