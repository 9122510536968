import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StatusPanelItem } from '../interfaces/status-panel-item';

/**
 * Сервис для работы с верхней панелью.
 */
@Injectable({
	providedIn: 'root'
})
export class TopBarService {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная с элементом панели статуса.
	 */
	statusPanelItem$$ = new BehaviorSubject<StatusPanelItem>(undefined);

	/**
	 * Спрятать панель статуса.
	 */
	hideStatusPanel(): void {
		this.statusPanelItem$$.next(undefined);
	}
}


