import {UpdateDrawInfoLottery} from "@app/core/net/http/api/models/update-draw-info";

/**
 * Mock-тиражи для игры "Гонка на деньги"
 */
export const DRAWS_FOR_GAME_GONKA: UpdateDrawInfoLottery | any = {
	'lottery': {
		"lott_name": "Гонка на гроші",
		"lott_code": "13",
		"currency": "",
		"draws":
			[
				{
					"draw":
						{
							"bet_sum": "2.00",
							"code": "65533",
							"data":
								{
									"bet_sum_max": "0.00",
									"bet_sum_min": "0.00",
									"max_win_sum": "0.00"
								},
							"dr_bdate": "2099-12-31T23:59:59",
							"dr_edate": "2099-12-31T23:59:59",
							"num": "",
							"sale_bdate": "2022-07-19T13:32:29",
							"sale_edate": "2099-12-31T23:59:59",
							"serie_code": "2",
							"serie_num": "",
							"status": "",
							"status_code": "DRST_BET",
							"version": "1",
							"win_bdate": "2099-12-31T23:59:59",
							"win_edate": "2099-12-31T23:59:59"
						}
				}
			]
	}
};
