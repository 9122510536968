import {UpdateDrawInfoLottery} from "@app/core/net/http/api/models/update-draw-info";

/**
 * Mock-тиражи для игры "Мегалот"
 */
export const DRAWS_FOR_GAME_MEGALOT: UpdateDrawInfoLottery | any = {
	"lottery": {
		"currency": "",
		"draws": [{
			"draw": {
				"bet_sum": "5.00",
				"code": "1582",
				"data": {
					"bet_sum_max": "20000.00",
					"bet_sum_min": "100.00",
					"mega": {
						"extra_max": "9.00",
						"extra_sum": "1.00"
					},
					"mega_prize": 150000
				},
				"dr_bdate": "2099-07-10 15:08:56",
				"dr_edate": "2099-07-10 15:08:58",
				"jackpot": 80000,
				"num": "1060",
				"sale_bdate": "2099-06-25 15:08:53",
				"sale_edate": "2099-07-10 15:08:54",
				"serie_code": "5",
				"serie_num": "",
				"status": "Регистрация ставок",
				"status_code": "DRST_BET",
				"version": "4",
				"win_bdate": "2099-07-10 15:08:59",
				"win_edate": "2100-01-25 15:08:53"
			}
		}, {
			"draw": {
				"bet_sum": "5.00",
				"code": "1599",
				"data": {
					"bet_sum_max": "20000.00",
					"bet_sum_min": "100.00",
					"mega": {
						"extra_max": "9.00",
						"extra_sum": "1.00"
					},
					"mega_prize": 1000000
				},
				"dr_bdate": "2099-07-20 12:31:20",
				"dr_edate": "2099-07-20 12:31:22",
				"jackpot": 5000000,
				"num": "1061",
				"sale_bdate": "2099-07-02 12:31:18",
				"sale_edate": "2099-07-20 12:31:18",
				"serie_code": "5",
				"serie_num": "",
				"status": "Регистрация многотиражных ставок",
				"status_code": "DRST_BET_MULTI",
				"version": "2",
				"win_bdate": "2099-07-20 12:31:24",
				"win_edate": "2100-01-02 12:31:18"
			}
		}, {
			"draw": {
				"bet_sum": "5.00",
				"code": "1600",
				"data": {
					"bet_sum_max": "20000.00",
					"bet_sum_min": "100.00",
					"mega": {
						"extra_max": "9.00",
						"extra_sum": "1.00"
					},
					"mega_prize": 150000
				},
				"dr_bdate": "2099-07-30 12:53:20",
				"dr_edate": "2099-07-30 12:53:22",
				"jackpot": 100000,
				"num": "1062",
				"sale_bdate": "2099-07-02 12:53:16",
				"sale_edate": "2099-07-30 12:53:18",
				"serie_code": "5",
				"serie_num": "",
				"status": "Регистрация многотиражных ставок",
				"status_code": "DRST_BET_MULTI",
				"version": "2",
				"win_bdate": "2099-07-30 12:53:24",
				"win_edate": "2100-01-02 12:53:16"
			}
		}, {
			"draw": {
				"bet_sum": "5.00",
				"code": "1601",
				"data": {
					"bet_sum_max": "20000.00",
					"bet_sum_min": "100.00",
					"mega": {
						"extra_max": "9.00",
						"extra_sum": "1.00"
					},
					"mega_prize": 250000
				},
				"dr_bdate": "2099-08-15 12:56:02",
				"dr_edate": "2099-08-15 12:56:04",
				"jackpot": 200000,
				"num": "1063",
				"sale_bdate": "2099-07-02 12:55:58",
				"sale_edate": "2099-08-15 12:56:00",
				"serie_code": "5",
				"serie_num": "",
				"status": "Регистрация многотиражных ставок",
				"status_code": "DRST_BET_MULTI",
				"version": "2",
				"win_bdate": "2099-08-15 12:56:06",
				"win_edate": "2100-02-02 12:55:58"
			}
		}],
		"lott_code": "1",
		"lott_name": "МЕГАЛОТ"
	}
};
