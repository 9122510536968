import { Component, ComponentFactoryResolver, Type, ViewChild } from '@angular/core';

import { DialogContainerDirective } from '../directives/dialog-container.directive';
import { DialogContainerService } from '../services/dialog-container.service';
import { IDialogComponent, IDialogContainer } from '../types';

/**
 * Компонент диалогового (модального) окна.
 * Базовая форма для информационных окон и диалогов с ошибками.
 */
@Component({
	selector: 'app-dialog-container',
	template: `
		<ng-template appDialogContainer></ng-template>
	`
})
export class DialogContainerComponent implements IDialogContainer {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Директива для динамической загрузки компонента.
	 */
	@ViewChild(DialogContainerDirective, { static: true })
	directive: DialogContainerDirective;

	/**
	 * Уникальный идентификатор компонента (текущая дата в миллисекундах)
	 */
	serial: number;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Родительский элемент в дереве (списке) отображаемых окон (одно поверх другого).
	 * @private
	 */
	private parent: DialogContainerComponent;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {ComponentFactoryResolver} componentFactoryResolver Фабрика для динамической загрузки компонента.
	 * @param {DialogContainerService} dialogContainerService Сервис для работы с диалоговыми окнами.
	 */
	constructor(
		private readonly componentFactoryResolver: ComponentFactoryResolver,
		private readonly dialogContainerService: DialogContainerService
	) {
		this.serial = Date.now();
		dialogContainerService.container.addContainer(this);
	}

	/**
	 * Установка родительского элемента в дереве (списке) отображаемых окон (одно поверх другого).
	 *
	 * @param {DialogContainerComponent} parent Родительский элемент.
	 */
	setParent(parent: DialogContainerComponent): void {
		this.parent = parent;
	}

	/**
	 * Получение родительского элемента в дереве (списке) отображаемых окон(одно поверх другого).
	 *
	 * @returns {DialogContainerComponent}
	 */
	getParent(): DialogContainerComponent {
		return this.parent;
	}

	/**
	 * Динамическая загрузка компонента (построение DOM на основе шаблона) конкретного типа диалогового окна.
	 *
	 * @param {Type<IDialogComponent>} component Тип конкретного типа диалогового окна.
	 * @returns {IDialogComponent} Экземпляр контейнера диалогово окна ограниченный интерфейсом.
	 */
	loadComponent(component: Type<IDialogComponent>): IDialogComponent {

		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
		const viewContainerRef = this.directive.viewContainerRef;
		viewContainerRef.clear();

		const componentRef = viewContainerRef.createComponent(componentFactory);
		this.dialogContainerService.container.setTopContainer(this);
		const dialog: IDialogComponent = componentRef.instance;
		dialog.container = this;

		return dialog;
	}

	/**
	 * Закрытие диалогового окна.
	 * Если в дереве (списке) отображаемых окон (одно поверх другого)
	 * есть родитель, то будет произведена установка текущего диалогового окна на родителя.
	 *
	 * @returns {boolean} Было ли спрятано окно.
	 */
	clean(): boolean {
		if (this.dialogContainerService.container.delContainer(this)) {
			this.directive.viewContainerRef.clear();

			return true;
		}

		return false;
	}
}
