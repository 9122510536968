import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { MakePositivePipe } from '@app/total-check/pipes/make-positive.pipe';
import { TotalCheckInfoPanelComponent } from '@app/total-check/components/total-check-info-panel/total-check-info-panel.component';
import { RouterModule } from '@angular/router';

/**
 * Интерфейс конфигурации модуля.
 */
interface TotalCheckModuleConfiguration {
	/**
	 * Пустой параметр.
	 */
	empty?: any;
}

/**
 * Модуль функционала "Общий чек".
 */
@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		RouterModule
	],
	declarations: [
		TotalCheckInfoPanelComponent,
		MakePositivePipe
	],
	exports: [
		TotalCheckInfoPanelComponent
	],
	providers: [
		// TotalCheckStorageService
	]
})
export class TotalCheckModule {
	static forRoot(config?: TotalCheckModuleConfiguration): ModuleWithProviders<TotalCheckModule> {
		return {
			ngModule: TotalCheckModule,
			providers: [
				// TotalCheckStorageService
			]
		};
	}

	static forChild(config?: TotalCheckModuleConfiguration): ModuleWithProviders<TotalCheckModule> {
		return {
			ngModule: TotalCheckModule,
			providers: [
				// TotalCheckStorageService
			]
		};
	}
}
