/**
 * Класс для работы с баркодом.
 */
export class BarcodeObject {
	/**
	 * Оригинальный баркод.
	 */
	readonly barcode: string;

	/**
	 * Номер игры в виде строки (4 цифры).
	 * Соответствует коду серии билета {@link UpdateDrawInfoDraw.serie_code serie_code} из модели {@link UpdateDrawInfoDraw}.
	 */
	gameNumber: string;

	/**
	 * Номер игры в виде числа.
	 * Соответствует коду серии билета {@link UpdateDrawInfoDraw.serie_code serie_code} из модели {@link UpdateDrawInfoDraw}.
	 */
	intGameNumber: number;

	/**
	 * Номер пачки (6 цифр).
	 */
	ticketPackage: string;

	/**
	 * Номер пачки в виде числа.
	 */
	intTicketPackage: number;

	/**
	 * Номер билета в виде строки (3 цифры).
	 */
	ticketNumber: string;

	/**
	 * Номер билета в виде числа.
	 */
	intTicketNumber: number;

	/**
	 * Проверочный код (3 цифры) в виде строки.
	 */
	verificationCode?: string;

	/**
	 * Конструктор объекта.
	 *
	 * @param {string} barcode Оригинальный баркод.
	 */
	constructor(barcode: string) {
		this.barcode = barcode;
	}
}
