import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

/**
 * Модель кнопки. Содержит метку, значение и различные статусы.
 */
export interface IButtonGroupItem {
	/**
	 * Индекс кнопки в массиве.
	 */
	index: number;

	/**
	 * Метка кнопки, которая будет отображаться непосредственно на ней.
	 */
	label: string;

	/**
	 * Признак, означающий что кнопка выбрана (активна).
	 */
	selected: boolean;

	/**
	 * Признак, означающий что кнопка отключена и не доступна к нажатию.
	 */
	disabled: boolean;
}

/**
 * Перечисление стилей кнопок, доступных к использованию.
 * В качестве значения используется имя класса, описанное в стиле компонента.
 * Возможные варианты:
 * - {@link ButtonGroupStyle.outline_square square} - квадраты с зеленым контуром и полной зеленой заливкой в выбранном состоянии,
 * - {@link ButtonGroupStyle.outline_square_radial_fill square-radial} - аналагочно, но с радиальной заливкой,
 * - {@link outline_circle circle} - круг с зеленой заливкой в выбранном состоянии,
 * - {@link ButtonGroupStyle.small_gray gray} - небольшие серые кнопки,
 * - {@link ButtonGroupStyle.button_hexagonal hexagonal} - шестиугольные кнопки.
 */
export enum ButtonGroupStyle {
	outline_square = 'square',
	outline_square_radial_fill = 'square-radial',
	outline_circle = 'circle',
	small_gray = 'gray',
	button_hexagonal = 'hexagonal',
	button_gold = 'gold'
}

/**
 * Перечисление стилей текста на кнопках, доступных к использованию.
 * В качестве значения используется имя класса, описанное в стиле компонента.
 * Возможные варианты:
 * - {@link ButtonTextStyle.button_text_none} - без какого-либо стиля,
 * - {@link ButtonTextStyle.button_text_lottery} - стиль как в лоттерее
 */
export enum ButtonTextStyle {
	button_text_none = 'text-none',
	button_text_lottery = 'text-lottery'
}

/**
 * Перечисление режимов работы компонента {@link ButtonsGroupComponent}.
 * Возможные варианты:
 * - {@link ButtonGroupMode.range range} - выбирает диапазон от первой до нажатой кнопки включительно,
 * - {@link ButtonGroupMode.range_deselect range_deselect} - выбирает диапазон от первой до нажатой кнопки включительно с возможностью
 * снятия выделения повторным нажатием,
 * - {@link ButtonGroupMode.range_deselect_2 range_deselect_2} - выбирает диапазон от первой до нажатой кнопки включительно с возможностью
 * снятия выделение повторным нажатием ДО нажатой кнопки НЕ включительно,
 * - {@link ButtonGroupMode.custom custom} - выбирает каждую кнопку индивидуально,
 * - {@link ButtonGroupMode.radio_deselect radio_deselect} - выбирает единственную кнопку с возможностью отжатия
 * - {@link ButtonGroupMode.radio radio} - выбирает единственную кнопку, т.е. работает как раидио-группа.
 */
export enum ButtonGroupMode {
	range,
	range_deselect,
	range_deselect_2,
	custom,
	radio,
	radio_deselect,
	no_selection
}

/**
 * Компонент, отрисовывающий группу кнопок.
 */
@Component({
	selector: 'app-buttons-group',
	templateUrl: './buttons-group.component.html',
	styleUrls: ['./buttons-group.component.scss']
})
export class ButtonsGroupComponent implements OnInit, OnDestroy {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Метка данной группы кнопок.
	 */
	@Input()
	buttonGroupLabel: string;

	/**
	 * Количество кнопок в группе.
	 */
	@Input()
	buttonsCount: number;

	/**
	 * Сеттер количества разрешенных кнопок в группе.
	 * @param value - количество разрешенных кнопок.
	 */
	@Input()
	set enabledButtonsCount(value: number) {
		this._enabledButtonsCount = value;
		this.updateButtons();
	}
	/**
	 * Геттер количества разрешенных кнопок в группе.
	 */
	get enabledButtonsCount(): number {
		return this._enabledButtonsCount;
	}

	/**
	 * Количество разрешенных кнопок в группе.
	 * @private
	 */
	private _enabledButtonsCount: number;

	/**
	 * Задать статус кнопкам {@link IButtonGroupItem.selected selected} по умолчанию.
	 */
	@Input()
	selectedButtons: number | Array<number>;

	/**
	 * Задать стиль текста кнопок.
	 * Вибирается из {@link ButtonTextStyle}.
	 */
	@Input()
	buttonTextStyle: ButtonTextStyle = ButtonTextStyle.button_text_none;

	/**
	 * Задать стиль кнопок.
	 * Вибирается из {@link ButtonGroupStyle}.
	 */
	@Input()
	buttonGroupStyle: ButtonGroupStyle = ButtonGroupStyle.outline_square;

	/**
	 * Метка данной группы кнопок.
	 */
	@Input()
	workMode: ButtonGroupMode = ButtonGroupMode.range;

	/**
	 * Максимальное количество нажатых кнопок в режиме {@link ButtonGroupMode.custom custom}.
	 */
	@Input()
	countSelectedButtons: number;

	/**
	 * Кастомный массив меток для кнопок.
	 */
	@Input()
	labelsArray: Array<string>;

	/**
	 * Кастомный массив значений для кнопок.
	 */
	@Input()
	valuesArray: Array<number>;

	/**
	 * Идентификатор группы.
	 */
	@Input()
	id: any;

	/**
	 * Флаг, указывающий на необходимость перевода метки кнопок.
	 */
	@Input()
	isNeededTranslation: boolean;

	/**
	 * Флаг, необходимый для деактивации кнопок. Если true, то они недоступны для нажатия
	 */
	@Input()
	isDisabled: boolean;

	/**
	 * Флаг, необходимый для мигания заголовка, по умолчанию - не мигает
	 */
	@Input()
	titleBlinking = false;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие, которое указывает на то, что клик по кнопке отработал успешно.
	 *
	 * @type {EventEmitter<IButtonGroupItem>}
	 */
	@Output()
	readonly clickButton: EventEmitter<IButtonGroupItem> = new EventEmitter();

	/**
	 * Событие, которое указывает на то, что был превышен параметр {@link countSelectedButtons}.
	 *
	 * @type {EventEmitter<IButtonGroupItem>}
	 */
	@Output()
	readonly maximumButtonsSelected: EventEmitter<IButtonGroupItem> = new EventEmitter();

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Массив кнопок с моделями типа {@link IButtonGroupItem}.
	 */
	buttons: Array<IButtonGroupItem> = [];

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Вспомогательная функция для отслеживания изменений в массиве элементов.
	 * @param index Индекс элемента.
	 * @param item Элемент.
	 */
	trackByFn = (index, item: IButtonGroupItem) => item.label;

	/**
	 * Обработчик нажатия кнопок в контейнере.
	 * Ожидает, что в целевом элементе будет содержаться атрибут <code>bg-index</code>,
	 * в котором будет указан индекс кнопки из массива кнопок {@link buttons}.
	 *
	 * @param $event Передаваемый объект события.
	 */
	onButtonClickHandler($event): void {
		if (this.isDisabled) {
			return;
		}

		const index = parseInt(($event.target as Element).getAttribute('bg-index'), 10);
		if (!isNaN(index)) {
			const button = this.buttons[index];
			if (!button.disabled) {
				this.changeButtonState(index);
			}
		}
	}

	/**
	 * Выполняет очистку кнопок, т.е. приводит их в исходное состояние.
	 */
	clearButtons(): void {
		// подготовить массив кнопок
		if (Number.isInteger(this.buttonsCount) && this.buttonsCount > 0) {
			this.buttons = Array.from(Array(this.buttonsCount))
				.map((_, index) => {
					return {
						index,
						label: this.labelsArray ? this.labelsArray[index] : `${index + 1}`,
						selected: this.initSelectedProperty(index),
						disabled: this.enabledButtonsCount === 0 || this.enabledButtonsCount <= index
					};
				});
		} else {
			this.buttons = [];
		}
	}

	/**
	 * По заданной кнопке возвращает значение из массива {@link valuesArray}, если данный массив был задан
	 * при инициализации компонента. В противном случае вернет просто параметр {@link IButtonGroupItem.label label}.
	 *
	 * @param {IButtonGroupItem} button Кнопка типа {@link IButtonGroupItem}, для которой будет произведен поиск значения.
	 * @returns {any} Любое значение из заданного списка {@link valuesArray} или <b>undefined</b>, если кнопка не найдена.
	 */
	getValueByButton(button: IButtonGroupItem): string | undefined {
		const index = this.buttons.indexOf(button);
		if (index !== -1) {
			if (this.valuesArray && index < this.valuesArray.length) {
				return this.valuesArray[index].toString();
			}

			return button.label;
		}

		return undefined;
	}

	/**
	 * Выбрать заданную кнопку по индексу.
	 * Срабатывает аналогично клику, но без генерации события {@link clickButton}.
	 *
	 * @param {number} index Индекс кнопки в диапазоне 0..N.
	 */
	selectButtonByIndex(index: number): void {
		this.changeButtonState(index, false);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Изменить состояние кнопки (или кнопок) {@link IButtonGroupItem.selected}.
	 * Для режима {@link ButtonGroupMode.custom custom} при заданном входном параметре {@link countSelectedButtons}
	 * выполнить проверку на возможность клика. Если клик возможен, производит смену состояния конпки и генерирует
	 * событие {@link clickButton}. Если клик невозможен из-за превышения параметра, то генерируетс событие
	 * {@link maximumButtonsSelected}.
	 *
	 * @param {number} idx Индекс кнопки, по которой был произведен клик.
	 * @param {boolean} emitEvent
	 */
	private changeButtonState(idx: number, emitEvent = true): void {
		let button = this.buttons[idx];
		let lastIdx: number;

		switch (this.workMode) {
			case ButtonGroupMode.range:
			case ButtonGroupMode.range_deselect:
				lastIdx = idx;
				if (this.workMode === ButtonGroupMode.range_deselect && button.selected) {
					lastIdx = idx - 1;
					button = this.buttons[lastIdx];
				}

				this.buttons.forEach((btn, index) => btn.selected = index <= lastIdx);
				break;

			case ButtonGroupMode.range_deselect_2:
				lastIdx = idx;
				const prevLastIdx = this.buttons.filter(btn => btn.selected).length - 1;
				if (button.selected && lastIdx >= prevLastIdx) {
					lastIdx = idx - 1;
					button = this.buttons[lastIdx];
				}

				this.buttons.forEach((btn, index) => btn.selected = index <= lastIdx);
				break;
			case ButtonGroupMode.custom:
				// проверить на макс кол-во нажатых кнопок, если задан параметр
				if (this.countSelectedButtons) {
					const count = this.buttons.filter(f => f.selected).length;
					if (count < this.countSelectedButtons || (this.countSelectedButtons === count && button.selected)) {
						button.selected = !button.selected;
						if (this.countSelectedButtons === count + 1 && button.selected) {
							this.maximumButtonsSelected.emit(button);
						}
					} else {
						this.maximumButtonsSelected.emit(button);

						return;
					}
				} else {
					button.selected = !button.selected;
				}
				break;

			case ButtonGroupMode.radio:
				this.buttons.forEach(btn => btn.selected = false);
				button.selected = true;
				break;

			case ButtonGroupMode.radio_deselect:
				this.buttons.forEach(btn => btn.selected = btn.index === button.index ? !btn.selected : false);
				break;

			case ButtonGroupMode.no_selection:
				break;

			default:
				return;
		}

		if (emitEvent) {
			this.clickButton.emit(button);
		}
	}

	/**
	 * Дефолтная инициализация состояния кнопки по входному параметру {@link selectedButtons}.
	 *
	 * @param {number} idx Индекс кнопки в массиве.
	 * @returns {boolean}
	 */
	private initSelectedProperty(idx: number): boolean {
		if (!!this.selectedButtons || this.selectedButtons >= 0) {
			if (Array.isArray(this.selectedButtons)) {
				return this.selectedButtons.indexOf(idx) !== -1;
			}

			return idx <= this.selectedButtons;
		}

		return false;
	}

	/**
	 * Обновляет состояние кнопок в зависимости от параметра {@link enabledButtonsCount}.
	 * @private
	 */
	private updateButtons(): void {
		if (!Array.isArray(this.buttons)) {
			return;
		}

		this.buttons.forEach((b, index) => {
			b.disabled = this.enabledButtonsCount === 0 || this.enabledButtonsCount <= index;
		});

		if (this.enabledButtonsCount === 0) {
			this.buttons.forEach(b => {
				b.selected = false;
			});
		}
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Событие инициализации компонента.
	 */
	ngOnInit(): void {
		this.clearButtons();
	}

	/**
	 * Событие уничтожения компонента.
	 */
	ngOnDestroy(): void {
		Object.keys(this)
			.forEach(k => {
			this[k] = undefined;
		});
	}

}
