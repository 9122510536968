<button
	appMslComponent
	type="button"
	class="button"
	[class.button_long-sum]="amount >= longSumAmount"
	[attr.disabled]="!amount || null"
	data-cy="button"
	>
	{{ 'dialog.to_pay' | translate }}: {{ (!amount ? 0 : amount) | mslCurrency }} {{ 'lottery.uah' | translate }}
</button>
