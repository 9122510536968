import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MslBaseInput } from '@app/shared/components/msl-base-input';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

/**
 * Компонент ввода пароля с иконкой глаза и виртуальной клавиатурой.
 */
@Component({
	selector: 'app-msl-input-with-eye',
	templateUrl: './msl-input-with-eye.component.html',
	styleUrls: ['./msl-input-with-eye.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: MslInputWithEyeComponent,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: MslInputWithEyeComponent,
			multi: true
		}
	]
})
export class MslInputWithEyeComponent extends MslBaseInput implements   ControlValueAccessor, Validator {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Подпись к компоненту ввода (псевдоэлемент, уже используется в разных местах приложения)
	 */
	@Input()
	placeholder = '';

	/**
	 * Значение компонента.
	 */
	@Input()
	value = '';

	/**
	 * Подпись к компоненту ввода с возможностью анимации
	 */
	@Input()
	label = '';

	/**
	 * Задает максимальную длину поля ввода.
	 */
	@Input()
	maxLength: number;

	/**
	 * Задает минимальную длину поля ввода.
	 */
	@Input()
	minLength: number;

	/**
	 * Флаг состояния ошибочного ввода
	 */
	@Input()
	errorState = false;

	/**
	 * Флаг недоступности ввода
	 */
	@Input()
	isDisabled = false;

	/**
	 * Уникальный ID для текстового поля
	 */
	@Input()
	uniqID = undefined;

	/**
	 * Защищенное ли поле?
	 */
	@Input()
	secured = true;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие, генерируемое при изменении значения компонента.
	 */
	@Output()
	readonly valueChanged = new EventEmitter<string>();

	/**
	 * Событие, генерируемое при нажатии на кнопку Подтвердить
	 */
	@Output()
	readonly confirm = new EventEmitter<HTMLInputElement>();

	/**
	 * Событие, генерируемое при установке фокуса на элемент ввода
	 */
	@Output()
	readonly focused = new EventEmitter<Event>();

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Элемент ввода
	 */
	@ViewChild('inputElement', {static: true}) inputElement: ElementRef;

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Унаследованное событие от базового класса.
	 * Вызывается в коллбеке при изменении значения UI-элемента
	 * @param value Передаваемое значение
	 */
	private onChange = (value: string) => {};

	/**
	 * Унаследованное событие от базового класса.
	 */
	private onTouched = () => {};

	/**
	 * Слушатель события ввода текста на элементе ввода.
	 *
	 * @param {Event} event Передаваемое событие
	 */
	onInputHandler(event: Event): void {
		const newValue = (event.target as HTMLInputElement).value;
		if (!newValue) {
			this.secured = true;
		}
		this.checkAndEmitValueChangeEvent(newValue);
	}

	/**
	 * Слушатель события нажатия клавиши на элементе ввода.
	 * @param event Передаваемое событие
	 */
	onKeyDownHandler(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.confirm.emit(event.target as HTMLInputElement);
		}
	}

	/**
	 * Слушатель события установки фокуса на элементе ввода.
	 * @param event Передаваемое событие
	 */
	onFocus(event: Event): void {
		this.focused.emit(event);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Проверяет, изменилось ли значение поля ввода и, если да, генерирует событие valueChanged.
	 * @param newValue Новое значение поля ввода
	 * @private
	 */
	private checkAndEmitValueChangeEvent(newValue: string): void {
		if (this.value !== newValue) {
			this.value = newValue;
			this.onChange(this.value);
			this.valueChanged.emit(this.value);
		}
	}

	// -----------------------------
	//  MslBaseInput
	// -----------------------------
	/**
	 * Устанавливает фокус на элемент ввода
	 */
	setFocus(): void {
		(this.inputElement.nativeElement as HTMLInputElement).focus();
	}

	// -----------------------------
	//  ControlValueAccessor
	// -----------------------------
	/**
	 * Метод, который вызывается при изменении значения UI-элемента
	 * @param fn Передаваемая callback-функция
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Метод, который вызывается при касании к UI-элементу
	 * @param fn Передаваемая callback-функция
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	/**
	 * Метод для установки недоступности компонента
	 * @param isDisabled Флаг недоступности
	 */
	setDisabledState(isDisabled: boolean): void {
	}

	/**
	 * Метод для программного присвоения значения компоненту
	 * @param obj Значение
	 */
	writeValue(obj: any): void {
		this.value = obj;
	}

	// -----------------------------
	//  Validator
	// -----------------------------
	/**
	 * Регистрирует функцию обратного вызова для выполнения при изменении входных данных валидатора
	 * @param fn Функция обратного вызова
	 */
	registerOnValidatorChange(fn: () => void): void {
	}

	/**
	 * Валидация компонента
	 * @param control Компонент
	 */
	validate(control: AbstractControl): ValidationErrors | null {
		return control.valid ? undefined : control.errors;
	}

	/**
	 * Обработчик события клика по иконке "Показать пароль"
	 */
	onEyeIconClickHandler(): void {
		if (!!this.value) {
			this.secured = !this.secured;
		}
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
}
