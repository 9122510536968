import { ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IDropdownListItem } from '@app/shared/components/drop-down-list/drop-down-list.component';

/**
 * Конфигурация кнопки диалогово окна.
 */
export interface IDialogBindButton {
	/**
	 * Текст кнопки.
	 */
	text: string;

	/**
	 * Функция, которая будет вызвана при нажатии на кнопку.
	 * @param v Значение, которое будет передано в функцию.
	 */
	click?(v?): void;
}

/**
 * Ориентации кнопок диалогового окна.
 */
export enum ButtonsOrientation {
	/**
	 * Горизонтальная ориентация.
	 */
	Horizontal = 0,
	/**
	 * Вертикальная ориентация.
	 */
	Vertical = 1
}

/**
 * Конфигурация поведения диалогового окна.
 */
export interface DialogConfig {
	/**
	 * Плавное появление диалогового окна.
	 */
	smooth?: boolean;
	/**
	 * Признак того, что диалоговое окно должно быть одно на экране.
	 */
	singleTop?: boolean;
	/**
	 * Спрятать диалоговое окно при клике на любую область экрана.
	 */
	hideOnClick?: boolean;

	/**
	 * Время в миллисекундах, после которого автоматически нажмется первая кнопка (и выполнится соответствующий код).
	 * Если время не задано, то таймер создаваться не будет.
	 */
	firstButtonAutoClickTimeOut?: number;

	/**
	 * Время в миллисекундах, после которого автоматически нажмется вторая кнопка (и выполнится соответствующий код).
	 * Если время не задано, то таймер создаваться не будет.
	 */
	secondButtonAutoClickTimeOut?: number;

	/**
	 * Ориентация кнопок в диалоговом окне.
	 */
	buttonsOrientation?: ButtonsOrientation;

	/**
	 * Если задано, то диалог прикрепляется к верху на top пикселей
	 */
	top?: number;
}

/**
 * Интерфейс для контейнера диалоговых окон.
 */
export interface IDialogContainer {
	/**
	 * Убрать все диалоговые окна.
	 */
	clean(): boolean;
}

/**
 * Интерфейс диалогового окна (с которым работают элементы UI).
 */
export interface IDialog {
	/**
	 * Скрыть диалоговое окно.
	 */
	hide(): boolean;
}

/**
 * Интерфейс для конфигурации двухкнопочного диалогового окна.
 */
export interface IDialogBindButtons {
	/**
	 * Первая кнопка.
	 */
	first?: IDialogBindButton;
	/**
	 * Вторая кнопка.
	 */
	second?: IDialogBindButton;
}

/**
 * Интерфейс базовой модели диалогового окна.
 */
export interface IDialogComponent {
	/** Заголовок сообщения. */
	title: string;

	/** Тело сообщения. */
	message: string;

	/**
	 * Скрыть ли диалоговое окно по клику на любую область экрана.
	 */
	hide: boolean;

	/**
	 * Ссылка на контейнер диалогового окна.
	 */
	container: IDialogContainer;

	/**
	 * Кнопки диалогового окна.
	 */
	buttons?: IDialogBindButtons;

	/** Признак информационного диалога. Если false - это диалог с ошибкой. */
	isInfoDialog: boolean;
}

/**
 * Интерфейс для диалогового окна с ошибкой.
 */
export interface IErrorDialogComponent extends IDialogComponent {
	/**
	 * Код ошибки.
	 */
	code?: number;
}

/**
 * Модель компонента диалогового окна с ошибкой.
 */
export class ErrorDialogComponent implements IErrorDialogComponent {
	/** Заголовок сообщения. */
	title: string;

	/** Тело сообщения. */
	message: string;

	/** Детализация сообщения. */
	messageDetails: string;

	/**
	 * Скрыть ли диалоговое окно по клику на любую область экрана.
	 */
	hide: boolean;

	/**
	 * Код ошибки.
	 */
	code: number;

	/**
	 * Перевод кода ошибки на человекочитаемый текст.
	 */
	code_translate: string;

	/**
	 * Ссылка на контейнер диалогового окна.
	 */
	container: IDialogContainer;

	/**
	 * Дополнительная информация.
	 */
	extra?: string;

	/**
	 * Кнопки диалогового окна.
	 */
	buttons?: IDialogBindButtons;

	/** Признак информационного диалога. Если false - это диалог с ошибкой. */
	isInfoDialog: boolean;

	/** Конфигурация поведения диалогового окна. */
	config: DialogConfig;

	/**
	 * Конструктор.
	 *
	 * @param {ChangeDetectorRef} changeDetector Ссылка на детектор изменений.
	 */
	constructor(
		public changeDetector: ChangeDetectorRef
	) {}

	/**
	 * Обработчик клика по первой кнопке.
	 */
	clickFirst(): void {
		if (this.hide) {
			this.container.clean();
		}

		if (this.buttons && this.buttons.first && this.buttons.first.click) {
			this.buttons.first.click();
		}
	}

	/**
	 * Обработчик клика по второй кнопке.
	 */
	clickSecond(): void {
		if (this.hide) {
			this.container.clean();
		}

		if (this.buttons && this.buttons.second && this.buttons.second.click) {
			this.buttons.second.click();
		}
	}
}

/**
 * Модель компонента диалогового окна с двумя кнопками и выпадающим списком.
 */
export class DialogWithDropdown extends ErrorDialogComponent {
	/**
	 * Список элементов типа {@link IDropdownListItem}, которые необходимо показать.
	 */
	itemsList: Array<IDropdownListItem>;

	/**
	 * Элемент, который будет показан по умалчанию.
	 */
	defaultItem: IDropdownListItem;

	/**
	 * Наблюдаемый объект в который будет возвращен результат выбора - элемент типа {@link IDropdownListItem}.
	 */
	result: Subject<IDropdownListItem>;
}

/**
 * Диалоговое окно.
 */
export class Dialog implements IDialog {

	/**
	 * Конструктор диалогового окна.
	 * @param container Ссылка на контейнер диалогового окна.
	 */
	constructor(
		private container: IDialogContainer
	) {}

	/**
	 * Скрыть диалоговое окно.
	 */
	hide(): boolean {
		if (this.container) {
			if (this.container.clean()) {
				this.container = undefined;

				return true;
			}
		}

		return false;
	}
}

/**
 * Диалоговое окно для отображения прогресса при выполнении транзакции.
 */
export class TransactionInfoComponent extends ErrorDialogComponent {
	/**
	 * Наблюдаемый объект, в котором будет прогресс выполнения транзакции.
	 */
	progress$$ = new BehaviorSubject<number>(0);
	/**
	 * Наблюдаемый объект, в котором будет количество загруженных изображений.
	 */
	imageCounter$$ = new BehaviorSubject<number>(0);
	/**
	 * Наблюдаемый объект, в котором будет дополнительная информация.
	 */
	extraMessage$$  = new BehaviorSubject<string>(undefined);
}

/**
 * Класс диалогового окна для отображения прогресса при выполнении транзакции.
 */
export class TransactionDialog extends Dialog {

	/**
	 * Конструктор класса.
	 * @param dialogComponent Ссылка на компонент диалогового окна.
	 */
	constructor(
		readonly dialogComponent: TransactionInfoComponent
	) {
		super(dialogComponent.container);
	}
}
