import { Stack } from '@app/util/stack';
import { ErrorCode } from '@app/core/error/dialog';
import { AppError, IError } from '@app/core/error/types';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { ReportSections, ReportViewType } from '@app/core/services/report/report';
import { PrintData, Text } from '@app/core/net/ws/api/models/print/print-models';
import {
	AccessibilityTag,
	AccessLevelTag, AccessListTag,
	CaptionTag,
	InputTag,
	ITagInit,
	OutputTag,
	PrinterTag,
	ReportingTag,
	ReportTag,
	ScreenTag,
	SectionTag,
	Tag
} from '@app/core/services/report/tags';
import { barcode } from 'pure-svg-code';
import { checkSum } from '@app/core/barcode/barcode-reader.service';

/**
 * Класс, предоставляющий функционал по обработке файла шаблона отчетов полученного из ЦС.
 * Основное назначение подготовка (форматирование) отчетов к печати или выводу на печать
 */
export class ReportParser {

	/**
	 * Стек тегов.
	 * @private
	 */
	private readonly flowStack: Stack<Tag> = new Stack<Tag>();

	/**
	 * Тег отчета.
	 * @private
	 */
	private _reporting: ReportingTag;

	/**
	 * Возникшая ошибка обработки шаблона отчета.
	 */
	private error: IError;

	/**
	 * Функция, которая возвращает блок с SVG-картинкой штрих-кода.
	 * @param svgPic SVG-картинка штрих-кода.
	 * @param bc Штрих-код.
	 * @private
	 */
	private static svgBlock(svgPic: string, bc: string): string {
		return `<div class="svg-result"><div class="svg-result__title">${bc}</div>${svgPic}</div>`;
	}

	/**
	 * Подготовка к печати (форматирование) обработанного шаблона отчета на основании ответа ЦС, на запрос по отчету.
	 *
	 * @param {ReportTag} report Обработанный шаблон отчета.
	 * @param {string} data Данные полученные из ЦС (как часть ответа на запрос отчета).
	 * @param {ReportViewType} type Тип вывода отчета: экран или печать.
	 * @param params Дополнительные параметры.
	 * @returns {string | Array<PrintData>} Данные отчета в зависимости от типа вывода.
	 */
	formatReport(report: ReportTag, data: string, type: ReportViewType, params?: any): string | Array<PrintData> {
		if (report.id === '23') {
			return this.formatReport23(report, data, type, params);
		}
		if (report.id === '22') {
			return this.formatReport22(report, data, type);
		}
		const reportData = new ReportSections(data);
		let formattedReport = '';

		if (type === 'printer') {
			for (const dataSection of reportData.sections) {
				const viewSection = report.output.printer.sections
					.find(p => dataSection.num === Number.parseInt(p.number, 10));
				if (viewSection) {
					const res = viewSection.formatText(dataSection.val);
					if (res) {
						formattedReport = formattedReport ? formattedReport.concat(res) : res;
					}
				} else {
					const viewSection2 = report.output.printer.sections
						.find(p => !isNaN(dataSection.num) && dataSection.num + 9000 === Number.parseInt(p.number, 10));
					if (viewSection2) {
						const res2 = viewSection2.formatText(dataSection.val);
						if (res2) {
							formattedReport = formattedReport ? formattedReport.concat(res2) : res2;
						}
					}
				}
			}

			Logger.Log.d('ReportParser', 'formattedReport: %s', formattedReport)
				.console();

			const print: Array<PrintData> = [];
			const lines = formattedReport.split('#n');
			for (const line of lines) {
				if (line) {
					const format = new Text(`${line}\n`);
					print.push(format);
				}
			}
			Logger.Log.d('ReportParser', 'print data: %s', print)
				.console();

			if (report.id === '7') {
				print.push(new Text(` \n`));
				print.push(new Text(` \n`));
			}

			return print;
		}

		if (type === 'screen') {
			for (const dataSection of reportData.sections) {
				if (isNaN(dataSection.num)) {
					if (dataSection.val[0]) {
						formattedReport = formattedReport.concat(dataSection.val[0]);
					}
				} else {
					const viewSection = report.output.screen.sections
						.find(p => dataSection.num === Number.parseInt(p.number, 10));
					if (viewSection) {
						const res = viewSection.formatText(dataSection.val);
						if (res) {
							formattedReport = formattedReport ? formattedReport.concat(res) : res;
						}
					}
				}
			}

			Logger.Log.d('ReportParser', 'formattedReport: %s', formattedReport)
				.console();

			return formattedReport;
		}
	}

	/**
	 * Подготовка к печати (форматирование) обработанного шаблона отчета на основании ответа ЦС, на запрос по отчету №22.
	 * Выводит часть отчета на экран.
	 *
	 * @param {ReportTag} report Обработанный шаблон отчета.
	 * @param {string} data Данные полученные из ЦС (как часть ответа на запрос отчета).
	 * @param {ReportViewType} type Тип вывода отчета: экран или печать.
	 */
	formatReport22(report: ReportTag, data: string, type: ReportViewType): string | Array<PrintData> {
		const reportData = new ReportSections(data);
		let formattedReport = '';

		if (type === 'printer') {
			for (const dataSection of reportData.sections) {
				const viewSection = report.output.printer.sections
					.find(p => dataSection.num === Number.parseInt(p.number, 10));
				if (viewSection) {
					const res = viewSection.formatText(dataSection.val);
					if (res) {
						formattedReport = formattedReport ? formattedReport.concat(res) : res;
					}
				}
			}

			Logger.Log.d('ReportParser', 'formattedReport: %s', formattedReport)
				.console();

			const print: Array<PrintData> = [];
			const lines = formattedReport.split('#n');
			for (const line of lines) {
				if (line) {
					const format = new Text(`${line}\n`);
					print.push(format);
				}
			}
			Logger.Log.d('ReportParser', 'print data: %s', print)
				.console();

			return print;
		}

		if (type === 'screen') {
			for (const dataSection of reportData.sections) {
				if (isNaN(dataSection.num)) {
					if (dataSection.val[0]) {
						formattedReport = formattedReport.concat(dataSection.val[0]);
					}
				} else {
					const viewSection = report.output.screen.sections
						.find(p => dataSection.num === Number.parseInt(p.number, 10));
					if (viewSection) {
						let res = viewSection.formatText(dataSection.val);
						if (res) {
							console.log('dataSection =', dataSection);
							if ((dataSection.num === 95) && (dataSection.val[1] === '5')) {
								res += `<div class="shortfall-btn-area" id="shortfall-btn-area"></div>`;
							}
							formattedReport = formattedReport ? formattedReport.concat(res) : res;
						}
					}
				}
			}

			Logger.Log.d('ReportParser', 'formattedReport: %s', formattedReport)
				.console();

			return formattedReport;
		}
	}

	/**
	 * Подготовка к печати (форматирование) обработанного шаблона отчета на основании ответа ЦС, на запрос по отчету №22.
	 * Выводит весь отчет на экран.
	 *
	 * @param {ReportTag} report Обработанный шаблон отчета.
	 * @param {string} data Данные полученные из ЦС (как часть ответа на запрос отчета).
	 * @param {ReportViewType} type Тип вывода отчета: экран или печать.
	 */
	formatReport22_2(report: ReportTag, data: string, type: ReportViewType): string | Array<PrintData> {
		const reportData = new ReportSections(data);
		let formattedReport = '';
		let bcStart = '';
		let bcEnd = '';
		let rangeStartFlag = false;
		let bcGraphical = false;
		let hiddenSection = false;
		let bc13: string;
		let fullBC: string;
		let rawSVG: string;
		let svgCodes = '';
		console.log('reportData =', reportData);

		if (type === 'printer') {
			for (const dataSection of reportData.sections) {
				const viewSection = report.output.printer.sections
					.find(p => dataSection.num === Number.parseInt(p.number, 10));
				if (viewSection) {
					const res = viewSection.formatText(dataSection.val);
					if (res) {
						formattedReport = formattedReport ? formattedReport.concat(res) : res;
					}
				}
			}

			Logger.Log.d('ReportParser', 'formattedReport: %s', formattedReport)
				.console();

			const print: Array<PrintData> = [];
			const lines = formattedReport.split('#n');
			for (const line of lines) {
				if (line) {
					const format = new Text(`${line}\n`);
					print.push(format);
				}
			}
			Logger.Log.d('ReportParser', 'print data: %s', print)
				.console();

			return print;
		}

		if (type === 'screen') {
			for (const [i, dataSection] of reportData.sections.entries()) {
				if (isNaN(dataSection.num)) {
					if (dataSection.val[0]) {
						formattedReport = formattedReport.concat(dataSection.val[0]);
					}
				} else {
					const viewSection = report.output.screen.sections
						.find(p => dataSection.num === Number.parseInt(p.number, 10));
					if (viewSection) {
						let res = viewSection.formatText(dataSection.val);
						if (res) {

							// Если пошла секция нехватки билетов, то выводим ее в виде графических штрих-кодов
							if (dataSection.num === 95) {
								bcGraphical = dataSection.val[1] === '5';
								hiddenSection = !bcGraphical;
							}

							if (bcGraphical) {
								svgCodes = '';

								// если что-то появилось
								// но не факт что штрих-коды
								// проверяем первый на формат
								if (dataSection.num === 98) {
									res = '';
									// Попытка разбить на штрих-коды с помощью запятых
									const bcs = dataSection.val[1] ? dataSection.val[1].split(',') : [];
									const bcFirst = bcs[0].split('')
										.filter(s => !isNaN(Number(s)))
										.join('');
									// если их несколько через запятую
									if (bcs.length > 1) {
										// добавить каждый
										for (const oneBC of bcs) {
											fullBC = oneBC.split('')
												.filter(s => !isNaN(Number(s)))
												.join('');
											rawSVG = barcode(fullBC, 'code128');
											svgCodes += ReportParser.svgBlock(rawSVG, fullBC);
										}
										res = svgCodes;
									} else {
										// если он 1, то он может быть как одиночным, так и началом диапазона
										// если есть троеточие в конце, то это начало диапазона
										if (/^.+\.\.\.$/.test(bcs[0])) {
											bcStart = bcs[0].split('')
												.filter(s => !isNaN(Number(s)))
												.join('')
												.substr(0, 13);
											// открываем диапазон
											rangeStartFlag = true;
										} else {
											// просто одиночный штрих-код, добавляем
											fullBC = bcs[0].split('')
												.filter(s => !isNaN(Number(s)))
												.join('');
											rawSVG = barcode(fullBC, 'code128');
											svgCodes += ReportParser.svgBlock(rawSVG, fullBC);
											res = svgCodes;
										}
									}
								}
								// закрытие диапазона
								if (dataSection.num === 97) {
									// был открыт диапазон
									bcEnd = dataSection.val[1].split('')
										.filter(s => !isNaN(Number(s)))
										.join('')
										.substr(0, 13);
									// первые 10 цифр
									const first10 = bcStart.substr(0, 10);
									// стартовый номер
									const tnStart = parseInt(bcStart.substr(10, 3), 10);
									// конечный номер
									const tnEnd = parseInt(bcEnd.substr(10, 3), 10);
									// формируем штрихкоды
									for (let tn = tnStart; tn <= tnEnd; tn++) {
										bc13 = first10 + tn.toString()
											.padStart(3, '0');
										fullBC = bc13 + checkSum(bc13);
										rawSVG = barcode(fullBC, 'code128');
										svgCodes += ReportParser.svgBlock(rawSVG, fullBC);
									}
									bcStart = '';
									bcEnd = '';
									// закрыть диапазон
									rangeStartFlag = false;
									res = svgCodes;
								}
							}

							if (dataSection.num === 96 && bcGraphical) {
								res = `<div class="inv-not-bc">${res}</div>`;
							}

							res = hiddenSection ? '' : res;

							formattedReport = formattedReport ? formattedReport.concat(res) : res;
						}
					}
				}
			}

			Logger.Log.d('ReportParser', 'formattedReport: %s', formattedReport)
				.console();

			return formattedReport;
		}
	}

	/**
	 * Подготовка к печати (форматирование) обработанного шаблона отчета на основании ответа ЦС, на запрос по отчету №23.
	 *
	 * @param {ReportTag} report Обработанный шаблон отчета.
	 * @param {string} data Данные полученные из ЦС (как часть ответа на запрос отчета).
	 * @param {ReportViewType} type Тип вывода отчета: экран или печать.
	 * @param params Дополнительные параметры.
	 * @returns {string | Array<PrintData>} Данные отчета в зависимости от типа вывода.
	 */
	formatReport23(report: ReportTag, data: string, type: ReportViewType, params?: any): string | Array<PrintData> {
		// const patchedData = type === 'screen' ? data.replace('                   ', ', ') : data;
		const patchedData = data;
		const reportData = new ReportSections(patchedData);
		let formattedReport = '';

		if (type === 'printer') {
			for (const dataSection of reportData.sections) {
				const viewSection = report.output.printer.sections
					.find(p => dataSection.num === Number.parseInt(p.number, 10));
				if (viewSection) {
					const res = viewSection.formatText(dataSection.val);
					if (res) {
						formattedReport = formattedReport ? formattedReport.concat(res) : res;
					}
				}
			}

			Logger.Log.d('ReportParser', 'formattedReport: %s', formattedReport)
				.console();

			const print: Array<PrintData> = [];
			const lines = formattedReport.split('#n');
			for (const line of lines) {
				if (line) {
					const format = new Text(`${line}\n`);
					print.push(format);
				}
			}
			Logger.Log.d('ReportParser', 'print data: %s', print)
				.console();

			return print;
		}

		if (type === 'screen') {
			let i = 0;
			formattedReport = `<h2 class="report-header">${params.report23Title}</h2>`;
			while (i < reportData.sections.length) {
				if (isNaN(reportData.sections[i].num)) {
					let serieInfo = '';
					let qInfo = '';
					if (reportData.sections[i + 2].val[1]) {
						const parts = reportData.sections[i + 2].val[1].split(/\s{5,}/);
						serieInfo = parts[0];
						qInfo = parts[1] || '';
					}
					formattedReport += `<div class="line-2-col">
						<div class="line-2-col__col_1"><span class="text-bold">${reportData.sections[i + 1].val[1] || ''}</span> ${serieInfo}</div>
						<div class="line-2-col__col_2">${qInfo}</div>
					</div>`;
					i += 3;
				} else {
					if (i === 0) {
						formattedReport += `<div class="line-1-col"><b>${params.report23Operator}: </b>${reportData.sections[i].val[1] || ''}</div>`;
						formattedReport += `<div class="line-1-col"><b>${params.report23Date}: </b>${reportData.sections[i].val[2] || ''}</div>`;
					} else if (i === reportData.sections.length - 1) {
						formattedReport += `<div class="line-1-col line-1-col_total"><b>${params.report23Total}:</b> ${reportData.sections[i].val[1] || ''}</div>`;
					} else {
						formattedReport += `<div class="line-1-col">${reportData.sections[i].val[1] || ''}</div>`;
					}
					i++;
				}
			}

			Logger.Log.d('ReportParser', 'formattedReport: %s', formattedReport)
				.console();

			return formattedReport;
		}
	}


	/**
	 * Парсинг файла шаблона отчетов.
	 * {@link https://confluence.emict.net/pages/viewpage.action?pageId=9404565}
	 *
	 * @param {Document} doc Шаблон отчета в формате XML.
	 * @returns {IError} Ошибка при обработке шаблона.
	 */
	parseTemplate(doc: Document): IError {
		const reporting = doc.getElementsByTagName(Tag.REPORTING)[0];

		const reportingTag = new ReportingTag();
		this._reporting = new ReportingTag();
		Tag.init(reportingTag, reporting.attributes);
		Tag.init(this._reporting, reporting.attributes);

		this.flowStack.push(reportingTag);

		for (let i = 0; i < reporting.childNodes.length; i++) {
			const node = reporting.childNodes.item(i);
			if (node.nodeType === 1) {
				try {
					this.processNode(node);
				} catch (e) {
					while (!(this.flowStack.peek() instanceof ReportingTag)) {
						const tag = this.flowStack.peek();
						if (tag instanceof ReportTag) {
							tag.broken = true;
						}
						this.flowStack.pop();
					}
					this.error = new AppError(e.message ? e.message : 'error while processing report', ErrorCode.ParseReport);
				}
			}
		}

		for (const tag of reportingTag.reports) {
			if (!tag.broken) {
				this._reporting.reports.push(tag);
			}
		}

		return this.error;
	}

	/**
	 * Возвращает контейнер шаблонов отчетов.
	 *
	 * @returns {ReportingTag}
	 */
	get reporting(): ReportingTag {
		return this._reporting;
	}

	/**
	 * Обрабатывает xml теги файла отчета заполняя модель секции отчета данными шаблона.
	 *
	 * @param {NodeList} list Список тегов.
	 */
	private parseReports(list: NodeList): void {
		for (let i = 0; i < list.length; i++) {
			const node = list.item(i);

			if (!this.flowStack.isEmpty()) {
				const unit = this.flowStack.peek();
				if (unit instanceof SectionTag) {
					if (node.nodeType === 3) {
						unit.setPrinterText(node.nodeValue);
					}
					if (node.nodeType === 8) {
						unit.setScreenText(node.nodeValue);
					}
				}
			}

			if (node.nodeType === 1) {
				this.processNode(node);
			}
		}
	}

	/**
	 * Обрабатывает XML теги и создает структуры для хранения и обработки содержимого и атрибутов данных тегов.
	 *
	 * @param {Node} node Тег.
	 */
	private processNode(node: Node): void {
		let tag;
		switch (node.nodeName) {
			case Tag.REPORT:
				tag = ReportTag;
				break;
			case Tag.ACCESSIBILITY:
				tag = AccessibilityTag;
				break;
			case Tag.ACCESS_LEVEL:
				tag = AccessLevelTag;
				break;
			case Tag.ACCESS_LIST:
				tag = AccessListTag;
				break;
			case Tag.INPUT:
				tag = InputTag;
				break;
			case Tag.CAPTION:
				tag = CaptionTag;
				break;
			case Tag.OUTPUT:
				tag = OutputTag;
				break;
			case Tag.SCREEN:
				tag = ScreenTag;
				break;
			case Tag.PRINTER:
				tag = PrinterTag;
				break;
			case Tag.SECTION:
				tag = SectionTag;
				break;
			default:
				if (node.hasChildNodes()) {
					this.parseReports(node.childNodes);
				}

				return;
		}
		this.processTag(tag, node);
	}

	/**
	 * Обрабатывает XML тег, создает структуры для хранения и обработки содержимого и атрибутов.
	 *
	 * @param T тип тега
	 * @param {Node} node элемент тега
	 */
	private processTag(T, node: Node): void {
		const tag: ITagInit = new T();
		Tag.init(tag, node['attributes']);

		const parent = this.flowStack.peek();
		const children = parent[tag.parent_tag];
		if (Array.isArray(children)) {
			children.push(tag);
		} else {
			parent[tag.parent_tag] = tag;
		}

		if (node.hasChildNodes()) {
			this.flowStack.push(tag);
			this.parseReports(node.childNodes);
			this.flowStack.pop();
		}
	}

}
