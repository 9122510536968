import { convertObjectToString } from '@app/util/utils';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { init, Tag } from '@app/tickets-print/parser/tag';
import { IGetParam } from '@app/tickets-print/parser/iget-param';

/**
 * Множественные ли соответствия?
 */
type Multiple = 'yes' | 'no';

/**
 * Модель xml тега <convert>.
 * Данные из ответа ЦС не всегда содержат готовые к печати значения параметров,
 * как это предусмотрено дизайном билета.
 * Для приведения данных к требуемому виду шаблон может включать директивы конвертации.
 */
export class ConvertTag extends Tag {

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Обязательный параметр.
	 * Имя тэгов в текущей секции, которые подлежат преобразованию.
	 * Входным параметром для функции преобразования является текст внутри этого тэга.
	 * Если указанных тэгов в секции несколько, функция преобразования будет применена к каждому из них.
	 */
	private readonly intag: string;

	/**
	 * Тип значения, к которому приводится входная строка. Сейчас поддерживается только "string".
	 */
	private readonly intype = 'str';

	/**
	 * Тип значения, к которому приводится выходная строка.
	 */
	private readonly outtype = 'str';

	/**
	 * Обязательный параметр.
	 * Имя тэга, который будет создан внутри текущей секции с результатом операции.
	 * Может содержать несколько строк, разделенных точками, - в этом случае будут
	 * созданы соответствующие вложенные тэги.
	 * Создаваемый тэг с результатом операции никогда не заменяет существующие с таким же именем.
	 * Новый тэг всегда добавляется в конец секции.
	 */
	private readonly outtag: string;

	/**
	 * Длина подстрок, на которые разделяется строка.
	 * По умолчанию "1".
	 */
	private readonly outsize = '1';

	/**
	 * При выделении подстрок по регулярному выражению может указывать номер сегмента в регулярном выражении,
	 * который определяет результирующую строку.
	 * Если 0, то результатом будет часть строки, соответствующая полному регулярному выражению.
	 * По умолчанию "0".
	 */
	private readonly capture = '0';

	/**
	 * Указывает на поиск только первого соответствия регулярному выражению в строке
	 * либо последовательный поиск всех соответствий.
	 * Возможные значения "yes", "no",
	 * По умолчанию "no".
	 */
	private readonly multiple: Multiple = 'no';

	/**
	 * Регулярное выражение, по которому входная строка разбивается на подстроки.
	 */
	private readonly outsplitre?: string;

	/**
	 * Регулярное выражение, по которому происходит поиск во входной строке.
	 * Подстроки, соответствующие указанному регулярному выражению, являются результатом операции.
	 * В зависимости от атрибута "multiple" это будут либо все подстроки, либо только одна - первая найденная.
	 */
	private readonly outre?: string;

	/**
	 * Строка, которая добавляется в начало каждой выходной подстроки.
	 */
	private readonly prefix?: string;

	/**
	 * Строка, которая добавляется в конец каждой выходной подстроки.
	 */
	private readonly suffix?: string;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Создание и инициализация на основе XML атрибутов.
	 *
	 * @param {IGetParam} param Интерфейс доступа к параметрам.
	 * @param {NamedNodeMap} attributes XML атрибуты.
	 */
	constructor(param: IGetParam, attributes: NamedNodeMap) {
		super();

		init(this, attributes);
		const input = convertObjectToString(param.getParam(this.intag));

		if (input !== undefined && typeof input === 'string') {
			Logger.Log.d('ConvertTag', 'intag: %s, value %s', this.intag, input)
				.console();

			const section = param.getSectionParam();
			let tokens = [];
			if (this.outsplitre) {
				tokens = input.split(new RegExp(this.outsplitre));

				// TODO: проверить этот фикс для ALT-467 (ошибка 700 на "Лас Вегас")
				if (typeof tokens[0] !== 'undefined' && tokens[0] === '') {
					tokens.splice(0, 1);
				}
			} else if (this.outre) {
				let capture = Number.parseInt(this.capture, 10);
				capture = isNaN(capture) ? 0 : capture;
				const regExp = new RegExp(this.outre, 'g');
				let matches: RegExpExecArray;
				do { // TODO: в этом месте зависает "Лабиринт" (ALT-533) ----------
					matches = regExp.exec(input);
					if (matches && matches.index < input.length) {
						tokens.push(matches[capture]);
					} else {
						break;
					}
				} while (true); // while (true); // TODO: fix for ALT-533 ---------
			} else {
				tokens = this.returnChunksArray(input, Number.parseInt(this.outsize, 10));
			}

			const tags = this.outtag.split('.');

			if (tags.length > 0) {
				let array = section[tags[0]];
				if (array !== undefined) {
					if (!Array.isArray(array)) {
						throw new Error(`ConvertTag tag ${tags[0]} doesn't array in section`);
					}
				} else {
					array = section[tags[0]] = [];
				}

				if (tags.length === 1) {
					for (const token of tokens) {
						array.push(this.concatToken(token));
					}
				} else if (tags.length === 2) {
					for (const token of tokens) {
						const element = {};
						element[tags[1]] = this.concatToken(token);
						array.push(element);
					}
				} else {
					throw new Error(`ConvertTag outtag: ${this.intag} empty or has more then 2 level: ${tags.toString()}`);
				}
			} else {
				throw new Error(`ConvertTag outtag undefined`);
			}
		} else {
			throw new Error(`ConvertTag intag doesn't string: ${this.intag}, result:${input}`);
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Добавление префикса или суфикса к подстроке.
	 *
	 * @param {string} token подстроке
	 * @returns {string}
	 */
	private concatToken(token: string): string {
		let value = token;
		if (this.prefix) {
			value = this.prefix + token;
		}

		if (this.suffix) {
			value = token + this.suffix;
		}

		return value;
	}

	/**
	 * Разбивает строку на подстроки.
	 *
	 * @param {string} srcStr Строка.
	 * @param {number} size Длина подстрок.
	 * @returns {Array<string>}
	 */
	private returnChunksArray(srcStr: string, size: number): Array<string> {
		let str = srcStr;
		const arr = [];
		while (str !== '') {
			arr.push(str.slice(0, size));
			str = str.slice(size);
		}

		return arr;
	}
}
