import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { ApiClient } from '@app/core/net/http/api/api-client';
import { IResponse } from '@app/core/net/http/api/types';

// -----------------------------
//  Request
// -----------------------------

/**
 * Модель запроса для вызова API BOChangePassword.
 */
export class BOChangePasswordReq extends ApiClient {

	/**
	 * Конструктор запроса.
	 *
	 * @param {AppStoreService} appStoreService Централизованное хранилище данных.
	 * @param {string} login Логин пользователя
	 * @param {string} passwd Текущий пароль пользователя
	 * @param {string} newpasswd Новый пароль пользователя
	 */
	constructor(
		appStoreService: AppStoreService,
		login: string,
		passwd: string,
		newpasswd: string
	) {
		super(appStoreService, appStoreService.Settings.getEsapActionUrl(LotteryGameCode.Undefined, EsapActions.BOChangePassword));

		this.params.append(EsapParams.LOGIN, login);
		this.params.append(EsapParams.PASSWD, passwd);
		this.params.append(EsapParams.NEWPASSWD, newpasswd);
	}

}

// -----------------------------
//  Response
// -----------------------------

/**
 * Модель ответа на запрос API BOAuthenticate
 */
export interface BOChangePasswordResp extends IResponse {
	/**
	 * Идентификатор клиента.
	 */
	client_id: string;
	/**
	 * Идентификатор транзакции.
	 */
	client_trans_id: string;
	/**
	 * Код ошибки.
	 */
	err_code: number;
	/**
	 * Описание ошибки.
	 */
	err_descr: string;
}
