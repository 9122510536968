import { DatePipe } from '@angular/common';
import {
	AfterContentChecked,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { CSDateToRusLocale, DATE_TEMPLATE_DD_MM_YYYY_HH_MM, DATE_TEMPLATE_DD_MM_YYYY_HH_MM_SS } from '@app/util/utils';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { IDrawingResult } from '@app/core/net/http/api/models/get-draw-results';
import { IAbstractViewResults, IGameResultsService, IResultPrintItem, PrintResultTemplate } from '@app/core/services/results/results-utils';
import { CalendarTheme } from '@app/calendar/enums/calendar-theme.enum';
import { ButtonGroupMode, ButtonGroupStyle } from '../buttons-group/buttons-group.component';
import { DrawsButtonsComponent } from '../draws-buttons/draws-buttons.component';
import { IPrintButtonAction } from '../print-button/print-button.component';
import { URL_INIT } from '@app/util/route-utils';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { DialogError } from '@app/core/error/dialog';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { AppType } from '@app/core/services/store/settings';

/**
 * Список кнопок, используемых для навигации по результатам.
 * - {@link Previous} - перейти на предыдущие результаты.
 * - {@link Next} - перейти на будущие результаты.
 */
export enum ResultNavigationDirection {
	Previous = 1,
	Next = -1
}

/**
 * Компонент для навигации по результатам тиражей.
 */
@Component({
	selector: 'app-results-navigator',
	templateUrl: './results-navigator.component.html',
	styleUrls: ['./results-navigator.component.scss']
})
export class ResultsNavigatorComponent implements OnInit, AfterContentChecked, OnDestroy {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Ссылка на сервис обработки результатов.
	 */
	@Input()
	service: IGameResultsService;

	/**
	 * Код игры.
	 */
	@Input()
	gameCode: LotteryGameCode;

	/**
	 * Имя игры.
	 */
	@Input()
	gameName: string;

	/**
	 * Кастомный XML-шаблон, который будет распечатан на термопринтере.
	 * По умолчанию это - {@link PrintResultTemplate}.
	 */
	@Input()
	printTemplate = PrintResultTemplate;

	/** Данные для печати в XML-шаблоне. */
	@Input()
	printData: Array<IResultPrintItem>;

	/**
	 * Геттер модели для отображения общей информации по результатам на вьюхах.
	 */
	@Input()
	get viewResults(): IAbstractViewResults {
		return this._viewResults;
	}

	/**
	 * Сеттер модели для отображения общей информации по результатам на вьюхах.
	 * @param v Информация по результатам.
	 */
	set viewResults(v: IAbstractViewResults) {
		if (v) {
			this._viewResults = {...v, drawDate: CSDateToRusLocale(v.drawDate, this.showSeconds)};
		}
	}

	/**
	 * Признак, указывающий на необходимость отображать секунды на компоненте {@link DrawsButtonsComponent}.
	 */
	@Input()
	showSeconds = false;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие, которое генерируется при выборе результата тиража.
	 */
	@Output()
	readonly resultSelected = new EventEmitter<IDrawingResult>();

	/**
	 * Событие, которое генерируется при выборе игры.
	 */
	@Output()
	readonly lotteryChanged = new EventEmitter<LotteryGameCode>();

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Перечисление режимов работы в компоненте группы кнопок.
	 */
	readonly ButtonGroupMode = ButtonGroupMode;

	/**
	 * Перечисление стилей компонента группы кнопок.
	 */
	readonly ButtonGroupStyle = ButtonGroupStyle;

	/**
	 * Длинный формат даты.
	 */
	readonly longDate = DATE_TEMPLATE_DD_MM_YYYY_HH_MM_SS;

	/**
	 * Короткий формат даты.
	 */
	readonly shortDate = DATE_TEMPLATE_DD_MM_YYYY_HH_MM;

	/**
	 * Перечисление направлений навигации по результатам (Вперед/Назад)
	 */
	readonly ResultNavigationDirection = ResultNavigationDirection;

	/**
	 * Перечисление кодов игр.
	 */
	readonly LotteryGameCode = LotteryGameCode;

	/**
	 * Перечисление тем календаря.
	 */
	readonly calendarTheme = CalendarTheme;

	/**
	 * Путь к начальной страницы любой игры.
	 */
	readonly Init = `../${URL_INIT}`;

	/**
	 * Тип приложения.
	 */
	readonly AppType = AppType;

	/**
	 * Показывать ли результаты.
	 */
	showResultsContent = false;

	/**
	 * Деактивирована ли кнопка "Печать".
	 */
	printResultsDisabled = true;

	/**
	 * Признак, указывающий, что нет точного совпадения даты для отображения результатов.
	 */
	notExactFilterDate = false;

	/**
	 * Список результатов.
	 */
	resultsList: Array<IDrawingResult>;

	/**
	 * Класс для отображения переключателя режима отображения результатов.
	 */
	pagePart = 'switchers_draws';

	/**
	 * Ссылка на компонент группы кнопок.
	 */
	@ViewChild(DrawsButtonsComponent, { static: false })
	drawsButtonsComponent: DrawsButtonsComponent;

	/**
	 * Ссылка на левую панель.
	 */
	@ViewChild('aside', {static: false})
	aside: ElementRef;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	/**
	 * Текущая страница.
	 * @private
	 */
	private currentPage = 1;

	/**
	 * Объект, содержащий информацию по результатам.
	 * @private
	 */
	private _viewResults: IAbstractViewResults = null;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {DatePipe} datePipe Пайп для форматирования даты.
	 * @param {DialogContainerService} dialogInfoService Сервис для отображения диалоговых окон.
	 * @param {AppStoreService} appStoreService Сервис для работы с хранилищем приложения.
	 */
	constructor(
		private readonly datePipe: DatePipe,
		private readonly dialogInfoService: DialogContainerService,
		readonly appStoreService: AppStoreService
	) {}

	/**
	 * Обработчик событий {@link printAction} кнопки "ПЕЧАТЬ".
	 *
	 * @param {IPrintButtonAction} printButtonItem Объект, отправляемый при генерации события.
	 */
	onClickPrintResultsHandler(printButtonItem: IPrintButtonAction): void {
		if (this.gameCode) {
			this.service.printLastResults({
				...printButtonItem,
				printTemplate: this.printTemplate,
				printData: this.printData
			});
		}
	}

	/**
	 * Обработчик выбранной даты.
	 *
	 * @param {Date} selectedDate Выбранная дата.
	 */
	onDateSelectedHandler(selectedDate: Date): void {
		if (this.gameCode) {
			this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.loading_results');
			this.service.filterDate = selectedDate;
			this.currentPage = 1;
			this.goToResultsPage();
		}
	}

	/**
	 * Обработчик события {@link clickButton}.
	 *
	 * @param {ResultNavigationDirection} direction Нажатая кнопка.
	 */
	onClickNextOrPrevButtonHandler(direction: ResultNavigationDirection): void {
		if (this.gameCode) {
			this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.loading_results');
			this.currentPage += direction;
			this.goToResultsPage();
		}
	}

	/**
	 * Обработчик выбора результата.
	 *
	 * @param {number} resultIndex Индекс результата из списка.
	 */
	onSelectedResultHandler(resultIndex: number): void {
		const resultData = this.resultsList[resultIndex];
		this.resultSelected.emit(resultData);
	}

	/**
	 * Обработчик выбора лотереи: "Тип" или "Топ".
	 *
	 * @param {Event} event Передаваемое событие.
	 */
	onSelectedLotteryTypeHandler(event: Event): void {
		const gameCode = parseInt((event.target as HTMLInputElement).value, 10);
		this.lotteryChanged.emit(gameCode);
	}

	/**
	 * Инициализация компонента.
	 */
	initComponent(): void {
		if (this.gameCode) {
			// вычисляем сколько можем показать тиражей в зависимости от высоты окна
			// показываем диалог ожидания
			this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.loading_results');
			// Ставим первую страницу
			this.currentPage = 1;
			// подписыываемся на изменение результата
			this.service.lastLoadedResults$.subscribe(val => this.resultsList = val);
			// очищаем замену локального хранилища
			this.service.localStore = [];
			// переходим на страницу результатов
			this.goToResultsPage();
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Перейти на страницу результатов.
	 */
	private goToResultsPage(): void {
		this.service.navigateTo(this.gameCode, this.currentPage)
			.then(data => {
				this.service.lastLoadedResults$.next(data);
				this.resultsList = data;
				// если есть список результатов
				if (this.resultsList && this.resultsList.length) {
					// показываем первый найденый результат
					this.resultSelected.emit(this.resultsList[0]);
				}
				// скрываем диалог ожидания
				this.dialogInfoService.hideAll();
			})
			.catch((error) => {
				this.dialogInfoService.showOneButtonError(
					new DialogError(error.code, error.message), {
						click: () => {
							this.service.lastLoadedResults$.next(undefined);
						},
						text: 'dialog.dialog_button_continue'
					});
			});
	}

	/**
	 * Обновить переменные шаблона для показа или скрытия его частей.
	 */
	private updateTemplateVars(): void {
		this.showResultsContent = (!!this._viewResults && !this.service.filterDate)
			|| (!!this._viewResults && this.service.filterDate && this.resultsList && this.resultsList.length > 0);
		this.printResultsDisabled = !this._viewResults || (this.service.filterDate && (!this.resultsList || this.resultsList.length === 0));
		this.notExactFilterDate = this.service.filterDate && !this.service.exactDate;
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		// Это временное решение, в дальнейшем нужно будет сделать через директиву
		// ==============
		// количество показанных тиражей в зависимости от разрешения экрана
		// this.service.drawLimit = DRAWS_LIMITS[screen.width] || 6;
		this.service.drawLimit = 6;
		this.service.cachedResultsCount = 6;
		this.service.cachedFilteredCount = 6;
		// для лотереи ТипТоп - на 1 меньще из-за кнопок-переключателей
		if ((this.gameCode === LotteryGameCode.Tip) || (this.gameCode === LotteryGameCode.Top)) {
			this.service.drawLimit--;
			this.service.cachedResultsCount--;
			this.service.cachedFilteredCount--;
		}
		// ==============
		this.initComponent();
		this.service.exactDate = false;
		this.service.filterDate = undefined;
		this.resultsList = [];
		this.updateTemplateVars();
	}

	/**
	 * Обработчик события изменения входных данных
 	 */
	ngAfterContentChecked(): void {
		this.updateTemplateVars();
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}
}
