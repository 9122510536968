import { ApiRequest, IResponse } from '@app/core/net/ws/api/types';
import { DocumentStatus, PrintData } from '@app/core/net/ws/api/models/print/print-models';

/**
 * Модель для вызова API Print Service - print
 */
export class PrintReq extends ApiRequest {

	/**
	 * Конструктор.
	 *
	 * @param {Array<PrintData>} data Массив данных для печати.
	 */
	constructor(
		private readonly data: Array<PrintData>
	) {
		super('ua.msl.alt.service.printer', 'print');
	}

}

/**
 * Модель ответа на запрос API Print Service
 */
export interface PrintResp extends IResponse {
	/**
	 * Идентификатор документа.
	 */
	docId: string;
	/**
	 * Статус документа.
	 */
	docStatus: DocumentStatus;
}
