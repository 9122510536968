import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Пайп для безопасного отображения HTML.
 */
@Pipe({
	name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

	/**
	 * Конструктор класса.
	 * @param sanitized Сервис для безопасного отображения HTML.
	 */
	constructor(private readonly sanitized: DomSanitizer) {}

	/**
	 * Функция преобразования html-кода в безопасный для отображения.
	 * @param value HTML-код.
	 */
	transform(value: string): SafeHtml {
		return this.sanitized.bypassSecurityTrustHtml(value);
	}


}
