import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
	ButtonGroupMode,
	ButtonGroupStyle,
	ButtonTextStyle
} from "@app/shared/components/buttons-group/buttons-group.component";
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator
} from "@angular/forms";

/**
 * Модель кнопки. Содержит метку, значение и различные статусы.
 */
export interface IButtonSetItem {
	/**
	 * Значение кнопки
	 */
	v: string | number;

	/**
	 * Надпись на кнопке
	 */
	t: string;

	/**
	 * Признак, означающий, что кнопка отключена и не доступна к нажатию.
	 */
	disabled?: boolean;
}


/**
 * Компонент, отрисовывающий группу кнопок.
 */
@Component({
	selector: 'app-buttons-set',
	templateUrl: './buttons-set.component.html',
	styleUrls: ['./buttons-set.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: ButtonsSetComponent
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: ButtonsSetComponent
		}
	]
})
export class ButtonsSetComponent implements ControlValueAccessor, Validator {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Метка данной группы кнопок.
	 */
	@Input()
	buttonSetLabel: string;

	/**
	 * Задать стиль текста кнопок.
	 * Вибирается из {@link ButtonTextStyle}.
	 */
	@Input()
	buttonTextStyle: ButtonTextStyle = ButtonTextStyle.button_text_none;

	/**
	 * Задать стиль кнопок.
	 * Вибирается из {@link ButtonGroupStyle}.
	 */
	@Input()
	buttonSetStyle: ButtonGroupStyle = ButtonGroupStyle.outline_square;

	/**
	 * Режим работы данной группы кнопок.
	 */
	@Input()
	workMode: ButtonGroupMode = ButtonGroupMode.range;


	/**
	 * Идентификатор группы.
	 */
	@Input()
	id: any;

	/**
	 * Флаг, указывающий на необходимость перевода метки кнопок.
	 */
	@Input()
	isNeededTranslation: boolean;

	/**
	 * Флаг, необходимый для деактивации кнопок. Если true, то они недоступны для нажатия
	 */
	@Input()
	isDisabled: boolean;

	/**
	 * Флаг, необходимый для мигания заголовка, по умолчанию - не мигает
	 */
	@Input()
	titleBlinking = false;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие, которое указывает на то, что клик по кнопке отработал успешно.
	 *
	 * @type {EventEmitter<IButtonSetItem>}
	 */
	@Output()
	readonly clickButton: EventEmitter<IButtonSetItem> = new EventEmitter();

	/**
	 * Событие, которое указывает на то, что был превышен параметр {@link countSelectedButtons}.
	 *
	 * @type {EventEmitter<IButtonSetItem>}
	 */
	@Output()
	readonly maximumButtonsSelected: EventEmitter<IButtonSetItem> = new EventEmitter();

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Массив кнопок с моделями типа {@link IButtonSetItem}.
	 */
	@Input()
	buttons: Array<IButtonSetItem> = [];

	/**
	 * Значение компонента
	 */
	value: string | number | undefined = undefined;

	onChange = (v) => {};

	onTouched = () => {};

	touched = false;

	disabled = false;

	selectedIndex = -1;

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Вспомогательная функция для отслеживания изменений в массиве элементов.
	 * @param index Индекс элемента.
	 * @param item Элемент.
	 */
	trackByFn = (index, item: IButtonSetItem) => item.v;

	/**
	 * Обработчик нажатия кнопок в контейнере.
	 * @param index
	 */
	onButtonClickHandler(index: number): void {
		this.markAsTouched();
		if (!this.disabled) {
			this.changeButtonState(index, true);
		}
	}

	writeValue(v: number | string) {
		this.value = v;
		const idx = this.buttons.findIndex(btn => btn.v === v);
		if (idx > -1) {
			this.changeButtonState(idx);
		}
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Изменить состояние кнопки (или кнопок)
	 */
	private changeButtonState(index: number, emitEvent = false): void {
		const button = this.buttons[index];
		if (!button.disabled) {
			if (index === this.selectedIndex) {
				this.selectedIndex = index - 1;
				this.value = this.selectedIndex > -1 ? this.buttons[this.selectedIndex].v : undefined;
			} else {
				this.selectedIndex = index;
				this.value = button.v;
			}
			if (emitEvent) {
				this.onChange(this.value);
			}
		}

		switch (this.workMode) {
			// case ButtonGroupMode.range:
			// case ButtonGroupMode.range_deselect:
			// 	lastIdx = idx;
			// 	if (this.workMode === ButtonGroupMode.range_deselect && button.selected) {
			// 		lastIdx = idx - 1;
			// 		button = this.buttons[lastIdx];
			// 	}
			//
			// 	this.buttons.forEach((btn, index) => btn.selected = index <= lastIdx);
			// 	break;

			// case ButtonGroupMode.range_deselect_2:
			// 	lastIdx = idx;
			// 	const prevLastIdx = this.buttons.filter(btn => btn.selected).length - 1;
			// 	if (button.selected && lastIdx >= prevLastIdx) {
			// 		lastIdx = idx - 1;
			// 		button = this.buttons[lastIdx];
			// 	}
			//
			// 	this.buttons.forEach((btn, index) => btn.selected = index <= lastIdx);
			// 	break;
			// case ButtonGroupMode.custom:
			// 	// проверить на макс кол-во нажатых кнопок, если задан параметр
			// 	if (this.countSelectedButtons) {
			// 		const count = this.buttons.filter(f => f.selected).length;
			// 		if (count < this.countSelectedButtons || (this.countSelectedButtons === count && button.selected)) {
			// 			button.selected = !button.selected;
			// 			if (this.countSelectedButtons === count + 1 && button.selected) {
			// 				this.maximumButtonsSelected.emit(button);
			// 			}
			// 		} else {
			// 			this.maximumButtonsSelected.emit(button);
			//
			// 			return;
			// 		}
			// 	} else {
			// 		button.selected = !button.selected;
			// 	}
			// 	break;
			//
			// case ButtonGroupMode.radio:
			// 	this.buttons.forEach(btn => btn.selected = false);
			// 	button.selected = true;
			// 	break;
			//
			// case ButtonGroupMode.radio_deselect:
			// 	this.buttons.forEach(btn => btn.selected = btn.index === button.index ? !btn.selected : false);
			// 	break;

			case ButtonGroupMode.no_selection:
				break;

			default:
				return;
		}

		// if (emitEvent) {
		// 	this.clickButton.emit(button);
		// }
	}

	validate(control: AbstractControl): ValidationErrors | null {
		const itemValue = control.value;
		if (!itemValue) {
			return {
				mustBeDefined: {
					errorMessage: 'Value must be selected'
				}
			};
		}
	}
}
