import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { InputTmlbmlComponent } from '@app/tml-bml-input/components/input-tmlbml/input-tmlbml.component';
import { MslInputBarcodeComponent } from '@app/tml-bml-input/components/msl-input-barcode/msl-input-barcode.component';
import { TmlBmlListComponent } from '@app/tml-bml-input/components/tml-bml-list/tml-bml-list.component';
import { TwoStepPanelComponent } from '@app/tml-bml-input/components/two-step-panel/two-step-panel.component';
import { TmlBmlInputStoreService } from '@app/tml-bml-input/services/tml-bml-input-store.service';
import {CameraModule} from "../camera/camera.module";

/**
 * Модуль ввода баркодов для лотереи "Стирачки".
 * Также используется в инвентаризации билетов.
 */
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        CameraModule
    ],
	declarations: [
		InputTmlbmlComponent,
		TmlBmlListComponent,
		TwoStepPanelComponent,
		MslInputBarcodeComponent
	],
	exports: [
		InputTmlbmlComponent
	],
	providers: [
		TmlBmlInputStoreService
	]
})
export class TmlBmlInputModule {
	static forRoot(config = {}): ModuleWithProviders<TmlBmlInputModule> {
		return {
			ngModule: TmlBmlInputModule,
			providers: [
				TmlBmlInputStoreService
			]
		};
	}

	static forChild(config = {}): ModuleWithProviders<TmlBmlInputModule> {
		return {
			ngModule: TmlBmlInputModule,
			providers: [
				TmlBmlInputStoreService
			]
		};
	}
}
