import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { TerminalRoles } from '@app/core/services/store/operator';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { createUrlParam, PARAM_MANUAL_MENU_CLICK } from '@app/util/route-utils';

/**
 * Интерфейс, описывающий модель элемента меню.
 */
export interface INavigationMenuItem {

	/**
	 * Метка меню.
	 */
	label: string;

	/**
	 * Признак активности меню (соответствие текущему активному маршруту).
	 */
	isActive?: boolean;

	/**
	 * Путь перехода.
	 */
	path?: string;

	/**
	 * Класс меню, который будет применен к данному элементу меню. Его можно описать в SCSS-файле компонента.
	 */
	class?: string;

	/**
	 * Функция, которая будет исполнена, по клику на данном элементе меню.
	 */
	fn?: Function;

	/**
	 * Атрибут, указывающий на необходимость показа данного элемента меню.
	 * Определяется методом {@link parseMenuItemsByUserRole}.
	 */
	isVisible?: boolean;

	/**
	 * Список разрешенных ролей для клика на данном элементе меню.
	 */
	allowedRoles?: Array<TerminalRoles>;

	/**
	 * Деактивирован ли пункт меню
	 */
	isDisabled?: boolean;

	/**
	 * Не прятать меню при клике на этот пункт
	 */
	dontHideMenu?: boolean;
}

/**
 * Базовый компонент меню.
 */
@Directive()
export class NavigationMenuComponent {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Сеттер списка элементов меню типа {@link INavigationMenuItem}.
	 */
	@Input()
	set menuItems(value: Array<INavigationMenuItem>) {
		this._menuItems = value;
		this.parseMenuItemsByUserRole();
	}

	/**
	 * Геттер списка элементов меню типа {@link INavigationMenuItem}.
	 */
	get menuItems(): Array<INavigationMenuItem> {
		return this._menuItems;
	}

	/**
	 * Задать роль оператора, чтобы определить доступность различных пунктов меню
	 * на основании заданной роли и списка элементов меню.
	 */
	@Input()
	set currentUserRole(value: TerminalRoles) {
		this._currentUserRole = value;
		this.parseMenuItemsByUserRole();
	}

	/**
	 * Получить роль оператора, чтобы определить доступность различных пунктов меню
	 */
	get currentUserRole(): TerminalRoles {
		return this._currentUserRole;
	}

	/**
	 * Объект маршрутизации.
	 */
	@Input()
	router: Router;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие, которое будет вызвано при клике на элементе меню.
	 */
	@Output()
	readonly clickMenuItem = new EventEmitter<INavigationMenuItem>();

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Список элементов меню типа {@link INavigationMenuItem}.
	 * @private
	 */
	private _menuItems: Array<INavigationMenuItem>;

	/**
	 * Роль оператора, чтобы определить доступность различных пунктов меню
	 * @private
	 */
	private _currentUserRole: TerminalRoles = TerminalRoles.OPERATOR;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Базовый обработчик нажатия на элементе меню.
	 *
	 * @param {MouseEvent} event Событие нажатия.
	 */
	onClickMenuItemHandler(event: MouseEvent): void {
		const el: HTMLLIElement = (event.target as HTMLElement).closest('li');
		const idx = el && Number.isInteger(el.value) ? el.value : -1;

		if (idx >= 0 && this._menuItems && this._menuItems.length > 0) {
			const item = this._menuItems[idx];
			if (item.path) {
				Logger.Log.i('NavigationMenuComponent', `onClickMenuItemHandler -> will navigate to path: ${item.path}`)
					.console();
				this.router.navigate([item.path], {queryParams: createUrlParam(PARAM_MANUAL_MENU_CLICK, true)})
					.catch(err => {
						Logger.Log.e('NavigationMenuComponent', `onClickMenuItemHandler -> can't navigate to path: ${err}`)
							.console();
					});
			} else if (item.fn) {
				item.fn();
			}

			this.clickMenuItem.emit(item);
		}
	}

	/**
	 * Пометить признак {@link INavigationMenuItem.isActive} как активный по предложенному пути.
	 *
	 * @param {string} path Путь, по которому необходимо пометить элемент меню.
	 */
	activateByPath(path: string): void {
		if (this._menuItems) {
			this._menuItems.forEach(i => i.isActive = false);

			const x = !!path && path.includes('?') ? path.split('?')[0] : path;
			const menu = this._menuItems.find(p => p.path === x);
			if (menu) {
				menu.isActive = true;
			}
		}
	}

	/**
	 * Деактивировать все элементы меню.
	 */
	deactivateAll(): void {
		if (this._menuItems) {
			this._menuItems.forEach(i => i.isActive = false);
		}
	}

	/**
	 * Пропарсить элементы меню на предмет возможности показа согласно текущей роли пользователя.
	 */
	parseMenuItemsByUserRole(): void {
		if (Array.isArray(this.menuItems)) {
			this.menuItems.forEach(v => {
				// если список доступных ролей задан - парсим его
				// если список не задан - разрешаем элемент меню
				v.isVisible = v.allowedRoles
					? v.allowedRoles.indexOf(this.currentUserRole) !== -1
					: true;
			});
		}
	}

	/**
	 * Обработчик изменения роли пользователя.
	 * @param index Индекс элемента меню.
	 * @param item Элемент меню.
	 */
	trackByIndex = (index, item: any) => index;
}
