import { Input, Directive } from '@angular/core';

/**
 * Базовый компонент - кнопка.
 */
@Directive()
export abstract class MslBaseInput {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Высота строки ввода.
	 * Размеры компонентов будут автоматически пересчитаны.
	 */
	@Input()
	inputHeight = 54;

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Устанавливает фокус на компонент (абстрактный метод)
	 */
	abstract setFocus(): void;
}
