import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Компонент переключателя для выбора одного значения из нескольких опций.
 */
@Component({
	selector: 'app-switch-button',
	templateUrl: './switch-button.component.html',
	styleUrls: ['./switch-button.component.scss']
})
export class SwitchButtonComponent  {

	/**
	 * Массив значений, которые будут отображаться в переключателе.
	 */
	@Input() values: Array<any> = [0, 1];

	/**
	 * Массив заголовков, которые будут отображаться в переключателе.
	 */
	@Input() titles: Array<string> = ['', ''];

	/**
	 * Значение, которое будет выбрано по умолчанию.
	 */
	@Input() value: any = 0;

	/**
	 * Флаг, отключающий переключатель.
	 */
	@Input() isDisabled = false;

	/**
	 * Событие, которое будет вызвано при изменении значения переключателя.
	 */
	@Output() readonly changed: EventEmitter<Event> = new EventEmitter<Event>();

	/**
	 * Функция для отслеживания изменений в массиве значений.
	 * @param index индекс элемента
	 * @param item элемент
	 */
	trackByFn = (index: number, item: any) => index;

	/**
	 * Функция, которая будет вызвана при изменении значения переключателя.
	 * @param event Передаваемое событие.
	 */
	onChangeHandler(event: Event): void {
		this.changed.emit(event);
	}
}
