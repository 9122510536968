<div class="modal-dialog-background">
	<div class="modal-dialog-container">

		<!-- DIALOG TITLE -->
		<div class="modal-alt-title"
			 [ngClass]="isInfoDialog ? 'modal-alt-title_info' : 'modal-alt-title_error'"
		>{{ title | translate }}</div>

		<!-- DIALOG MESSAGE -->
		<div *ngIf="!!message"
			class="modal-alt-message"
			[innerHTML]="message | translate"
			>
		</div>

		<ng-container *ngIf="!isEmptyList">

			<div class="modal-text">{{ 'header.shutdown_message' | translate }}</div>
			<!-- REASON OF SHUTDOWN SELECTOR -->
			<app-drop-down-list
				class="reason-dropdown"
				[dataProvider]="itemsList"
				(itemChanged)="onItemChanged($event)"
				[titleText]="defaultItem?.label"
				>
				{{ (currentSelection ? currentSelection.label : defaultItem?.label) | translate }}
			</app-drop-down-list>

			<!-- USER'S REASON -->
			<app-msl-input
				#customMessage
				class="reason-input"
				[type]="'text'"
				*ngIf="currentSelection?.value === custom"
				>
			</app-msl-input>

		</ng-container>

		<!-- ПАНЕЛЬ КНОПОК -->
		<div class="modal-buttons-container">

			<!-- ВТОРИЧНАЯ КНОПКА (НАЗАД) -->
			<button type="button"
				class="button button_theme_white modal-button modal-button_back"
				(click)="clickSecond()"
				>
				{{ buttons.second.text | translate }}
			</button>

			<!-- ПЕРВИЧНАЯ КНОПКА (ПОДТВЕРЖДЕНИЕ) -->
			<button type="button"
				class="button button_theme_light-green modal-button modal-button_confirm"
				(click)="clickFirst()"
				[attr.disabled]="(isDisabled && !isEmptyList) || null"
				>
				{{ buttons.first.text | translate }}
			</button>

		</div>

	</div>
</div>

<app-dialog-container></app-dialog-container>
