/**
 * Модель запроса для вызова API BOGetClientList.
 */
import { ApiClient } from '@app/core/net/http/api/api-client';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { ClientPhoneItemAS, IResponse } from '@app/core/net/http/api/types';

/**
 * Модель запроса API BOGetClientList
 */
export class BOGetClientListReq extends ApiClient {

	/**
	 * Конструктор запроса.
	 *
	 * @param {AppStoreService} appStoreService Централизованое хранилище данных.
	 * @param multiUserID
	 */
	constructor(
		appStoreService: AppStoreService,
		multiUserID?: string
	) {
		super(appStoreService, appStoreService.Settings.getEsapActionUrl(LotteryGameCode.Undefined, EsapActions.BOGetClientList));

		// this.params.append(EsapParams.USER_ID, appStoreService.);
		if (multiUserID) {
			this.params.append(EsapParams.MULTIUSER_ID, multiUserID);
		}
	}

}

// -----------------------------
//  Response
// -----------------------------

/**
 * Модель ответа на запрос API BOGetClientList
 */
export interface BOGetClientListResp extends IResponse {
	/**
	 * Идентификатор клиента
	 */
	client_id?: string;

	/**
	 * Идентификатор пользователя
	 */
	user_id: string;

	/**
	 * Идентификатор созданной сессии
	 */
	sid: string;

	/**
	 * Массив клиентских устройств (телефонов), с которыми может работать пользователь
	 */
	client_list: Array<ClientPhoneItemAS>;
}
