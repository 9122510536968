<div class="print-button-container" data-cy="print-button-container">

	<!-- КОЛ-ВО КОПИЙ -->
	<div
		class="pbc-page-count"
		data-cy="pbc-page-count"
		*ngIf="showCopiesCounter"
		>
		<label class="pbc-page-count-lbl"
			   data-cy="pbc-page-count-lbl"
		>{{ 'reports.quantity' | translate }}</label>

		<button
			class="pbc-page-count__btn"
			data-cy="pbc-page-count-btn"
			[attr.disabled]="btnLessDisabled || isDisabled || null"
			(click)="onClickCopiesCountHandler(-1)"
			>
			<i class="material-icons">navigate_before</i>
		</button>

		<label [class.disabled]="isDisabled"
			   class="pbc-page-label"
			   data-cy="pbc-page-label"
		>{{ copiesCount }}</label>

		<button
			class="pbc-page-count__btn"
			data-cy="pbc-page-count-btn"
			[attr.disabled]="btnMoreDisabled || isDisabled || null"
			(click)="onClickCopiesCountHandler(1)"
			>
			<i class="material-icons">navigate_next</i>
		</button>
	</div>

	<!-- КНОПКА ПЕЧАТИ -->
	<button type="button"
		class="button button_theme_dark-green rnc-print-button"
		data-cy="rnc-print-button"
		[attr.disabled]="isDisabled || null"
		(click)="onClickPrintHandler()"
		>{{ 'reports.print' | translate }}</button>

</div>
