import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BarcodeObject } from '@app/core/barcode/barcode-object';
import { createBarcodeObject } from '@app/core/barcode/barcode-reader.service';
import { TMLBarcode } from '@app/core/barcode/tml-barcode';
import { UpdateDrawInfoDraws } from '@app/core/net/http/api/models/update-draw-info';
import { StepNumber } from '../enums/step-number.enum';
import { TmlBmlInputFieldStates } from '../enums/tml-bml-input-field-states.enum';
import { TmlBmlInputMode } from '../enums/tml-bml-input-mode.enum';
import { ITmlBmlListItem } from '../interfaces/itml-bml-list-item';

/**
 * Интерфейс ошибки ввода штрих-кода.
 */
export interface IInputBarcodeError {
	/**
	 * Введенный штрих-код.
	 */
	barcode?: string;

	/**
	 * Текст ошибки.
	 */
	errorText: string;
}

/**
 * Сервис хранения состояния ввода штрих-кода.
 */
@Injectable({
	providedIn: 'root'
})
export class TmlBmlInputStoreService {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Задает список тиражей, по которым возможен ввод билетов.
	 * Строит хешмап {@link drawsMap}.
	 *
	 * @param {Array<UpdateDrawInfoDraws>} value Список тиражей.
	 */
	set draws(value: Array<UpdateDrawInfoDraws>) {
		this.drawsMap.clear();
		if (Array.isArray(value)) {
			value.forEach(v => this.drawsMap.set(+v.draw.serie_code, v));
		}
	}

	/**
	 * Введенное значение на первом шаге.
	 */
	stepOneValue$$ = new BehaviorSubject<TMLBarcode>(undefined);

	/**
	 * Введенное значение на втором шаге.
	 */
	stepTwoValue$$ = new BehaviorSubject<TMLBarcode>(undefined);

	/**
	 * Необходимость контролировать код тиража в списке.
	 * Если контроль включен и количество кодов будет более одного, будет сгенерирована ошибка.
	 */
	isNeededDrawsCodeCheck = false;

	/**
	 * Список введенных диапазонов с баркодами.
	 */
	ticketsList$$ = new BehaviorSubject<Array<ITmlBmlListItem>>([]);

	/**
	 * Текущий режим ввода.
	 */
	currentRangeMode$$ = new BehaviorSubject<TmlBmlInputMode>(TmlBmlInputMode.OneStep);

	/**
	 * Текущий шаг.
	 */
	currentStep$$ = new BehaviorSubject<StepNumber>(StepNumber.One);

	/**
	 * Статус ввода штрих-кода на первом шаге.
	 */
	inputStatusForStep1$$ = new BehaviorSubject<TmlBmlInputFieldStates>(TmlBmlInputFieldStates.Clear);

	/**
	 * Статус ввода штрих-кода на втором шаге.
	 */
	inputStatusForStep2$$ = new BehaviorSubject<TmlBmlInputFieldStates>(TmlBmlInputFieldStates.Clear);

	/**
	 * Текущая ошибка ввода штрих-кода на первом шаге.
	 */
	currentErrorForStep1$$ = new BehaviorSubject<IInputBarcodeError>(undefined);

	/**
	 * Текущая ошибка ввода штрих-кода на втором шаге.
	 */
	currentErrorForStep2$$ = new BehaviorSubject<IInputBarcodeError>(undefined);

	/**
	 * Ошибка ввода диапазона штрих-кодов.
	 */
	parsedError$$ = new Subject<IInputBarcodeError>();

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Хешмап тиражей.
	 * @private
	 */
	private readonly drawsMap = new Map<number, UpdateDrawInfoDraws>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Привести форму в начальное состояние.
	 */
	init(): void {
		this.currentStep$$.next(StepNumber.One);
		this.currentRangeMode$$.next(TmlBmlInputMode.OneStep);
		this.currentErrorForStep1$$.next(undefined);
		this.currentErrorForStep2$$.next(undefined);
		this.inputStatusForStep1$$.next(TmlBmlInputFieldStates.Clear);
		this.inputStatusForStep2$$.next(TmlBmlInputFieldStates.Clear);
		this.stepOneValue$$.next(undefined);
		this.stepTwoValue$$.next(undefined);
		console.log(JSON.stringify(this.ticketsList$$.value));
	}

	/**
	 * Выполнить проверку текстового ввода с элемента ввода или с ручного ввода.
	 * Создает объект {@link TMLBarcode} и проверяет на наличие тиражей по соответствующей игре.
	 *
	 * @param {string} barcode Штрих-код в виде текста, который нужно пропарсить.
	 */
	parseNewBarcode(barcode: string): void {
		const bcObj = new TMLBarcode(barcode);
		if (this.drawsMap.has(bcObj.intGameNumber)) {
			this.updateBarcodeListByNewBarcodeObject(bcObj);
		} else {
			if (this.currentStep$$.value === StepNumber.One) {
				this.stepOneValue$$.next(bcObj);
			} else if (this.currentStep$$.value === StepNumber.Two) {
				this.stepTwoValue$$.next(bcObj);
			}

			this.emitErrorState();
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Сгенерировать ошибку ввода штрих-кода.
	 * @private
	 */
	private emitErrorState(): void {
		if (this.currentStep$$.value === StepNumber.One) {
			this.currentErrorForStep1$$.next({errorText: 'lottery.tmlbml.error_bc_ticket'});
			this.inputStatusForStep1$$.next(TmlBmlInputFieldStates.BadInput);
		} else {
			this.currentErrorForStep2$$.next({errorText: 'lottery.tmlbml.error_bc_ticket'});
			this.inputStatusForStep2$$.next(TmlBmlInputFieldStates.BadInput);
		}
	}

	/**
	 * Обновить список баркодов новым корректным баркодом.
	 *
	 * @param {TMLBarcode} tmlBarcode Валидный баркод, для которого существует тираж.
	 */
	private updateBarcodeListByNewBarcodeObject(tmlBarcode: TMLBarcode): void {
		let arr: [TMLBarcode, TMLBarcode];
		if (this.currentRangeMode$$.value === TmlBmlInputMode.OneStep) {
			arr = [tmlBarcode, tmlBarcode];
			this.addPackageToList(arr);
		} else {
			if (this.currentStep$$.value === StepNumber.One) {
				this.stepOneValue$$.next(tmlBarcode);
				this.currentStep$$.next(StepNumber.Two);
				this.inputStatusForStep1$$.next(TmlBmlInputFieldStates.OkInput);
				this.currentErrorForStep1$$.next(undefined);
			} else {
				this.stepTwoValue$$.next(tmlBarcode);

				// упорядочить пачку по номеру (меньший идет первым)
				if (this.stepOneValue$$.value.intTicketNumber < this.stepTwoValue$$.value.intTicketNumber) {
					this.addPackageToList([this.stepOneValue$$.value, this.stepTwoValue$$.value]);
				} else {
					this.addPackageToList([this.stepTwoValue$$.value, this.stepOneValue$$.value]);
				}
			}
		}
	}

	/**
	 * Добавить новую пачку (диапазон) билетов в список.
	 *
	 * @param {[BarcodeObject, BarcodeObject]} range Диапазон билетов (первый и последний элемент {@link BarcodeObject}).
	 */
	private addPackageToList(range: [TMLBarcode, TMLBarcode]): void {
		const rangeFrom = range[0];
		const rangeTo = range[1];

		// проверить пачки
		if (rangeFrom.intTicketPackage !== rangeTo.intTicketPackage) {
			this.parsedError$$.next({errorText: 'dialog.packages_are_different'});
			this.emitErrorState();

			return;
		}

		const udi = this.drawsMap.get(range[0].intGameNumber);
		const rangeSerie = rangeFrom.intGameNumber;
		const rangePackage = rangeFrom.intTicketPackage;
		let list = this.ticketsList$$.value;

		// если задан признак проверки на код тиража, то заблокировать ввод нового диапазона с другим кодом
		// т.е. список будет содержать диапазоны только для определенной игры
		if (this.isNeededDrawsCodeCheck) {
			const existCode = list.find(v => v.draws.draw.code === udi.draw.code);
			if (list.length > 0 && !existCode) {
				this.parsedError$$.next({errorText: 'dialog.tickets_from_different_lotteries_cant_be_registered'});

				return;
			}
		}

		// отфильтровать список по коду серии игры и номеру пачки
		// т.е. выбираем баркоды: "SSSS-PPPPPP-...-..."
		const filteredPackage = list
			.filter(v => v.bcFrom.intGameNumber === rangeSerie && v.bcFrom.intTicketPackage === rangePackage)
			.map(m => [m.bcFrom, m.bcTo]);

		// если по фильтру "игра-пачка" имеются элементы - объединить список
		if (filteredPackage.length > 0) {
			// построить вектор с индексами типа: [,,,true,,,,,,true]
			const flatArray = [];
			[...filteredPackage, range]
				.forEach(v => {
					Array.from(Array(v[1].intTicketNumber - v[0].intTicketNumber + 1))
						.forEach((_, i) => flatArray[v[0].intTicketNumber + i] = true);
				});

			// нарезать вектор на новые диапазоны
			let d1 = -1;
			let d2 = -1;
			const newList: Array<ITmlBmlListItem> = [];
			for (let i = 0; i < flatArray.length; i++) {
				if (!flatArray[i] || (flatArray[i] && i === flatArray.length - 1)) {
					if (flatArray[i] && i === flatArray.length - 1) {
						if (d1 < 0) {
							d1 = i;
						}

						d2 = i;
					}

					if (d1 >= 0 && d2 >= 0) {
						newList.push({
							bcFrom: createBarcodeObject(rangeSerie, rangePackage, d1),
							bcTo: createBarcodeObject(rangeSerie, rangePackage, d2),
							draws: udi
						});
					}

					d1 = d2 = -1;
				} else {
					if (d1 < 0 && d2 < 0) {
						d1 = d2 = i;
					} else {
						d2 = i;
					}
				}
			}

			// удалить старые диапазоны и создать новые
			const arr = list
				.filter(v => !(v.bcFrom.intGameNumber === rangeSerie && v.bcFrom.intTicketPackage === rangePackage));
			list = arr ? arr : [];
			list.push(...newList);

			this.ticketsList$$.next(list);
			this.init();
		} else {
			// елементов нет - просто добавляем новый диапазон в список
			list.push({
				bcFrom: rangeFrom,
				bcTo: rangeTo,
				draws: udi
			});

			this.ticketsList$$.next(list);
			this.init();
		}
	}

}
