import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { TransactionAction } from '@app/util/utils';

/**
 * Mock-данные для списка операций в компоненте суммарного чека.
 */
export const OPS_LIST = [
	{
		timestamp: Date.now(),
		gameName: 'Лото Забава',
		gameCode: LotteryGameCode.Zabava,
		operationType: TransactionAction.REGISTER,
		operationTypeLabel: 'Регистрация ставок',
		count: 1,
		summa: 200,
		operationResult: 'Успех',
		request: null
	},
	{
		timestamp: Date.now(),
		gameName: 'Лото Забава',
		gameCode: LotteryGameCode.Zabava,
		operationType: TransactionAction.CANCEL_AUTO,
		operationTypeLabel: 'Отмена',
		count: 1,
		summa: 100,
		operationResult: 'Успех',
		request: null
	},
	{
		timestamp: Date.now(),
		gameName: 'Лото Забава',
		gameCode: LotteryGameCode.Zabava,
		operationType: TransactionAction.REGISTER,
		operationTypeLabel: 'Регистрация ставок',
		count: 1,
		summa: 300,
		operationResult: 'Успех',
		request: null
	},
	{
		timestamp: Date.now(),
		gameName: 'Лото Забава',
		gameCode: LotteryGameCode.Zabava,
		operationType: TransactionAction.PAYMENT,
		operationTypeLabel: 'Выплата',
		count: 1,
		summa: 300,
		operationResult: 'Успех',
		request: null
	},
	{
		timestamp: Date.now(),
		gameName: 'Лото Забава',
		gameCode: LotteryGameCode.Zabava,
		operationType: TransactionAction.REGISTER,
		operationTypeLabel: 'Регистрация ставок',
		count: 1,
		summa: 200,
		operationResult: 'Успех',
		request: null
	},
	{
		timestamp: Date.now(),
		gameName: 'Лото Забава',
		gameCode: LotteryGameCode.Zabava,
		operationType: TransactionAction.CANCEL_AUTO,
		operationTypeLabel: 'Отмена',
		count: 1,
		summa: 100,
		operationResult: 'Успех',
		request: null
	},
	{
		timestamp: Date.now(),
		gameName: 'Лото Забава',
		gameCode: LotteryGameCode.Zabava,
		operationType: TransactionAction.REGISTER,
		operationTypeLabel: 'Регистрация ставок',
		count: 1,
		summa: 300,
		operationResult: 'Успех',
		request: null
	},
	{
		timestamp: Date.now(),
		gameName: 'Лото Забава',
		gameCode: LotteryGameCode.Zabava,
		operationType: TransactionAction.PAYMENT,
		operationTypeLabel: 'Выплата',
		count: 1,
		summa: 300,
		operationResult: 'Успех',
		request: null
	}
];
