import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { CentralMenuComponent } from '@app/central-menu/central-menu/central-menu.component';

/**
 * Модуль центрального меню.
 */
@NgModule({
	imports: [
		CommonModule,
		TranslateModule.forChild(),
	],
	declarations: [
		CentralMenuComponent
	],
	exports: [
		CentralMenuComponent
	],
	providers: [
	]
})
export class CentralMenuModule {}
