import { Logger } from '@app/core/net/ws/services/log/logger';
import { init, Tag } from '@app/tickets-print/parser/tag';
import { FormatType, ParamTag } from './param';
import { IGetParam } from '@app/tickets-print/parser/iget-param';

/**
 * Тип формата.
 */
const IDPARAM_FMT = 'idparam-fmt';

/**
 * Модель XML тега <img>.
 */
export class ImgTag extends Tag {

	/**
	 * Тип тега.
	 */
	type = 'inline';

	/**
	 * Тип формата тега.
	 */
	idparam_type: FormatType = 'str';

	/**
	 * Идентификатор картинки.
	 */
	id?: string;

	/**
	 * Идентификатор параметра.
	 */
	idparam?: string;

	/**
	 * Ширина картинки.
	 */
	width?: string;

	/**
	 * Путь к картинке.
	 * @private
	 */
	private readonly path: string;

	/**
	 * Создание и инициализация на основе xml атрибутов.
	 *
	 * @param {IGetParam} param интерфейс доступа к параметрам
	 * @param {NamedNodeMap} attributes xml атрибуты
	 */
	constructor(param: IGetParam, attributes: NamedNodeMap) {
		super();
		init(this, attributes);

		const idType = this['idparam-type'];
		if (idType) {
			this.idparam_type = idType;
		}

		if (this.id) {
			this.path = this.id;
		}
		if (this.idparam) {
			const input = param.getParam(this.idparam);
			if (input != null) {
				Logger.Log.d('ImgTag', `idparam(%s) = "%s"`, this.idparam, input)
					.console();
				const value = ParamTag.ValidateParam(this.idparam, input, this.idparam_type);
				if (value === null || value === undefined) {
					this.path = null;
				} else {
					const tValue = String(value)
						.trim();

					this.path = ParamTag.Format(this.idparam_type, this[IDPARAM_FMT], tValue);
				}
			}
		}
	}

	/**
	 * Возвращает путь к картинке (относительно урла запроса регистрации ставки)
	 * @returns {string}
	 */
	getPath(): string {
		return this.path;
	}
}
