<div
	appMslComponent
	class="modal-dialog-background"
	data-cy="modal-dialog-background"
	[ngClass]="{'is-visible': isVisible}"
	[zIndex]="zIndex"
	>
	<div appMslComponent class="modal-dialog-container"
		 data-cy="modal-dialog-container"
		 [width]="width"
		 [height]="height"
		 [style.padding]="padding">
		<app-close-button
			class="modal-dialog-close"
			*ngIf="canClose" (click)="onCancel($event)"
			data-cy="modal-dialog-close"
		></app-close-button>

		<ng-content></ng-content>

		<!-- ПАНЕЛЬ КНОПОК -->
		<div *ngIf="showButtons"
			 class="modal-buttons-container"
			 data-cy="modal-buttons-container"
		>
			<button
				class="button modal-button"
				data-cy="button-modal-button"
				[ngClass]="buttonStyle"
				[attr.disabled]="buttonDisabled || null"
				(click)="onConfirm($event)"
				>
				{{ buttonText | translate }}
			</button>
		</div>
	</div>
</div>
