import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { ApplicationAppId, AppType } from '@app/core/services/store/settings';
import { StorageGetResp } from '@app/core/net/ws/api/models/storage/storage-get';
import { StorageKeys } from '@app/core/net/ws/api/models/storage/storage-models';
import { SavedOperator } from '@app/core/net/http/api/types';
import { Operator } from '@app/core/services/store/operator';

/**
 * Базовый класс контроля доступа к модулям (страницам) приложения
 */
@Injectable({
	providedIn: 'root'
})
export abstract class BaseGuard implements CanActivate {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {AppStoreService} appStoreService Сервис хранилища данных приложения
	 * @param {StorageService} storageService Сервис хранилища данных в localStorage
	 */
	protected constructor(
		readonly appStoreService: AppStoreService,
		readonly storageService: StorageService
	) {}

	/**
	 * Проверка возможности перехода на страницу.
	 * @param next Снимок маршрута, который будет активирован.
	 * @param state Снимок текущего маршрута.
	 */
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(next, state);
	}

	/**
	 * Проверка наличия сессии в localStorage
	 * @protected
	 */
	protected checkWebAltSession(): boolean {
		if (!!this.appStoreService.Settings && (this.appStoreService.Settings.appType !== AppType.ALTTerminal)) {

			// Если мы не на терминале, то пытаемся получить оператора и код терминала из localStorage
			const sessionData: StorageGetResp = this.storageService.getSync(
				ApplicationAppId,
				[StorageKeys.Operator, StorageKeys.TermCodeKey]
			);

			// если у на всё есть, то можно считать, что мы залогинены
			if (!!sessionData.data[0] && (sessionData.data[0].key === StorageKeys.Operator)
				&& !!sessionData.data[1] && (sessionData.data[1].key === StorageKeys.TermCodeKey)) {
				const savedOp: SavedOperator = JSON.parse(sessionData.data[0].value);
				this.appStoreService.operator.next(new Operator(
					savedOp._userId, savedOp._sessionId, savedOp._operCode, savedOp._access_level
				));
				this.appStoreService.Settings.termCode = sessionData.data[1].value;

				return true;
			}
		}

		return false;
	}

}
