import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CalendarDateInputComponent } from './components/calendar-date-input/calendar-date-input.component';
import { CalendarDateSelectorComponent } from './components/calendar-date-selector/calendar-date-selector.component';
import { CalendarDateInputDirective } from './directives/calendar-date-input.directive';
import { CalendarService } from './services/calendar.service';

/**
 * Модуль выбора даты.
 */
@NgModule({
	imports: [
		CommonModule,
		TranslateModule.forChild()
	],
	providers: [
		CalendarService
	],
	declarations: [
		CalendarDateSelectorComponent,
		CalendarDateInputComponent,
		CalendarDateInputDirective
	],
	exports: [
		CalendarDateSelectorComponent,
		CalendarDateInputComponent,
		CalendarDateInputDirective
	],
	entryComponents: [
		CalendarDateSelectorComponent
	]
})
export class CalendarModule {

	static forRoot(config = {}): ModuleWithProviders<CalendarModule> {
		return {
			ngModule: CalendarModule,
			providers: [
				CalendarService
			]
		};
	}

	static forChild(config = {}): ModuleWithProviders<CalendarModule> {
		return {
			ngModule: CalendarModule,
			providers: [
				CalendarService
			]
		};
	}

}
