import { DurationDay, DurationHour, DurationMinute, DurationYear } from '../../../../util/utils';
import { ITimeLeft } from './draws-buttons-models';

enum CountType {
	Second, Minute, Hour, Day, Year
}

enum CountKeySecond {
	One = 'draws-buttons.second',
	Few = 'draws-buttons.few_seconds',
	Many = 'draws-buttons.seconds'
}

enum CountKeyMinute {
	One = 'draws-buttons.minute',
	Few = 'draws-buttons.few_minutes',
	Many = 'draws-buttons.minutes'
}

enum CountKeyHour {
	One = 'draws-buttons.hour',
	Few = 'draws-buttons.few_hours',
	Many = 'draws-buttons.hours'
}

enum CountKeyDay {
	One = 'draws-buttons.day',
	Few = 'draws-buttons.few_days',
	Many = 'draws-buttons.days'
}

enum CountKeyYear {
	One = 'draws-buttons.year',
	Few = 'draws-buttons.few_years',
	Many = 'draws-buttons.years'
}

/**
 * Пропарсить значение осташегося времени по определенному типу даты.
 *
 * @param {CountType} countType Тип оставшегося времени.
 * @param {number} value Оставшееся время.
 */
const parseCount = (countType: CountType, value: number): ITimeLeft => {
	let type = '';

	let v = value % 10;
	const v2 = value % 100;
	const r1 = v2 >= 11 && v2 <= 20;
	const r2 = v < 1 || v > 4;

	if (r1 || r2) {
		v = Number.MAX_SAFE_INTEGER;
	}

	switch (countType) {
		case CountType.Second:
			type = v === 1 ? CountKeySecond.One : (v < 5 ? CountKeySecond.Few : CountKeySecond.Many);
			break;
		case CountType.Minute:
			type = v === 1 ? CountKeyMinute.One : (v < 5 ? CountKeyMinute.Few : CountKeyMinute.Many);
			break;
		case CountType.Hour:
			type = v === 1 ? CountKeyHour.One : (v < 5 ? CountKeyHour.Few : CountKeyHour.Many);
			break;
		case CountType.Day:
			type = v === 1 ? CountKeyDay.One : (v < 5 ? CountKeyDay.Few : CountKeyDay.Many);
			break;
		case CountType.Year:
			type = v === 1 ? CountKeyYear.One : (v < 5 ? CountKeyYear.Few : CountKeyYear.Many);
			break;
		default:
			type = '';
	}

	return {value, type};
};

/**
 * Расчет остатка времени до закрытия примема ставок на тираж.
 *
 * @param {number} endSale
 * @returns {ITimeLeft} Оставшееся время в единицах типа {@link ITimeLeft.type}
 */
export const calcTimeLeft = (endSale: number): ITimeLeft => {
	const diff = endSale - Date.now();

	if (diff < 0) {
		return undefined;
	}

	if (diff < DurationMinute) {
		return parseCount(CountType.Second, Math.floor(diff / 1000));
	} else if (diff < DurationHour) {
		return parseCount(CountType.Minute, Math.floor(diff / DurationMinute));
	} else if (diff < DurationDay) {
		return parseCount(CountType.Hour, Math.floor(diff / DurationHour));
	} else if (diff < DurationYear) {
		return parseCount(CountType.Day, Math.floor(diff / DurationDay));
	}

	return parseCount(CountType.Year, Math.floor(diff / DurationYear));
};
