import { Directive, ViewContainerRef } from '@angular/core';
import { DialogContainerComponent } from '../components/dialog-container.components';

/**
 * Директива, якорь, к которому крепится основной (в DOM) элемент, используемый
 * для динамического инстанциирования компонентов диалоговых окон {@link DialogContainerComponent}.
 */
@Directive({
	selector: '[appDialogContainer]'
})
export class DialogContainerDirective {

	/**
	 * Идентификатор последовательности при создании диалогового окна. Зависит от времени создания.
	 */
	serial: number;

	/**
	 * Конструктор директивы.
	 * @param viewContainerRef Ссылка на контейнер, в который будет инстанциирован компонент {@link DialogContainerComponent}.
	 */
	constructor(
		readonly viewContainerRef?: ViewContainerRef
	) {
		this.serial = Date.now();
	}

}
