<div class="top-bar"
	 data-cy="top-bar"
>

	<!-- КНОПКА АКТИВАЦИИ "МЕНЮ-ГАМБУРГЕР" И НЕ ТОЛЬКО -->
	<div class="top-bar__left"
		 data-cy="top-bar-left"
	>
		<div
			class="top-bar__hamburger tbp-hamburger"
			[class.top-bar__hamburger_active]="hamburgerMenuService.stateExpression === AnimationState.expanded"
			data-cy="top-bar-hamburger"
			(click)="onClickToggleHamburgerMenuHandler()"
		>
			<i class="material-icons tbp-hamburger__icon"
			   [class.tbp-hamburger__icon_active]="hamburgerMenuService.stateExpression === AnimationState.expanded"
			   data-cy="tbp-hamburger-icon"
			>menu</i>
			<label class="tbp-hamburger__title"
				[class.tbp-hamburger__title_active]="hamburgerMenuService.stateExpression === AnimationState.expanded"
				data-cy="tbp-hamburger-title"
			>{{ 'header.menu' | translate | uppercase }}</label>
		</div>

		<!-- КНОПКА "ДОМОЙ" -->
		<div class="top-bar__button top-bar__button_home button-home"
			 [class.button-home_level_second]="!isHomePage"
			 (click)="onClickGoHomeHandler()"
			 data-cy="top-bar-button"
		>
			<i class="material-icons button-home__icon"
			   [class.button-home__icon_level_second]="!isHomePage"
			   data-cy="button-home-icon"
			>home</i>
		</div>
	</div>

	<!-- ДАТА/ВРЕМЯ-->
	<div class="top-bar__date tbp-date" data-cy="top-bar-date">
		<label class="tbp-date__date" data-cy="tbp-date-date">{{ (dateTime$ | async) | date:dateFormat }}</label>
		<label class="tbp-date__time" data-cy="tbp-date-time">{{ (dateTime$ | async) | date:timeFormat }}</label>
	</div>

	<!-- СТАТУСЫ -->
<!--
	<div class="top-bar__statuses tbp-statuses">
		<app-display-icon
			class="tbp-statuses__item tbp-statuses__item_display"
			[class.tbp-statuses__item_active]="iconType === StatusIconType.Display && showStatusPanel"
		></app-display-icon>
		<app-scanner-icon
			class="tbp-statuses__item tbp-statuses__item_scanner"
			[class.tbp-statuses__item_active]="iconType === StatusIconType.Scanner && showStatusPanel"
		></app-scanner-icon>

		&lt;!&ndash; СТАТУС КОММУНИКАЦИОННОГО СЕРВИСА &ndash;&gt;
		<app-communication-icon
			class="tbp-statuses__item tbp-statuses__item_modem"
			[alertState]="!communicationReady"
			[canClickOpenDialog]="!showStatusPanel"
			(activateCommunicationDialog)="onClickCommunicationIconHandler()"
		>
		</app-communication-icon>
	</div>
-->

	<!-- КНОПКИ ВЫКЛЮЧЕНИЯ, ПЕРЕЗАГРУЗКИ -->
	<div class="top-bar__right"
		 data-cy="top-bar-right"
	>
		<app-printer-icon
			class="top-bar__status-item tbp-statuses__item"
			[class.top-bar__status-item_active]="iconType === StatusIconType.Printer && showStatusPanel"
			data-cy="top-bar-status-item"
		></app-printer-icon>
		<!-- ПАНЕЛЬ СТАТУСА -->
		<app-status-panel
			[iconTarget]="iconTarget"
			[showStatusPanel]="showStatusPanel"
			[statusPanelText]="statusPanelText"
			data-cy="status-panel"
		>
		</app-status-panel>
		<app-log-out-button
			class="top-bar__button"
			data-cy="top-bar-button"
		></app-log-out-button>
	</div>
</div>
