import { Injectable } from '@angular/core';

import { from, Observable, Subscriber, timer } from 'rxjs';
import { concatMap, finalize, switchMap, tap } from 'rxjs/operators';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { EsapActions } from '@app/core/configuration/esap';
import { ITransactionResponse } from '@app/core/services/transaction/transaction-types';
import { IError } from '@app/core/error/types';
import { BonusPayReq } from '@app/core/net/http/api/models/bonus-pay';
import { CancelBonusReq } from '@app/core/net/http/api/models/cancel-bonus';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { HttpService } from '@app/core/net/http/services/http.service';
import { ResponseCacheService } from '@app/core/services/response-cache.service';
import { PrintService } from '@app/core/net/ws/services/print/print.service';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { ApplicationAppId } from '@app/core/services/store/settings';
import { StorageKeys } from '@app/core/net/ws/api/models/storage/storage-models';
import { StorageGetResp } from '@app/core/net/ws/api/models/storage/storage-get';

import { HamburgerMenuService } from '@app/hamburger/services/hamburger-menu.service';
import { TotalCheckStorageService } from '@app/total-check/services/total-check-storage.service';
import { PeripheralService } from '@app/core/services/peripheral.service';
import {
	PARAM_ACTION_BET,
	PARAM_DEMO_BET,
	URL_ACTIONS, URL_CHECK,
	URL_DEMO,
	URL_LOTTERIES,
	URL_TICKETS
} from '@app/util/route-utils';
import { Router } from '@angular/router';
import { DialogError } from '@app/core/error/dialog';
import { LogOutService } from '@app/logout/services/log-out.service';

/**
 * Список статусов бонусной транзакции.
 * - {@link Request} - Начало транзакции. Выполняется запрос в ЦС.
 * - {@link SkipCancel} - Неуспешное завершение транзакции. Отмена производиться не будет (попытка выплаты с уже выплаченного билета).
 * - {@link Canceling} - Вызов отмены в результате неуспешной транзакции.
 * - {@link Canceled} - Неуспешное завершение транзакции. Отмена выполнена.
 * - {@link Printed} - Успешное завершение транзакции. Все билеты отпечатаны.
 */
enum BonusPayTransactionStatus {
	Request			= 'request',
	SkipCancel		= 'skip-cancel',
	Canceling		= 'canceling',
	Canceled		= 'canceled',
	Printed			= 'printed',
}

/**
 * Модель состояния транзакции выплаты бонуса.
 */
interface BonusPayTransactionState {

	/**
	 * Идентификатор бонуса.
	 */
	bonusId: string;

	/**
	 * Штрихкод бонусного билета.
	 */
	barcode: string;

	/**
	 * Время начала транзакции.
	 */
	start: number;

	/**
	 * Текущий статус транзакции.
	 */
	status?: BonusPayTransactionStatus;

	/**
	 * Время окончания транзакции.
	 * Отсутствие данного параметра может означать незавершенную транзакцию.
	 */
	end?: number;

}

/**
 * Сервис предоставляющий функционал по управлению финансовой транзакцией регистрации и печати бонусных билетов.
 */
@Injectable({
	providedIn: 'root'
})
export class BonusPayTransactionService {

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Последняя транзакция по выплате бонуса.
	 * @private
	 */
	private lastTransaction: BonusPayTransactionState;

	/**
	 * Конструктор сервиса.
	 *
	 * @param {HamburgerMenuService} hamburgerMenuService Сервис управления гамбургер меню.
	 * @param {AppStoreService} appStoreService Сервис хранилища приложения.
	 * @param {DialogContainerService} dialogInfoService Сервис диалоговых окон.
	 * @param {HttpService} httpService Сервис HTTP запросов.
	 * @param {ResponseCacheService} responseCacheService Сервис кэширования ответов.
	 * @param {PrintService} printService Сервис печати.
	 * @param {StorageService} storageService Сервис браузерного хранилища.
	 * @param {TotalCheckStorageService} totalCheckStorageService Сервис для работы с функционалом подсчета общей суммы по чекам, отменам и выплатам.
	 * @param {PeripheralService} peripheralService Сервис управления периферийными устройствами.
	 * @param router Сервис маршрутизации.
	 * @param logoutService Сервис выхода из системы.
	 */
	constructor(
		private readonly hamburgerMenuService: HamburgerMenuService,
		private readonly appStoreService: AppStoreService,
		private readonly dialogInfoService: DialogContainerService,
		private readonly httpService: HttpService,
		private readonly responseCacheService: ResponseCacheService,
		private readonly printService: PrintService,
		private readonly storageService: StorageService,
		private readonly totalCheckStorageService: TotalCheckStorageService,
		private readonly peripheralService: PeripheralService,
		private readonly router: Router,
		private readonly logoutService: LogOutService
	) {
		console.log();
	}

	/**
	 * Вызвать транзакцию выплаты бонуса.
	 *
	 * @param {string} barcode Штрихкод билета, содержащего бонусный набор.
	 * @param {string} bonusId Идентификатор бонуса.
	 * @param tel Телефон игрока
	 * @param sms Проверочный код из СМС
	 * @returns {Observable<BonusPayTransactionState>} Возвращает текущую модель состояния транзакции.
	 */
	executeBonusRequest(barcode: string, bonusId: string, tel: string, sms: string): Observable<BonusPayTransactionState> {
		Logger.Log.i('BonusPayTransactionService', `executeBonusRequest -> barcode: ${barcode}, bonusId: ${bonusId}`)
			.console();

		let transactionError: IError;
		let response: ITransactionResponse;
		const instantGameCodes = this.appStoreService.Settings.getLotteryCodesByEsapActionName(EsapActions.EInstantRegBet);

		this.peripheralService.stopExternalDevices();

		// показать диалог с транзакцией покупки
		this.dialogInfoService.showTransactionDialog('dialog.in_progress', 'dialog.ticket_register_wait_info');
		this.dialogInfoService.updateTransactionDialog({extra: 'dialog.much_time_to_wait'});

		// выполнить запрос и печать билетов
		this.hamburgerMenuService.deactivateCancelButton();
		const request = new BonusPayReq(this.appStoreService, barcode, bonusId, tel, sms);

		return new Observable<BonusPayTransactionState>(subscriber => {
			// this.createTransactionState(barcode, bonusId, subscriber);
			from(this.httpService.sendApi(request))
				.pipe(
					finalize(() => {
						Logger.Log.i('BonusPayTransactionService', `executeBonusRequest -> transaction finished`)
							.console();

						this.peripheralService.startExternalDevices();

						if (!!transactionError) {
							if (transactionError.code === 719) {
								this.updateTransactionState(BonusPayTransactionStatus.SkipCancel, subscriber);
								subscriber.error(transactionError);
								subscriber.complete();
							} else {
								this.updateTransactionState(BonusPayTransactionStatus.Canceling, subscriber);
								this.cancelBonusTransaction(bonusId)
									.subscribe(
										ok => {
											this.dialogInfoService.hideAll();
											this.updateTransactionState(BonusPayTransactionStatus.Canceled, subscriber);
											subscriber.error(transactionError);
											subscriber.complete();
										},
										error => {
										}
									);
							}
						} else {
							this.dialogInfoService.hideAll();
							// this.updateTransactionState(BonusPayTransactionStatus.Printed, subscriber);
							// subscriber.complete();

							if (response.err_code === 0) {
								// обновить журнал
								this.totalCheckStorageService.paymentAndRegisterBonus(request, response);

								this.routeLotteries();
								this.dialogInfoService.showOneButtonInfo('dialog.info_title', 'dialog.op_successful', {
									text: 'dialog.dialog_button_continue',
									click: () => {}
								});
							} else {
								this.dialogInfoService.showOneButtonError(new DialogError(response.err_code, response.err_descr), {
									click: () => {
										if ((response.err_code === 4313) || (response.err_code === 4318)) {
											this.logoutService.logoutOperator();
										} else {
											this.router.navigate([`/${URL_TICKETS}/${URL_CHECK}`])
												.catch(err => console.error(err));
										}
									},
									text: 'dialog.dialog_button_continue'
								});
							}
						}
					})
				)
				.subscribe(
					(next: ITransactionResponse) => {
						response = next;
					},
					error => {
						transactionError = error;
					}
				);
		});
	}

	/**
	 * Проверить наличие не завершенной транзакции и отменить ее
	 * @returns {Observable<any>}
	 */
	checkAndCancelUnfinishedBonusTransaction(): Observable<any> {
		Logger.Log.i('BonusPayTransactionService',	`checkAndCancelUnfinishedBonusTransaction`)
			.console();

		return new Observable(subscriber => {
			from(this.storageService.get(ApplicationAppId, [StorageKeys.BonusTransactionState]))
				.subscribe(
					(next: StorageGetResp) => {
						Logger.Log.i('BonusPayTransactionService', `checkAndCancelUnfinishedBonusTransaction -> loaded OK: %s`, next)
							.console();

						if (Array.isArray(next.data)) {
							const data = next.data.find(f => f.key === StorageKeys.BonusTransactionState);
							if (data) {
								const bt = JSON.parse(data.value) as BonusPayTransactionState;
								if (bt && isNaN(bt.end)) {
									Logger.Log.i('BonusPayTransactionService',
										`checkAndCancelUnfinishedBonusTransaction -> unfinished transaction found: ${bt.bonusId}`)
										.console();

									this.cancelBonusTransaction(bt.bonusId)
										.subscribe(
											next1 => {
												console.log('++++сссссссссс-next', next1);
											},
											error => {
												console.log('++++ссссссссссссс-error', error);
											}
										);
								}
							}
						}
					},
					error => {
						console.log('++++ttttttttttttt err', error);
					},
					() => {
						console.log('++++ttttttttttttt complete');
					}
				);
		});
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Отмена выплаты бонусного билета.
	 *
	 * @param {string} bonusId Идентификатор бонусного билета
	 * @returns {Observable<any>}
	 */
	private cancelBonusTransaction(bonusId: string): Observable<any> {
		Logger.Log.i('BonusPayTransactionService', `cancelBonusTransaction -> bonusId: ${bonusId}`)
			.console();

		this.peripheralService.stopExternalDevices();

		this.dialogInfoService.showNoneButtonsInfo('dialog.in_progress', 'dialog.ticket_cancel_wait_info');
		const requestCancel = new CancelBonusReq(this.appStoreService, bonusId);

		return new Observable(subscriber => {
			from(this.httpService.sendApi(requestCancel))
				.pipe(switchMap(() => timer(5000)))
				.subscribe(
					next => {
						console.log('+++xxx1', next);
						this.dialogInfoService.hideAll();
						subscriber.next(next);
						subscriber.complete();
					},
					error => {
						console.log('+++xxx2', error);
						// TODO: Диалог + повтор отмены
						subscriber.error(error);
						subscriber.complete();

						// new ApiError(undefined, 'dialog.ticket_cancel_last_error_repeat')
						// this.dialogInfoService.showOneButtonError(new DialogError(error.code, 'dialog.ticket_cancel_last_error_repeat'), {
						// 	click: () => {
						// 		this.cancelBonusTransaction(bonusId);
						// 	},
						// 	text: 'dialog.dialog_button_continue'
						// });
					},
					() => {
						this.peripheralService.startExternalDevices();
					});
		});
	}

	/**
	 * Переход на страницу лотерей.
	 * @private
	 */
	private routeLotteries(): void {
		this.peripheralService.startExternalDevices();

		// определить страницу возврата и перейти к ней
		let returnUrl = URL_LOTTERIES;
		const urlTree = this.router.parseUrl(this.router.url);
		if (urlTree.queryParamMap.has(PARAM_ACTION_BET)) {
			returnUrl = URL_ACTIONS;
		}
		if (urlTree.queryParamMap.has(PARAM_DEMO_BET)) {
			returnUrl = URL_DEMO;
		}
		this.router.navigate([returnUrl])
			.catch(err => Logger.Log.e('TransactionService', `routeLotteries -> can't navigate to return page: ${err}`)
				.console());
	}

	/**
	 * Создать новую модель транзакции выплаты бонуса.
	 *
	 * @param {string} barcode Штрихкод билета, содержащего бонусный набор.
	 * @param {string} bonusId Идентификатор бонуса.
	 * @param {Subscriber<BonusPayTransactionState>} subscriber
	 */
	private createTransactionState(barcode: string, bonusId: string, subscriber: Subscriber<BonusPayTransactionState>): void {
		this.lastTransaction = {
			barcode,
			bonusId,
			start: Date.now()
		};

		this.updateTransactionState(BonusPayTransactionStatus.Request, subscriber);
	}

	/**
	 * Обновление информации о состоянии текущей транзакции в Storage-сервисе.
	 *
	 * @param {BonusPayTransactionStatus} status Статус транзакции.
	 * @param {Subscriber<BonusPayTransactionState>} subscriber Подписчик на состояние транзакции.
	 */
	private updateTransactionState(status: BonusPayTransactionStatus, subscriber: Subscriber<BonusPayTransactionState>): void {
		Logger.Log.i('BonusPayTransactionService', `updateTransactionState -> status: ${status}`)
			.console();

		const updatedTransaction: BonusPayTransactionState = {...this.lastTransaction};
		switch (status) {
			case BonusPayTransactionStatus.Request:
				break;

			case BonusPayTransactionStatus.Canceling:
				break;

			case BonusPayTransactionStatus.Canceled:
				updatedTransaction.end = Date.now();
				break;

			case BonusPayTransactionStatus.SkipCancel:
				updatedTransaction.end = Date.now();
				break;

			case BonusPayTransactionStatus.Printed:
				updatedTransaction.end = Date.now();
				break;

			default:
				break;
		}
		updatedTransaction.status = status;

		this.lastTransaction = updatedTransaction;
		const data = [{
			key: StorageKeys.BonusTransactionState,
			value: JSON.stringify(updatedTransaction)
		}];

		from(this.storageService.put(ApplicationAppId, data))
			.subscribe(
				next => {
					// console.log('+++++++++updateTransactionState next', next);
					// this.state = this.store.state;
					// this.tickets = this.store.tickets ? this.cloneTickets(this.store.tickets) : this.store.tickets;
				},
				error => {
					Logger.Log.e('BonusPayTransactionService', `updateTransactionState storage update error: ${error.message}`)
						.console();
				},
				() => {
					subscriber.next(updatedTransaction);
				}
			);
	}

}
