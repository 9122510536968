import {
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	EmbeddedViewRef,
	Injectable,
	Injector
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import { Logger } from '@app/core/net/ws/services/log/logger';
import { ChangeLogComponent } from '../components/change-log/change-log.component';
import { IChangeLog, IChangeLogRowItem } from '../interfaces/ichange-log';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { AppType } from '@app/core/services/store/settings';
import { environment } from '@app/env/environment';

/**
 * Сервис для работы с логом изменений.
 */
@Injectable({
	providedIn: 'root'
})
export class ChangeLogService {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Последняя версия софта.
	 */
	latestVersion: string;

	/**
	 * Сгенерированный HTML контент списка изменений.
	 */
	parsedChangelog: string;

	/**
	 * Сгенерированный HTML контент только с последними изменениями.
	 */
	parsedLatestChangelog: string;

	/**
	 * Терминал ли?
	 */
	isTerminal = this.appStoreService.Settings.appType === AppType.ALTTerminal;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Ссылка на компонент окна.
	 * @private
	 */
	private componentRef: ComponentRef<ChangeLogComponent>;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {ComponentFactoryResolver} componentFactoryResolver Фабрика компонентов.
	 * @param {ApplicationRef} appRef Ссылка на контейнер приложения.
	 * @param {Injector} injector Инжектор зависимостей.
	 * @param {TranslateService} translate Сервис перевода.
	 * @param {HttpClient} httpClient Сервис HTTP запросов.
	 * @param {AppStoreService} appStoreService Сервис хранилища приложения.
	 */
	constructor(
		private readonly componentFactoryResolver: ComponentFactoryResolver,
		private readonly appRef: ApplicationRef,
		private readonly injector: Injector,
		private readonly translate: TranslateService,
		private readonly httpClient: HttpClient,
		private readonly appStoreService: AppStoreService
	) {}

	/**
	 * Показать окно с изменениями по версии.
	 * @param {boolean} onlyLatest Показывать только последние изменения.
	 */
	showChangeLog(onlyLatest = false): void {
		this.httpClient.get(`assets/webchangelog.json`)
			.subscribe((response: IChangeLog) => {

				this.requestChangelogHandler(response);

				Logger.Log.i('ChangeLogService', `show changelog`)
					.console();

				// создать компонент окна
				this.componentRef = this.componentFactoryResolver
					.resolveComponentFactory(ChangeLogComponent)
					.create(this.injector);
				this.componentRef.instance.changeLogService = this;
				this.componentRef.instance.isOnlyLatestChanges = onlyLatest;
				this.appRef.attachView(this.componentRef.hostView);
				const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
				document.body.appendChild(domElem);
			});
	}

	/**
	 * Скрыть окно с изменениями по версии.
	 */
	hideDialog(): void {
		Logger.Log.i('ChangeLogService', `hide changelog`)
			.console();

		if (this.componentRef) {
			this.appRef.detachView(this.componentRef.hostView);
			this.componentRef.destroy();
			this.componentRef = undefined;
		}
	}

	/**
	 * Запросить список с изменениями.
	 */
	requestChangelog(): void {
		this.httpClient.get(`assets/webchangelog.json`)
			.subscribe(this.requestChangelogHandler.bind(this));
	}

	/**
	 * Сравнить текущую версию софта с версией из хранилища.
	 *
	 * @param {string} currentVersion Текущая версия софта.
	 * @param {string} storageVersion Версия софта из хранилища.
	 */
	isNewSoftwareVersions(currentVersion: string, storageVersion: string): boolean {
		const convertVersion = (version: string): number => {
			if (!!version) {
				const arr = version.split('.');
				if (arr.length === 3) {
					let d1 = Number.parseInt(arr[0], 10) * 1000000;
					d1 = isNaN(d1) ? 0 : d1;
					let d2 = Number.parseInt(arr[1], 10) * 1000;
					d2 = isNaN(d2) ? 0 : d2;
					let d3 = Number.parseInt(arr[2], 10);
					d3 = isNaN(d3) ? 0 : d3;

					return d1 + d2 + d3;
				}
			}

			return Number.MIN_SAFE_INTEGER;
		};

		return convertVersion(currentVersion) > convertVersion(storageVersion);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Обработчик ответа на запрос списка с изменениями.
	 * @param response Ответ на запрос списка с изменениями.
	 * @private
	 */
	private requestChangelogHandler(response: IChangeLog): void {
		if (response && Array.isArray(response.items)) {
			this.parsedChangelog = this.parseChangelog(response);
			this.parsedLatestChangelog = this.parseChangelog(response, false);
		} else {
			Logger.Log.e('ChangeLogService', `incorrect changelog data`)
				.console();
		}
	}

	/**
	 * Пропарсить полученные данные в HTML контент.
	 *
	 * @param {IChangeLog} data Данные с изменениями.
	 * @param {boolean} parseAllItems Парсить все элементы или только последние.
	 */
	private parseChangelog(data: IChangeLog, parseAllItems = true): string {
		const makeList = (listType: string, list: Array<IChangeLogRowItem>): string => {
			const lang = this.translate.currentLang;
			const header = this.translate.instant(`system-info.${listType}`);

			let html = '';
			if (Array.isArray(list) && list.length > 0) {
				list.forEach(li => html += `<label class="cl-item">${li[lang]}</label>`);
				html = `
					<label class="cl-header ${listType}">${header}</label>
					${html}
				`;
			}

			return html;
		};

		let result = '';
		if (data && Array.isArray(data.items)) {
			// сохранить последнюю версию
			data.items[0].version = environment.version;
			this.latestVersion = data.items[0].version;

			const arr = parseAllItems ? data.items : [data.items[0]];
			arr.forEach(item => {
				if (parseAllItems) {
					const txt = this.translate.instant(`system-info.list_of_changes`);
					result += `<div class="cl-version">${txt} <span>${item.version}</span></div>`;
				}

				result += makeList('added', item.added);
				result += makeList('changed', item.changed);
				result += makeList('fixed', item.fixed);
			});
		}

		return result;
	}
}
