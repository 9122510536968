import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MslBaseInput } from '@app/shared/components/msl-base-input';
import { timer } from 'rxjs';

/**
 * Компонент ввода пинкода с иконкой клавиатуры и виртуальной клавиатурой.
 */
@Component({
	selector: 'app-msl-input-pin',
	templateUrl: './msl-input-pin.component.html',
	styleUrls: ['./msl-input-pin.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: MslInputPinComponent,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: MslInputPinComponent,
			multi: true
		}
	]
})
export class MslInputPinComponent extends MslBaseInput implements   ControlValueAccessor, Validator {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {ElementRef} elementRef Ссылка на собственный DOM-элемент.
	 */
	constructor(
		private readonly elementRef: ElementRef
	) {
		super();
	}

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Количество цифр в пинкоде.
	 */
	@Input()
	pinCodeLength = 4;

	/**
	 * Значение автофокуса по-умолчанию.
	 */
	@Input()
	autoFocus = 100;

	/**
	 * Надпись сверху
	 */
	@Input()
	title: string;

	/**
	 * Надпись сверху об ошибке
	 */
	@Input()
	errorTitle: string;

	/**
	 * Выравнивать ли виртуальную клавиатуру по правому краю от поля ввода ?
	 */
	@Input()
	vkAlignRight = false;

	/**
	 * Выравнивать ли виртуальную клавиатуру по нижнему краю от поля ввода ?
	 */
	@Input()
	vkAlignBottom = false;

	/**
	 * Отступ от правого края
	 */
	@Input()
	rightPadding = 130;

	/**
	 * Фиксировать фокус на элементе ввода
	 */
	@Input()
	lockFocusOnInput = true;

	/**
	 * Показывать ли кнопку очистки
	 */
	@Input()
	showClearBtn = false;

	/**
	 * Показывать ли кнопку очистки, когда хоть что-то введено?
	 */
	@Input()
	alwaysShowClearBtn = false;

	/**
	 * Состояние ошибки
	 */
	@Input()
	errorState = false;

	/**
	 * Тип поля ввода
	 */
	@Input()
	type = 'text';

	// -----------------------------
	//  Output properties
	// -----------------------------
	/**
	 * Событие ввода символов
	 */
	@Output() readonly charsInput = new EventEmitter<Event>();

	/**
	 * Событие получения фокуса
	 */
	@Output() readonly focused = new EventEmitter<FocusEvent>();

	/**
	 * Событие потери фокуса
	 */
	@Output() readonly blured = new EventEmitter<FocusEvent>();

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Ссылка на элемент ввода
	 */
	@ViewChild('inputElement') inputElement: ElementRef;

	/**
	 * Значение поля ввода
	 */
	value = '';

	/**
	 * Массив для отображения пустых ячеек пинкода
	 */
	spacers = new Array(this.pinCodeLength);

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Установить позицию курсора в поле ввода
	 * @param target Поле ввода
	 * @param caretPos Позиция курсора
	 * @private
	 */
	private static setCaretPosition(target: HTMLInputElement, caretPos: number): void {
		if (target['createTextRange']) {
			const range = target['createTextRange']();
			range.move('character', caretPos);
			range.select();
		} else if (target.selectionStart) {
			target.setSelectionRange(caretPos, caretPos);
		}
	}

	/**
	 * Унаследованное событие от базового класса.
	 * Вызывается в коллбеке при изменении значения UI-элемента
	 * @param value Передаваемое значение
	 */
	private onChange = (value: string) => {};

	/**
	 * Унаследованное событие от базового класса.
	 */
	private onTouched = () => {};

	/**
	 * Функция для отслеживания изменений в массиве
	 * @param index Индекс элемента
	 * @param item Элемент
	 */
	trackByFn = (index, item) => item;

	// -----------------------------
	//  MslBaseInput
	// -----------------------------
	/**
	 * Установить фокус на элемент ввода
	 */
	setFocus(): void {
		(this.inputElement.nativeElement as HTMLInputElement).focus();
	}

	// -----------------------------
	//  ControlValueAccessor
	// -----------------------------
	/**
	 * Метод, который вызывается при изменении значения UI-элемента
	 * @param fn Передаваемая callback-функция
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Метод, который вызывается при касании к UI-элементу
	 * @param fn Передаваемая callback-функция
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	/**
	 * Метод для установки недоступности компонента
	 * @param isDisabled Флаг недоступности
	 */
	setDisabledState(isDisabled: boolean): void {
	}

	/**
	 * Метод для программного присвоения значения компоненту
	 * @param v Значение
	 */
	writeValue(v: string): void {
		this.value = v;
	}

	// -----------------------------
	//  Validator
	// -----------------------------
	/**
	 * Регистрирует функцию обратного вызова для выполнения при изменении входных данных валидатора
	 * @param fn Функция обратного вызова
	 */
	registerOnValidatorChange(fn: () => void): void {
	}

	/**
	 * Валидация компонента
	 * @param control Компонент
	 */
	validate(control: AbstractControl): ValidationErrors | null {
		return control.valid ? null : control.errors;
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Исправить позицию курсора в поле ввода
	 * @param inputElem Поле ввода
	 * @private
	 */
	private fixCursorPos(inputElem: HTMLInputElement): void {
		const txtValue = inputElem.value;
		const txtValueArr = txtValue.split('')
			.map(elem => isNaN(parseInt(elem, 10)) ? '0' : '1');
		const cursorPos = txtValueArr.lastIndexOf('1') + 1;
		const tmr = timer(200)
			.subscribe(() => {
				MslInputPinComponent.setCaretPosition(inputElem, cursorPos);
				tmr.unsubscribe();
			});
	}
	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик изменения модели компонента
	 * @param v Изменившееся значение
	 */
	onModelChange(v: string): void {
		this.onChange(v);
	}

	/**
	 * Обработчик нажатия кнопки очистки
	 */
	onClearHandler(): void {
		this.value = '';
		this.errorState = false;
		this.setFocus();
		this.onChange(this.value);
	}

	/**
	 * Обработчик получения фокуса
	 * @param event Передаваемое событие
	 */
	onFocusHandler(event: FocusEvent): void {
		this.fixCursorPos(event.target as HTMLInputElement);
		this.focused.emit(event);
	}

	/**
	 * Обработчик потери фокуса
	 * @param event Передаваемое событие
	 */
	onBlurHandler(event: FocusEvent): void {
		this.blured.emit(event);
	}

	/**
	 * Обработчик нажатия на поле ввода
	 * @param event Передаваемое событие
	 */
	onClickHandler(event: MouseEvent): void {
		this.fixCursorPos(event.target as HTMLInputElement);
	}

	/**
	 * Обработчик ввода символов в поле ввода
	 * @param event Передаваемое событие
	 */
	onInputHandler(event: Event): void {
		this.charsInput.emit(event);
	}
}
