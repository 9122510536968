import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { timer } from 'rxjs';

/**
 * Директива для подсветки места клика по экрану.
 * Активируется/деактивируется по параметру {@link isActive}.
 */
@Directive({
	selector: '[appMouseTrack]'
})
export class MouseTrackDirective implements OnInit, OnDestroy {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Признак активности подсветки курсора.
	 */
	@Input()
	isActive = true;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Элемент для подсветки места клика.
	 * @private
	 */
	private _element;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор директивы.
	 *
	 * @param {ElementRef} elementRef Ссылка на элемент, на котором установлена директива.
	 * @param {Renderer2} renderer Сервис для работы с DOM.
	 */
	constructor(
		private readonly elementRef: ElementRef,
		private readonly renderer: Renderer2
	) {}

	/**
	 * Слушатель события нажатия кнопки мыши.
	 * @param event Передаваемое событие.
	 */
	@HostListener('document:mousedown', ['$event'])
	onMouseDownHandler(event: MouseEvent): void {
		if (this.isActive && this._element) {
			this.renderer.setStyle(this._element, 'top', `${event.clientY - 50}px`);
			this.renderer.setStyle(this._element, 'left', `${event.clientX - 50}px`);
			this.renderer.setStyle(this._element, 'transform', 'scale(1)');
		}
	}

	/**
	 * Слушатель события отпускания кнопки мыши.
	 * @param event Передаваемое событие.
	 */
	@HostListener('document:mouseup', ['$event'])
	onMouseUpHandler(event: MouseEvent): void {
		if (this.isActive && this._element) {
			timer(300)
				.subscribe(() => {
					this.renderer.setStyle(this._element, 'transform', 'scale(0)');
				});
		}
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		const el = this.renderer.createElement('div');
		this.renderer.setStyle(el, 'cursor', 'pointer');
		this.renderer.setStyle(el, 'pointer-events', 'none');
		this.renderer.setStyle(el, 'background', `radial-gradient(#00fff0a0, #ffffff00)`);
		this.renderer.setStyle(el, 'z-index', `1000000`);
		this.renderer.setStyle(el, 'width', '100px');
		this.renderer.setStyle(el, 'height', '100px');
		this.renderer.setStyle(el, 'position', 'absolute');
		this.renderer.setStyle(el, 'border-radius', '100px');
		this.renderer.setStyle(el, 'transition', 'transform 0.5s');
		this.renderer.setStyle(el, 'transform', 'scale(0)');
		this.renderer.appendChild(document.body, el);

		this._element = el;
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		if (this._element) {
			this.renderer.removeChild(document.body, this._element);
		}
	}

}
