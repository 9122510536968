import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IRequest, IResponse } from '../api/types';
import { HttpRequestType, Transport } from '../transport';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { environment } from '@app/env/environment';
import { Mocks } from '../../../../features/mocks/mocks';

/**
 * Сервис предоставляющий функциональность по взаимодейсвию приложения
 * с внешними сервисами посредством HTTP протокола.
 */
@Injectable()
export class HttpService extends Transport {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {HttpClient} http
	 */
	constructor(
		private readonly http: HttpClient
	) {
		super(http);
	}

	/**
	 * Отправка запроса в ЦС по протоколу eSAP v3.
	 *
	 * @param {IRequest} request Объект, инкапсулирующий запрос и его параметры (тело).
	 * @param {number} timeout Таймаут на выполнения запроса в ms.
	 * @param {number} retryCount Кол-во повторов запроса до момента генерации ошибки.
	 * @returns {Promise<IResponse>}
	 */
	sendApi(request: IRequest, timeout?: number, retryCount?: number): Promise<IResponse> {
		return new Promise<IResponse>((resolve, reject) => {
			if (!request.url) {
				return reject(new Error('request url undefined'));
			}

			const body = request.params.toString();

			// тело запроса для логгирования
			const logBody = request.params;

			// удаление пароля из логгирования
			if (logBody.get('PASSWD')) {
				logBody.set('PASSWD', '');
			}

			if (logBody.get('NEWPASSWD')) {
				logBody.set('NEWPASSWD', '');
			}

			Logger.Log.i('HttpService', `send eSAP request: ${request.url} with params: ${logBody.toString()}`)
				.console();

			// || body.startsWith('ACTION=BOGetSID')
			// || body.startsWith('ACTION=BOGetClientList') || body.startsWith('ACTION=ResendAuth2')*/
			if (environment.mockData) {
				const mocks = new Mocks(request.url, body, {
					headers: request.headers,
					observe: 'response',
					responseType: 'text'
				});
				resolve(mocks.sendRequest());
			} else {
				const observable = this.httpClient.post(request.url, body, {
					headers: request.headers,
					observe: 'response',
					responseType: 'text'
				});

				this.sendRequest(HttpRequestType.Esap, observable, {onResult: resolve, onError: reject}, timeout, retryCount);
			}
		});
	}

	/**
	 * Отправка HTTP запроса типа GET.
	 *
	 * @param {string} url Url запроса (в стиле GET).
	 * @param {HttpHeaders} headers Дополнительные заголовки.
	 * @param {number} timeout Таймаут на выполнения запроса в ms.
	 * @param {number} retryCount Кол-во повторов запроса до момента генерации ошибки.
	 * @returns {Promise<string>}
	 */
	sendGet(url: string, headers: HttpHeaders, timeout?: number, retryCount?: number): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			if (!url) {
				return reject(new Error('request url undefined'));
			}

			Logger.Log.i('HttpService', `http send get request: ${url}`)
				.console();

			const observable = this.httpClient.get(
				url, {
					headers,
					observe: 'response',
					responseType: 'text'
				}
			);

			this.sendRequest(HttpRequestType.Get, observable, {onResult: resolve, onError: reject}, timeout, retryCount);
		});
	}
}
