import { Injector } from '@angular/core';

import { DurationMinute } from '@app/util/utils';

import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { IError } from '@app/core/error/types';
import { ICancelableRequest } from '@app/core/net/http/api/types';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { ApplicationAppId } from '@app/core/services/store/settings';
import { CancelFlag, ITransactionParams } from '@app/core/services/transaction/transaction-types';
import { CancelPrintReq } from '@app/core/net/http/api/models/cancel-print';
import { CancelLastTransactionReq } from '@app/core/net/http/api/models/cancel-last-transaction';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { StorageGetResp } from '@app/core/net/ws/api/models/storage/storage-get';
import { StorageKeys } from '@app/core/net/ws/api/models/storage/storage-models';

/**
 * Таймаут (в мс), в течении которого существует возможность отменить последнюю транзакцию.
 */
export const CANCEL_TIMEOUT = DurationMinute * 15;

/**
 * Таймаут (в мс) для открытого диалога, по истечению которого диалог закроется.
 */
export const INACTIVITY_DIALOG_TIMEOUT = DurationMinute * 3;

/**
 * Состояние транзакции.
 * Список стейтов:
 * - {@link Clean} - Начальное состояние
 * - {@link Success} - Все операции прошли успешно
 * - {@link Error} - Ответ ЦС содержит код ошибки
 * - {@link Canceled} - Успешно отменена
 * - {@link Request} - Запрос отослан
 * - {@link Response} - Ответ получен и не содержит ошибок
 * - {@link Print} - Перед отправкой на печать
 * - {@link Printed} - После успешной печати
 * - {@link Cancel} - Ручная отмена
 * - {@link PartialSuccess} - Часть билетов отменена
 * - {@link CannotBeUndone} - ???
 */
export enum TransactionState {
	Clean			= 0,
	Success			= 1,
	Error			= 2,
	Canceled		= 3,
	Request			= 4,
	Response		= 5,
	Print			= 6,
	Printed			= 7,
	Cancel			= 8,
	PartialSuccess	= 9,
	CannotBeUndone	= 10
}

/**
 * Коды ошибок ЦС после получения которых транзакция помечается "неотменяемая".
 */
export enum CancelErrorCode {
	/**
	 * Код ошибки, который возвращается ЦС, если транзакция не может быть отменена.
	 */
	Session = 4313
}

/**
 * Модель для хранения информации о билетах финансовой транзакции.
 */
export interface StorageTicket {
	/**
	 * Идентификатор билета.
	 */
	id: string;

	/**
	 * Описание билета.
	 */
	description: string;

	/**
	 * Стоимость билета.
	 */
	price: number;

	/**
	 * Напечатан ли билет.
	 */
	printed: boolean;

	/**
	 * Время печати.
	 */
	printTimestamp?: number;
}

/**
 * Возвращает объект {@link ITransactionParams} из запроса на регистацию (транзакции).
 * Эти данные необходимы для хранения в Storage-сервисе.
 *
 * @param {ICancelableRequest} request Объект с ответом ЦС на запрос регистрации ставки.
 * @param {LotteryGameCode} code Код лотереи.
 * @returns {ITransactionParams}
 */
const createTransactionParams = (request: ICancelableRequest, code: LotteryGameCode): ITransactionParams => {
	return {
		url: request.url,
		trans_id: request.trans_id,
		user_id: request.user_id,
		sess_id: request.sess_id,
		game_code: code
	};
};

/**
 * Модель для хранения данных о финансовой транзакции в Storage-сервисе.
 */
export class StorageTransaction {
	/**
	 * Текущее состояние транзакции.
	 */
	state: TransactionState = TransactionState.Clean;

	/**
	 * Параметры транзакции.
	 */
	params: ITransactionParams;

	/**
	 * Номера билетов для печати.
	 */
	tickets: Array<StorageTicket>;

	/**
	 * Дата (в мс) регистрации ставки в ЦС.
	 */
	regdate: number;
}

/**
 * Модель финансовой транзакции хранящейся в памяти терминала.
 */
export class Transaction {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Возвращает ссылку на контейнер, который содержит модель
	 * последней транзакции {@link StorageTransaction} хранимой в Storage-сервисе.
	 */
	get store(): StorageTransaction {
		return this._store;
	}

	/**
	 * Признак отсутствия проблем с текущей транзакцией.
	 */
	get isClean(): boolean {
		switch (this.state) {
			case TransactionState.Clean:
			case TransactionState.Success:
			case TransactionState.Error:
			case TransactionState.Canceled:
			case TransactionState.PartialSuccess:
			case TransactionState.CannotBeUndone:
				return true;
			default:
				return false;
		}
	}

	/**
	 * Идентифицирует есть ли хоть один напечатанный билет.
	 */
	get hasPrinted(): boolean {
		if (Array.isArray(this.tickets)) {
			return !!this.tickets.find(ticket => ticket.printed);
		}

		return false;
	}

	/**
	 * Текущее состояние транзакции.
	 */
	state: TransactionState = TransactionState.Clean;

	/**
	 * Список билетов.
	 */
	tickets: Array<StorageTicket>;

	/**
	 * Флаг отмены транзакции.
	 */
	cancelFlag: CancelFlag;

	/**
	 * Признак того, что транзакция не может быть отменена.
	 */
	canNotBeCancel = false;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Хранилище данных о транзакции.
	 * @private
	 */
	private _store: StorageTransaction = new StorageTransaction();

	/**
	 * Сервис для работы с хранилищем localStorage.
	 * @private
	 */
	private readonly storageService: StorageService;

	/**
	 * Сервис для работы с хранилищем приложения.
	 * @private
	 */
	private readonly appStoreService: AppStoreService;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор.
	 *
	 * @param {Injector} injector - инжектор зависимостей.
	 */
	constructor(
		private readonly injector: Injector
	) {
		this.storageService = injector.get(StorageService);
		this.appStoreService = injector.get(AppStoreService);
	}

	/**
	 * Создать запрос на отмену транзакции.
	 * Запрос будет создан в зависимости от того, есть ли напечатанные билеты или нет.
	 *
	 * @returns {CancelPrintReq | CancelLastTransactionReq}
	 */
	makeCancelRequest(): CancelPrintReq | CancelLastTransactionReq {
		return this.hasPrinted
			? new CancelPrintReq(
				this.appStoreService,
				this.store.params.url,
				this.store.params.trans_id,
				this.store.params.user_id,
				this.store.params.sess_id,
				this.store.params.game_code,
				this.cancelFlag,
				this.getCanceledTickets()
			)
			: new CancelLastTransactionReq(
				this.appStoreService,
				this.store.params.url,
				this.store.params.trans_id,
				this.store.params.user_id,
				this.store.params.sess_id,
				this.store.params.game_code,
				this.cancelFlag
			);
	}

	/**
	 * Загрузить данные о последней транзакции из Storage-сервиса.
	 *
	 * @returns {Promise<void>}
	 */
	load(): Promise<void> {
		Logger.Log.i('Transaction', `load last transaction from storage by key (%s)`, StorageKeys.StorageTransactionKey)
			.console();

		return this.storageService.get(ApplicationAppId, [StorageKeys.StorageTransactionKey])
			.then((response: StorageGetResp) => {
				Logger.Log.i('Transaction', `load OK: %s`, response)
					.console();

				if (Array.isArray(response.data)) {
					const ts = response.data.find(f => f.key === StorageKeys.StorageTransactionKey);
					if (ts) {
						// заполняем модель последней транзакцией
						this._store = JSON.parse(ts.value) as StorageTransaction;

						this.cancelFlag = CancelFlag.Auto_PowerOff;
						this.state = this.store.state;
						this.tickets = this.store.tickets ? this.cloneTickets(this.store.tickets) : this.store.tickets;
					} else {
						this._store = new StorageTransaction();
					}
				}
			})
			.catch((error: IError) => {
				Logger.Log.e('Transaction', `load ERROR: ${error.message}`)
					.console();

				throw new Error(`fatal error on loading transaction from storage: ${error.message}`);
			});
	}

	/**
	 * Возвращает описание последнего напечатанного билета.
	 *
	 * @returns {string}
	 */
	getLastPrintedDesc(): string {
		if (!Array.isArray(this.tickets)) {
			return undefined;
		}

		const arr = this.tickets
			.filter(f => f.printed)
			.sort((a, b) => b.printTimestamp - a.printTimestamp);

		return arr.length > 0 ? arr[0].description : undefined;
	}

	/**
	 * Возвращает список напечатанных билетов.
	 *
	 * @returns {Array<StorageTicket>}
	 */
	getPrintedTickets(): Array<StorageTicket> {
		const tickets: Array<StorageTicket> = [];
		if (this.tickets) {
			this.tickets.forEach(value => {
				if (value.printed) {
					tickets.push(value);
				}
			});
		}

		return tickets;
	}

	/**
	 * Возвращает кол-во напечатанных билетов.
	 *
	 * @returns {string}
	 */
	getNumberOfPrinted(): number {
		const tickets = this.getPrintedTickets();
		if (tickets) {
			return tickets.length;
		}

		return 0;
	}

	/**
	 * Возвращает общее кол-во билетов.
	 *
	 * @returns {string}
	 */
	getNumberOfTickets(): number {
		if (this.tickets) {
			return this.tickets.length;
		}

		return 0;
	}

	/**
	 * Идентифицирует, остался ли хоть один ненапечатанный билет.
	 *
	 * @param {Array<StorageTicket>} tickets Список билетов.
	 * @returns {boolean}
	 */
	hasUnPrinted(tickets: Array<StorageTicket>): boolean {
		if (tickets) {
			return !!tickets.find(ticket => !ticket.printed);
		}

		return false;
	}

	/**
	 * Возвращает список билетов для отмены.
	 *
	 * @returns {Array<string>}
	 */
	getCanceledTickets(): Array<string> {
		const tickets: Array<string> = [];
		if (this.tickets) {
			this.tickets.forEach(value => {
				if (!value.printed) {
					tickets.push(value.id);
				}
			});
		}

		return tickets;
	}

	/**
	 * Возвращает сумму отмененных чеков в копейках.
	 */
	getCanceledTicketsSum(): number {
		const arr = this.tickets
			.filter(f => !f.printed)
			.map(m => m.price);

		return arr.reduce((p, c) => p + c, 0);
	}

	/**
	 * Идентифицирует код ответа на запрос отмены транзакции как ошибку которая не будет иметь повторов.
	 *
	 * @param {number} code Код ответа.
	 * @returns {boolean}
	 */
	isCancelError(code: number): boolean {
		for (const error of Object.keys(CancelErrorCode)) {
			if (code === CancelErrorCode[error]) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Устанавливает текущую транзакцию в начальное состояние.
	 */
	cleanState(): void {
		this.store.state = TransactionState.Clean;
	}

	/**
	 * Регистрация успешной продажи.
	 *
	 * @returns {Promise<void>}
	 */
	successState(): Promise<void> {
		if (this.state !== TransactionState.Success) {
			this.store.state = TransactionState.Success;

			return this.update();
		}

		return Promise.resolve();
	}

	/**
	 * Устанавливает текущую транзакцию в состояние частичной отмены билетов.
	 *
	 * @returns {Promise<void>}
	 */
	partialSuccessState(): Promise<void> {
		this.store.state = TransactionState.PartialSuccess;

		return this.update();
	}

	/**
	 * Устанавливает текущую транзакцию в состояние ошибки.
	 *
	 * @returns {Promise<void>}
	 */
	errorState(): Promise<void> {
		this.store.state = TransactionState.Error;

		return this.update();
	}

	/**
	 * Устанавливает текущую транзакцию в состояние успешно отменена.
	 *
	 * @returns {Promise<void>}
	 */
	canceledState(): Promise<void> {
		this.store.state = TransactionState.Canceled;

		return this.update();
	}

	/**
	 * Регистрация запроса в ЦС.
	 *
	 * @param {ICancelableRequest} apiRequest идентификационные данные запроса
	 * @param {LotteryGameCode} code код лотереи
	 * @returns {Promise<void>}
	 */
	requestState(apiRequest: ICancelableRequest, code: LotteryGameCode): Promise<void> {
		this.store.state = TransactionState.Request;
		this.store.params = createTransactionParams(apiRequest, code);
		this.store.tickets = [];
		this.canNotBeCancel = false;

		return this.update();
	}

	/**
	 * Регистрация положительного ответа от ЦС.
	 *
	 * @param {Array<StorageTicket>} tickets Список билетов для печати.
	 * @returns {Promise<void>}
	 */
	responseState(tickets: Array<StorageTicket>): Promise<void> {
		this.store.state = TransactionState.Response;
		this.store.regdate = new Date().getTime();
		this.store.tickets = tickets;

		Logger.Log.i('Transaction', `register successful response from CS: %s, %s`, tickets, this.store)
			.console();

		return this.update();
	}

	/**
	 * Регистрация билета перед отправкой на печать.
	 *
	 * @returns {Promise<void>}
	 */
	printState(): Promise<void> {
		this.store.state = TransactionState.Print;

		return this.update();
	}

	/**
	 * Регистрация билета как напечатанного.
	 *
	 * @param {string} ticketId номер билета
	 * @returns {Promise<void>}
	 */
	printedState(ticketId: string): Promise<void> {
		this.setAsPrinted(ticketId, this.store.tickets);
		if (this.hasUnPrinted(this.store.tickets)) {
			this.store.state = TransactionState.Printed;
		} else {
			this.store.state = TransactionState.Success;
			this.store.tickets = [];
		}

		return this.update();
	}

	/**
	 * Регистрация текущей транзакции как начало ручной отмены.
	 *
	 * @returns {Promise<void>}
	 */
	cancelState(): Promise<void> {
		this.store.state = TransactionState.Cancel;
		this.store.tickets = [];

		return this.update();
	}

	/**
	 * Задать состояние транзакции как невозможно отменить в дальнейшем {@link TransactionState.CannotBeUndone}.
	 *
	 * @returns {Promise<void>}
	 */
	setCannotBeUndoneState(): Promise<void> {
		this.store.state = TransactionState.CannotBeUndone;

		return this.update();
	}

	/**
	 * Идентифицирует наличие достаточного кол-ва параметров для отмены транзакции.
	 *
	 * @returns {boolean}
	 */
	hasSufficientParams(): boolean {
		return !!this.store
			&& !!this.store.params
			&& !!this.store.params.url
			&& !!this.store.params.trans_id
			&& !!this.store.params.user_id
			&& !!this.store.params.sess_id
			&& Number.isInteger(this.store.params.game_code);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Устанавливает признак напечатан билет или нет.
	 *
	 * @param {string} ticketId идентификатор билета
	 * @param {Array<StorageTicket>} tickets список билетов
	 */
	private setAsPrinted(ticketId: string, tickets: Array<StorageTicket>): void {
		if (tickets) {
			const item = tickets.find(ticket => ticket.id === ticketId);
			if (item) {
				item.printed = true;
				item.printTimestamp = Date.now();
			}
		}
	}

	/**
	 * Клонирует список билетов.
	 *
	 * @param {StorageTicket[]} tickets оригинальный список билетов
	 * @returns {StorageTicket[]} копия списка билетов
	 */
	private cloneTickets(tickets: Array<StorageTicket>): Array<StorageTicket> {
		if (tickets) {
			return tickets.map(value => ({...value}));
		}
	}

	/**
	 * Обновление информации о состоянии текущей транзакции в Storage-сервисе.
	 *
	 * @returns {Promise<void>}
	 */
	private update(): Promise<void> {
		const data = [{
			key: StorageKeys.StorageTransactionKey,
			value: JSON.stringify(this.store)
		}];

		return this.storageService.put(ApplicationAppId, data)
			.catch((error: IError) => {
				Logger.Log.e('Transaction', `storage update error: ${error.message}`)
					.console();
				throw error;
			})
			.then(() => {
				this.state = this.store.state;
				this.tickets = this.store.tickets ? this.cloneTickets(this.store.tickets) : this.store.tickets;
			});
	}

}
