import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LogOutService } from '@app/logout/services/log-out.service';
import { IDropdownListItem } from '@app/shared/components/drop-down-list/drop-down-list.component';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';

/**
 * Список причин разлогинивания.
 */
const REASON_LIST = [
	// {label: 'header.shutdown_message_0', value: CUSTOM},
	// {label: 'header.shutdown_message_1', value: '1', rolesList: [TerminalRoles.OPERATOR]},
	// {label: 'header.shutdown_message_2', value: '2', rolesList: [TerminalRoles.OPERATOR]},
	// {label: 'header.shutdown_message_3', value: '3', rolesList: [TerminalRoles.OPERATOR]}
];

/**
 * Компонент-кнопка для разлогинивания оператора.
 */
@Component({
	selector: 'app-log-out-button',
	templateUrl: './log-out-button.component.html',
	styleUrls: ['./log-out-button.component.scss']
})
export class LogOutButtonComponent  {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {TranslateService} translate Сервис для работы с мультиязычностью.
	 * @param {DialogContainerService} dialogInfoService Сервис для работы с диалоговыми окнами.
	 * @param {LogOutService} logOutService Сервис для работы с разлогиниванием оператора.
	 */
	constructor(
		private readonly translate: TranslateService,
		private readonly dialogInfoService: DialogContainerService,
		private readonly logOutService: LogOutService
	) {}

	/**
	 * Обработчик нажатия кнопки "Разлогинить оператора".
	 * Оператору будет показано диалоговое окно с выпадающим списком и двумя кнопками.
	 * В данном окне необходимо указать причину отключения данного терминала.
	 * Причина отключения будет залогирована.
	 */
	onClickLogoutHandler(): void {
		Logger.Log.i('LogOutButtonComponent', `onClickLogoutHandler -> operator clicked on LOGOUT button`)
			.console();

		// после ввода причин выключения терминала вывести результат в лог
		const result = new Subject<IDropdownListItem>();
		const subs$ = result
			.subscribe((value: IDropdownListItem) => {
				subs$.unsubscribe();

				// если окно возвращет ключ с причиной - логируем его
				if (value && value.label) {
					const msg = this.translate.instant(value.label);

					Logger.Log.i('LogOutButtonComponent', `onClickLogoutHandler -> operator has logged off the terminal: ${msg}`)
						.console();
				}
			});

		this.dialogInfoService.showTwoButtonsWithDropdown('header.logout', '', REASON_LIST, {
				label: 'header.shutdown_message_header',
				value: undefined
			}, {
				first: {
					text: 'header.exit',
					click: () => this.logOutService.logoutOperator()
				},
				second: {
					text: 'header.back'
				}
			},
			result
		);
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------



}
