/**
 * Элемент структуры стека
 */
class Node<T> {

	/**
	 * Ссылка на предыдущий элемент
	 */
	previous: Node<T>;

	/**
	 * Данные элемента
	 */
	data: T;

	/**
	 * Конструктор класса
	 * @param data Данные элемента
	 * @param previous Ссылка на предыдущий элемент
	 */
	constructor(data: T, previous: Node<T>) {
		this.previous = previous;
		this.data = data;
	}

}

/**
 * Простой стек
 */
export class Stack<TData> {

	/**
	 * Ссылка на верхний элемент стека
	 * @private
	 */
	private _topNode: Node<TData> = undefined;

	/**
	 * Количество элементов в стеке
	 * @private
	 */
	private _count = 0;

	/**
	 * Возвращает количество элементов в стеке
	 */
	count(): number {
		return this._count;
	}

	/**
	 * Проверяет, пустой ли стек
	 */
	isEmpty(): boolean {
		return this._topNode === undefined;
	}

	/**
	 * Добавляет элемент в стек
	 * @param value Добавляемый элемент
	 */
	push(value: TData): void {
		// create a new Node and add it to the top
		const node = new Node<TData>(value, this._topNode);
		this._topNode = node;
		this._count++;
	}

	/**
	 * Удаляет элемент из стека
	 */
	pop(): TData {
		// remove the top node from the stack.
		// the node at the top now is the one before it
		const poppedNode = this._topNode;
		this._topNode = poppedNode.previous;
		this._count--;

		return poppedNode.data;
	}

	/**
	 * Возвращает верхний элемент стека
	 */
	peek(): TData {
		return this._topNode ? this._topNode.data : undefined;
	}

}
