import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { delay, filter, take, takeUntil, tap } from 'rxjs/operators';
import { DurationMinute } from '@app/util/utils';
import { TransactionInfoComponent } from '@app/core/dialog/types';

/**
 * Диалог отображения хода транзакции.
 */
@Component({
	selector: 'app-transaction-dialog',
	templateUrl: './transaction-dialog.component.html',
	styleUrls: ['./transaction-dialog.component.scss']
})
export class TransactionDialogComponent extends TransactionInfoComponent implements OnInit, OnDestroy {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Прогресс выполнения транзакции. Фейковый, потому что нет возможности получить реальный прогресс.
	 */
	fakeProgress$: Observable<number>;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {ChangeDetectorRef} changeDetector Детектор обнаружения изменений.
	 */
	constructor(
		readonly changeDetector: ChangeDetectorRef
	) {
		super(changeDetector);
	}

	/**
	 * Спрятать экстра-сообщение.
	 */
	hideExtraMessage(): void {
		this.extra = undefined;
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.fakeProgress$ = timer(0, 400)
			.pipe(take(DurationMinute * 10));

		// если в экстра-сообщение приходит непустое значение, показать его с задержкой в 15сек
		this.extraMessage$$
			.pipe(
				takeUntil(this.unsubscribe$$),
				filter(f => !!f),
				delay(15000)
			)
			.subscribe(msg => {
				this.extra = msg;
			});

		// обработать счетчик картинок
		// this.imageCounter$$
		// 	.pipe(takeUntil(this.unsubscribe$))
		// 	.subscribe(v => console.log('++++img', v));
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

}
