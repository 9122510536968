import { ApiRequest } from '@app/core/net/ws/api/types';

/**
 * Модель для вызова API Storage Service - del
 */
export class StorageDelReq extends ApiRequest {
	/**
	 * Данные для удаления
	 */
	data: Array<string>;

	/**
	 * Конструктор модели
	 * @param appId Идентификатор приложения
	 */
	constructor(appId: string) {
		super(appId, 'delete');
	}
}
