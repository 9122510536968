import * as StringFormatter from 'sprintf-js';
import * as TimeFormatter from 'strftime';

import { alignCenter, alignLeft, alignRight, convertObjectToString } from '@app/util/utils';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { init, Tag } from '@app/tickets-print/parser/tag';
import { AlignFormat } from '@app/core/net/ws/api/models/print/print-models';
import { IGetParam } from '@app/tickets-print/parser/iget-param';

/**
 * Тип формата.
 */
export type FormatType = 'str' | 'int' | 'uint' | 'long' | 'ulong' | 'timestamp';

/**
 * Модель XML тега <param>.
 */
export class ParamTag extends Tag {
	/**
	 * Имя параметра.
	 * @private
	 */
	private readonly name: string;

	/**
	 * Формат параметра.
	 * @private
	 */
	private readonly fmt?: string;

	/**
	 * Тип формата.
	 * @private
	 */
	private readonly type: FormatType = 'str';

	/**
	 * Ширина поля.
	 * @private
	 */
	private readonly width?: string;

	/**
	 * Выравнивание.
	 * @private
	 */
	private readonly align: AlignFormat = 'right';

	/**
	 * Значение параметра.
	 * @private
	 */
	private readonly value: number | string;

	/**
	 * Создание и инициализация на основе xml атрибутов.
	 *
	 * @param {IGetParam} param интерфейс доступа к параметрам
	 * @param {NamedNodeMap} attributes xml атрибуты
	 */
	constructor(param: IGetParam, attributes: NamedNodeMap) {
		super();
		init(this, attributes);

		Logger.Log.d('ParamTag', `create param name: ${this.name}`)
			.console();
		const nameParam = param.getParam(this.name);

		this.value = ParamTag.ValidateParam(this.name, nameParam, this.type);

	}

	/**
	 * Проверяет наличие параметра и соответствие фактического типа указанному типу.
	 * Предварительно параметр необходимо привести к строке, т.к. на входе может быть
	 * объект или массив, после чего пытаться кастить к заданному типу.
	 * если работаем с интом или timestamp то согласно структуре шаблонов на входе должно быть соот-щее значение
	 * параметра (не должно быть объектов и т.д), сгенерированный Exception будет указывать на ошибку в шаблоне.
	 *
	 * @param {string} name имя параметра
	 * @param param объект параметра
	 * @param {string} type указанный тип параметра
	 * @returns {string | number} проверенный объект параметра
	 */

	static ValidateParam(name: string, param: any, type: string): string | number {
		Logger.Log.d('ParamTag', `validate param name: ${name}`)
			.console();
		let value: number | string;
		let tValue: string;
		if (param != null) {
			tValue = convertObjectToString(param); // always not NULL, can be empty
		} else {
			throw new Error(`ParamTag ${name}: undefined`);
		}

		switch (type) {
			case 'int':
			case 'uint':
			case 'long':
			case 'ulong':
				value = Number.parseInt(tValue, 10);
				Logger.Log.d('ParamTag', `param value number to string: ${value}`)
					.console();
				if (Number.isNaN(value)) {
					throw new Error(`ParamTag name does"nt number: ${name}, param:${param}`);
				}

				break;

			case 'timestamp':
				value = tValue;
				break;

			default:
				value = tValue;
		}

		return value;
	}

	/**
	 * Форматирует строку в соответствии с шаблоном и аргументами шаблона.
	 *
	 * @param {string} srcType Тип параметра.
	 * @param {string} fmt Шаблон.
	 * @param {string | number} srcValue Значение для шаблона.
	 * @returns {string}
	 */
	static Format(srcType: string, fmt: string, srcValue: string | number): string {
		let value = srcValue;
		let type = srcType;

		if (value === null || value === undefined) {
			value = '';
		}

		if (type === null || type === undefined) {
			type = 'str';
		}

		if (type === 'str' && typeof value === 'string') {
			Logger.Log.d('ParamTag', `format string: fmt: ${fmt}, ${value}`)
				.console();

			if (fmt) {
				return StringFormatter.sprintf(fmt, value);
			}

			return value;
		}

		if ((type === 'int' || type === 'uint' || type === 'long' || type === 'ulong')) {
			if (typeof value === 'string') {
				value = Number.parseInt(value, 10);
				value = isNaN(value) ? 0 : value;
			}
			Logger.Log.d('ParamTag', `format number: fmt: ${fmt}, ${value}`)
				.console();
			if (fmt) {
				return StringFormatter.sprintf(fmt, value);
			}

			return value.toString();
		}

		if (type === 'timestamp' && typeof value === 'string') {
			Logger.Log.d('ParamTag', `format timestamp: fmt: ${fmt}, ${value}`)
				.console();

			if (fmt) {
				const date = new Date(value);
				if (Number.isNaN(date.getTime())) {
					throw new Error(`invalid time value: ${value}`);
				}

				return TimeFormatter(fmt, date);
			}
		}
	}

	/**
	 * Форматирует и выравнивает строку
	 * @returns {string}
	 */
	format(): string {
		const orig = ParamTag.Format(this.type, this.fmt, this.value);
		const width = Number.parseInt(this.width, 10);
		if (this.width && width > orig.length) {
			let value = orig;
			if (this.align === 'right') {
				value = alignRight(width, orig);
			}

			if (this.align === 'left') {
				value = alignLeft(width, orig);
			}

			if (this.align === 'center') {
				value = alignCenter(width, orig);
			}

			return value;
		}

		return orig;
	}

}
