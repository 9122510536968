import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { URL_AUTH } from '@app/util/route-utils';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { DialogContainerService } from '@app/core/dialog/services/dialog-container.service';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { BarcodeReaderService } from '@app/core/barcode/barcode-reader.service';
import { LotteriesService } from '@app/core/services/lotteries.service';
import { TotalCheckStorageService } from '@app/total-check/services/total-check-storage.service';
import { ApplicationAppId } from '@app/core/services/store/settings';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { StorageKeys } from '@app/core/net/ws/api/models/storage/storage-models';

/**
 * Сервис, обеспечивающий функционал для деавторизации юзера и управления выключением терминала.
 */
@Injectable({
	providedIn: 'root'
})
export class LogOutService {

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Флаг блокировки выключения терминала.
	 * @private
	 */
	private _shutdownIsBlocked = false;

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Действие на кнопку "Назад" на случай тупика
	 */
	deadLockAction: () => void = null;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {TranslateService} translate Сервис для работы с мультиязычностью
	 * @param {DialogContainerService} dialogInfoService Сервис для работы с диалоговыми окнами
	 * @param {AppStoreService} appStoreService Сервис для работы с хранилищем приложения
	 * @param {TotalCheckStorageService} totalCheckStorageService Сервис для работы с хранилищем истории чеков
	 * @param {BarcodeReaderService} barcodeReaderService Сервис для работы с баркод-сканером
	 * @param {LotteriesService} lotteriesService Сервис, управляющий лотереями и тиражами
	 * @param {Router} router Сервис для работы с маршрутизацией
	 * @param {StorageService} storageService Сервис для работы с хранилищем браузера
	 */
	constructor(
		private readonly translate: TranslateService,
		private readonly dialogInfoService: DialogContainerService,
		private readonly appStoreService: AppStoreService,
		private readonly totalCheckStorageService: TotalCheckStorageService,
		private readonly barcodeReaderService: BarcodeReaderService,
		private readonly lotteriesService: LotteriesService,
		private readonly router: Router,
		private readonly storageService: StorageService
	) {
	}

	/**
	 * Разлогинить оператора.
	 * Будет выполнена очистка необходимых параметров и остановлены сервисы.
	 */
	logoutOperator(): void {
		Logger.Log.i('LogOutService', `logoutOperator -> perform logout...`)
			.console();

		this.totalCheckStorageService.clearHistory();
		// this.stopPeripheralServices();
		this.lotteriesService.cleanAllFeatureUpdates();
		this.appStoreService.isLoggedIn$$.next(false);
		this.appStoreService.Settings.termCode = '';
		this.storageService.delSync(ApplicationAppId, [StorageKeys.Operator, StorageKeys.TermCodeKey]);

		this.dialogInfoService.hideAll();
		this.router.navigate([URL_AUTH])
			.then(() => {
				this.appStoreService.operator.next(undefined);
				this.appStoreService.Draws = undefined;
			})
			.catch(err => {
				Logger.Log.e('LogOutService', `logoutOperator -> can't navigate to authorization screen: ${err}`)
					.console();
			});
	}

	/**
	 * Активировать возможность выключения терминала.
	 */
	enableShutdown(): void {
		this._shutdownIsBlocked = false;
	}

	/**
	 * Отключить возможность выключения терминала.
	 */
	disableShutdown(): void {
		this._shutdownIsBlocked = true;
	}
}
