import { ChangeDetectorRef, Component } from '@angular/core';
import { ErrorDialogComponent } from '../../types';

/**
 * Диалог подтверждения с двумя кнопками.
 */
@Component({
	selector: 'app-confirm-two-buttons',
	templateUrl: '../two-buttons-error/two-buttons-error.component.html',
	styleUrls: ['./confirm-two-buttons.component.scss']
})
export class ConfirmTwoButtonsComponent extends ErrorDialogComponent  {

	// -----------------------------
	//  Public properties
	// -----------------------------



	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {ChangeDetectorRef} changeDetector Детектор обнаружения изменений.
	 */
	constructor(
		readonly changeDetector: ChangeDetectorRef
	) {
		super(changeDetector);
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------



}
