<div class="modal-dialog-background">
	<div class="modal-dialog-container">

		<!-- DIALOG TITLE -->
		<div class="modal-alt-title"
			 [ngClass]="isInfoDialog ? 'modal-alt-title_info' : 'modal-alt-title_error'"
		>{{ title | translate }}</div>

		<!-- DIALOG MESSAGE -->
		<div
			class="modal-alt-message"
			[innerHTML]="message | translate"
			>
		</div>

		<!-- DIALOG EXTRA MESSAGE -->
		<div
			class="modal-alt-extra-message"
			*ngIf="messageDetails"
			[innerHTML]="messageDetails | translate"
			>
		</div>

		<!-- EXTRA TEXT FOR ERROR CODE -->
		<div
			class="modal-alt-extra"
			*ngIf="extra"
			>
			{{ extra | translate }}
		</div>

		<!-- ERROR CODE -->
		<div
			class="modal-alt-code"
			*ngIf="code"
			>
			{{ code_translate | translate }}:
			<span class="modal-alt-code__value">№{{ code }}</span>
		</div>

		<!-- ПАНЕЛЬ КНОПОК -->
		<div class="modal-buttons-container">
			<button type="button" data-cy="modal-button"
				class="button button_theme_green modal-button"
				(click)="clickFirst()"
				>
				{{ buttons.first.text | translate }}
			</button>
		</div>
	</div>
</div>
<app-dialog-container></app-dialog-container>
