import { Stack } from '@app/util/stack';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { init, Tag } from '@app/tickets-print/parser/tag';
import { IGetParam } from '@app/tickets-print/parser/iget-param';
import { IFlowUnit } from '@app/tickets-print/parser/iflow-unit';

/**
 * Интерфейс модели доступа к параметрам секции
 */
export interface IStackParams {
	/**
	 * Корневая секция
	 */
	section_root?: Object;
}

/**
 * Модель xml тега <section> со всеми дочерними тегами
 */
export class SectionUnit extends Tag implements IFlowUnit {

	/**
	 * Имя секции
	 */
	name: string;

	/**
	 * Корень секции
	 * @private
	 */
	private readonly root: string;

	/**
	 * Индекс секции
	 * @private
	 */
	private readonly index?: string;

	/**
	 * Базовый индекс секции
	 * @private
	 */
	private readonly ibase = '0';

	/**
	 * Данные секции
	 * @private
	 */
	private stackData: IStackParams = {};

	/**
	 * Корневые параметры
	 * @private
	 */
	private rootParam: Array<Object>;

	/**
	 * Индекс корневых параметров
	 * @private
	 */
	private rootIndex = 0;

	/**
	 * Создание и инициализация на основе XML атрибутов.
	 *
	 * @param {Stack<IStackParams>} stack стек на котором будет создана секция(с ее параметрами)
	 * @param {IGetParam} param param интерфейс доступа к параметрам
	 * @param {NamedNodeMap} attributes xml атрибуты
	 */
	constructor(private stack: Stack<IStackParams>, param: IGetParam, attributes: NamedNodeMap) {
		super();

		init(this, attributes);
		this.rootParam = param.getParam(this.root);
		if (this.rootParam) {
			// this.stack.push(this.stackData);
			if (Array.isArray(this.rootParam) && this.rootParam.length > 0) {
				Logger.Log.d('SectionUnit', 'root: %s', this.root)
					.console();

				this.stack.push(this.stackData);

				// Ugly workaround if we have object against array
				// } else if (typeof this.rootParam === 'object') {
				//     const names = Object.getOwnPropertyNames(this.rootParam);
				//     if (names && names.length === 1) {
				//       const obj = {};
				//       obj[names[0]] = this.rootParam[names[0]];
				//       this.rootParam = [];
				//       this.rootParam.push(obj);
				//       Logger.Log.d('SectionUnit', 'root: %s', this.root).console();
				//       Logger.Log.d('SectionUnit', 'rootParam: %s', Logger.ToString(this.rootParam)).console();
				//       this.stack.push(this.stackData);
				//     } else {
				//       throw new Error('SectionUnit root object but hasn`t child or children more then one: ' + this.root);
				//     }
			} else {
				this.stack.push(this.stackData);
				// Section can be empty or absent
				// throw new Error(`SectionUnit root not array or empty: ${this.root}`);
			}
		} else {
			// Section can be empty or absent
			// throw new Error(`SectionUnit root not array or empty: ${this.root}`);
		}
	}

	/**
	 * Переход к следующему элементу секции (дочернему XML тегу).
	 *
	 * @returns {boolean}
	 */
	next(): boolean {
		if (this.rootParam && this.rootIndex < this.rootParam.length) {
			Logger.Log.d('SectionUnit', 'root: %s, index: %s', this.root, this.rootIndex)
				.console();
			this.stackData.section_root = this.rootParam[this.rootIndex];
			this.stackData[this.index] = Number.parseInt(this.ibase, 10) + this.rootIndex;
			this.rootIndex++;

			return true;
		} else if (typeof  this.rootParam === 'object' && this.rootIndex < 1) {
			this.stackData.section_root = this.rootParam;
			this.stackData[this.index] = 1;
			this.rootIndex++;

			return true;
		}

		return false;
	}

	/**
	 * Очистка данных секции.
	 */
	clean(): void {
		if (this.rootParam) {
			if (this.stack.isEmpty()) {
				// Section can be empty or absent
				// throw new Error('SectionUnit stack is empty');
			} else {
				this.stack.pop();
			}
		}
		this.stack = null;
		this.stackData = null;
		this.rootParam = null;
	}
}
