<div
	class="msl-input-with-eye-container"
	[class.msl-input-with-eye-container_disabled]="isDisabled"
	[class.msl-input-with-eye-container_error]="errorState"
>
	<div class="input-wrapper">
		<span
			*ngIf="label"
			class="msl-input-label"
			[class.msl-input-label_error]="errorState"
			[class.msl-input-label_active]="!!value"
			[class.msl-input-label_disabled]="isDisabled"
		>{{ label | translate }}:</span>

		<span class="eye-input-button">
			<input
				#inputElement
				class="eye-input"
				inputmode="numeric"
				pattern="\d*"
				type="text"
				[class.eye-input_error]="errorState"
				[attr.disabled]="isDisabled || null"
				[class.active]="!!value"
				[class.eye-input_secured]="secured"
				[id]="uniqID"
				[value]="value"
				(input)="onInputHandler($event)"
				(keydown)="onKeyDownHandler($event)"
				[attr.maxlength]="maxLength"
				[attr.minlength]="minLength"
				(focus)="onFocus($event)"
			/>
			<span
				class="eye-button"
				[class.eye-button_active]="value?.length > 0"
				[class.eye-button_pressed]="value?.length > 0 && !secured"
				[class.eye-button_disabled]="isDisabled"
				(click)="onEyeIconClickHandler()"
			>

				<svg class="eye-button__icon eye-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
					 xmlns="http://www.w3.org/2000/svg">
					<path class="eye-icon__eye" d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27
					16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24
					17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15
					12C15 10.34 13.66 9 12 9Z"></path>
				</svg>
			</span>
		</span>
	</div>
</div>
