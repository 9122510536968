import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TerminalRoles } from '@app/core/services/store/operator';

/**
 * Интерфейс, описывающий один элемент выпадающего списка.
 */
export interface IDropdownListItem {
	/**
	 * Метка элемента.
	 * Отображается непосредственно в списке.
	 */
	label: string;

	/**
	 * Значение элемента списка.
	 * Прописывается в атрибут кнопки <code>id</code>.
	 */
	value: string;

	/**
	 * Список ролей, для которых показывать причину выхода
	 */
	rolesList?: Array<TerminalRoles>;
}

/**
 * Компонент - выпадающий список.
 * Содержит в себе массив элементов типа {@link IDropdownListItem}.
 */
@Component({
	selector: 'app-drop-down-list',
	templateUrl: './drop-down-list.component.html',
	styleUrls: ['./drop-down-list.component.scss']
})
export class DropDownListComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Массив элементов типа {@link IDropdownListItem}, которые будут
	 * отображаться в выпадающем списке.
	 */
	@Input()
	dataProvider: Array<IDropdownListItem>;

	/**
	 * Заголовок к списку.
	 */
	@Input()
	titleText: string;

	/**
	 * Признак, указывающий на то, что список открыт (true) или закрыт (false).
	 */
	@Input()
	isExpandedList = false;

	/**
	 * Признак, указывающий на то, закрывать ли список по клику на свободную область.
	 */
	@Input()
	closeOnLostFocus = true;

	/**
	 * Признак, указывающий на то, закрывать ли список при выборе элемента.
	 */
	@Input()
	hideBySelect = true;

	/**
	 * Признак, указывающий на то, менять ли заголовок при выборе элемента.
	 */
	@Input()
	changeTitle = true;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие, указывающее на то, что элемент из списка был выбран.
	 * В теле события будет содержаться сам элемент {@link IDropdownListItem}.
	 *
	 * @type {EventEmitter<IDropdownListItem>}
	 */
	@Output()
	readonly itemChanged = new EventEmitter<IDropdownListItem>();

	/**
	 * Событие, указывающее на то, что список был свернут или развернут.
	 * В теле события будет содержаться сам список {@link DropDownListComponent}.
	 *
	 * @type {EventEmitter<DropDownListComponent>}
	 */
	@Output()
	readonly listChanged = new EventEmitter<DropDownListComponent>();

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Текущий выбранный элемент из списка.
	 */
	selectedItem: IDropdownListItem | undefined;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Слушатель нажатия на заголовок списка.
	 * Открывает или закрывает список.
	 */
	onClickTitleHandler(): void {
		this.isExpandedList = !this.isExpandedList;
		this.listChanged.emit(this);
	}

	/**
	 * Обработчика нажатия на один из элементов списка.
	 *
	 * @param {MouseEvent} event Передаваемое событие.
	 */
	onClickListItemHandler(event: MouseEvent): void {
		this.isExpandedList = !this.hideBySelect;

		const attr = (event.target as HTMLElement).getAttribute('value');
		if (attr) {
			const item = this.dataProvider.find(p => p.value === attr);

			if (item !== this.selectedItem) {
				this.selectedItem = item;
				this.itemChanged.emit(item);
			}
		}
	}

	/**
	 * Выбрать указанный элемент программно.
	 *
	 * @param {IDropdownListItem} item Элемент типа {@link IDropdownListItem}.
	 */
	selectItem(item: IDropdownListItem): void {
		this.selectedItem = item;
		this.itemChanged.emit(item);
	}

	/**
	 * Вспомогательная функция для отслеживания изменений в массиве элементов.
	 * @param index Индекс элемента.
	 * @param item Элемент.
	 */
	trackByFn = (index, item: IDropdownListItem) => item.value;

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Слушатель клика за элементавми выбора.
	 * Закрывает список.
	 *
	 * @param {MouseEvent} event
	 */
	@HostListener('document:click', ['$event'])
	onDocumentClickHandler(event: MouseEvent): void {
		const el: DOMTokenList = (event.target as HTMLElement).classList;
		if (!el.contains('dlc-title') && !el.contains('dlc-item') && this.closeOnLostFocus) {
			this.isExpandedList = false;
		}
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------


}
