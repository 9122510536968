<div class="modal-dialog-background" [class.at-top]="!!config?.top" [style.paddingTop.px]="config?.top">
	<div class="modal-dialog-container" [class.smooth]="config?.smooth">

		<!-- DIALOG TITLE -->
		<div class="modal-alt-title" [ngClass]="isInfoDialog ? 'modal-alt-title_info' : 'modal-alt-title_error'"
		>{{ title | translate }}</div>

		<!-- DIALOG MESSAGE -->
		<div
			class="modal-alt-message"
			[innerHTML]="message | translate"
			>
		</div>

		<!-- EXTRA TEXT FOR ERROR CODE -->
		<div
			class="modal-alt-extra"
			*ngIf="extra"
			>
			{{ extra | translate }}
		</div>

		<!-- ERROR CODE -->
		<div *ngIf="code" class="modal-alt-code">
			{{ code_translate | translate }}
			<span class="modal-alt-code__value">№{{ code }}</span>
		</div>

		<!-- DIALOG MESSAGE -->
		<div *ngIf="messageDetails"
			class="modal-alt-message"
			[innerHTML]="messageDetails | translate"
		>
		</div>

	</div>
</div>
<app-dialog-container></app-dialog-container>
