<div class="participants-list-container" data-cy="participants-list-container">
	<ng-container *ngFor="let item of participantsList; trackBy: trackByParticipants; let i = index">

		<!-- PROGRAM -->
		<div class="plc-row"
			 *ngIf="currentMode === ParticipantsListMode.Program; else resultsContainer"
			 data-cy="plc-row"
		>
			<div class="plc-row-date" data-cy="plc-row-date">{{ item.gameDate | date:shortDate }}</div>
			<div class="plc-row-position" data-cy="plc-row-position">{{ item.position }}</div>
			<div class="plc-row-teams-group" data-cy="plc-row-teams-group">
				<div class="plc-row-team1" data-cy="plc-row-team1">{{ item.teamName1 }}</div>
				<div class="plc-row-perc1" data-cy="plc-row-perc1">{{ item.outcome_forecast_1 }}%</div>
				<div class="plc-row-percX" data-cy="plc-row-percX">{{ item.outcome_forecast_X }}%</div>
				<div class="plc-row-perc2" data-cy="plc-row-perc2">{{ item.outcome_forecast_2 }}%</div>
				<div class="plc-row-team2" data-cy="plc-row-team2">{{ item.teamName2 }}</div>
				<div class="plc-row-tournament" data-cy="plc-row-tournament">{{ item.tournament }}</div>
			</div>
		</div>

		<!-- RESULTS -->
		<ng-template #resultsContainer>
			<div class="plc-row plc-row_header"
				 *ngIf="isVisibleHeader && i === 0"
				 data-cy="plc-row"
			>
				<div class="plc-row__cell plc-row__cell_num plc-row__cell_header"
					 data-cy="plc-row-cell"
				>{{ '№' }}</div>
				<div class="plc-row__group plc-row__group_header"
					 data-cy="plc-row-group"
				>
					<div class="plc-row__cell plc-row__cell_team1 plc-row__cell_header"
						 data-cy="plc-row-cell"
					>{{ 'lottery.team' | translate }}&nbsp;A</div>
					<div class="plc-row__cell plc-row__cell_sep plc-row__cell_header"
						 data-cy="plc-row-cell"
					>/</div>
					<div class="plc-row__cell plc-row__cell_team2 plc-row__cell_header"
						 data-cy="plc-row-cell"
					>{{ 'lottery.team' | translate }}&nbsp;B</div>
					<div class="plc-row__cell plc-row__cell_tournament plc-row__cell_header"
						 data-cy="plc-row-cell"
					>{{ 'lottery.tournament' | translate }}</div>
					<div class="plc-row__cell plc-row__cell_score plc-row__cell_header"
						 data-cy="plc-row-cell"
					>{{ 'lottery.score' | translate }}</div>
					<div class="plc-row__cell plc-row__cell_result plc-row__cell_header"
						 data-cy="plc-row-cell"
					>{{ 'lottery.result' | translate }}</div>
				</div>
			</div>
			<div class="plc-row" data-cy="plc-row" [ngClass]="currentMode">
				<div class="plc-row__cell plc-row__cell_num"
					 data-cy="plc-row-cell"
				>{{ item.position }}</div>
				<div class="plc-row__group" data-cy="plc-row-group">
					<div class="plc-row__cell plc-row__cell_team1"
						 data-cy="plc-row-cell"
					>{{ item.teamName1 }}</div>
					<div class="plc-row__cell plc-row__cell_sep"
						 data-cy="plc-row-cell"
					>-</div>
					<div class="plc-row__cell plc-row__cell_team2"
						 data-cy="plc-row-cell"
					>{{ item.teamName2 }}</div>
					<div class="plc-row__cell plc-row__cell_tournament"
						 data-cy="plc-row-cell"
					>{{ item.tournament }}</div>
					<div class="plc-row__cell plc-row__cell_score"
						 data-cy="plc-row-cell"
					>{{ item.score }}</div>
					<div class="plc-row__cell plc-row__cell_result"
						 data-cy="plc-row-cell"
					>{{ item.result }}</div>
				</div>
			</div>
		</ng-template>

	</ng-container>
</div>
