import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownListItem } from '@app/shared/components/drop-down-list/drop-down-list.component';
import { MslInputComponent } from '@app/shared/components/msl-input/msl-input.component';
import { AppStoreService } from '../../../services/store/app-store.service';
import { DialogWithDropdown } from '../../types';

/**
 * Пользовательский вариант выбора (ввод текста)
 */
export const CUSTOM = 'custom';

/**
 * Диалог с двумя кнопками и выпадающим списком.
 */
@Component({
	selector: 'app-two-buttons-with-dropdown',
	templateUrl: './two-buttons-with-dropdown.component.html',
	styleUrls: ['./two-buttons-with-dropdown.component.scss']
})
export class TwoButtonsWithDropdownComponent extends DialogWithDropdown implements OnInit {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Ссылка на компонент пользовательского ввода текста.
	 */
	@ViewChild('customMessage', { static: false })
	customMessage: MslInputComponent;

	/**
	 * Пользовательский вариант выбора (ввод текста)
	 */
	readonly custom = CUSTOM;

	/**
	 * Выбранный элемент выпадающего списка.
	 */
	currentSelection: IDropdownListItem;

	/**
	 * Деактивирована ли кнопка "Продолжить".
	 */
	get isDisabled(): boolean {
		return !this.currentSelection || (this.currentSelection.value === CUSTOM && this.customMessage && !this.customMessage.value);
	}

	/**
	 * Пустой ли список.
	 */
	isEmptyList = true;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {ChangeDetectorRef} changeDetector Детектор обнаружения изменений.
	 * @param {AppStoreService} appStoreService Сервис хранилища приложения.
	 */
	constructor(
		readonly changeDetector: ChangeDetectorRef,
		private readonly appStoreService: AppStoreService
	) {
		super(changeDetector);
	}

	/**
	 * Слушатель события изменения выбранного элемента.
	 *
	 * @param {IDropdownListItem} item Выбранный элемент.
	 */
	onItemChanged(item: IDropdownListItem): void {
		this.currentSelection = item;
		this.changeDetector.detectChanges();

		if (item.value === CUSTOM) {
			this.customMessage.setFocus();
		}
	}

	// -----------------------------
	//  ErrorDialogComponent
	// -----------------------------
	/**
	 * Обработчик нажатия на кнопку "Продолжить".
	 */
	clickFirst(): void {
		if (this.result) {
			if (this.customMessage) {
				this.currentSelection = {
					value: CUSTOM,
					label: this.customMessage.value
				};
			}

			this.result.next(this.currentSelection ? this.currentSelection : undefined);
		}

		super.clickFirst();
	}

	/**
	 * Обработчик нажатия на кнопку "Назад".
	 */
	clickSecond(): void {
		if (this.result) {
			this.result.next(undefined);
		}

		super.clickSecond();
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		const level = this.appStoreService.operator.value.accessLevel;

		this.itemsList = this.itemsList
			.filter(itm => (typeof itm.rolesList === 'undefined') || (Array.isArray(itm.rolesList) && itm.rolesList.indexOf(level) > -1));

		this.isEmptyList = this.itemsList.length < 2;
		if (this.isEmptyList) {
			this.message = 'header.confirmation';
		}
	}



}
