import { ApiRequest, IResponse, KeyValue } from '@app/core/net/ws/api/types';

/**
 * Модель для вызова API Storage Service - get
 */
export class StorageGetReq extends ApiRequest {
	/**
	 * Данные для получения
	 */
	data: Array<string>;

	/**
	 * Конструктор модели
	 * @param appId Идентификатор приложения
	 * @param ownerId Идентификатор владельца для которого получаем данные
	 */
	constructor(
		appId: string,
		private readonly ownerId: string
	) {
		super(appId, 'get');
	}
}

/**
 * Модель ответа на запрос API Storage Service - get
 */
export interface StorageGetResp extends IResponse {
	/**
	 * Полученные данные
	 */
	data: Array<KeyValue>;
}
