<div class="custom-scrollbar"
	 #customScroll
	 [ngClass]="{
	 'custom-scrollbar_scroll': showButtons || alwaysShowScroll,
	 'custom-scrollbar_overflow': showButtons,
	 'custom-scrollbar_small': isSmall,
	 'custom-scrollbar_buttons': (showButtons || alwaysShowScroll) && buttonsVisible
	 }"
	 data-cy="custom-scrollbar"
	 (scroll)="checkForActive()">

	<ng-content></ng-content>
</div>

<ng-container *ngIf="(showButtons || alwaysShowScroll) && buttonsVisible">
	<div class="btn-wrapper btn-wrapper_up" [class.btn-wrapper_small]="isSmall"
		 data-cy="btn-wrapper"
	>
		<button
			(click)="onClickButtonHandler(-50)"
			class="scroll-btn scroll-btn_up"
			[ngClass]="{'scroll-btn_active': upButtonActive, 'scroll-btn_small': isSmall}"
			data-cy="scroll-btn"
		>
		</button>
	</div>

	<div class="btn-wrapper btn-wrapper_down"
		 [class.btn-wrapper_small]="isSmall"
		 data-cy="btn-wrapper"
	>
		<button
			(click)="onClickButtonHandler(50)"
			class="scroll-btn scroll-btn_down"
			[ngClass]="{'scroll-btn_active': downButtonActive, 'scroll-btn_small': isSmall}"
			data-cy="scroll-btn"
		>
		</button>
	</div>
</ng-container>

