import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {BaseAlertIcon} from '@app/top-bar/base-alert-icon';
import {TopBarService} from '@app/top-bar/services/top-bar.service';
import {StatusIconType} from '@app/top-bar/enums/status-icon-type.enum';
import {PrintService} from '@app/core/net/ws/services/print/print.service';
import {PrinterState} from "@app/core/net/ws/api/models/print/print-models";

/**
 * Компонент иконки принтера.
 */
@Component({
	selector: 'app-printer-icon',
	templateUrl: './printer-icon.component.html',
	styleUrls: ['./printer-icon.component.scss']
})
export class PrinterIconComponent extends BaseAlertIcon implements OnInit, OnDestroy {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Возможные состояния принтера
	 */
	readonly PrinterState = PrinterState;

	/**
	 * Готов ли принтер?
	 */
	printerReady: boolean;

	/**
	 * Состояние принтера
	 */
	printerState: PrinterState;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {PrintService} printService Сервис принтера
	 * @param {TopBarService} topBarService Сервис верхней панели
	 * @param {ElementRef} elementRef Ссылка на собственный DOM-элемент
	 */
	constructor(
		private readonly printService: PrintService,
		readonly topBarService: TopBarService,
		readonly elementRef: ElementRef
	) {
		super();
	}

	/**
	 * Получить статус принтера в виде языкового ключа
	 */
	get parseStatus(): string {
		const status = this.printService.getStatus();

		return status === PrinterState.Disconnected ? 'printer.service_is_not_working' :
			status === PrinterState.OnLine ? 'printer.is_working' : 'printer.is_not_working';
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		// подписаться на события принтера
		this.printerReady = this.printService.isReady();
		this.printService.statusEvent$$
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(() => {
				this.printerReady = this.printService.isReady();
				this.printerState = this.printService.getStatus();

				const status = this.topBarService.statusPanelItem$$.value;
				if (status && status.iconType === StatusIconType.Printer) {
					this.topBarService.statusPanelItem$$.next({...status, statusText: this.parseStatus});
				}
			});
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

}
