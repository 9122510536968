import { Subject, Subscription, timer } from 'rxjs';
import { RoutedTransportType } from './routed-transport';
import { SimpleTransportType } from './simple-transport';
import { Logger } from '@app/core/net/ws/services/log/logger';

/**
 * Интервал в секундах перед очередным повтором подключения к сервису.
 */
export const SERVICE_CHECK_INTERVAL = 5;

/**
 * Типы состояния транспорта.
 * Возможные значения состояния:
 * - {@link READY} - транспорт работает
 * - {@link ERROR} - транспорт не работает
 */
export enum TransportState {
	READY = 0,
	ERROR = 1
}

/**
 * Интерфейс модели наблюдателя за изменением состояния транспорта.
 */
export interface ITransportEventsObserver {
	/**
	 * Событие готовности транспорта.
	 */
	ready?(): void;

	/**
	 * Событие ошибки транспорта.
	 * @param e Событие ошибки.
	 */
	error?(e: Event): void;
}

/**
 * Интерфейс модели уведомлений об изменении состояния транспорта.
 */
export interface ITransportEvent {
	/**
	 * Тип состояния транспорта.
	 */
	type: TransportState;
	/**
	 * Событие транспорта.
	 */
	event: Event;
}

/**
 * Абстрактная модель транспорта.
 */
export abstract class AbstractTransport {

	/**
	 * Поток событий транспорта.
	 * @protected
	 */
	protected readonly eventsListeners: Subject<ITransportEvent> = new Subject();

	/**
	 * Тэг транспорта.
	 * @protected
	 */
	protected readonly Tag: string;

	/**
	 * Ссылка на WebSocket.
	 * @protected
	 */
	protected ws: WebSocket;

	/**
	 * URL для подключения к Web-сокету.
	 * @protected
	 */
	protected url: string;

	/**
	 * Таймаут для повторного подключения к веб-сокету.
	 * @protected
	 */
	protected retryTimeout: number;

	/**
	 * Наблюдаемая переменная с потоком сообщений.
	 */
	wsObserver$$: Subject<MessageEvent>;

	/**
	 * Подписка на поток пинг-сообщений.
	 * @protected
	 */
	protected heartbeat: Subscription;

	/**
	 * Признак изменения состояния транспорта.
	 * @protected
	 */
	protected isStateChange = true;

	/**
	 * Конструктор абстрактного транспорта.
	 *
	 * @param {RoutedTransportType | SimpleTransportType} type Тип транспорта.
	 */
	protected constructor(
		type: RoutedTransportType | SimpleTransportType
	) {
		this.Tag = `${type}Transport`;
	}

	/**
	 * Монитор состояния транспорта (соединения с удаленной стороной).
	 *
	 * @param {ITransportEventsObserver} observer Наблюдатель за соединением.
	 * @returns {Subscription} Подписка на наблюдение за соединением.
	 */
	stateMonitor(observer: ITransportEventsObserver): Subscription {
		return this.eventsListeners.subscribe(value => {
			if (value.type === TransportState.READY) {
				observer.ready();
			}

			if (value.type === TransportState.ERROR) {
				observer.error(value.event);
			}
		});
	}

	/**
	 * Возвращает состояние транспорта (соединения с удаленной стороной).
	 *
	 * @returns {TransportState}
	 */
	getState(): TransportState {
		if (this.ws && this.ws.readyState === WebSocket.OPEN) {
			return TransportState.READY;
		}

		return TransportState.ERROR;
	}

	/**
	 * Прекратить повторные попытки подключения к сервису.
	 */
	stopRetryConnection(): void {
		this.retryTimeout = 0;
	}

	/**
	 * Закрытие соединение.
	 */
	protected closeConnection(): void {
		if (this.ws && this.ws.readyState === WebSocket.OPEN) {
			this.ws.close();
		}
	}

	/**
	 * Сбросить подключение к веб-сокету.
	 * @param event Событие закрытия сокета.
	 * @protected
	 */
	protected resetState(event: CloseEvent | Event): void {
		this.ws = undefined;
	}

	/**
	 * Слушатель открытия сокета.
	 *
	 * @param {Event} event Событие открытия сокета.
	 */
	protected onWSOpenHandler(event: Event): void {
		Logger.Log.i('AbstractTransport', `onWSOpenHandler[${this.Tag}] -> type: (${event.type})`)
			.console();

		this.isStateChange = true;
		this.eventsListeners.next({type: TransportState.READY, event});
	}

	/**
	 * Слушатель закрытия сокета.
	 *
	 * @param {CloseEvent} event Событие закрытия сокета.
	 */
	protected onWSCloseHandler(event: CloseEvent): void {
		Logger.Log.i('AbstractTransport', `onWSCloseHandler[${this.Tag}] -> is clean? (%s), code: (%s), reason: ('%s')`,
				event.wasClean, event.code, event.reason)
			.console();

		this.resetState(event);

		if (event.wasClean) {
			// this.wsObserver$$.complete();
		} else {
			this.wsObserver$$.error(event);
		}

		this.wsObserver$$.complete();

		if (this.retryTimeout > 0) {
			// window.setTimeout(() => this.open(), this.retryTimeout);
			timer(this.retryTimeout)
				.subscribe(() => this.open());
		}
	}

	/**
	 * Слушатель ошибки сокета.
	 *
	 * @param {Event} event Событие ошибки сокета.
	 */
	protected onWSErrorHandler(event: Event): void {
		Logger.Log.e('AbstractTransport', `onWSErrorHandler[${this.Tag}] -> type: (${event.type})`)
			.console();

		this.resetState(event);
		this.wsObserver$$.error(event);
	}

	/**
	 * Слушатель получения данных от сокета.
	 *
	 * @param {MessageEvent} event Событие получения данных от сокета.
	 */
	protected onWSMessageHandler(event: MessageEvent): void {
		Logger.Log.d('AbstractTransport', `onWSMessageHandler[${this.Tag}] -> ${event.data}`)
			.console();

		let event2 = event;
		const eventData = JSON.parse(event.data);

		// if (eventData?.response?.docStatus === 'in-queue') {
		// 	eventData.response.errorCode = 0;
		// 	eventData.response.errorDesc = 'Operation complete';
		// 	event2 = {...event, data: JSON.stringify(eventData)};
		// 	console.log('event2 =', event2);
		// }

		// if (eventData?.response?.docStatus === 'printing') {
		// 	eventData.notification = {...eventData.response, event: 'docStatusChanged'};
		// 	delete eventData.response;
		// 	event2 = {...event, data: JSON.stringify(eventData)};
		// 	console.log('event2 =', event2);
		// }

		if (eventData?.notification?.docStatus === 'Operation complete') {
			eventData.notification.docStatus = 'printComplete';
			event2 = {...event, data: JSON.stringify(eventData)};
			console.log('event2 =', event2);
		}

		this.wsObserver$$.next(event2);
	}

	// -----------------------------
	//  Abstract functions
	// -----------------------------

	/**
	 * Соединение с удаленной стороной.
	 */
	protected abstract open(): void;

	/**
	 * Инициализация и установка соединения с удаленной стороной.
	 *
	 * @param {string} url Путь подключения к удаленной стороне.
	 * @param {string} retryTimeout Время ожидания установки соединения до повтора (в секундах).
	 */
	protected abstract create(url: string, retryTimeout: number): void;

	// -----------------------------
	//  Private functions
	// -----------------------------

}
