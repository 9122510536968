import { Component, Input } from '@angular/core';
import { DATE_TEMPLATE_DD_MM_YYYY_HH_MM } from '@app/util/utils';

/**
 * Режимы отображения списка участников.
 */
export enum ParticipantsListMode {
	/**
	 * Программа соревнований.
	 */
	Program = 'program',
	/**
	 * Результаты соревнований.
	 */
	Results = 'results'
}

/**
 * Интерфейс элемента списка участников.
 */
export interface IParticipantsListItem {
	/**
	 * Позиция в списке.
	 */
	position: number;
	/**
	 * Дата проведения матча.
	 */
	gameDate: Date;
	/**
	 * Название команды 1.
	 */
	teamName1: string;
	/**
	 * Название команды 2.
	 */
	teamName2: string;
	/**
	 * Название турнира.
	 */
	tournament: string;
	/**
	 * Прогноз исхода матча - выиграет команда 1.
	 */
	outcome_forecast_1: string;
	/**
	 * Прогноз исхода матча - ничья.
	 */
	outcome_forecast_X: string;
	/**
	 * Прогноз исхода матча - выиграет команда 2.
	 */
	outcome_forecast_2: string;
	/**
	 * Очки.
	 */
	score?: string;
	/**
	 * Результат.
	 */
	result?: string;
}

/**
 * Компонент - список участников соревнований в лотерее Спортпрогноз.
 */
@Component({
	selector: 'app-participants-list',
	templateUrl: './participants-list.component.html',
	styleUrls: ['./participants-list.component.scss']
})
export class ParticipantsListComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------
	/**
	 * Список участников.
	 */
	@Input()
	participantsList: Array<IParticipantsListItem>;

	/**
	 * Режим отображения списка участников.
	 */
	@Input()
	currentMode: ParticipantsListMode = ParticipantsListMode.Program;

	/**
	 * Отображать ли заголовок таблицы.
	 */
	@Input()
	isVisibleHeader = false;

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Короткий формат записи даты и времени.
	 */
	readonly shortDate = DATE_TEMPLATE_DD_MM_YYYY_HH_MM;

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Режимы отображения списка участников.
	 */
	readonly ParticipantsListMode = ParticipantsListMode;

	/**
	 * Функция для отслеживания изменений в массиве участников.
	 * @param index индекс элемента
	 * @param item элемент
	 */
	trackByParticipants = (index, item: IParticipantsListItem) => item.position;

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
}
