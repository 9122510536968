import * as StringFormatter from 'sprintf-js';

/**
 * Интерфейс модели родительского тега
 */
export interface ITagInit {
	/**
	 * Имя тега
	 */
	parent_tag: string;
}

/**
 * Базовая модель для контейнеров всех xml тегов
 */
export class Tag {

	/**
	 * Тег, который обозначает секцию отчетов
	 */
	static readonly REPORTING = 'REPORTING';

	/**
	 * Тег, который обозначает отчет
	 */
	static readonly REPORT = 'REPORT';

	/**
	 * Тег, который обозначает секцию информации о доступе
	 */
	static readonly ACCESSIBILITY = 'ACCESSIBILITY';

	/**
	 * Тег, который обозначает уровень доступа пользователя
	 */
	static readonly ACCESS_LEVEL = 'ACCESS_LEVEL';

	/**
	 * Тег, который обозначает список доступа
	 */
	static readonly ACCESS_LIST = 'ACCESS_LIST';

	/**
	 * Тег, который обозначает элемент пользовательского ввода
	 */
	static readonly INPUT = 'INPUT';

	/**
	 * Тег, который обозначает элемент надписи
	 */
	static readonly CAPTION = 'CAPTION';

	/**
	 * Тег, который обозначает секцию вывода информации
	 */
	static readonly OUTPUT = 'OUTPUT';

	/**
	 * Тег, который обозначает вывод информации на экран
	 */
	static readonly SCREEN = 'SCREEN';

	/**
	 * Тег, который обозначает вывод информации на принтер
	 */
	static readonly PRINTER = 'PRINTER';

	/**
	 * Тег, который обозначает секцию
	 */
	static readonly SECTION = 'SECTION';

	/**
	 * Функция инициализации модели тега
	 * @param tag Модель тега
	 * @param attributes Атрибуты тега
	 */
	public static init(tag: Tag, attributes: NamedNodeMap): void {
		if (attributes) {
			for (const key of Object.keys(attributes)) {
				const attribute = attributes[key];
				tag[attribute.name] = attribute.value;
			}
		}
	}
}

/**
 * Модель контейнера xml тега <ACCESS_LEVEL>
 */
export class AccessLevelTag implements ITagInit {
	/**
	 * Имя родительского тега
	 */
	parent_tag = 'access_level';

	/**
	 * Роль пользователя
	 */
	role: string;
}

/**
 * Модель контейнера xml тега <ACCESS_LIST>
 */
export class AccessListTag implements ITagInit {
	/**
	 * Имя родительского тега
	 */
	parent_tag = 'access_list';

	/**
	 * Роли пользователей
	 */
	roles: string;
}

/**
 * Модель контейнера XML тега <ACCESSIBILITY>.
 */
export class AccessibilityTag implements ITagInit {
	/**
	 * Имя родительского тега
	 */
	parent_tag = 'accessibility';

	// level: AccessLevelTag; TODO remove???
	/**
	 * Тег уровня доступа пользователя
	 */
	access_level: AccessLevelTag;

	/**
	 * Тег списка доступа пользователей
	 */
	access_list: AccessListTag;
}

/**
 * Модель контейнера xml тега <PRINTER>
 */
export class PrinterTag implements ITagInit {
	/**
	 * Имя родительского тега
	 */
	parent_tag = 'printer';

	/**
	 * Массив секций для вывода на принтер
	 */
	sections: Array<SectionTag> = [];

	/**
	 * Признак, указывающий на необходимость авто-печати.
	 */
	autoprint: boolean;
}

/**
 * Модель контейнера xml тега <SCREEN>
 */
export class ScreenTag implements ITagInit {
	/**
	 * Имя родительского тега
	 */
	parent_tag = 'screen';

	/**
	 * Массив секций для вывода на экран
	 */
	sections: Array<SectionTag> = [];
}

/**
 * Модель контейнера xml тега <OUTPUT>
 */
export class OutputTag implements ITagInit {
	/**
	 * Имя родительского тега
	 */
	parent_tag = 'output';

	/**
	 * Тег, который обозначает вывод информации на принтер
	 */
	printer: PrinterTag;

	/**
	 * Тег, который обозначает вывод информации на экран
	 */
	screen: ScreenTag;
}

/**
 * Модель контейнера xml тега <CAPTION>
 */
export class CaptionTag implements ITagInit {
	/**
	 * Имя родительского тега
	 */
	parent_tag = 'caption';

	/**
	 * Содержимое тега
	 */
	text: string;
}

/**
 * Тип ввода в форме запроса отчета
 */
export type ReportInputType = 'system' | 'lineedit' | 'label' | 'dateedit' | 'iticketlist';

/**
 * Модель контейнера xml тега <INPUT>
 */
export class InputTag implements ITagInit {
	/**
	 * Имя родительского тега
	 */
	parent_tag = 'inputs';

	/**
	 * Имя класса, который будет использоваться для отображения элемента ввода
	 */
	class_name = '';

	/**
	 * Тип элемента ввода
	 */
	type: ReportInputType;

	/**
	 * Номер параметра
	 */
	param_number: string;

	/**
	 * Надпись возле элемента ввода
	 */
	caption: CaptionTag;
}

/**
 * Модель контейнера xml тега <SECTION>
 */
export class SectionTag implements ITagInit {
	/**
	 * Имя родительского тега
	 */
	parent_tag = 'sections';

	/**
	 * Номер секции
	 */
	number: string;

	/**
	 * Текст в секции
	 */
	text: string;

	/**
	 * Преобразует и сохраняет текст шаблона для секции screen
	 * @param {string} text
	 */
	setScreenText(text: string): void {
		this.text = this.text ? this.text.concat(text) : text;
	}

	/**
	 * Преобразует и сохраняет текст шаблона для секции printer
	 * @param {string} text
	 */
	setPrinterText(text: string): void {
		const matches = text.match(/\"(.*)\"/g);
		if (matches) {
			for (const match of matches) {
				const token = match.slice(1, match.length - 1);
				this.text = this.text ? this.text.concat(token) : token;
			}
		}
	}

	/**
	 * Обрабатывает (форматирует) текст шаблона, секции отчета,
	 * подставляя данные из ответа ЦС, на запрос информации об отчете, в шаблон.
	 *
	 * @param {string[]} data данные из ответа ЦС, на запрос информации об отчете
	 * @returns {string} обработаный шаблон секции в виде строки
	 */
	formatText(data: Array<string>): string {
		if (!this.text) {
			return undefined;
		}

		const matches = this.text.match(/\$d(\d{2})/g);

		if (matches) {
			const arr = [];
			for (const match of matches) {
				const index = Number.parseInt(match.slice(2), 10);
				arr.push(typeof data[index] === 'undefined' ? '' : data[index]);
			}
			let text = this.text.replace(/%/g, '%%');
			text = text.replace(/\$d\d{2}%/g, '');
			text = text.replace(/\$d\d{2}/g, '%s');
			const formatted = StringFormatter.sprintf(text, ...arr);

			return formatted.replace(/%%/g, '%');
		}

		// если параметры отсутствуют, выводим результат запроса
		return this.text;
	}
}

/**
 * Модель контейнера XML тега <REPORT>.
 */
export class ReportTag implements ITagInit {
	/**
	 * Имя родительского тега
	 */
	parent_tag = 'reports';

	/**
	 * Идентификатор отчета
	 */
	id: string;

	/**
	 * Версия отчета
	 */
	version: string;

	/**
	 * Видимое имя отчета
	 */
	visible_name: string;

	/**
	 * Признак того, что отчет не работает
	 */
	broken = false;

	/**
	 * Элементы ввода для запроса формирования отчета
	 */
	inputs: Array<InputTag> = [];

	/**
	 * Элементы вывода для формирования отчета
	 */
	output: OutputTag;

	/**
	 * Тег с правами доступа к отчету
	 */
	accessibility: AccessibilityTag;
}

/**
 * Модель контейнера xml тега <REPORTING>
 */
export class ReportingTag implements ITagInit {
	/**
	 * Имя родительского тега
	 */
	parent_tag: 'reporting';

	/**
	 * Версия шаблона отчета
	 */
	version: string;

	/**
	 * Список отчетов
	 */
	reports: Array<ReportTag> = [];
}
