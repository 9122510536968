import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import {CameraService} from "../../services/camera.service";

/**
 * Компонент для работы с камерой и сканированием штрих-кодов.
 */
@Component({
	selector: 'app-camera',
	templateUrl: './camera.component.html',
	styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {

	/**
	 * Ошибка работы сканера
	 */
	error = '';

	/**
	 * Признак активности камеры
	 * @private
	 */
	active = true;

	/**
	 * Начальное состояние камеры
	 */
	@Input() initialState = true;

	/**
	 * Показывать ли переключатель между вводом с камеры и с клавиатуры
	 */
	@Input() showCameraSwitcher = true;

	/**
	 * Событие, которое будет вызвано при сканировании штрих-кода.
	 */
	@Output() readonly barcodeScanned = new EventEmitter<string>();

	constructor(readonly cameraService: CameraService) { }

	onCameraChange(id: string): void {
		this.changeCamera(id).subscribe();
	}

	private changeCamera(id: string): Observable<void> {
		return this.cameraService.stopScanning()
			.pipe(
				concatMap(() => {
					this.cameraService.currentCameraId = id;
					return this.cameraService.startScanning();
				})
			);
	}

	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.active = this.initialState;

		this.cameraService.scanFailure$.subscribe((error: any) => {
			// handle scan failure, usually better to ignore and keep scanning.
			// for example:
			console.warn(`Code scan error = ${error}`);
		});
	}
}
