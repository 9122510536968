<label class="input-label"
	   data-cy="input-label"
>
	<input [appOnlyNumbers]="onlyNumbers"
		class="input-field"
		[class.input-field_fix-placeholder]="fixPlaceholder"
		[class.input-field_error]="errorState"
		data-cy="input-field"
		#inputElement
		appMslComponent
		pattern="\d*"
		inputmode="numeric"
		[textAlign]="textAlign"
		[inputWidthOnFocus]="inputWidthOnFocus"
		[placeholder]="placeholder || ' '"
		[value]="value"
		(input)="onInputHandler($event)"
		(keydown)="onKeydownHandler($event)"
		[attr.disabled]="isDisabled || null"
		[attr.maxlength]="maxLength"
		[attr.minlength]="minLength"
		(focus)="onFocusHandler($event)"
		(blur)="onBlurHandler($event)"
	/>
	<span *ngIf="label"
		  class="input-title"
		  data-cy="input-title"
	>{{ label }}</span>
</label>
