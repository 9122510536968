import { EsapParams } from '@app/core/configuration/esap';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { CancelFlag } from '@app/core/services/transaction/transaction-types';
import { ApiClient } from '@app/core/net/http/api/api-client';

/**
 * Модель для вызова API CancelLastTransaction
 */
export class CancelLastTransactionReq extends ApiClient {

	/**
	 * Название действия
	 */
	static readonly Action = 'CancelLastTransaction';

	/**
	 * Конструктор.
	 *
	 * @param {AppStoreService} appStoreService централизованое хранилище данных
	 * @param {string} url урл к сервису отмены
	 * @param {string} transactionId идентификатор транзакции
	 * @param {string} userId идентификатор пользователя
	 * @param {string} sessionId идентификатор сессии
	 * @param {LotteryGameCode} code код лотереи
	 * @param {CancelFlag} flag флаг отмены
	 */
	constructor(
		appStoreService: AppStoreService,
		url: string,
		transactionId: string,
		userId: string,
		sessionId: string,
		code: LotteryGameCode,
		flag: CancelFlag
	) {
		super(appStoreService, {url, action: CancelLastTransactionReq.Action});

		this.params.append(EsapParams.CNL_TRANS_ID, transactionId);
		this.params.append(EsapParams.CNL_FLAG, flag);
		this.params.append(EsapParams.GAME_CODE, code.toString());

		this.params.append(EsapParams.USER_ID, userId);
		this.params.append(EsapParams.SID, sessionId);
	}

}
