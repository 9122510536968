import { Pipe, PipeTransform } from '@angular/core';

/**
 * Пайп для форматирования чисел в украинскую запись денежных сумм
 */
@Pipe({
	name: 'mslCurrency'
})
export class MslCurrencyPipe implements PipeTransform {

	/**
	 * Функция преобразования числа в украинскую запись денежных сумм
	 * @param value число
	 * @param args количество знаков после запятой
	 */
	transform(value: any, args?: any): string {

		let ruSumm: string;
		const digitsCount = Number.isInteger(args) ? args : 2;

		if (typeof value === 'string') {
			const r = parseFloat(value);
			if (isNaN(r)) {
				return value;
			}
			ruSumm = r.toLocaleString('ru-RU', {minimumFractionDigits: digitsCount, maximumFractionDigits: digitsCount});
		} else if (typeof value === 'number') {
			ruSumm = value.toLocaleString('ru-RU', {minimumFractionDigits: digitsCount, maximumFractionDigits: digitsCount});
		}

		return ruSumm.replace(',', '.');
	}
}
