<div class="header">
	<div class="header__line tt-u">ТОВ "Ваша організація"</div>
	<div class="header__line">Автозаправочний комплекс з кафе</div>
	<div class="header__line">м.Київ, Голосіївський р-н, пр-т. Академіка Глушкова 9А</div>
	<div class="header__line">ID 44212988</div>
	<div>ПН 442129810301</div>
</div>

<div class="content dashed block">
	<div class="content__item" *ngFor="let ticket of tickets">
		<ng-container *ngIf="!!ticket.url">
			<div class="content__line content__line_spb">
				<div class="content__left">
					<span class="q">1.00</span>X
				</div>
				<div class="content__right">{{ ticket.bet_sum }}</div>
			</div>

			<div class="content__line">
				<div class="content__left tt-u">Штрих-код:</div>
				<div class="content__right">{{ ticket.mac_code }}</div>
			</div>

			<div class="content__line">
				<div class="content__left">Код:</div>
				<div class="content__right">41944</div>
			</div>

			<div class="content__line content__line_spb">
				<div class="content__left">{{ ticket.game_name || gameName }}</div>
				<div class="content__right">{{ ticket.bet_sum }}Б</div>
			</div>
		</ng-container>

		<ng-container *ngIf="!ticket.url">
			<ng-container *ngFor="let serie of ticket.serie">
				<div class="content__line content__line_spb">
					<div class="content__left">
						<span class="q">{{ getRangeCount(serie.range) }}</span>X
					</div>
					<div class="content__right">{{ serie.ticket_price }}</div>
				</div>

				<div class="content__line content__line_spb">
					<div class="content__left tt-u">Штрих-код:</div>
					<div class="content__right">{{ serie.range[0].numbers }}</div>
				</div>

				<ng-container *ngFor="let ri of serie.range; let i = index">
					<div *ngIf="i > 0" class="content__line content__line_spb">
						<div class="content__left"></div>
						<div class="content__right">{{ ri.numbers }}</div>
					</div>
				</ng-container>

				<div class="content__line">
					<div class="content__left">Код:</div>
					<div class="content__right">41944</div>
				</div>

				<div class="content__line content__line_spb">
					<div class="content__left">{{ serie.name }}</div>
					<div class="content__right">{{ getRangePrice(serie) }}Б</div>
				</div>
			</ng-container>
		</ng-container>
	</div>

</div>

<div class="payment">
	<div class="block dashed">
		<div class="payment__line tt-u ta-c">Форми оплати</div>
		<div class="payment__line tt-u">Готівка HFT+CLM</div>
	</div>
	<div class="block dashed">
		<div class="payment__line payment__line_2">
			<div class="payment__left tt-u">Форма оплати: готівка</div>
			<div class="payment__right tt-u">{{ sum }} грн</div>
		</div>
		<div class="payment__line payment__line_2">
			<div class="payment__left tt-u">Сума, грн</div>
			<div class="payment__right">{{ sum }}</div>
		</div>
		<div class="payment__line payment__line_2">
			<div class="payment__left">ПДВ: Б = 0.00%</div>
			<div class="payment__right">0.00</div>
		</div>
		<div class="payment__line payment__line_2">
			<div class="payment__left"><b class="tt-u fs-14">До сплати:</b></div>
			<div class="payment__right fs-14 fw-n">{{ sum }}</div>
		</div>
	</div>

	<div class="block dashed">
		<div class="payment__line payment__line_2">
			<div class="payment__left tt-u">Готівка:</div>
			<div class="payment__right">{{ sum }}</div>
		</div>
		<div class="payment__line payment__line_2">
			<div class="payment__left tt-u">Решта</div>
			<div class="payment__right">0.00</div>
		</div>
	</div>
</div>
<div class="footer block">
	<div class="footer__line footer__line_2">
		<div class="payment__left tt-u">Оператор 05</div>
		<div class="payment__right">Карпенко Т.Л.</div>
	</div>
	<div class="footer__line ta-c tt-u">Приходьте ще!</div>
	<div class="footer__line">MAC: {{ macOfCheck }}</div>
	<div class="footer__line footer__line_2">
		<div class="payment__left tt-u">Чек 227723</div>
		<div class="payment__right tt-u">Дата {{ localeDate }} Час {{ localeTime }} </div>
	</div>
	<div class="footer__line">ЗН НД04001686</div>
	<div class="footer__line">ФН 3000925144</div>
	<div class="footer__line ta-c tt-u fw-n">Фіскальний чек</div>
	<div class="footer__line ta-c tt-u"><b class="fs-20">ND</b></div>
</div>
