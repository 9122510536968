import { Logger } from '@app/core/net/ws/services/log/logger';

/**
 * Типы представления отчета.
 */
export type ReportViewType = 'printer' | 'screen' | 'raw';

/**
 * Интерфейс модели xml тега <SECTION>.
 */
export interface ReportSection {
	/**
	 * Номер секции отчета.
	 */
	num: number;
	/**
	 * Значения секции отчета.
	 */
	val: Array<string>;
}

/**
 * Модель для хранения необработанных секций шаблона отчета.
 */
export class ReportSections {
	/**
	 * Секции отчета.
	 */
	sections: Array<ReportSection> = [];

	/**
	 * Конструктор модели.
	 * @param raw Отчет в виде строки.
	 */
	constructor(raw: string) {
		const lines = raw.split('\n');
		for (const line of lines) {
			const section = line.split('|');
			this.sections.push({num: Number.parseInt(section[0], 10), val: section});
		}
		Logger.Log.d('ReportSections', 'section: %s', this.sections)
			.console();
	}
}
