import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { StorageService } from '@app/core/net/ws/services/storage/storage.service';
import { BaseGuard } from '@app/core/guards/base.guard';

/**
 * Контроль доступа к модулям (страницам) приложения на основе статусе инициализации (этапе загрузки) терминала.
 */
@Injectable({
	providedIn: 'root'
})
export class InitGuard extends BaseGuard implements CanActivate {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Статус инициализации терминала.
	 */
	isInitialized = false;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {AppStoreService} appStoreService Сервис хранилища данных приложения.
	 * @param {StorageService} storageService Сервис хранилища данных в браузере.
	 */
	constructor(
		readonly appStoreService: AppStoreService,
		readonly storageService: StorageService
	) {
		super(appStoreService, storageService);
	}

	/**
	 * Проверка доступа к модулю (странице) приложения.
	 * @param next Снимок маршрута, который будет активирован.
	 * @param state Снимок текущего маршрута.
	 */
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

		return this.isInitialized || this.checkWebAltSession();
	}

}
