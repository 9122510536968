import { ApiClient } from '@app/core/net/http/api/api-client';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { IResponse } from '@app/core/net/http/api/types';

/**
 * Модель запроса для вызова API ResendAuth2.
 */
export class ResendAuth2Req extends ApiClient {

	/**
	 * Конструктор запроса.
	 *
	 * @param {AppStoreService} appStoreService Централизованное хранилище данных.
	 * @param {string} multiUserID Идентификатор пользователя, который обнаружен на пользовательском компьютере.
	 * Используется для отслеживания нескольких аккаунтов от одного и того же пользователя
	 */
	constructor(
		appStoreService: AppStoreService,
		multiUserID?: string
	) {
		super(appStoreService, appStoreService.Settings.getEsapActionUrl(LotteryGameCode.Undefined, EsapActions.ResendAuth2));

		if (multiUserID) {
			this.params.append(EsapParams.MULTIUSER_ID, multiUserID);
		}
	}

}

// -----------------------------
//  Response
// -----------------------------

/**
 * Модель ответа на запрос API ResendAuth2
 */
export interface ResendAuth2Resp extends IResponse {
	/**
	 * Идентификатор клиента
	 */
	client_id?: string;
}
