<div
	class="calendar-overlay"
	*ngIf="(calendarService.calendarConfiguration$$ | async).showOverlay"
	>
</div>

<div
	class="calendar-date-selector-container"
	[ngClass]="(calendarService.calendarConfiguration$$ | async).calendarTheme"
	[style.top.px]="(calendarService.calendarConfiguration$$ | async).top"
	[style.left.px]="(calendarService.calendarConfiguration$$ | async).left"
	>

	<!-- ТИТУЛЬНАЯ КНОПКА -->
	<div
		class="calendar-title"
		*ngIf="(calendarService.calendarConfiguration$$ | async).showTitleText !== undefined"
		>
		<label>{{ (calendarService.calendarConfiguration$$ | async).showTitleText | translate }}</label>
	</div>

	<!-- ВЫБОР МЕСЯЦА -->
	<div
		class="cds-month"
		(click)="onClickMonthHandler($event)"
		>
		<i class="material-icons" [attr.direction]="-1">keyboard_arrow_left</i>
		<label>{{ MONTH_BY_KEY[displayMonth] | translate }}&nbsp;{{ displayYear }}</label>
		<i class="material-icons" [attr.direction]="1">keyboard_arrow_right</i>
	</div>

	<!-- ДНИ НЕДЕЛИ -->
	<div class="cds-week">
		<ng-container *ngFor="let weekDay of WEEK_DAYS_BY_KEY; trackBy: trackByWeekDayFn">
			<label>{{ weekDay | translate | uppercase }}</label>
		</ng-container>
	</div>

	<!-- ДНИ -->
	<div
		class="cds-days"
		(click)="onClickDayHandler($event)"
		>
		<ng-container *ngFor="let day of days; trackBy: trackByDaysFn">
			<label
				[class.activeMonth]="day.activeMonth"
				[class.currentDate]="day.currentDate"
				[class.selectedDate]="day.selectedDate"
				[class.disabledDay]="day.disabledDay"
				[attr.date]="day.date"
				>
				{{ day.label }}
			</label>
		</ng-container>
	</div>

	<!-- КНОПКА ВЫБОРА -->
	<div class="select-button">
		<button type="button"
			(click)="onClickSelectHandler()"
			[attr.disabled]="!selectedDate || null">{{ 'calendar.select' | translate | uppercase }}
		</button>
	</div>

</div>
