import { Subject } from 'rxjs';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { Lotteries, LotteriesGroupCode, LotteryGameCode } from '@app/core/configuration/lotteries';
import { UpdateDrawInfoDraws, UpdateDrawInfoLottery } from '@app/core/net/http/api/models/update-draw-info';

/**
 * Модель для хранения данных о лотереях и тиражах.
 */
export class LotteriesDraws {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Наблюдатель за изменениями в списке лотерей, доступных на терминале.
	 * Лотерея заносится методом {@link setLottery} и получается методом {@link getLottery} по коду {@link LotteryGameCode}.
	 * Состояние списка изменится в случае занесения новой лотереи в список.
	 */
	readonly lotteryList$ = new Subject<Map<LotteryGameCode, UpdateDrawInfoLottery>>();

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Объект с лотереями и тиражами по данным лотереям
	 * @private
	 */
	private readonly _lotteries: Map<LotteryGameCode, UpdateDrawInfoLottery> = new Map();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Возвращает список тиражей для лотереи.
	 *
	 * @param {LotteryGameCode} gameCode Код лотереи.
	 * @returns {Array<UpdateDrawInfoDraws>}
	 */
	getDraws(gameCode: LotteryGameCode): Array<UpdateDrawInfoDraws> | undefined {
		const lottery = this.getLottery(gameCode);

		return lottery ? lottery.draws : undefined;
	}

	/**
	 * Добаляет лотерею в список лотерей, доступных терминалу.
	 *
	 * @param {LotteryGameCode} gameCode Код лотереи.
	 * @param {UpdateDrawInfoLottery} lottery Данные о лотерее.
	 */
	setLottery(gameCode: LotteryGameCode, lottery: UpdateDrawInfoLottery): void {
		this._lotteries.set(gameCode, lottery);

		if (lottery.lott_extra && lottery.lott_extra.group_id) {
			Lotteries.setInstantLottery(gameCode, Number.parseInt(lottery.lott_extra.group_id, 10));
		}

		this.lotteryList$.next(this._lotteries);
	}

	/**
	 * Возвращает данные об определенной лотерее, включая тиражи.
	 *
	 * @param {LotteryGameCode} gameCode Код лотереи.
	 * @returns {UpdateDrawInfoLottery} Данные о лотерее.
	 */
	getLottery(gameCode: LotteryGameCode): UpdateDrawInfoLottery | undefined {
		if (!this._lotteries) {
			Logger.Log.e('LotteriesDraws', `lottery story is empty`)
				.console();

			return undefined;
		}

		const lottery = this._lotteries.get(gameCode);

		return lottery ? {...lottery} : undefined;
	}

	/**
	 * Удаляет лотерею из списока лотерей доступных терминалу.
	 *
	 * @param {LotteryGameCode} gameCode Код лотереи
	 */
	delLottery(gameCode: LotteryGameCode): void {
		this._lotteries.delete(gameCode);
		this.lotteryList$.next(this._lotteries);
	}

	/**
	 * Возвращает список кодов лотерей доступных терминалу.
	 *
	 * @returns {Array<LotteryGameCode>}
	 */
	getLotteriesCodes(): Array<LotteryGameCode> {
		return Array.from(this._lotteries.keys());
	}

	/**
	 * Доступна ли лотерея на терминале.
	 *
	 * @param {LotteryGameCode} gameCode код лотереи
	 * @returns {boolean}
	 */
	hasLottery(gameCode: LotteryGameCode): boolean {
		// TODO temporary checker
		if (!this._lotteries) {
			Logger.Log.i('BarcodeReaderService draws.ts hasLottery', `this._lotteries == undefine`)
				.console();

			return false;
		}

		return this._lotteries.has(gameCode);
	}

	/**
	 * Доступна ли, хоть одна моментальная лотерея, для указаной группы на терминале.
	 *
	 * @param {LotteriesGroupCode} groupId группа лотерей
	 * @returns {boolean}
	 */
	hasInstantLotteries(groupId: LotteriesGroupCode): boolean {
		const group = Lotteries.getInstantLotteriesCodes(groupId);

		return group ? group.length > 0 : false;
	}
}
