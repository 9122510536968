import { Subject } from 'rxjs';

import { ApiError, NetError } from '../../error/types';

import { ApiResponseObserver, IApiNotificationObserver, IMessage, INotification, IRequest, IResponse, ISubscription } from './api/types';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { ITransportEventsObserver } from '@app/core/net/ws/ws-models';

/**
 * Класс, который представляет собой модель роутера сообщений от сервисов терминала на основании идентификатора запроса.
 */
export class MessageRouter implements ITransportEventsObserver {

	/**
	 * Таблица маршрутизации ответов.
	 * @private
	 */
	private readonly responseRouteMap: Map<string, ApiResponseObserver> = new Map();

	/**
	 * Таблица маршрутизации уведомлений.
	 * @private
	 */
	private readonly notificationRouteMap: Map<string, Subject<INotification>> = new Map();

	/**
	 * Обработчик готовности роутера.
	 */
	ready(): void {
		Logger.Log.d('MessageRouter', 'ready router')
			.console();
	}

	/**
	 * Очистка таблицы маршрутизации ответов и генерация ошибок для наблюдателей запросов.
	 *
	 * @param {Event} err Ошибка.
	 */
	error(err: Event): void {
		this.responseRouteMap.forEach((observer: ApiResponseObserver) => {
			Logger.Log.d('MessageRouter', 'send net error')
				.console();
			observer.error(new NetError(err.type, undefined, undefined));
		});
		Logger.Log.d('MessageRouter', 'clear router')
			.console();
		this.responseRouteMap.clear();
	}

	/**
	 * Парсинг (десериализация) сообщения от сервисов терминала.
	 *
	 * @param {string} message Сериализованное в строку сообщение.
	 */
	routeMessage(message: string): void {
		let apiMessage: IMessage;
		try {
			apiMessage = JSON.parse(message) as IMessage;
		} catch (e) {
			Logger.Log.e('MessageRouter', `error while parsing message: ${(e as Error).message}`)
				.console();

			return;
		}

		if (apiMessage.response) {
			this.routeResponse(apiMessage.response);
		} else if (apiMessage.notification) {
			this.routeNotification(apiMessage.notification);
		} else if (apiMessage.heartbeat) {
			this.routeHeartbeat(message);
		} else {
			Logger.Log.e('MessageRouter', `message type doesn't recognised`)
				.console();
		}
	}

	/**
	 * Маршрутизация (передача наблюдателю) ответа от сервисов терминала на основании идентификатора запроса.
	 *
	 * @param {IResponse} response Интерфейс для модели ответа.
	 */
	routeResponse(response: IResponse): void {
		if (response.requestId) {
			const observer = this.responseRouteMap.get(response.requestId);
			if (observer) {
				const now = Date.now();
				const delay = now - observer.timestamp;
				if (delay > 100) {
					Logger.Log.i('MessageRouter', `response: %s delayed more then: %s ms`, response.requestId, delay)
						.console();
				}

				observer.timestamp = now; // Date.now();
				if (response.errorCode === 0) {
					observer.next(response);
					observer.complete();
				} else {
					observer.error(new ApiError(response.errorDesc, undefined, response.errorCode));
				}
			} else {
				Logger.Log.e('MessageRouter', `requestId: ${response.requestId} don't found in route map`)
					.console();
			}
		}
	}

	/**
	 * Маршрутизация (передача наблюдателю) нотификации от сервисов терминала на основании типа нотификации.
	 *
	 * @param {INotification} notification Интерфейс модели нотификации.
	 */
	routeNotification(notification: INotification): void {
		const subject = this.notificationRouteMap.get(notification.event);
		Logger.Log.d('MessageRouter', 'routeNotification, event: %s', notification.event)
			.console();
		if (subject) {
			subject.next(notification);
		}
	}

	/**
	 * Маршрутизация (передача наблюдателю) сообщения о состоянии соединения с сервисами терминала.
	 * @param message
	 */
	routeHeartbeat(message: string): void {
		Logger.Log.d('MessageRouter', `heartbeat message arrived: ${message}`)
			.console();
	}

	/**
	 * Получение наблюдателя за ходом выполнения запроса на основе идентификатора запроса.
	 *
	 * @param {string} requestId Идентификатор запроса.
	 * @returns {ApiResponseObserver} Наблюдатель.
	 */
	getApiResponseObserver(requestId: string): ApiResponseObserver {
		return this.responseRouteMap.get(requestId);
	}

	/**
	 * Добавление запроса в таблицу маршрутизации ответов и связь с наблюдателем.
	 *
	 * @param {IRequest} request Запрос.
	 * @param {ApiResponseObserver} observer Наблюдатель за ходом выполнения запроса.
	 */
	addRequest(request: IRequest, observer: ApiResponseObserver): void {
		this.responseRouteMap.set(request.requestId, observer);
	}

	/**
	 * Удаление запроса из таблицы маршрутизации.
	 *
	 * @param {string} requestId Идентификатора запроса.
	 */
	delApiResponseObserver(requestId: string): void {
		this.responseRouteMap.delete(requestId);
	}

	/**
	 * Добавление типа нотификации в таблицу маршрутизации нотификаций и связь с наблюдателем.
	 *
	 * @param {string} event тип нотификации
	 * @param {IApiNotificationObserver} observer наблюдатель за ходом событиями нотификаций
	 * @returns {ISubscription} подписка
	 */
	addNotification(event: string, observer: IApiNotificationObserver): ISubscription {
		let subject = this.notificationRouteMap.get(event);
		if (!subject) {
			subject = new Subject();
			this.notificationRouteMap.set(event, subject);
		}

		return subject.subscribe({
			next: value => observer.onNotify(value)
		});
	}

}
