import { Injectable } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { AnimationState } from '@app/util/animations';
import { Logger } from '@app/core/net/ws/services/log/logger';
import { CANCEL_TIMEOUT } from '@app/core/services/transaction/transaction';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { HttpService } from '@app/core/net/http/services/http.service';
import { GetShiftInfo, GetShiftInfoResp } from '@app/core/net/http/api/models/get-shift-info';
import { EsapActions } from '@app/core/configuration/esap';

/**
 * Сервис для меню-гамбургера. Содержит основную логику для управления меню.
 */
@Injectable({
	providedIn: 'root'
})
export class HamburgerMenuService {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Текущий баланс по смене
	 */
	balance = '0.00';

	/**
	 * Идет ли загрузка баланса по смене?
	 */
	balanceLoading = false;

	/**
	 * Текущее состояние меню типа {@link AnimationState}.
	 */
	stateExpression: AnimationState;

	/**
	 * Признак, указывающий на невовозможность нажать кнопку отмены последней транзакции.
	 */
	get cancelButtonDisabled(): boolean {
		return this._cancelButtonDisabled;
	}

	/**
	 * Конструктор сервиса
	 *
	 * @param {AppStoreService} appStoreService Сервис для работы с хранилищем приложения.
	 * @param {HttpService} httpService Сервис для работы с HTTP.
	 */
	constructor(private readonly appStoreService: AppStoreService, private readonly httpService: HttpService) {}

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Признак, указывающий на невовозможность нажать кнопку отмены последней транзакции.
	 * @private
	 */
	private _cancelButtonDisabled = true;

	/**
	 * Подписка на таймер отмены последней транзакции.
	 * @private
	 */
	private _cancelTimerSubscription: Subscription;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Переключиться на указанное состояние меню-гамбургер.
	 *
	 * @param {AnimationState} menuState Состояние, на которое требуется переключиться.
	 */
	switchMenu(menuState: AnimationState): void {
		this.stateExpression = menuState;
		// !!!ФУНКЦИОНАЛ НА БУДУЩЕЕ!!!
		if ((menuState === AnimationState.expanded) && (!this.balanceLoading) && false) {
			this.balanceLoading = true;

			const getShiftInfoReq = new GetShiftInfo(
				this.appStoreService,
				EsapActions.GetShiftInfo
			);

			// Linter error - Promises must be handled appropriately
			// this.httpService.sendApi(getShiftInfoReq)
			// .then((resp: GetShiftInfoResp) => {
			// this.balance = (resp.amount / 100).toFixed(2);
			// })
			// .catch(() => {
			// this.balance = '0.00';
			// })
			// .finally(() => this.balanceLoading = false);
		}
	}

	/**
	 * Разрешить кнопку отмены последней транзакции.
	 *
	 * @param {number} timeout Время активности кнопки отмены. По умолчанию равно константе {@link CANCEL_TIMEOUT}.
	 */
	activateCancelButton(timeout = CANCEL_TIMEOUT): void {
		Logger.Log.i('HamburgerMenuService', `activateCancelButton -> activate cancel button (for ${timeout} ms)`)
			.console();

		this._cancelButtonDisabled = false;
		this.deleteCancelTimerSubscription();

		this._cancelTimerSubscription = timer(timeout)
			.subscribe(() => {
				Logger.Log.i('HamburgerMenuService', `activateCancelButton -> will deactivate CANCEL due to the timeout (${timeout} ms)`)
					.console();

				this.deactivateCancelButton();
			});
	}

	/**
	 * Запретить кнопку отмены последней транзакции.
	 */
	deactivateCancelButton(): void {
		Logger.Log.i('HamburgerMenuService', `deactivateCancelButton`)
			.console();

		this._cancelButtonDisabled = true;
		this.deleteCancelTimerSubscription();
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Очистить подписку на таймер деактивации кнопки отмены последней транзакции.
	 */
	private deleteCancelTimerSubscription(): void {
		if (this._cancelTimerSubscription) {
			this._cancelTimerSubscription.unsubscribe();
			this._cancelTimerSubscription = undefined;
		}
	}

}
