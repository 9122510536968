import { Type } from 'class-transformer';
import { IsNotEmpty, ValidateIf, ValidateNested } from 'class-validator';
import { IsCurrencyFormat, IsDateFormat } from '@app/util/decorators';
import { EsapActions, EsapParams } from '@app/core/configuration/esap';
import { LotteryGameCode } from '@app/core/configuration/lotteries';
import { AppStoreService } from '@app/core/services/store/app-store.service';
import { ApiClient } from '@app/core/net/http/api/api-client';
import { IResponse } from '@app/core/net/http/api/types';

// -----------------------------
//  Request
// -----------------------------

/**
 * Информация по обновлению тиражей
 */
export interface UpdateDrawInfoItem {
	/**
	 * Номер тиража
	 */
	c: number;
	/**
	 * Версия тиража
	 */
	v: number;
}

/**
 * Модель для вызова запроса API {@link EsapParams.UpdateDrawInfo UpdateDrawInfo}.
 */
export class UpdateDrawInfoReq extends ApiClient {

	/**
	 * Конструктор.
	 *
	 * @param {AppStoreService} appStoreService Ссылка на централизованное хранилище данных.
	 * @param {Array<UpdateDrawInfoItem>} draws Список тиражей и их версии присутствующие на терминале.
	 * @param {number} lottery Код лотереи.
	 */
	constructor(appStoreService: AppStoreService, draws: Array<UpdateDrawInfoItem>, lottery?: number) {
		super(appStoreService, appStoreService.Settings.getEsapActionUrl(LotteryGameCode.Undefined, EsapActions.UpdateDrawInfo));

		if (appStoreService.hasOperator()) {
			this.params.append(EsapParams.USER_ID, appStoreService.operator.getValue().userId);
			this.params.append(EsapParams.SID, appStoreService.operator.getValue().sessionId);
		}

		if (lottery) {
			this.params.append(EsapParams.LOTTERY, lottery.toString());
		}

		this.params.append(EsapParams.DRAWS, JSON.stringify(draws));
	}

}

// -----------------------------
//  Response
// -----------------------------

/**
 * Данные для обновления тиражей
 */
export type UpdateDrawInfoData = MegalotData | ZabavaData | InstantData | any;

/**
 * Данные для обновления тиражей моментальных лотерей
 */
export type UpdateDrawInfoLotteryExtra = EInstantExtra | any;

/**
 * Модель тиража.
 */
export class UpdateDrawInfoDraw {
	/**
	 * Цена ставки или билета для данного тиража в валюте указанной в теге currency.
	 * В качестве десятичного разделителя для "копеек" используется точка.
	 */
	@ValidateIf(self => !self.remove)
	@IsCurrencyFormat()
	bet_sum?: string;

	/**
	 * Код тиража.
	 */
	@IsNotEmpty()
	code: string;

	/**
	 * Удалить ли тираж при синхронизации.
	 */
	remove?: boolean;

	/**
	 * Версия тиража.
	 */
	@ValidateIf(self => !self.remove)
	@IsNotEmpty()
	version?: string;

	/**
	 * Номер тиража.
	 */
	num?: string;

	/**
	 * Наименование статуса тиража.
	 */
	status?: string;

	/**
	 * Статус тиража {@link DrawsStatus}.
	 */
	status_code?: string;

	/**
	 * Код серии билетов (для моментальных лотерей).
	 */
	serie_code?: string;

	/**
	 * Номер серии билетов (для моментальных лотерей).
	 */
	serie_num?: string;

	/**
	 * Дата начала розыгрыша тиража.
	 */
	@ValidateIf(self => !self.remove)
	@IsDateFormat()
	dr_bdate?: string;

	/**
	 * Дата окончания розыгрыша тиража.
	 */
	@ValidateIf(self => !self.remove)
	@IsDateFormat()
	dr_edate?: string;

	/**
	 * Дата начала приема ставок на тираж.
	 */
	@ValidateIf(self => !self.remove)
	@IsDateFormat()
	sale_bdate?: string;

	/**
	 * Дата окончания приема ставок на тираж.
	 */
	@ValidateIf(self => !self.remove)
	@IsDateFormat()
	sale_edate?: string;

	/**
	 * Дата начала выплаты выигрышей.
	 */
	@ValidateIf(self => !self.remove)
	@IsDateFormat()
	win_bdate?: string;

	/**
	 * Дата окончания выплаты выигрышей.
	 */
	@ValidateIf(self => !self.remove)
	@IsDateFormat()
	win_edate?: string;

	/**
	 * Тег, в котором передаются специфические для данной лотереи данные по тиражу.
	 * Содержание тега зависит от лотереи.
	 */
	data?: UpdateDrawInfoData;

	/**
	 * Название моментальной лотереи (только для бумажных).
	 */
	inst_lott_name?: string;

	/**
	 * Джекпот для данного тиража в валюте указанной в теге currency.
	 * В качестве десятичного разделителя для "копеек" используется точка.
	 */
	// @ValidateIf(self => !self.remove)
	// @IsCurrencyFormat()
	jackpot?: string;
}

/**
 * Информация об одном тираже.
 */
export class UpdateDrawInfoDraws {
	/**
	 * Тег, в котором передается информация о одном тираже.
	 */
	@Type(() => UpdateDrawInfoDraw)
	@ValidateNested()
	draw: UpdateDrawInfoDraw;
}

/**
 * Модель лотереи.
 */
export class UpdateDrawInfoLottery {
	/**
	 * Код лотереи.
	 */
	lott_code: string;

	/**
	 * Наименование лотереи на языке, установленном в системе по умолчанию.
	 */
	lott_name: string;

	/**
	 * Трехбуквенный код валюты, в котором указываются строимости. Например, UAH, USD, EUR.
	 */
	currency: string;

	/**
	 * Опциональный тег, содержащий специфические параметры для лотереи.
	 */
	lott_extra: UpdateDrawInfoLotteryExtra;

	/**
	 * Тег, в котором содержится список тиражей (теги {@link UpdateDrawInfoDraws.draw}).
	 */
	@Type(() => UpdateDrawInfoDraws)
	@ValidateNested()
	draws: Array<UpdateDrawInfoDraws>;
}

/**
 * Описывает лотерею.
 */
export class UpdateDrawInfoLotteries {
	/**
	 * Лотерея.
	 */
	@Type(() => UpdateDrawInfoLottery)
	@ValidateNested()
	lottery: UpdateDrawInfoLottery;
}

/**
 * Модель ответа на запрос API {@link EsapParams.UpdateDrawInfo UpdateDrawInfo}.
 */
export class UpdateDrawInfoResp implements IResponse {
	/**
	 * Идентификатор транзакции.
	 */
	client_trans_id: string;

	/**
	 * Код ошибки.
	 */
	err_code: number;

	/**
	 * Описание ошибки.
	 */
	err_descr: string;

	/**
	 * Идентификатор клиента.
	 */
	client_id: string;

	/**
	 * Идентификатор пользователя.
	 */
	user_id: string;

	/**
	 * Идентификатор сессии.
	 */
	sid: string;

	/**
	 * Время формирования ответа.
	 */
	time_stamp: string;

	/**
	 * Содержит список лотерей.
	 */
	@Type(() => UpdateDrawInfoLotteries)
	@ValidateNested()
	lotteries: Array<UpdateDrawInfoLotteries>;
}

// -----------------------------
//  Specific data for lotteries
// -----------------------------

/**
 * Specific data for E-Instant lotteries. "EInstant -> UpdateDrawInfoLottery.lott_extra"
 */
export class EInstantExtra {
	/**
	 * Идентификатор группы лотереи.
	 */
	group_id: string;
}

/**
 * Specific data for Megalot. "MegalotData -> UpdateDrawInfoDraw.data"
 */
export class MegalotMegaData {
	/**
	 * Максимальная ставка на данном тираже в валюте указанной в теге currency.
	 */
	@IsCurrencyFormat()
	extra_max: string;

	/**
	 * Сумма ставки на данном тираже в валюте указанной в теге currency.
	 */
	@IsCurrencyFormat()
	extra_sum: string;
}

/**
 * Класс данных для лотереи "Megalot"
 */
export class MegalotData {
	/**
	 * Максимальная ставка на данном тираже в валюте указанной в теге currency.
	 */
	@IsCurrencyFormat()
	bet_sum_max: string;

	/**
	 * Минимальная ставка на данном тираже в валюте указанной в теге currency.
	 */
	@IsCurrencyFormat()
	bet_sum_min: string;

	/**
	 * Мега-приз для данного тиража в валюте указанной в теге currency.
	 * В качестве десятичного разделителя для "копеек" используется точка.
	 */
		// @ValidateIf(self => !self.remove)
		// @IsCurrencyFormat()
	mega_prize?: string;

	/**
	 * Дополнительные данные для лотереи "Megalot"
	 */
	mega: MegalotMegaData;
}

/**
 * Информация о доп. розыгрышах.
 */
export class ZabavaExtraGame {

	/**
	 * Код (идентификатор) розыгрыша.
	 */
	@IsNotEmpty()
	code: string;

	/**
	 * Название розыгрыша.
	 */
	@IsNotEmpty()
	name: string;

	/**
	 * Стоимость игровой комбинации.
	 */
	@IsCurrencyFormat()
	sum: string;

	/**
	 * Максимальное количество комбинаций дополнительного розыгрыша для одного билета.
	 */
	max_count: number;
}

/**
 * Данные для лотереи "Забава"
 */
export class ZabavaData {
	/**
	 * Максимальная ставка на данном тираже в валюте указанной в теге currency.
	 */
	@IsCurrencyFormat()
	bet_sum_max: string;

	/**
	 * Минимальная ставка на данном тираже в валюте указанной в теге currency.
	 */
	@IsCurrencyFormat()
	bet_sum_min: string;

	/**
	 * Информация о доп. розыгрышах.
	 */
	extra_game: Array<ZabavaExtraGame>;

	/**
	 * Максимальная сумма выигрыша
	 */
	max_win_sum?: string;
}

/**
 * Specific data for Instant. "InstantData -> UpdateDrawInfoDraw.data"
 */
export class InstantData {
	/**
	 * Максимальная ставка на данном тираже в валюте указанной в теге currency.
	 */
	@IsCurrencyFormat()
	bet_sum_max: string;

	/**
	 * Минимальная ставка на данном тираже в валюте указанной в теге currency.
	 */
	@IsCurrencyFormat()
	bet_sum_min: string;
}
