<div class="results-navigator-container" data-cy="results-navigator-container">

	<!-- LOGO -->
	<app-msl-game-logo
		class="rnc-logo"
		data-cy="rnc-logo"
		[gameCode]="gameCode"
	></app-msl-game-logo>

	<div class="rt-cell" data-cy="rt-cell">
		<!-- SELL TICKETS BUTTON -->
		<button type="button"
				class="button button_theme_light-green rnc-sell-btn"
				data-cy="rnc-sell-btn"
				[ngClass]="'rnc-sell-btn_game_' + gameCode"
				[routerLink]="Init">
			{{ 'tickets.tickets_sell' | translate }} "{{ gameName | translate }}"
		</button>
	</div>

	<!-- Переключение контента -->
	<div class="switchers"
		 [ngClass]="pagePart"
		 data-cy="switchers"
	>
		<input id="draws"
			   class="switcher-input switcher-input_draws"
			   type="radio"
			   name="content_switcher"
			   value="switchers_draws"
			   data-cy="switcher-input"
			   [(ngModel)]="pagePart">
		<label class="switcher-item switcher-item_active switcher-item_draws"
			   for="draws"
			   data-cy="switcher-item"
		>{{ 'lottery.select-draw' | translate }}</label>
		<input id="results"
			   class="switcher-input switcher-input_results"
			   type="radio"
			   name="content_switcher"
			   value="switchers_results"
			   data-cy="switcher-input"
			   [(ngModel)]="pagePart">
		<label class="switcher-item switcher-item_results"
			   for="results"
			   data-cy="switcher-item"
		>{{ 'lottery.results' | translate }}</label>
	</div>

	<!-- ЛЕВАЯ ПАНЕЛЬ -->
	<aside class="rnc-left"
		   [class.rnc-left_filtered]="!!service.filterDate"
		   data-cy="rnc-left"
		   #aside
	>
		<div class="rnc-left__top" data-cy="rnc-left-top">
			<!-- КНОПКА ВЫБОРА ДАТЫ ТИРАЖА -->
			<app-calendar-date-input
				class="rnc-left__date-input"
				data-cy="rnc-left-date-input"
				[prompt]="'lottery.draws_date'"
				[showTitleText]="'lottery.draws_date'"
				[calendarTheme]="calendarTheme.Yellow"
				[topPadding]="270"
				[leftPadding]="10"
				[absolutePaddings]="true"
				(dateChanged)="onDateSelectedHandler($event)"
			>
			</app-calendar-date-input>
			<app-switch-button
				*ngIf="(gameCode === LotteryGameCode.Tip) || (gameCode === LotteryGameCode.Top)"
				class="rnc-left__switcher"
				data-cy="rnc-left-switcher"
				[value]="gameCode"
				[values]="[LotteryGameCode.Tip, LotteryGameCode.Top]"
				[titles]="['lottery.tiptop.game_tip_name' | translate, 'lottery.tiptop.game_top_name' | translate]"
				(changed)="onSelectedLotteryTypeHandler($event)"
			></app-switch-button>
			<ng-container *ngIf="(resultsList?.length > 0) && notExactFilterDate">
				<div class="no-results">{{ 'lottery.no_filter_results' | translate }}</div>
				<div class="no-results-nearest">{{ 'lottery.nearest_draws' | translate }}</div>
			</ng-container>
		</div>

		<!-- ТИРАЖИ -->
		<ng-container *ngIf="resultsList?.length > 0; else noResults">
			<div class="draws-buttons-wrapper"
				 data-cy="draws-buttons-wrapper">
				<app-draws-buttons
					class="rnc-draws"
					data-cy="rnc-draws"
					[ngClass]="'rnc-draws_game-code_' + gameCode"
					[isVisibleJackpot]="false"
					[isVisibleTimeLeft]="false"
					[dontHideDraws]="true"
					[showSeconds]="showSeconds"
					[results]="resultsList"
					[autoUpdateTime]="0"
					(action)="onSelectedResultHandler($event)">
				</app-draws-buttons>
			</div>
		</ng-container>
		<ng-template #noResults>
			<div *ngIf="service.filterDate" class="no-results" data-cy="no-results">
				{{ 'lottery.no_filter_results' | translate }}
			</div>
		</ng-template>
	</aside>

	<!-- ВНЕДРЯЕМЫЙ КОНТЕНТ С РЕЗУЛЬТАТАМИ ПО РАЗЛИЧНЫМ ЛОТЕРЕЯМ -->
	<main class="rnc-main" data-cy="rnc-main"
		 *ngIf="showResultsContent; then contentResults else noContentResults">
	</main>

	<!-- ЕСТЬ РЕЗУЛЬТАТЫ -->
	<ng-template #contentResults>
		<div class="rnc-content-container"
			 data-cy="rnc-content-container"
			 [ngClass]="'rnc-content-container_game-code_' + gameCode"
		>
			<div class="rnc-content-scroll webalt-scroll"
				 [ngClass]="'rnc-content-scroll_game-code_' + gameCode"
				 data-cy="rnc-content-scroll"
			>
				<!-- ШАПКА -->
				<label class="rnc-header-date"
					   [ngClass]="'rnc-header-date_game-code_' + gameCode"
					   data-cy="rnc-header-date"
				>
					{{
					'lottery.results_header' | translate: {
						number: viewResults?.drawNumber,
						date: viewResults?.drawDate
					}
					}}
				</label>

				<!-- КОНТЕНТ ЛОТЕРЕИ -->
				<div class="rnc-content" data-cy="rnc-content">
					<ng-content></ng-content>
				</div>
			</div>
		</div>
	</ng-template>

	<!-- НЕТ РЕЗУЛЬТАТОВ -->
	<ng-template #noContentResults>
		<div class="rnc-content-container rnc-content-container_inactive"
			 data-cy="rnc-content-container"
		>
			<div class="no-content-results"
				 data-cy="no-content-results"
			>
				{{ 'lottery.no_selected_draws' | translate }}
			</div>
		</div>
	</ng-template>

	<div class="lb-cell" data-cy="lb-cell">
		<!-- КНОПКИ НАВИГАЦИИ -->
		<app-results-navigation-buttons
			class="rnc-navigation"
			data-cy="rnc-navigation"
			[isEnabledGoBack]="service.isEnabledGoBack"
			[isEnabledGoForward]="service.isEnabledGoForward"
			(goBack)="onClickNextOrPrevButtonHandler(ResultNavigationDirection.Previous)"
			(goForward)="onClickNextOrPrevButtonHandler(ResultNavigationDirection.Next)"
			>
		</app-results-navigation-buttons>
	</div>

	<div class="rb-cell" data-cy="rb-cell">
		<!-- ПЕЧАТЬ РЕЗУЛЬТАТОВ -->
		<app-print-button class="rnc-print-results"
						  data-cy="rnc-print-results"
			[class.rnc-print-results_disabled]="printResultsDisabled"
			[isDisabled]="!viewResults || (service.filterDate && (!resultsList || resultsList.length === 0))"
			[showCopiesCounter]="true"
			(printAction)="onClickPrintResultsHandler($event)"
		>
		</app-print-button>
	</div>
</div>
