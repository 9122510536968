<div
	class="log-out-container"
	data-cy="log-out-container"
	(click)="onClickLogoutHandler()"
	>
	<svg class="icon" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="16" cy="16" r="15.5" fill="url(#paint0_radial)" stroke="#009788"/>
		<path d="M16 21.0794C17.1175 21.0794 18.0318 20.1651 18.0318 19.0477C18.0318 17.9302 17.1175 17.0159 16 17.0159C14.8826 17.0159 13.9683 17.9302 13.9683 19.0477C13.9683 20.1651 14.8826 21.0794 16 21.0794ZM22.0953 11.9366H21.0794V9.90483C21.0794 7.10102 18.8038 4.82546 16 4.82546C13.1962 4.82546 10.9207 7.10102 10.9207 9.90483H12.8508C12.8508 8.16769 14.2629 6.75562 16 6.75562C17.7372 6.75562 19.1492 8.16769 19.1492 9.90483V11.9366H9.90479C8.78733 11.9366 7.87305 12.8509 7.87305 13.9683V24.1271C7.87305 25.2445 8.78733 26.1588 9.90479 26.1588H22.0953C23.2127 26.1588 24.127 25.2445 24.127 24.1271V13.9683C24.127 12.8509 23.2127 11.9366 22.0953 11.9366ZM22.0953 24.1271H9.90479V13.9683H22.0953V24.1271Z" fill="#009788"/>
		<rect x="9" y="13" width="14" height="12" fill="#009788"/>
		<circle cx="16" cy="19" r="2" fill="#E8F9F9"/>
		<defs>
			<radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16 16) rotate(90) scale(16)">
				<stop stop-color="white"/>
				<stop offset="0.0001" stop-color="white"/>
				<stop offset="1" stop-color="#D8F4F4"/>
			</radialGradient>
		</defs>
	</svg>


</div>
