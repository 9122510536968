import { init, Tag } from '../tag';
import { ImgTag } from './img';
import { AlignFormat } from '@app/core/net/ws/api/models/print/print-models';
import {QRTag} from "@app/tickets-print/parser/tags/qr";

/**
 * Жирный ли шрифт
 */
type Bold = '0' | '1';

/**
 * Ширина линии
 */
type Width = '1' | '2';

/**
 * Не переводить на новую строку
 */
type Nolf = '0' | '1';

/**
 * Типы данных для печати
 */
export type PrintLine = string | ImgTag | QRTag;

/**
 * Модель xml тега <line>
 */
export class LineTag extends Tag {
	/**
	 * Не переводить на новую строку
	 */
	public nolf: Nolf = '0';

	/**
	 * Выравнивание
	 */
	public align?: AlignFormat;

	/**
	 * Жирный ли шрифт
	 */
	public bold?: Bold;

	/**
	 * Ширина линии
	 */
	public width?: Width;

	/**
	 * Расстояние между строками
	 */
	public spacing?: string;

	/**
	 * Данные для печати
	 * @private
	 */
	private readonly lineData: Array<PrintLine> = [];

	/**
	 * Создание и инициализация на основе xml атрибутов
	 * @param {NamedNodeMap} attributes xml атрибуты
	 */
	constructor(attributes: NamedNodeMap) {
		super();
		init(this, attributes);
	}

	/**
	 * Установка содержимого тега
	 * @param {string} content содержимое тега
	 */
	setText(content: string): void {
		this.lineData.push(content);
	}

	/**
	 * Устанока параметров, тег <param>
	 * @param {string} content содержимое тега
	 */
	setParam(content: string): void {
		this.lineData.push(content);
	}

	/**
	 * Установка картинки, тег <img>
	 * @param {string} content содержимое тега
	 */
	setImage(content: ImgTag): void {
		this.lineData.push(content);
	}

	/**
	 * Установка QR-кода, тег <qr>
	 * @param content содержимое тега
	 */
	setQR(content: QRTag): void {
		this.lineData.push(content);
	}

	/**
	 * Возвращает содержимое тега
	 * @returns {string[]}
	 */
	getData(): Array<PrintLine> {
		return this.lineData;
	}
}
