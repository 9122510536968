import { animate, state, style, transition, trigger } from '@angular/animations';

/**
 * Анимация для плавного масштабирования объекта.
 */
export const scaleAnimation = trigger('scaleAnimation', [
	transition('void => *', [
		style({transform: 'scale3d(.1, .1, .1)'}),
		animate('300ms ease-in-out')
	]),
	transition('* => void', [
		style({transform: 'scale3d(.1, .1, .1)'}),
		animate('300ms ease-in-out')
	])
]);

/**
 * Константы для фаз и состояний анимации.
 */
export enum AnimationState {
	/**
	 * Свернутое состояние.
	 */
	collapsed = 'collapsed',
	/**
	 * Развернутое состояние.
	 */
	expanded = 'expanded'
}

/**
 * Анимация для плавного открытия/закрытия гамбургер-меню.
 */
export const hamburgerAnimation = trigger('openClose', [
	state('collapsed', style({transform: 'translate3d(-100%, 0, 0)', 'margin-left': '-12px'})),
	state('expanded', style({transform: 'translate3d(0, 0, 0)', 'margin-left': '12px'})),
	transition('collapsed => expanded', animate('300ms ease-in-out')),
	transition('expanded => collapsed', animate('300ms ease-in-out'))
]);

/**
 * Анимация для плавного открытия/закрытия меню в "Стирачках".
 */
export const tmlOpenClose = trigger('tmlOpenClose', [
	state('collapsed', style({width: '300px', 'box-shadow': 'none'})), // TODO: временный костыль
	state('expanded', style({width: 'calc(100vw - 230px)', 'box-shadow': '6px 0px 20px #ccc'})),
	transition('collapsed => expanded', animate('300ms ease-in-out')),
	transition('expanded => collapsed', animate('300ms ease-in-out'))
]);

/**
 * Анимация для плавного появления/исчезновения элемента.
 */
export const fadeInAnimation = trigger('fadeInAnimation', [
	transition(':enter', [
		style({opacity: 0}),
		animate(500)
	]),
	transition(':leave', [
		animate(500, style({opacity: 0}))
	]),
	state('*', style({opacity: 1}))
]);
