import { BarcodeObject } from './barcode-object';

/**
 * Баркод бумажной лотереи с разделением на подгруппы.
 */
export class TMLBarcode extends BarcodeObject {

	/**
	 * Конструктор объекта.
	 *
	 * @param {string} barcode Оригинальный баркод.
	 */
	constructor(barcode: string) {
		super(barcode);

		if (!!barcode && (barcode.length === 16 || barcode.length === 13)) {
			this.gameNumber = barcode.substr(0, 4);
			this.intGameNumber = +this.gameNumber;

			this.ticketPackage = barcode.substr(4, 6);
			this.intTicketPackage = +this.ticketPackage;

			this.ticketNumber = barcode.substr(10, 3);
			this.intTicketNumber = +this.ticketNumber;

			if (barcode.length === 16) {
				this.verificationCode = barcode.substr(13, 3);
			}
		}
	}
}
