import { plainToClass } from 'class-transformer';
import { validate, validateSync, ValidationError } from 'class-validator';
import { Logger } from '@app/core/net/ws/services/log/logger';

/**
 * Интерфейс наблюдателя за ходом выполнения валидации.
 */
export interface IValidateObserver<T> {
	/**
	 * Обработчик успешной валидации.
	 * @param value Результат валидации.
	 */
	onSucceed(value: T): void;

	/**
	 * Обработчик неуспешной валидации.
	 * @param error Ошибка валидации.
	 */
	onFailed(error: Error): void;
}

/**
 * Форматирование ошибки проверки.
 *
 * @param {Array<ValidationError>} errors ошибки проверки
 * @returns {string} результат форматирования
 */
export const getError = (errors: Array<ValidationError>): string => {
	let children = errors;
	while (true) {
		if (children && children.length) {
			if (children[0].constraints) {
				let error = '';
				error = error.concat(`constraints: ${JSON.stringify(children[0].constraints)}`);
				error = error.concat(`, property: ${JSON.stringify(children[0].property)}`);
				error = error.concat(`, value: ${JSON.stringify(children[0].value)}`);

				return error;
			}
			children = children[0].children;
		} else {
			return undefined;
		}
	}
};

/**
 * Вызов методов наблюдателя за результатом проверки в зависимости от результатов проверки.
 *
 * @param {T} verifiable тип на основании которого производятся все проверки
 * @param {IValidateObserver<T>} observer наблюдатель за ходом проверки
 * @param {ValidationError[]} errors ошибки проверки
 */
const validateResult = <T>(verifiable: T, observer: IValidateObserver<T>, errors: Array<ValidationError>): void => {
	if (errors.length > 0) {
		Logger.Log.e('validateResult', `validation failed: ${getError(errors)}`)
			.console();
		observer.onFailed(new Error(`validation failed: ${getError(errors)}`));
	} else {
		Logger.Log.i('Validator', 'validation succeed')
			.console();
		observer.onSucceed(verifiable);
	}
};

/**
 * Асинхронная (не блокирующая) валидация, проверка простого js объекта на соответствие указанной схеме (типу данных).
 *
 * @param T схема(тип данных) для которого осуществляется проверка
 * @param {Object} obj проверяемый объект
 * @returns {Promise<T>} observer наблюдатель за ходом проверки
 */
export const validateResponse = <T>(T, obj: Object): Promise<T> => {
	const verifiable: T = plainToClass(T, obj);

	return validate(verifiable)
		.then(errors => {
			if (errors.length > 0) {
				throw new Error(`validation failed: ${getError(errors)}`);
			}

			return verifiable;
		})
		.catch((error: Error) => {
			throw error;
		});
};

/**
 * Синхронная (блокирующая) валидация, проверка простого js объекта на соответствие указанной схеме (типу данных).
 *
 * @param T схема(тип данных) для которого осуществляется проверка
 * @param {Object} obj проверяемый объект
 * @param {IValidateObserver<T>} observer наблюдатель за ходом проверки
 */
export const validateResponseSync = <T>(T, obj: Object, observer: IValidateObserver<T>): void => {
	const verifiable: T = plainToClass(T, obj);
	const errors = validateSync(verifiable);
	validateResult(verifiable, observer, errors);
};
