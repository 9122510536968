/**
 * Объект среды выполнения приложения для unit-тестов и mock-окружения
 */
export const environment = {
	version: 'DEV-100605',
	production: false,
	terminal: false,
	enableProfile: true,
	enableActions: false,
	mockData: true,
	login: '100605',
	password: '100605',
	printerUrl: 'ws://127.0.0.1:2424',
	sms: '1234'
};
