import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { MslBaseInput } from '@app/shared/components/msl-base-input';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

/**
 * Компонент ввода текстовых значений с иконкой клавиатуры и виртуальной клавиатурой.
 */
@Component({
	selector: 'app-msl-input-with-keyboard',
	templateUrl: './msl-input-with-keyboard.component.html',
	styleUrls: ['./msl-input-with-keyboard.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: MslInputWithKeyboardComponent,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: MslInputWithKeyboardComponent,
			multi: true
		}
	]
})
export class MslInputWithKeyboardComponent extends MslBaseInput implements   ControlValueAccessor, Validator {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Подпись к компоненту ввода (псевдоэлемент, уже используется в разных местах приложения)
	 */
	@Input()
	placeholder = '';

	/**
	 * Значение компонента.
	 */
	@Input()
	value = '';

	/**
	 * Подпись к компоненту ввода с возможностью анимации
	 */
	@Input()
	label = '';

	/**
	 * Задает максимальную длину поля ввода.
	 */
	@Input()
	maxLength: number;

	/**
	 * Задает минимальную длину поля ввода.
	 */
	@Input()
	minLength: number;

	/**
	 * Задает определенную ширину элемента в пикселях при получении фокуса.
	 */
	@Input()
	inputWidthOnFocus = 0;

	/**
	 * Задает выравнивание в текстовом поле.
	 */
	@Input()
	textAlign = 'left';

	/**
	 * Исправляет вертикальное положение плейсхолдера.
	 */
	@Input()
	fixPlaceholder = false;

	/**
	 * Флаг состояния ошибочного ввода
	 */
	@Input()
	errorState = false;

	/**
	 * Флаг недоступности поля ввода
	 */
	@Input()
	isDisabled = false;

	@Input()
	onlyNumbers = true;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие генерируется при изменении значения компонента.
	 */
	@Output()
	readonly valueChanged = new EventEmitter<string>();

	/**
	 * Событие генерируется при получении фокуса.
	 */
	@Output()
	readonly focused = new EventEmitter<FocusEvent>();

	/**
	 * Событие генерируется при потере фокуса.
	 */
	@Output()
	readonly blured = new EventEmitter<FocusEvent>();

	/**
	 * Событие генерируется при нажатии на клавишу Enter.
	 */
	@Output()
	readonly realEnterPressed = new EventEmitter<KeyboardEvent>();

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Ссылка на элемент ввода.
	 */
	@ViewChild('inputElement', {static: true})
	inputElement: ElementRef;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Унаследованное событие от базового класса.
	 * Вызывается в коллбеке при изменении значения UI-элемента
	 * @param value Передаваемое значение
	 */
	private onChange = (value: string) => {};

	/**
	 * Унаследованное событие от базового класса.
	 */
	private onTouched = () => {};

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Слушатель события {@link input} на элементе ввода.
	 *
	 * @param {Event} event Передаваемое событие.
	 */
	onInputHandler(event: Event): void {
		const newValue = (event.target as HTMLInputElement).value;
		this.checkAndEmitValueChangeEvent(newValue);
	}

	/**
	 * Обработчик события фокуса.
	 * @param event Передаваемое событие.
	 */
	onFocusHandler(event: FocusEvent): void {
		this.focused.emit(event);
	}

	/**
	 * Обработчик события потери фокуса.
	 * @param event Передаваемое событие.
	 */
	onBlurHandler(event: FocusEvent): void {
		this.blured.emit(event);
	}


	/**
	 * Обработчик события нажатия клавиши.
	 * @param event Передаваемое событие.
	 */
	onKeydownHandler(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.realEnterPressed.emit(event);
		}
	}

	/**
	 * Очистить поле ввода.
	 */
	clearValue(): void {
		this.checkAndEmitValueChangeEvent('');
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Проверяет, изменилось ли значение и если да, то генерирует событие {@link valueChanged}.
	 * @param newValue Новое значение.
	 * @private
	 */
	private checkAndEmitValueChangeEvent(newValue: string): void {
		if (this.value !== newValue) {
			this.value = newValue;
			this.onChange(this.value);
			this.valueChanged.emit(this.value);
		}
	}

	// -----------------------------
	//  MslBaseInput
	// -----------------------------
	/**
	 * Функция установки фокуса на элемент ввода.
	 */
	setFocus(): void {
		(this.inputElement.nativeElement as HTMLInputElement).focus();
	}

	/**
	 * Функция снятия фокуса с элемента ввода.
	 */
	removeFocus(): void {
		(this.inputElement.nativeElement as HTMLInputElement).blur();
	}

	// -----------------------------
	//  ControlValueAccessor
	// -----------------------------
	/**
	 * Метод, который вызывается при изменении значения UI-элемента
	 * @param fn Передаваемая callback-функция
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Метод, который вызывается при касании к UI-элементу
	 * @param fn Передаваемая callback-функция
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	/**
	 * Метод для установки недоступности компонента
	 * @param isDisabled Флаг недоступности
	 */
	setDisabledState(isDisabled: boolean): void {
	}

	/**
	 * Метод для программного присвоения значения компоненту
	 * @param obj Значение
	 */
	writeValue(obj: any): void {
		this.value = obj;
	}

	// -----------------------------
	//  Validator
	// -----------------------------

	/**
	 * Регистрирует функцию обратного вызова для выполнения при изменении входных данных валидатора
	 * @param fn Функция обратного вызова
	 */
	registerOnValidatorChange(fn: () => void): void {
	}

	/**
	 * Валидация компонента
	 * @param control Компонент
	 */
	validate(control: AbstractControl): ValidationErrors | null {
		return control.valid ? undefined : control.errors;
	}


	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
}
