import { Component, Input } from '@angular/core';
import { LotteriesGroupCode, LotteryGameCode } from '@app/core/configuration/lotteries';

/**
 * Модель логотипа.
 */
interface IMslGameLogoItem {
	/**
	 * Код игры.
	 */
	code: LotteryGameCode;
	/**
	 * Код группы игр.
	 */
	groupCode?: LotteriesGroupCode;
	/**
	 * Ссылка на изображение.
	 */
	imgUrl: string;
	/**
	 * Заголовок.
	 */
	title: string;
	/**
	 * Класс для элемента логотипа.
	 */
	logoItemClass: string;
}

/**
 * Список вариантов отображения:
 * - {@link Default} - дефолтное, в левом верхнем углу
 * - {@link ActionsList} - для списка акций
 * - {@link DemoList} - для списка демо-игр
 */
export enum LogoViewStyle {
	Default		= 'default',
	ActionsList	= 'actions-list',
	DemoList = 'demo-list'
}

/**
 * Компонент для отображения логотипа определенной игры, задаваемой параметром {@link gameCode} или {@link groupCode}.
 */
@Component({
	selector: 'app-msl-game-logo',
	templateUrl: './msl-game-logo.component.html',
	styleUrls: ['./msl-game-logo.component.scss']
})
export class MslGameLogoComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Способ отображения компонента.
	 * По умолчанию {@link LogoViewStyle.Default Default}.
	 */
	@Input()
	logoViewStyle: LogoViewStyle = LogoViewStyle.Default;

	/**
	 * Сеттер кода игры.
	 * @param value Код игры.
	 */
	@Input()
	set gameCode(value: LotteryGameCode) {
		this._gameCode = value;
		this.model = this.parseCode(value);
	}

	/**
	 * Сеттер кода группы игр для ЭМЛ
	 * @param value Код группы игр.
	 */
	@Input()
	set groupCode(value: LotteriesGroupCode) {
		this.model = this.model || this.parseGroupCode(value);
	}

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Логотипы игр в зависимости от кода игры.
	 * @private
	 */
	private readonly logosMap = new Map([
		[106, '/assets/img/instant/shvydkogroshi.png'],
		[107, '/assets/img/instant/morskiy_biy.png'],
		[109, '/assets/img/instant/hto_tam.png'],
		[121, '/assets/img/instant/grosheniata.png'],
		[122, '/assets/img/instant/do_sviata.png'],
		[123, '/assets/img/instant/shaslyvyj_kvytok.png'],
		[124, '/assets/img/instant/gamanec.png'],
		[126, '/assets/img/instant/shalena_piatirka.png'],
		[127, '/assets/img/instant/hrestyky_noliky.png'],
		[132, '/assets/img/instant/shvydki_7.png'],
		[133, '/assets/img/instant/volodar_gry.png'],
		[134, '/assets/img/instant/maxi_lotto.png'],
		[139, '/assets/img/instant/jak_kozaky.png'],
		[140, '/assets/img/instant/vesela_skarbnychka.png'],
		[141, '/assets/img/instant/goroskop.png'],
		[142, '/assets/img/instant/charivna_para.png'],
		[143, '/assets/img/instant/vygrashni_linii.png'],
		[144, '/assets/img/instant/garacha_shtuchka.png'],
		[145, '/assets/img/instant/veselyk.png'],
		[146, '/assets/img/instant/sviatkova_graylyve_lito.png'],
		[147, '/assets/img/instant/korolivka_velych.png'],
		[149, '/assets/img/instant/zyvi_groshi.png'],
		[151, '/assets/img/instant/poluvannia_za_skarbamy.png'],
		[152, '/assets/img/instant/kupa_groshey.png'],
		[153, '/assets/img/instant/las-vegas.png'],
		[154, '/assets/img/instant/zolota_kopalnia.png'],
		[155, '/assets/img/instant/gol.png'],
		[157, '/assets/img/instant/labyrinth.png'],
		[160, '/assets/img/instant/myttevyj_biljard.png'],
		[161, '/assets/img/instant/gran_pri.png'],
		[162, '/assets/img/instant/autoloto.jpg'],
		[163, '/assets/img/instant/lucky_numbers_400х434.png']
	]);

	/**
	 * Текущий код игры.
	 * @private
	 */
	private _gameCode: LotteryGameCode;

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Экземпляр модели для отображения логотипа игры.
	 */
	model: IMslGameLogoItem;

	/**
	 * Список вариантов отображения логотипа игры.
	 */
	readonly LogoViewStyle = LogoViewStyle;

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Парсинг кода игры и получение модели для отображения логотипа игры.
	 * @param code Код игры.
	 * @private
	 */
	private parseCode(code: LotteryGameCode): IMslGameLogoItem {
		let imgUrl: string;
		let title: string;

		switch (code) {
			case LotteryGameCode.MegaLot:
				imgUrl = '/assets/img/pic/logo_megalot.png';
				title = 'lottery.megalot.megalot_name';
				break;

			case LotteryGameCode.Gonka:
				imgUrl = '/assets/img/pic/logo_gg.png';
				title = 'lottery.race_for_money.loto_gonka';
				break;

			case LotteryGameCode.Sportprognoz:
				imgUrl = '/assets/img/pic/logo_sportprognoz.png';
				title = 'lottery.sportprognoz.game_name';
				break;

			case LotteryGameCode.Tip:
				imgUrl = '/assets/img/pic/logo_tiptop.png';
				title = 'lottery.tiptop.game_name';
				break;

			case LotteryGameCode.Top:
				imgUrl = '/assets/img/pic/logo_tiptop.png';
				title = 'lottery.tiptop.game_name';
				break;

			case LotteryGameCode.TML_BML:
				imgUrl = '/assets/img/pic/logo_styrachka.png';
				title = 'lottery.tmlbml.game_name';
				break;

			case LotteryGameCode.Zabava:
				imgUrl = '/assets/img/pic/logo_zabava.svg';
				title = 'lottery.zabava.loto_zabava';
				break;

			case LotteryGameCode.Kare:
				imgUrl = '/assets/img/pic/logo_kare.png';
				title = 'lottery.kare.game_name';
				break;

			default:
				return undefined;
		}

		return {
			code,
			imgUrl,
			title,
			groupCode: LotteriesGroupCode.Regular,
			logoItemClass: `game-logo__image_game-code_${code}`
		};
	}

	/**
	 * Парсинг кода группы игр и получение модели для отображения логотипа игры.
	 * @param code Код группы игр.
	 * @private
	 */
	private parseGroupCode(code: LotteriesGroupCode): IMslGameLogoItem {
		if (code) {
			let imgUrl = code === LotteriesGroupCode.EInstant ?
				'/assets/img/pic/logo_instant.png' : '/assets/img/pic/golden-triumph_logo.png';
			const title = code === LotteriesGroupCode.EInstant ?
				'lottery.instant.loto_momentary' : 'lottery.instant.loto_golden_triumph';

			if (this.logoViewStyle === LogoViewStyle.DemoList) {
				imgUrl = this.logosMap.get(this._gameCode) ? this.logosMap.get(this._gameCode) : imgUrl;
			}

			return {code: 0, groupCode: code, imgUrl, title, logoItemClass: `group-code-${code}`};
		}

		return undefined;
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------



}
