import * as StringFormatter from 'sprintf-js';

/**
 * Модель данных для отображения в диалоговом окне ошибок
 */
export class DialogError {
	/**
	 * Код ошибки
	 */
	code: number;

	/**
	 * Сообщение об ошибке
	 */
	message: string;

	/**
	 * Подробное описание ошибки
	 */
	messageDetails: string;

	/**
	 * Конструктор.
	 *
	 * @param {ErrorCode} code Код ошибки
	 * @param {string} message Сообщение об ошибке
	 * @param {string} messageDetails Подробное описание ошибки
	 * @param {string} args Параметры для форматирования сообщения об ошибке
	 */
	constructor(
		code: ErrorCode,
		message: string,
		messageDetails?: string,
		...args: Array<string>
	) {
		this.message = args && args.length > 0 ? StringFormatter.sprintf(message, ...args) : message;
		this.code = code;
		this.messageDetails = messageDetails;
	}
}

/**
 * Модель данных для отображения в информационном диалоговом окне
 */
export class DialogInfo {
	/**
	 * Информационное сообщение
	 */
	message: string;

	/**
	 * Подробное описание информационного сообщения
	 */
	messageDetails: string;

	/**
	 * Конструктор.
	 *
	 * @param {string} message Информационное сообщение
	 * @param {string} messageDetails Подробное описание информационного сообщения
	 * @param {string} args Параметры для форматирования информационного сообщения
	 */
	constructor(
		message: string,
		messageDetails?: string,
		...args: Array<string>
	) {
		this.message = args && args.length > 0 ? StringFormatter.sprintf(message, ...args) : message;
		this.messageDetails = messageDetails;
	}
}

/**
 * Список кодов ошибок, для отображения в диалоговом окне ошибок
 */
export enum ErrorCode {

	/**
	 * Неправильная СМС
	 */
	IncorrectSMS = 843,

	/**
	 * Неопределенная ошибка, полученная от глобального перехватчика.
	 */
	Undefined = 1000,

	/**
	 * Ошибка сети.
	 */
	Network = 1001,

	/**
	 * Ошибка при выполнении HTTP запроса.
	 */
	HttpRequest = 1002,

	/**
	 * Сервис браузерного хранилища недоступен.
	 */
	StorageServiceUnreach = 1003,

	/**
	 * Данные в браузерном хранилище не найдены.
	 */
	StorageServiceDataNotFound = 1004,

	/**
	 * Ошибка при получении информации об аппаратном обеспечении.
	 */
	BackendHardwareInfo = 1005,

	/**
	 * Терминал не сконфигурирован
	 */
	LogServiceUrl = 1011,

	/**
	 * Ошибка конфигурации терминала
	 */
	LogServiceConfig = 1012,

	/**
	 * Сервис логирования недоступен
	 */
	LogServiceUnreach = 1013,

	/**
	 * Принт-сервис не сконфигурирован
	 */
	PrintServiceUrl = 1021,

	/**
	 * Ошибка конфигурации принт-сервиса
	 */
	PrintServiceConfig = 1022,

	/**
	 * Принт-сервис недоступен
	 */
	PrintServiceUnreach = 1023,

	/**
	 * Сетевой сервис недоступен
	 */
	CommunicationServiceUnreach = 1024,

	/**
	 * Сетевой сервис не сконфигурирован
	 */
	CommunicationServiceUrl = 1025,

	/**
	 * Ошибка конфигурации сетевого сервиса
	 */
	CommunicationServiceConfig = 1026,

	/**
	 * Сервис сканирования не сконфигурирован
	 */
	ScannServiceUrl = 1031,

	/**
	 * Ошибка конфигурации сервиса сканирования
	 */
	ScannServiceConfig = 1032,

	/**
	 * Загрузка терминала невозможна. Scan service недоступный
	 */
	ScannServiceUnreach = 1033,

	/**
	 * Неизвестный купон.
	 */
	ScannUnknownCoupon = 1034,

	/**
	 * Отсутствуют тиражи по сканированному купону.
	 */
	ScannNoDrawsByCoupon = 1035,

	/**
	 * Отсутствуют параметры терминала
	 */
	TermAbsentParam = 1051,

	/**
	 * Ошибка сохранения параметров терминала
	 */
	TermSaveParam = 1052,

	/**
	 * Ошибка инициализации терминала
	 */
	TermInit = 1053,

	/**
	 * Ошибка обработки конфигурации терминала
	 */
	ConfigCs = 1061,

	/**
	 * Ошибка обработки конфигурации терминала
	 */
	ConfigCsParam = 1063,

	/**
	 * Ошибка перехода на не назначенную на терминале лотерею
	 */
	LotteryNotAssigned = 1064,

	/**
	 * Ошибка загрузки переводов
	 */
	Localization = 1071,

	/**
	 * Ошибка загрузки перевода по ключу
	 */
	LocalizationKey = 1072,

	/**
	 * Ошибка авторизации оператора
	 */
	OperAuth = 1081,

	/**
	 * Ошибка авторизации терминала
	 */
	TermAuth = 1083,

	/**
	 * Ошибка получения роли пользователя
	 */
	GetRole = 1083,

	/**
	 * Недействительный баркод
	 */
	BarcodeRegexp = 1091,

	/**
	 * Ошибка проверки билета
	 */
	TicketChecking = 1092,

	/**
	 * Ошибка выплаты по билету
	 */
	TicketPayment = 1093,

	/**
	 * Ошибка печати: будет проведена отмена транзакции
	 */
	TicketRegister = 1095,

	/**
	 * Ошибка регистрации: будет проведена отмена транзакции
	 */
	TicketPrint = 1096,

	/**
	 * Последняя транзакция отсутствует
	 */
	LastTransactionExist = 1097,

	/**
	 * Ошибка обработки параметров тиражей
	 */
	DrawsService = 1101,

	/**
	 * Ошибка получения параметров тиражей
	 */
	DrawsServiceGet = 1102,

	/**
	 * Ошибка сохранения параметров тиражей
	 */
	DrawsServiceSave = 1103,

	/**
	 * Ошибка удаления параметров тиражей
	 */
	DrawsServiceDel = 1104,

	/**
	 * Ошибка получения параметров тиражей из ЦС
	 */
	DrawsCsGet = 1105,

	/**
	 * Ошибка проверки параметров тиражей в ЦС
	 */
	DrawsCsValidate = 1106,

	/**
	 * Ошибка в сервисе отчетов
	 */
	ReportService = 1111,

	/**
	 * Ошибка получения отчета
	 */
	GetReport = 1112,

	/**
	 * Ошибка печати отчета
	 */
	PrintReport = 1113,

	/**
	 * Ошибка парсинга отчета
	 */
	ParseReport = 1114,

	/**
	 * Необработанная ошибка при проведении финансовой транзакции.
	 */
	Transaction = 1121,

	/**
	 * Проблема с моделью транзакции.
	 */
	TransactionModelError = 1122
}
