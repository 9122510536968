import {UpdateDrawInfoLotteries} from "@app/core/net/http/api/models/update-draw-info";

/**
 * Mock-тиражи для игры "Забава".
 */
export const DRAWS_FOR_GAME_ZABAVA: UpdateDrawInfoLotteries | any = {
	"lottery": {
		"currency": "",
		"draws": [{
			"draw": {
				"bet_sum": "10.00",
				"code": "1643",
				"data": {
					"bet_sum_max": "20000.00",
					"bet_sum_min": "100.00"
				},
				"dr_bdate": "2029-11-01 13:35:23",
				"dr_edate": "2029-11-01 14:35:23",
				"jackpot": '1000000',
				"num": "1305",
				"sale_bdate": "2029-09-07 12:35:23",
				"sale_edate": "2029-11-01 12:35:23",
				"serie_code": "10",
				"serie_num": "",
				"status": "Регистрация ставок",
				"status_code": "DRST_BET",
				"version": "6",
				"win_bdate": "2029-11-01 15:35:23",
				"win_edate": "2030-01-07 15:35:23"
			}
		}, {
			"draw": {
				"bet_sum": "10.00",
				"code": "1646",
				"data": {
					"bet_sum_max": "20000.00",
					"bet_sum_min": "100.00"
				},
				"dr_bdate": "2029-11-19 13:21:15",
				"dr_edate": "2029-11-19 13:21:16",
				"jackpot": '100000',
				"num": "1306",
				"sale_bdate": "2029-09-11 13:21:14",
				"sale_edate": "2029-11-19 13:21:14",
				"serie_code": "10",
				"serie_num": "",
				"status": "Регистрация многотиражных ставок",
				"status_code": "DRST_BET_MULTI",
				"version": "2",
				"win_bdate": "2029-11-19 13:21:17",
				"win_edate": "2030-09-11 13:21:14"
			}
		}],
		"lott_code": "3",
		"lott_name": "Лото Забава"
	}
};
