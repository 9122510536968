import { IReports } from './cs';
import {GetPayedTicketInfoReq} from "@app/core/net/http/api/models/get-payed-ticket-info";

/**
 * Список имен параметров используемых при взаимодействии с ЦС по eSAP протоколу.
 */
export class EsapParams {

	/**
	 * Имя операции.
	 * Тип передаваемых данных: {@link string}
	 */
	static readonly ACTION = 'ACTION';

	/**
	 * Версия протокола (константа).
	 * На основании версии протокола выполняется контроль наличия обязательных параметров в запросе.
	 * Тип передаваемых данных: Число в виде {@link string}.
	 */
	static readonly PROTO_VER = 'PROTO_VER';

	/**
	 * Тип представления данных в протоколе.
	 * Параметр может не передаваться, в этом случае подразумевается использование типа keyvalue.
	 * Возможные значения параметра:
	 * - keyvalue (default) - запрос в виде пар «ключ=значение», разделенные символом '&'.
	 * Данные тип соответствует описанию в этом документе.
	 * - keyvalue-json - запрос в виде пар «ключ=значение», разделенные символом '&'.
	 * Ответ формируется в виде json структур.
	 * - protobuf - данные в формате google protobuf (для внутреннего использования)
	 * <br>
	 * Тип передаваемых данных: {@link string}
	 */
	static readonly PROTO_TYPE = 'PROTO_TYPE';

	/**
	 * Канал распространения.
	 * В зависимости от указанного канала распространения на серверной части может выполняться ветвление логики
	 * по обработке входных параметров и по формированию ответа.
	 * Возможные значения:
	 * - CHANNEL_TYPE=rich_term
	 * - CHANNEL_TYPE=lt_term
	 * - CHANNEL_TYPE=sst
	 * - CHANNEL_TYPE=web
	 * - CHANNEL_TYPE=mobile
	 * <br>
	 * В версии eSAP v.2 этот параметр не использовался.
	 * Тип передаваемых данных: {@link string}
	 */
	static readonly CHANNEL_TYPE = 'CHANNEL_TYPE';

	/**
	 * Идентификатор транзакции.
	 * Для каждого {@link CLIENT_ID} должен быть уникальный идентификатор транзакции.
	 * Клиентам, идентификатор транзакции рекомендуется формировать на основании текущего системного времени и
	 * дополнительного циклического счетчика.
	 * Допускается исключение, когда поддерживать транзакционность не нужно. В этом случае параметр {@link CLIENT_TRANS_ID}
	 * для данного типа запроса всегда должен быть равен 1. При этом, сервис каждый запрос будет обрабатывать как новую транзакцию.
	 * Тип передаваемых данных: {@link number}
	 */
	static readonly CLIENT_TRANS_ID = 'CLIENT_TRANS_ID';

	/**
	 * Идентификатор клиента.
	 * В данном случае под клиентом подразумевается устройство или клиентское приложение, а не пользователь.
	 * Параметр используется для того, чтобы идентифицировать и контролировать транзакции по данному клиенту.
	 * В сервисе этот параметр будет использоваться для хранения транзакций по данному клиенту.
	 * В качестве {@link CLIENT_ID} для терминальной сети может передаваться код терминала.
	 * Предполагается, что в системе существует единая база клиентов.
	 * В этом случае, каждый веб-сайт в отношении сервиса тоже будет клиентом и будет представлены одним идентификатором.
	 * Тип передаваемых данных: {@link string}
	 */
	static readonly CLIENT_ID = 'CLIENT_ID';

	/**
	 * Информация о клиенте (web-браузере).
	 * Тип передаваемых данных: {@link string}
	 */
	static readonly CLIENT_INFO = 'CLIENT_INFO';

	/**
	 * Тип клиента.
	 */
	static readonly CLIENT_TYPE = 'CLIENT_TYPE';

	/**
	 * Идентификатор используемого языка ("en", "ru", "ua").
	 * Используется для поддержки многоязычности.
	 * В частности, на указанном в запросе языке будут формироваться сообщения об ошибках в ответе сервисов.
	 * В версии v.2 этот параметр не использовался.
	 * Тип передаваемых данных: {@link string}
	 */
	static readonly LANG = 'LANG';

	/**
	 * Идентификатор пользователя.
	 */
	static readonly USER_ID = 'USER_ID';

	/**
	 * Идентификатор сессии пользователя.
	 */
	static readonly SID = 'SID';

	/**
	 * Идентификатор пользователя, для которого выполняется операция.
	 */
	static readonly TARGET_USER_ID = 'TARGET_USER_ID';

	/**
	 * Логин пользователя.
	 */
	static readonly LOGIN = 'LOGIN';

	/**
	 * Пароль пользователя.
	 */
	static readonly PASSWD = 'PASSWD';

	/**
	 * Новый пароль пользователя.
	 */
	static readonly NEWPASSWD = 'NEWPASSWD';

	/**
	 * Идентификатор отменяемой транзакции.
	 */
	static readonly CNL_TRANS_ID = 'CNL_TRANS_ID';

	/**
	 * Флаг отмены транзакции.
	 */
	static readonly CNL_FLAG = 'CNL_FLAG';

	/**
	 * Список идентификаторов нераспечатанных билетов, которые необходимо отменить.
	 */
	static readonly CHECK_ID_LIST = 'CHECK_ID_LIST';

	/**
	 * Идентификатор терминала.
	 */
	static readonly UID = 'UID';

	/**
	 * Код лотереи.
	 */
	static readonly LOTTERY = 'LOTTERY';

	/**
	 * Список тиражей
	 */
	static readonly DRAWS = 'DRAWS';

	/**
	 * Код терминала.
	 */
	static readonly TERM_CODE = 'TERM_CODE';

	/**
	 * Код оператора.
	 */
	static readonly OPER_CODE = 'OPER_CODE';

	/**
	 * Стоимость билета
	 */
	static readonly AMOUNT = 'AMOUNT';

	/**
	 * Количество ставок.
	 */
	static readonly BETS_COUNT = 'BETS_COUNT';

	/**
	 * Сумма ставки.
	 */
	static readonly BET_SUM = 'BET_SUM';

	/**
	 * Количество билетов.
	 */
	static readonly TICKETS_COUNT = 'TICKETS_COUNT';

	/**
	 * Данные ставок
	 */
	static readonly BETS_DATA = 'BETS_DATA';

	/**
	 * Данные по ставкам ("Спортпрогноз").
	 */
	static readonly BETS = 'BETS';

	/**
	 * Сумма ставки ("Спортпрогноз").
	 */
	static readonly PAY_AMOUNT = 'PAY_AMOUNT';

	/**
	 * Количество тиражей.
	 */
	static readonly DRAW_COUNT = 'DRAW_COUNT';

	/**
	 * Номер тиража.
	 * Может передаваться в запросе при {@link CHANNEL_TYPE}=rich_term.
	 * Если параметр не передается, сервис считает, что запрос сформирован для текущего тиража.
	 */
	static readonly DRAW_NUM = 'DRAW_NUM';

	/**
	 * Код тиража.
	 */
	static readonly DRAW_CODE = 'DRAW_CODE';

	/**
	 * Код игры (лотереи).
	 */
	static readonly GAME_CODE = 'GAME_CODE';

	/**
	 * Специфический параметр для игры "Забава".
	 * SALE_TYPE = 1 - используется для режима когда билеты для продажи выбираются произвольно на стороне ЦС и данные о билетах
	 * передаются клиентской стороне.
	 * SALE_TYPE = 2 - используется для режима когда клиентская сторона уже имеет данные о билетах (игровые комбинации),
	 * и данной командой клиент пытается зарегистрировать эти билеты как проданные (данный режим пока не рассматривается).
	 */
	static readonly SALE_TYPE = 'SALE_TYPE';

	/**
	 * Идентификатор картинки.
	 */
	static readonly IMAGE_ID = 'IMAGE_ID';

	/**
	 * Тип картинки.
	 */
	static readonly TYPE = 'TYPE';

	/**
	 * Мак-код билета.
	 */
	static readonly BARCODE = 'BARCODE';

	/**
	 * Штрихкод, в котором зашифрован код категории выигрыша (используется только для бумажных МЛ).
	 */
	static readonly WINBARCODE = 'WINBARCODE';

	/**
	 * Сумма выигрыша.
	 */
	static readonly WIN_SUM = 'WIN_SUM';

	/**
	 * Идентификатор действия.
	 */
	static readonly ACTION_ID = 'ACTION_ID';

	/**
	 * Дополнительная информация о результатах проверки билета, которая может содержать причины запрета выплаты,
	 * рекомендации по дальнейшим действиям игрока или продавца и т.д. Эта информация может выводиться на экран продавца,
	 * экран игрока, страницу веб-сайта..
	 * seller_notice, seller_hint, customer_notice, customer_hint
	 */
	static readonly ACCEPT_ADDITIONAL_INFO = 'ACCEPT_ADDITIONAL_INFO';

	/**
	 * Тип ввода ставки:
	 * - 0 - с купона
	 * - 1 - с купона с отметкой "АВТО"
	 * - 2 - ручной ввод
	 * - 3 - кнопкой автогенерации ставки ("QP")
	 *
	 * Тип передаваемых данных: {@link number}
	 */
	static readonly INPUT_MODE = 'INPUT_MODE';

	/**
	 * Количество тиражей в ставке по игре Тип.
	 */
	static readonly TIP_DRAW_COUNT = 'TIP_DRAW_COUNT';

	/**
	 * Количество ставок в запросе продажи лотереи Тип.
	 */
	static readonly TIP_BETS_COUNT = 'TIP_BETS_COUNT';

	/**
	 * Признак того, что комбинации в каждом тираже должны быть одинаковые (Тип).
	 */
	static readonly TIP_IS_UNIQUE = 'TIP_IS_UNIQUE';

	/**
	 * Признак того, что запрашивается единый мультитиражный чек (Тип)
	 */
	static readonly TIP_SINGLE_TICKET = 'TIP_SINGLE_TICKET';

	/**
	 * Количество тиражей в ставке по игре Топ.
	 */
	static readonly TOP_DRAW_COUNT = 'TOP_DRAW_COUNT';

	/**
	 * Количество ставок в запросе продажи лотереи Топ.
	 */
	static readonly TOP_BETS_COUNT = 'TOP_BETS_COUNT';

	/**
	 * Признак того, что комбинации в каждом тираже должны быть одинаковые (Топ).
	 */
	static readonly TOP_IS_UNIQUE = 'TOP_IS_UNIQUE';

	/**
	 * Признак того, что запрашивается единый мультитиражный чек (Топ)
	 */
	static readonly TOP_SINGLE_TICKET = 'TOP_SINGLE_TICKET';

	/**
	 * Тип отчета, который требуется сформировать.
	 */
	static readonly REPORT_TYPE = 'REPORT_TYPE';

	/**
	 * Параметры отчета.
	 */
	static readonly REPORT_PARAMS = 'REPORT_PARAMS';

	/**
	 * В параметре передается массив информации о бумажных билетах мгновенных лотерей,
	 * продажу которых требуется зарегистрировать в ЦС.
	 */
	static readonly TICKETS_DATA = 'TICKETS_DATA';

	/**
	 * Максимальное количество тиражей в ответе.
	 */
	static readonly MAX_DRAW_COUNT = 'MAX_DRAW_COUNT';

	/**
	 * Верхнее ограничение по дате/времени розыгрыша для выборки тиражей.
	 * При отсутствии параметра в запросе предполагается текущая дата/время.
	 */
	static readonly DRAWING_DATE_MAX = 'DRAWING_DATE_MAX';

	/**
	 * Опциональное нижнее ограничение по дате/времени розыгрыша для выборки тиражей.
	 */
	static readonly DRAWING_DATE_MIN = 'DRAWING_DATE_MIN';

	/**
	 * Сумма пополнения счета игрока в копейках.
	 */
	static readonly PAY_SUM = 'PAY_SUM';

	/**
	 * Код квитанции для операции вывода денег в экшене {@link EsapActions.CheckCashOut CheckCashOut}
	 * и {@link EsapActions.CheckCashSms CheckCashSms}.
	 */
	static readonly CODE = 'CODE';

	/**
	 * Код подтверждения, введенный игроком из СМС в экшене {@link EsapActions.PayCash PayCash}.
	 */
	static readonly SMS_CODE = 'SMS_CODE';

	/**
	 * Идентификатор выплачиваемого бонуса.
	 * Соответствует параметру {@link IExtraWin.id id} из массива {@link WinCheckResp.extra_win extra_win}
	 * в ответе на запрос {@link EsapActions.WinCheck WinCheck}.
	 */
	static readonly BONUS_ID = 'BONUS_ID';

	/**
	 * Режим продаж лотерейных билетов.
	 * Возможные значения параметра:
	 * - 0 - обычная продажа;
	 * - 1 – продажа билетов из меню "быстрая продажа"
	 */
	static readonly SALE_MODE = 'SALE_MODE';

	/**
	 * Идентификатор отменяемого бонуса.
	 */
	static readonly CNL_BONUS_ID = 'CNL_BONUS_ID';

	/**
	 * Идентификатор пользователя, который обнаружен на пользовательском компьютере.
	 * Используется для отслеживания нескольких аккаунтов от одного и того же пользователя
	 */
	static readonly MULTIUSER_ID = 'MULTIUSER_ID';

	/**
	 * Код авторизации, который пользователь получил по альтернативному каналу двухфакторной авторизации
	 */
	static readonly AUTH2_SECRET = 'AUTH2_SECRET';

	/**
	 * Номер телефона игрока в формате 9 цифр
	 */
	static readonly PLAYER_PHONE = 'PLAYER_PHONE';

	/**
	 * Проверочный код игрока
	 */
	static readonly PLAYER_AUTH_CODE = 'PLAYER_AUTH_CODE';

	/**
	 * Для web-кассы - идентификатор авторизованного кассира
	 */
	static readonly CASHIER_ID = 'CASHIER_ID';

	/**
	 * Формат билета для web-альта
	 */
	static readonly TICKET_FORMAT = 'TICKET_FORMAT';

	/**
	 * Идентификатор документа в случае большого выигрыша (1 или 2)
	 */
	static readonly DOCUMENT_ID = 'DOCUMENT_ID';

	/**
	 * Параметры документа. Опциональный параметр. Для каждого типа документа набор параметров может отличаться
	 */
	static readonly DOCUMENT_PARAMS = 'DOCUMENT_PARAMS';

	static readonly MACCODE = 'MACCODE';

	/**
	 * Номер карты лояльности
	 */
	static readonly CARD_NUM = 'CARD_NUM';

	/**
	 * Номер карты лояльности
	 */
	static readonly CARD_NUMBER = 'CARD_NUMBER';

	/**
	 * Телефон пользователя
	 */
	static readonly PHONE = 'PHONE';

	static readonly PIN = 'PIN';

	static readonly BONUS_PAY_SUM = 'BONUS_PAY_SUM';

	static readonly TOTAL_BET_SUM = 'TOTAL_BET_SUM';

	static readonly TICKET_COUNT = 'TICKET_COUNT';

	static readonly LOTTERY_CODE = 'LOTTERY_CODE';

	static readonly BONUS_WITHDRAW_SUM = 'BONUS_WITHDRAW_SUM';
}

/**
 * Класс используется как хранилище ключей экшенов, значения которых читаются из конфигурации ЦС.
 * Хранит модель(схему) которая применяется на этапе запуска терминала и требует обязательное
 * наличие указанных в ней пар метров в конфигурационном файле полученном из ЦС.
 */
export class EsapActions {

	// -----------------------------
	//  Constants
	// -----------------------------
	/**
	 * Действие авторизации клиентского устройства.
	 */
	static readonly ClientAuth = 'ClientAuth';

	/**
	 * Действие аутентификации пользователя.
	 */
	static readonly BOAuthenticate = 'BOAuthenticate';

	/**
	 * Действие авторизации пользователя (второй шаг двухфакторной авторизации
	 */
	static readonly BOAuth2 = 'BOAuth2';

	/**
	 * Действие повторного запроса кода авторизации.
	 */
	static readonly ResendAuth2 = 'ResendAuth2';

	/**
	 * Действие по выбору точки продаж.
	 */
	static readonly SetPos = 'SetPos';

	/**
	 * Действие по отправке кода авторизации.
	 */
	static readonly SendAuthCode = 'SendAuthCode';

	/**
	 * Действие по смене пароля.
	 */
	static readonly BOChangePassword = 'BOChangePassword';

	/**
	 * Действие по получению идентификатора сессии.
	 */
	static readonly BOGetSID = 'BOGetSID';

	/**
	 * Действие по получению списка терминалов.
	 */
	static readonly BOGetClientList = 'BOGetClientList';

	/**
	 * Обновление информации о тиражах (сериях билетов).
	 * Операция обеспечивает синхронизацию параметров тиражей, хранимых на терминале с параметрами в ЦС
	 * с оптимизацией трафика между терминалом и ЦС (передаются только новые и измененные тиражи).
	 * Механизм синхронизации предусматривает наличие параметра {@link UpdateDrawInfoDraw.version} в каждом тираже,
	 * значение которого изменяется при любом изменении тиража в ЦС.
	 * Алгоритм синхронизации тиражей:
	 * - В запросе обновления терминал отправляет в ЦС информацию о параметрах тиражей, имеющихся на терминале,
	 * каждый тираж описывается кодом тиража и версией его параметров.
	 * - При обработке запроса ЦС составляет список тиражей, которые должны присутствовать на терминале.
	 * Этот список зависит от лотерей, назначенных на терминал и включают все тиражи по этим лотереям, доступные для приема ставок.
	 * - Подготовленный список тиражей и список, полученный от теримнала сравниваются. Те тиражи, которые есть на теримнале,
	 * но отсутствуют в подготовленном списке, заносятся в список удалемых тиражей.
	 * - Из подготовленного списка исключаются все тиражи, которые уже имеются на терминале, и для которых версия параметров
	 * на терминале равна версии параметров в ЦС
	 * - Формируется ответ на запрос, который включает все тиражи, оставшиеся в подготовленном списке.
	 * К ответу добавляется список удаляемых тиражей,
	 * при этом каждый такой тираж включает код тиража и параметр {@link UpdateDrawInfoDraw.remove}
	 * - Ответ передается на терминал.
	 * Для вызова сервиса используется модель {@link UpdateDrawInfoReq}.
	 * В ответе используется модель {@link UpdateDrawInfoResp}.
	 */
	static readonly UpdateDrawInfo = 'UpdateDrawInfo';

	/**
	 * Получение результатов по тиражу.
	 */
	static readonly	GetDrawResults = 'GetDrawResults';

	/**
	 * Действие по продаже лотереи "Мегалот".
	 */
	static readonly MegalotRegBet = 'MegalotRegBet';

	/**
	 * Регистрация продажи билетов лотереи "Лото-Забава".
	 * Используется в запросе {@link ZabavaRegBetReq}.
	 */
	static readonly ZabavaRegBet = 'ZabavaRegBet';

	/**
	 * Регистрация продажи билетов лотереи "Тип-Топ".
	 */
	static readonly TipTopRegBet = 'TipTopRegBet';

	/**
	 * Регистрация продажи билетов лотереи "ЭМЛ".
	 */
	static readonly EInstantRegBet = 'EInstantRegBet';

	/**
	 * Действие по получению картинки.
	 */
	static readonly GetImage = 'GetImage';

	/**
	 * Проверка выигрыша по билету.
	 */
	static readonly WinCheck = 'WinCheck';

	/**
	 * Действие по выплате выигрыша по билету.
	 */
	static readonly WinPay = 'WinPay';

	/**
	 * Регистрация выплаты бонуса (дополнительного выигрыша по билету).
	 */
	static readonly BonusPay = 'BonusPay';

	/**
	 * Отмена последней регистрации выплаты бонуса (дополнительного выигрыша).
	 */
	static readonly CancelBonus = 'CancelBonus';

	/**
	 * Действие по получению данных для отчета.
	 */
	static readonly GetReportData = 'GetReportData';

	/**
	 * Действие по передачи билетов моментальных лотерей с одного пользователя на другого.
	 */
	static readonly MoveML = 'MoveML';

	/**
	 * Действие по управлению ролями пользователей.
	 */
	static readonly BOManageUserRoles = 'BOManageUserRoles';

	/**
	 * Действие по продаже лотереи "Гонка".
	 */
	static readonly GonkaRegBet = 'GonkaRegBet';

	/**
	 * Регистрация продажи билетов лотереи "Спортпрогноз".
	 * Используется в запросе {@link SportPrognozRegBetReq}.
	 */
	static readonly SportPrognozRegBet = 'SportPrognozRegBet';

	/**
	 * Регистрация продажи билетов бумажных лотерей.
	 * Используется в запросе {@link PaperInstantRegBetReq}.
	 */
	static readonly PaperInstantRegBet = 'PaperInstantRegBet';

	/**
	 * Регистрация продажи билетов лотереи "Каре".
	 * Используется в запросе {@link KareRegBetReq}.
	 */
	static readonly KareRegBet = 'KareRegBet';

	/**
	 * Покупка ваучера на ТП.
	 * Обработчик регистрирует внесение средств в ЦС, создает и возвращает код активации.
	 */
	static readonly BuyVoucher = 'BuyVoucher';

	/**
	 * Проверка квитанции для выплаты на ТП.
	 * Обработчик проверяет по коду сосотояние квитанции и возвращает информацию о сумме заказанного вывода денег,
	 * разрешена ли выплата наэтом терминале, дату, до которой действительна квитанция, если выплата запрещена,
	 * то причину отказа (код и текстовое описание).
	 */
	static readonly CheckCashOut = 'CheckCashOut';

	/**
	 * Отправка СМС с кодом подтверждения.
	 * Обработчик отправляет СМС с кодом подтверждения вывода денег на телефон игрока (телефон ищется по номеру квитанции).
	 */
	static readonly CheckCashSms = 'CheckCashSms';

	/**
	 * Регистрация выплаты по квитанции на ТП.
	 * Обработчик проверяет по коду сосотояние квитанции, SMS код и сумму выплаты и регистрирует выплату.
	 */
	static readonly PayCash = 'PayCash';

	/**
	 * Запрос документа для его последующей печати или отображения на интерфейсе клиента
	 */
	static readonly GetDocument = 'GetDocument';

	/**
	 * Запрос информации о текущей смене распространителя.
	 */
	static readonly GetShiftInfo = 'GetShiftInfo';

	/**
	 * Список обязательных экшенов, без которых запуск приложения невозможен.
	 */
	static readonly general: Array<string> = [
		EsapActions.ClientAuth,
		EsapActions.BOAuthenticate,
		EsapActions.UpdateDrawInfo
		// ...
		// ...
		// EsapActions.BOManageUserRoles,
		// EsapActions.GetReportData,
		// EsapActions.MoveML
	];

	/**
	 * Массив конфигураций отчетов.
	 */
	static readonly reports: Array<IReports> = [];

	/**
	 * Массив ключей экшенов, которые невозможно отменить вручную.
	 */
	static readonly restrictedToCancelActions = [
		EsapActions.EInstantRegBet,
		EsapActions.PaperInstantRegBet,
		EsapActions.GonkaRegBet,
		EsapActions.KareRegBet
		// ...
		// ...
		// ...
	];

	/**
	 * Запрос информации о стоимости бланка и количества дополнительных комбинаций для последующей регистрации ставки и отображения информации на интерфейсе клиента
	 */
	static readonly GetBlankInfo = 'GetBlankInfo';

	static readonly GetBonusBalance = 'GetBonusBalance';

	static readonly SendPinCode = 'SendPinCode';

	static readonly VerifyPhone = 'VerifyPhone';

	static readonly GetUserByPhone = 'GetUserByPhone';

	static readonly AuthPhone = 'AuthPhone';

	static readonly AssignCard = 'AssignCard';

	static readonly GetCardUser = 'GetCardUser';

	static readonly GetCardInfo = 'GetCardInfo';

	static readonly GetAvailableBonus = 'GetAvailableBonus';

	static readonly GetWithdrawBonus = 'GetWithdrawBonus';

	static readonly WithdrawBonus = 'WithdrawBonus';

	static readonly WinPayToCard = 'WinPayToCard';

	static readonly GetPayedTicketInfo = 'GetPayedTicketInfo';
}
