<div class="win-cat-table" data-cy="win-cat-table">

	<!-- ШАПКА -->
	<div *ngIf="winCategoryList && winCategoryList.length > 0"
		 class="win-cat-table__row win-cat-table__row_header"
		 data-cy="win-cat-table-row"
	>
		<div class="win-cat-table__cell win-cat-table__cell_name win-cat-table__cell_header"
			 data-cy="win-cat-table-cell"
		>{{ 'lottery.category' | translate }}</div>
		<div class="win-cat-table__cell win-cat-table__cell_sum win-cat-table__cell_header"
			 data-cy="win-cat-table-cell"
		>{{ 'lottery.win' | translate }}</div>
	</div>

	<!-- ТАБЛИЦА -->
	<div *ngFor="let info of winCategoryList; trackBy: trackByWinCatFn"
		 class="win-cat-table__row"
		 data-cy="win-cat-table-row"
	>
		<div class="win-cat-table__cell win-cat-table__cell_name"
			 data-cy="win-cat-table-cell"
		>{{ info.name }}</div>
		<div class="win-cat-table__cell win-cat-table__cell_sum"
			 data-cy="win-cat-table-cell"
		>{{ info.win_sum | mslCurrency }}</div>
	</div>

</div>
